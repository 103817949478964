@charset "UTF-8";
/*-----------------------------------------------------------------------------------

    Theme Name: Canvas
    Theme URI: http://themes.semicolonweb.com/html/canvas
    Description: The Multi-Purpose Template
    Author: SemiColonWeb
    Author URI: http://themeforest.net/user/semicolonweb
    Version: 6.5.1

    SASS Stylesheet

-----------------------------------------------------------------------------------*/
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,300;0,400;0,700;1,400&family=PT+Serif:ital@0;1&family=Poppins:wght@300;400;500;600;700&display=swap");
/*!
 * Bootstrap v5.0.0 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-primary: #0d6efd;
  --bs-secondary: #6c757d;
  --bs-success: #198754;
  --bs-info: #0dcaf0;
  --bs-warning: #ffc107;
  --bs-danger: #dc3545;
  --bs-light: #f8f9fa;
  --bs-dark: #212529;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

hr {
  margin: 1rem 0;
  color: inherit;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-weight: 500;
  line-height: 1.2;
}

h1, .h1 {
  font-size: calc(1.375rem + 1.5vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.5rem;
  }
}

h2, .h2 {
  font-size: calc(1.325rem + 0.9vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 2rem;
  }
}

h3, .h3 {
  font-size: calc(1.3rem + 0.6vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.75rem;
  }
}

h4, .h4 {
  font-size: calc(1.275rem + 0.3vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.5rem;
  }
}

h5, .h5 {
  font-size: 1.25rem;
}

h6, .h6 {
  font-size: 1rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  text-decoration: underline dotted;
  cursor: help;
  text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}

blockquote {
  margin: 0 0 1rem;
}

b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #0d6efd;
  text-decoration: underline;
}
a:hover {
  color: #0a58ca;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.25rem;
  font-weight: 300;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 300;
  line-height: 1.2;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  margin-bottom: 1rem;
  font-size: 1.25rem;
}
.blockquote > :last-child {
  margin-bottom: 0;
}

.blockquote-footer {
  margin-top: -1rem;
  margin-bottom: 1rem;
  font-size: 0.875em;
  color: #6c757d;
}
.blockquote-footer::before {
  content: "— ";
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1320px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: flex;
  flex-wrap: wrap;
  margin-top: calc(var(--bs-gutter-y) * -1);
  margin-right: calc(var(--bs-gutter-x) / -2);
  margin-left: calc(var(--bs-gutter-x) / -2);
}
.row > * {
  flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) / 2);
  padding-left: calc(var(--bs-gutter-x) / 2);
  margin-top: var(--bs-gutter-y);
}

.col {
  flex: 1 0 0%;
}

.row-cols-auto > * {
  flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  flex: 0 0 auto;
  width: auto;
}

.col-1 {
  flex: 0 0 auto;
  width: 8.3333333333%;
}

.col-2 {
  flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-3 {
  flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  flex: 0 0 auto;
  width: 33.3333333333%;
}

.col-5 {
  flex: 0 0 auto;
  width: 41.6666666667%;
}

.col-6 {
  flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  flex: 0 0 auto;
  width: 58.3333333333%;
}

.col-8 {
  flex: 0 0 auto;
  width: 66.6666666667%;
}

.col-9 {
  flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  flex: 0 0 auto;
  width: 83.3333333333%;
}

.col-11 {
  flex: 0 0 auto;
  width: 91.6666666667%;
}

.col-12 {
  flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.3333333333%;
}

.offset-2 {
  margin-left: 16.6666666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.3333333333%;
}

.offset-5 {
  margin-left: 41.6666666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.3333333333%;
}

.offset-8 {
  margin-left: 66.6666666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.3333333333%;
}

.offset-11 {
  margin-left: 91.6666666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }

  .col-sm-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .col-sm-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }

  .col-sm-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }

  .col-sm-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }

  .col-sm-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }

  .col-sm-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }

  .col-sm-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.3333333333%;
  }

  .offset-sm-2 {
    margin-left: 16.6666666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.3333333333%;
  }

  .offset-sm-5 {
    margin-left: 41.6666666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.3333333333%;
  }

  .offset-sm-8 {
    margin-left: 66.6666666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.3333333333%;
  }

  .offset-sm-11 {
    margin-left: 91.6666666667%;
  }

  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }

  .col-md-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .col-md-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }

  .col-md-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }

  .col-md-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }

  .col-md-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }

  .col-md-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }

  .col-md-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.3333333333%;
  }

  .offset-md-2 {
    margin-left: 16.6666666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.3333333333%;
  }

  .offset-md-5 {
    margin-left: 41.6666666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.3333333333%;
  }

  .offset-md-8 {
    margin-left: 66.6666666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.3333333333%;
  }

  .offset-md-11 {
    margin-left: 91.6666666667%;
  }

  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }

  .col-lg-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .col-lg-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }

  .col-lg-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }

  .col-lg-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }

  .col-lg-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }

  .col-lg-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }

  .col-lg-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.3333333333%;
  }

  .offset-lg-2 {
    margin-left: 16.6666666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.3333333333%;
  }

  .offset-lg-5 {
    margin-left: 41.6666666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.3333333333%;
  }

  .offset-lg-8 {
    margin-left: 66.6666666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.3333333333%;
  }

  .offset-lg-11 {
    margin-left: 91.6666666667%;
  }

  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }

  .col-xl-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .col-xl-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }

  .col-xl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }

  .col-xl-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }

  .col-xl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }

  .col-xl-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }

  .col-xl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xl-11 {
    margin-left: 91.6666666667%;
  }

  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    flex: 1 0 0%;
  }

  .row-cols-xxl-auto > * {
    flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xxl-auto {
    flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    flex: 0 0 auto;
    width: 8.3333333333%;
  }

  .col-xxl-2 {
    flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xxl-3 {
    flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .col-xxl-5 {
    flex: 0 0 auto;
    width: 41.6666666667%;
  }

  .col-xxl-6 {
    flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    flex: 0 0 auto;
    width: 58.3333333333%;
  }

  .col-xxl-8 {
    flex: 0 0 auto;
    width: 66.6666666667%;
  }

  .col-xxl-9 {
    flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    flex: 0 0 auto;
    width: 83.3333333333%;
  }

  .col-xxl-11 {
    flex: 0 0 auto;
    width: 91.6666666667%;
  }

  .col-xxl-12 {
    flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.3333333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.6666666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.3333333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.6666666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.3333333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.6666666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.3333333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.6666666667%;
  }

  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.table {
  --bs-table-bg: transparent;
  --bs-table-striped-color: #212529;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #212529;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #212529;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  vertical-align: top;
  border-color: #dee2e6;
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}
.table > :not(:last-child) > :last-child > * {
  border-bottom-color: currentColor;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #cfe2ff;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bacbe6;
}

.table-secondary {
  --bs-table-bg: #e2e3e5;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #cbccce;
}

.table-success {
  --bs-table-bg: #d1e7dd;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bcd0c7;
}

.table-info {
  --bs-table-bg: #cff4fc;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #badce3;
}

.table-warning {
  --bs-table-bg: #fff3cd;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6dbb9;
}

.table-danger {
  --bs-table-bg: #f8d7da;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfc2c4;
}

.table-light {
  --bs-table-bg: #f8f9fa;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfe0e1;
}

.table-dark {
  --bs-table-bg: #212529;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #373b3e;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.375rem + 1px);
  padding-bottom: calc(0.375rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  font-size: 1.25rem;
}

.col-form-label-sm {
  padding-top: calc(0.25rem + 1px);
  padding-bottom: calc(0.25rem + 1px);
  font-size: 0.875rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  appearance: none;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not(:read-only) {
  cursor: pointer;
}
.form-control:focus {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::placeholder {
  color: #6c757d;
  opacity: 1;
}
.form-control:disabled, .form-control:read-only {
  background-color: #e9ecef;
  opacity: 1;
}
.form-control::file-selector-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::file-selector-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not(:read-only)::file-selector-button {
  background-color: #dde0e3;
}
.form-control::-webkit-file-upload-button {
  padding: 0.375rem 0.75rem;
  margin: -0.375rem -0.75rem;
  margin-inline-end: 0.75rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    transition: none;
  }
}
.form-control:hover:not(:disabled):not(:read-only)::-webkit-file-upload-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.375rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}
.form-control-sm::file-selector-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.25rem 0.5rem;
  margin: -0.25rem -0.5rem;
  margin-inline-end: 0.5rem;
}

.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}
.form-control-lg::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  margin-inline-end: 1rem;
}

textarea.form-control {
  min-height: calc(1.5em + 0.75rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.5em + 0.5rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.5em + 1rem + 2px);
}

.form-control-color {
  max-width: 3rem;
  height: auto;
  padding: 0.375rem;
}
.form-control-color:not(:disabled):not(:read-only) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}
.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0.25rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  appearance: none;
}
.form-select:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 0.75rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #e9ecef;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #212529;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  appearance: none;
  color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-check-input:checked {
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #0d6efd;
  border-color: #0d6efd;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #fff, 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #0d6efd;
  border: 0;
  border-radius: 1rem;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b6d4fe;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #adb5bd;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #adb5bd;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  padding: 1rem 0.75rem;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  transform-origin: 0 0;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    transition: none;
  }
}
.form-floating > .form-control::placeholder {
  color: transparent;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: flex;
  align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #198754;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #198754;
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #198754;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #198754;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #198754;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #dc3545;
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #dc3545;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 3;
}

.btn {
  display: inline-block;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  text-decoration: none;
  vertical-align: middle;
  cursor: pointer;
  user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  border-radius: 0.25rem;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    transition: none;
  }
}
.btn:hover {
  color: #212529;
}
.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}

.btn-primary {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0b5ed7;
  border-color: #0a58ca;
}
.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #fff;
  background-color: #0b5ed7;
  border-color: #0a58ca;
  box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
}
.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0a58ca;
  border-color: #0a53be;
}
.btn-check:checked + .btn-primary:focus, .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(49, 132, 253, 0.5);
}
.btn-primary:disabled, .btn-primary.disabled {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}

.btn-secondary {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #5c636a;
  border-color: #565e64;
}
.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #fff;
  background-color: #5c636a;
  border-color: #565e64;
  box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
}
.btn-check:checked + .btn-secondary, .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #565e64;
  border-color: #51585e;
}
.btn-check:checked + .btn-secondary:focus, .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(130, 138, 145, 0.5);
}
.btn-secondary:disabled, .btn-secondary.disabled {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}

.btn-success {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}
.btn-success:hover {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
}
.btn-check:focus + .btn-success, .btn-success:focus {
  color: #fff;
  background-color: #157347;
  border-color: #146c43;
  box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
}
.btn-check:checked + .btn-success, .btn-check:active + .btn-success, .btn-success:active, .btn-success.active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #146c43;
  border-color: #13653f;
}
.btn-check:checked + .btn-success:focus, .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus, .show > .btn-success.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(60, 153, 110, 0.5);
}
.btn-success:disabled, .btn-success.disabled {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}

.btn-info {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-info:hover {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
}
.btn-check:focus + .btn-info, .btn-info:focus {
  color: #000;
  background-color: #31d2f2;
  border-color: #25cff2;
  box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}
.btn-check:checked + .btn-info, .btn-check:active + .btn-info, .btn-info:active, .btn-info.active, .show > .btn-info.dropdown-toggle {
  color: #000;
  background-color: #3dd5f3;
  border-color: #25cff2;
}
.btn-check:checked + .btn-info:focus, .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus, .show > .btn-info.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(11, 172, 204, 0.5);
}
.btn-info:disabled, .btn-info.disabled {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}

.btn-warning {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-warning:hover {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
}
.btn-check:focus + .btn-warning, .btn-warning:focus {
  color: #000;
  background-color: #ffca2c;
  border-color: #ffc720;
  box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
}
.btn-check:checked + .btn-warning, .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active, .show > .btn-warning.dropdown-toggle {
  color: #000;
  background-color: #ffcd39;
  border-color: #ffc720;
}
.btn-check:checked + .btn-warning:focus, .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus, .show > .btn-warning.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(217, 164, 6, 0.5);
}
.btn-warning:disabled, .btn-warning.disabled {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}

.btn-danger {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-danger:hover {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
}
.btn-check:focus + .btn-danger, .btn-danger:focus {
  color: #fff;
  background-color: #bb2d3b;
  border-color: #b02a37;
  box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}
.btn-check:checked + .btn-danger, .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #b02a37;
  border-color: #a52834;
}
.btn-check:checked + .btn-danger:focus, .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(225, 83, 97, 0.5);
}
.btn-danger:disabled, .btn-danger.disabled {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}

.btn-light {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-light:hover {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
}
.btn-check:focus + .btn-light, .btn-light:focus {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
  box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
}
.btn-check:checked + .btn-light, .btn-check:active + .btn-light, .btn-light:active, .btn-light.active, .show > .btn-light.dropdown-toggle {
  color: #000;
  background-color: #f9fafb;
  border-color: #f9fafb;
}
.btn-check:checked + .btn-light:focus, .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus, .show > .btn-light.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(211, 212, 213, 0.5);
}
.btn-light:disabled, .btn-light.disabled {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}

.btn-dark {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-dark:hover {
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21;
}
.btn-check:focus + .btn-dark, .btn-dark:focus {
  color: #fff;
  background-color: #1c1f23;
  border-color: #1a1e21;
  box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
}
.btn-check:checked + .btn-dark, .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #1a1e21;
  border-color: #191c1f;
}
.btn-check:checked + .btn-dark:focus, .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus, .show > .btn-dark.dropdown-toggle:focus {
  box-shadow: 0 0 0 0.25rem rgba(66, 70, 73, 0.5);
}
.btn-dark:disabled, .btn-dark.disabled {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}

.btn-outline-primary {
  color: #0d6efd;
  border-color: #0d6efd;
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
}
.btn-check:checked + .btn-outline-primary, .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.btn-check:checked + .btn-outline-primary:focus, .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.5);
}
.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: #0d6efd;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #6c757d;
  border-color: #6c757d;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
}
.btn-check:checked + .btn-outline-secondary, .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
  color: #fff;
  background-color: #6c757d;
  border-color: #6c757d;
}
.btn-check:checked + .btn-outline-secondary:focus, .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(108, 117, 125, 0.5);
}
.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
  color: #6c757d;
  background-color: transparent;
}

.btn-outline-success {
  color: #198754;
  border-color: #198754;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}
.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
}
.btn-check:checked + .btn-outline-success, .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
  color: #fff;
  background-color: #198754;
  border-color: #198754;
}
.btn-check:checked + .btn-outline-success:focus, .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(25, 135, 84, 0.5);
}
.btn-outline-success:disabled, .btn-outline-success.disabled {
  color: #198754;
  background-color: transparent;
}

.btn-outline-info {
  color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-outline-info:hover {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}
.btn-check:checked + .btn-outline-info, .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
  color: #000;
  background-color: #0dcaf0;
  border-color: #0dcaf0;
}
.btn-check:checked + .btn-outline-info:focus, .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(13, 202, 240, 0.5);
}
.btn-outline-info:disabled, .btn-outline-info.disabled {
  color: #0dcaf0;
  background-color: transparent;
}

.btn-outline-warning {
  color: #ffc107;
  border-color: #ffc107;
}
.btn-outline-warning:hover {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}
.btn-check:checked + .btn-outline-warning, .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
  color: #000;
  background-color: #ffc107;
  border-color: #ffc107;
}
.btn-check:checked + .btn-outline-warning:focus, .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(255, 193, 7, 0.5);
}
.btn-outline-warning:disabled, .btn-outline-warning.disabled {
  color: #ffc107;
  background-color: transparent;
}

.btn-outline-danger {
  color: #dc3545;
  border-color: #dc3545;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}
.btn-check:checked + .btn-outline-danger, .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
  color: #fff;
  background-color: #dc3545;
  border-color: #dc3545;
}
.btn-check:checked + .btn-outline-danger:focus, .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(220, 53, 69, 0.5);
}
.btn-outline-danger:disabled, .btn-outline-danger.disabled {
  color: #dc3545;
  background-color: transparent;
}

.btn-outline-light {
  color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-outline-light:hover {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}
.btn-check:checked + .btn-outline-light, .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
  color: #000;
  background-color: #f8f9fa;
  border-color: #f8f9fa;
}
.btn-check:checked + .btn-outline-light:focus, .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(248, 249, 250, 0.5);
}
.btn-outline-light:disabled, .btn-outline-light.disabled {
  color: #f8f9fa;
  background-color: transparent;
}

.btn-outline-dark {
  color: #212529;
  border-color: #212529;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
}
.btn-check:checked + .btn-outline-dark, .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #212529;
  border-color: #212529;
}
.btn-check:checked + .btn-outline-dark:focus, .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0.25rem rgba(33, 37, 41, 0.5);
}
.btn-outline-dark:disabled, .btn-outline-dark.disabled {
  color: #212529;
  background-color: transparent;
}

.btn-link {
  font-weight: 400;
  color: #0d6efd;
  text-decoration: underline;
}
.btn-link:hover {
  color: #0a58ca;
}
.btn-link:disabled, .btn-link.disabled {
  color: #6c757d;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.5rem 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.fade {
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 10rem;
  padding: 0.5rem 0;
  margin: 0;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0.125rem;
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto /* rtl:ignore */;
  left: 0 /* rtl:ignore */;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0 /* rtl:ignore */;
  left: auto /* rtl:ignore */;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto /* rtl:ignore */;
    left: 0 /* rtl:ignore */;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0 /* rtl:ignore */;
    left: auto /* rtl:ignore */;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0.125rem;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0.125rem;
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0.125rem;
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.15);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.25rem 1rem;
  clear: both;
  font-weight: 400;
  color: #212529;
  text-align: inherit;
  text-decoration: none;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #1e2125;
  background-color: #e9ecef;
}
.dropdown-item.active, .dropdown-item:active {
  color: #fff;
  text-decoration: none;
  background-color: #0d6efd;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #adb5bd;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.875rem;
  color: #6c757d;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.25rem 1rem;
  color: #212529;
}

.dropdown-menu-dark {
  color: #dee2e6;
  background-color: #343a40;
  border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item {
  color: #dee2e6;
}
.dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
  color: #fff;
  background-color: rgba(255, 255, 255, 0.15);
}
.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: #fff;
  background-color: #0d6efd;
}
.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #adb5bd;
}
.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(0, 0, 0, 0.15);
}
.dropdown-menu-dark .dropdown-item-text {
  color: #dee2e6;
}
.dropdown-menu-dark .dropdown-header {
  color: #adb5bd;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.5625rem;
  padding-left: 0.5625rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.375rem;
  padding-left: 0.375rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.btn-group-vertical {
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: flex;
  flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.5rem 1rem;
  color: #0d6efd;
  text-decoration: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: #0a58ca;
}
.nav-link.disabled {
  color: #6c757d;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid #dee2e6;
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: #e9ecef #e9ecef #dee2e6;
  isolation: isolate;
}
.nav-tabs .nav-link.disabled {
  color: #6c757d;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #495057;
  background-color: #fff;
  border-color: #dee2e6 #dee2e6 #fff;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.25rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #0d6efd;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: flex;
  flex-wrap: inherit;
  align-items: center;
  justify-content: space-between;
}
.navbar-brand {
  padding-top: 0.3125rem;
  padding-bottom: 0.3125rem;
  margin-right: 1rem;
  font-size: 1.25rem;
  text-decoration: none;
  white-space: nowrap;
}
.navbar-nav {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.navbar-collapse {
  flex-basis: 100%;
  flex-grow: 1;
  align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0.75rem;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  box-shadow: 0 0 0 0.25rem;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    flex-wrap: nowrap;
    justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 0.5rem;
    padding-left: 0.5rem;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: flex !important;
    flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
}
.navbar-expand {
  flex-wrap: nowrap;
  justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: flex !important;
  flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: rgba(0, 0, 0, 0.7);
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-toggler {
  color: rgba(0, 0, 0, 0.55);
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: rgba(0, 0, 0, 0.55);
}
.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: rgba(0, 0, 0, 0.9);
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: rgba(255, 255, 255, 0.75);
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #fff;
}
.navbar-dark .navbar-toggler {
  color: rgba(255, 255, 255, 0.55);
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%28255, 255, 255, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: rgba(255, 255, 255, 0.55);
}
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #fff;
}

.card {
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.125);
  border-radius: 0.25rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  flex: 1 1 auto;
  padding: 1rem 1rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link:hover {
  text-decoration: none;
}
.card-link + .card-link {
  margin-left: 1rem;
}

.card-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  background-color: rgba(0, 0, 0, 0.03);
  border-bottom: 1px solid rgba(0, 0, 0, 0.125);
}
.card-header:first-child {
  border-radius: calc(0.25rem - 1px) calc(0.25rem - 1px) 0 0;
}

.card-footer {
  padding: 0.5rem 1rem;
  background-color: rgba(0, 0, 0, 0.03);
  border-top: 1px solid rgba(0, 0, 0, 0.125);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.25rem - 1px) calc(0.25rem - 1px);
}

.card-header-tabs {
  margin-right: -0.5rem;
  margin-bottom: -0.5rem;
  margin-left: -0.5rem;
  border-bottom: 0;
}

.card-header-pills {
  margin-right: -0.5rem;
  margin-left: -0.5rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1rem;
  border-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}

.card-group > .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-group {
    display: flex;
    flex-flow: row wrap;
  }
  .card-group > .card {
    flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 1rem;
  color: #212529;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: #0c63e4;
  background-color: #e7f1ff;
  box-shadow: inset 0 -1px 0 #dddddd;
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  transform: rotate(-180deg);
}
.accordion-button::after {
  flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  transition: transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: #fff;
  border: 1px solid #dddddd;
}
.accordion-item:first-of-type {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.breadcrumb {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0;
  margin-bottom: 1rem;
  list-style: none;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.5rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  padding-right: 0.5rem;
  color: #6c757d;
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: #6c757d;
}

.pagination {
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #0d6efd;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid #dee2e6;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: #0a58ca;
  background-color: #e9ecef;
  border-color: #dee2e6;
}
.page-link:focus {
  z-index: 3;
  color: #0a58ca;
  background-color: #e9ecef;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.page-item.disabled .page-link {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
  border-color: #dee2e6;
}

.page-link {
  padding: 0.375rem 0.75rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.25rem;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.875rem;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.75em;
  font-weight: 700;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  color: #084298;
  background-color: #cfe2ff;
  border-color: #b6d4fe;
}
.alert-primary .alert-link {
  color: #06357a;
}

.alert-secondary {
  color: #41464b;
  background-color: #e2e3e5;
  border-color: #d3d6d8;
}
.alert-secondary .alert-link {
  color: #34383c;
}

.alert-success {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}
.alert-success .alert-link {
  color: #0c4128;
}

.alert-info {
  color: #055160;
  background-color: #cff4fc;
  border-color: #b6effb;
}
.alert-info .alert-link {
  color: #04414d;
}

.alert-warning {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
}
.alert-warning .alert-link {
  color: #523e02;
}

.alert-danger {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}
.alert-danger .alert-link {
  color: #6a1a21;
}

.alert-light {
  color: #636464;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.alert-light .alert-link {
  color: #4f5050;
}

.alert-dark {
  color: #141619;
  background-color: #d3d3d4;
  border-color: #bcbebf;
}
.alert-dark .alert-link {
  color: #101214;
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: #e9ecef;
  border-radius: 0.25rem;
}

.progress-bar {
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #0d6efd;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    animation: none;
  }
}

.list-group {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #f8f9fa;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #212529;
  text-decoration: none;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #0d6efd;
  border-color: #0d6efd;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.25rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.25rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.25rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.25rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #084298;
  background-color: #cfe2ff;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #084298;
  background-color: #bacbe6;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #084298;
  border-color: #084298;
}

.list-group-item-secondary {
  color: #41464b;
  background-color: #e2e3e5;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #41464b;
  background-color: #cbccce;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #41464b;
  border-color: #41464b;
}

.list-group-item-success {
  color: #0f5132;
  background-color: #d1e7dd;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #0f5132;
  background-color: #bcd0c7;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #0f5132;
  border-color: #0f5132;
}

.list-group-item-info {
  color: #055160;
  background-color: #cff4fc;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #055160;
  background-color: #badce3;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #055160;
  border-color: #055160;
}

.list-group-item-warning {
  color: #664d03;
  background-color: #fff3cd;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #664d03;
  background-color: #e6dbb9;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #664d03;
  border-color: #664d03;
}

.list-group-item-danger {
  color: #842029;
  background-color: #f8d7da;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #842029;
  background-color: #dfc2c4;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #842029;
  border-color: #842029;
}

.list-group-item-light {
  color: #636464;
  background-color: #fefefe;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #636464;
  background-color: #e5e5e5;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #636464;
  border-color: #636464;
}

.list-group-item-dark {
  color: #141619;
  background-color: #d3d3d4;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #141619;
  background-color: #bebebf;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #141619;
  border-color: #141619;
}

.btn-close {
  box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.25rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.toast:not(.showing):not(.show) {
  opacity: 0;
}
.toast.hide {
  display: none;
}

.toast-container {
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast-header {
  display: flex;
  align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word;
}

.modal-open {
  overflow: hidden;
}
.modal-open .modal {
  overflow-x: hidden;
  overflow-y: auto;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1060;
  display: none;
  width: 100%;
  height: 100%;
  overflow: hidden;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  transition: transform 0.3s ease-out;
  transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    transition: none;
  }
}
.modal.show .modal-dialog {
  transform: none;
}
.modal.modal-static .modal-dialog {
  transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: flex;
  align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: flex;
  flex-wrap: wrap;
  flex-shrink: 0;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
}
.modal-footer > * {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}
.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: var(--bs-font-sans-serif);
  font-style: normal;
  font-weight: 400;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 1rem;
  background-color: #f0f0f0;
  border-bottom: 1px solid #d8d8d8;
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  backface-visibility: hidden;
  transition: transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  transition-property: opacity;
  transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  box-sizing: content-box;
  flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

@keyframes spinner-border {
  to {
    transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@keyframes spinner-grow {
  0% {
    transform: scale(0);
  }
  50% {
    opacity: 1;
    transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    animation-duration: 1.5s;
  }
}
.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1050;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  transition: transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    transition: none;
  }
}

.offcanvas-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1rem 1rem;
}
.offcanvas-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  flex-grow: 1;
  padding: 1rem 1rem;
  overflow-y: auto;
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(-100%);
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateX(100%);
}

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(-100%);
}

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
  transform: translateY(100%);
}

.offcanvas.show {
  transform: none;
}

.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #0d6efd;
}
.link-primary:hover, .link-primary:focus {
  color: #0a58ca;
}

.link-secondary {
  color: #6c757d;
}
.link-secondary:hover, .link-secondary:focus {
  color: #565e64;
}

.link-success {
  color: #198754;
}
.link-success:hover, .link-success:focus {
  color: #146c43;
}

.link-info {
  color: #0dcaf0;
}
.link-info:hover, .link-info:focus {
  color: #3dd5f3;
}

.link-warning {
  color: #ffc107;
}
.link-warning:hover, .link-warning:focus {
  color: #ffcd39;
}

.link-danger {
  color: #dc3545;
}
.link-danger:hover, .link-danger:focus {
  color: #b02a37;
}

.link-light {
  color: #f8f9fa;
}
.link-light:hover, .link-light:focus {
  color: #f9fafb;
}

.link-dark {
  color: #212529;
}
.link-dark:hover, .link-dark:focus {
  color: #1a1e21;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: calc(3 / 4 * 100%);
}

.ratio-16x9 {
  --bs-aspect-ratio: calc(9 / 16 * 100%);
}

.ratio-21x9 {
  --bs-aspect-ratio: calc(9 / 21 * 100%);
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 1030;
}

.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: flex !important;
}

.d-inline-flex {
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}

.shadow {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.shadow-sm {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.shadow-lg {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.shadow-none {
  box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  transform: translateX(-50%) !important;
}

.translate-middle-y {
  transform: translateY(-50%) !important;
}

.border {
  border: 1px solid #dee2e6 !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid #dee2e6 !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid #dee2e6 !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid #dee2e6 !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid #dee2e6 !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #0d6efd !important;
}

.border-secondary {
  border-color: #6c757d !important;
}

.border-success {
  border-color: #198754 !important;
}

.border-info {
  border-color: #0dcaf0 !important;
}

.border-warning {
  border-color: #ffc107 !important;
}

.border-danger {
  border-color: #dc3545 !important;
}

.border-light {
  border-color: #f8f9fa !important;
}

.border-dark {
  border-color: #212529 !important;
}

.border-white {
  border-color: #fff !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  flex: 1 1 auto !important;
}

.flex-row {
  flex-direction: row !important;
}

.flex-column {
  flex-direction: column !important;
}

.flex-row-reverse {
  flex-direction: row-reverse !important;
}

.flex-column-reverse {
  flex-direction: column-reverse !important;
}

.flex-grow-0 {
  flex-grow: 0 !important;
}

.flex-grow-1 {
  flex-grow: 1 !important;
}

.flex-shrink-0 {
  flex-shrink: 0 !important;
}

.flex-shrink-1 {
  flex-shrink: 1 !important;
}

.flex-wrap {
  flex-wrap: wrap !important;
}

.flex-nowrap {
  flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.gap-6 {
  gap: 5rem !important;
}

.justify-content-start {
  justify-content: flex-start !important;
}

.justify-content-end {
  justify-content: flex-end !important;
}

.justify-content-center {
  justify-content: center !important;
}

.justify-content-between {
  justify-content: space-between !important;
}

.justify-content-around {
  justify-content: space-around !important;
}

.justify-content-evenly {
  justify-content: space-evenly !important;
}

.align-items-start {
  align-items: flex-start !important;
}

.align-items-end {
  align-items: flex-end !important;
}

.align-items-center {
  align-items: center !important;
}

.align-items-baseline {
  align-items: baseline !important;
}

.align-items-stretch {
  align-items: stretch !important;
}

.align-content-start {
  align-content: flex-start !important;
}

.align-content-end {
  align-content: flex-end !important;
}

.align-content-center {
  align-content: center !important;
}

.align-content-between {
  align-content: space-between !important;
}

.align-content-around {
  align-content: space-around !important;
}

.align-content-stretch {
  align-content: stretch !important;
}

.align-self-auto {
  align-self: auto !important;
}

.align-self-start {
  align-self: flex-start !important;
}

.align-self-end {
  align-self: flex-end !important;
}

.align-self-center {
  align-self: center !important;
}

.align-self-baseline {
  align-self: baseline !important;
}

.align-self-stretch {
  align-self: stretch !important;
}

.order-first {
  order: -1 !important;
}

.order-0 {
  order: 0 !important;
}

.order-1 {
  order: 1 !important;
}

.order-2 {
  order: 2 !important;
}

.order-3 {
  order: 3 !important;
}

.order-4 {
  order: 4 !important;
}

.order-5 {
  order: 5 !important;
}

.order-last {
  order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-6 {
  margin: 5rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-6 {
  margin-right: 5rem !important;
  margin-left: 5rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-6 {
  margin-top: 5rem !important;
  margin-bottom: 5rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-6 {
  margin-top: 5rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-6 {
  margin-right: 5rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-6 {
  margin-bottom: 5rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-6 {
  margin-left: 5rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.p-6 {
  padding: 5rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.px-6 {
  padding-right: 5rem !important;
  padding-left: 5rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-6 {
  padding-top: 5rem !important;
  padding-bottom: 5rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pt-6 {
  padding-top: 5rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pe-6 {
  padding-right: 5rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.pb-6 {
  padding-bottom: 5rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.ps-6 {
  padding-left: 5rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.375rem + 1.5vw) !important;
}

.fs-2 {
  font-size: calc(1.325rem + 0.9vw) !important;
}

.fs-3 {
  font-size: calc(1.3rem + 0.6vw) !important;
}

.fs-4 {
  font-size: calc(1.275rem + 0.3vw) !important;
}

.fs-5 {
  font-size: 1.25rem !important;
}

.fs-6 {
  font-size: 1rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 300 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 400 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-initial {
  text-transform: initial !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  color: #0d6efd !important;
}

.text-secondary {
  color: #6c757d !important;
}

.text-success {
  color: #198754 !important;
}

.text-info {
  color: #0dcaf0 !important;
}

.text-warning {
  color: #ffc107 !important;
}

.text-danger {
  color: #dc3545 !important;
}

.text-light {
  color: #f8f9fa !important;
}

.text-dark {
  color: #212529 !important;
}

.text-white {
  color: #fff !important;
}

.text-body {
  color: #212529 !important;
}

.text-muted {
  color: #6c757d !important;
}

.text-black-50 {
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  color: inherit !important;
}

.bg-primary {
  background-color: #0d6efd !important;
}

.bg-secondary {
  background-color: #6c757d !important;
}

.bg-success {
  background-color: #198754 !important;
}

.bg-info {
  background-color: #0dcaf0 !important;
}

.bg-warning {
  background-color: #ffc107 !important;
}

.bg-danger {
  background-color: #dc3545 !important;
}

.bg-light {
  background-color: #f8f9fa !important;
}

.bg-dark {
  background-color: #212529 !important;
}

.bg-body {
  background-color: #fff !important;
}

.bg-white {
  background-color: #fff !important;
}

.bg-transparent {
  background-color: transparent !important;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  user-select: all !important;
}

.user-select-auto {
  user-select: auto !important;
}

.user-select-none {
  user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.25rem !important;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.2rem !important;
}

.rounded-2 {
  border-radius: 0.25rem !important;
}

.rounded-3 {
  border-radius: 0.3rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0.25rem !important;
  border-top-right-radius: 0.25rem !important;
}

.rounded-end {
  border-top-right-radius: 0.25rem !important;
  border-bottom-right-radius: 0.25rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.25rem !important;
  border-bottom-left-radius: 0.25rem !important;
}

.rounded-start {
  border-bottom-left-radius: 0.25rem !important;
  border-top-left-radius: 0.25rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    flex: 1 1 auto !important;
  }

  .flex-sm-row {
    flex-direction: row !important;
  }

  .flex-sm-column {
    flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .gap-sm-6 {
    gap: 5rem !important;
  }

  .justify-content-sm-start {
    justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    justify-content: center !important;
  }

  .justify-content-sm-between {
    justify-content: space-between !important;
  }

  .justify-content-sm-around {
    justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    align-items: flex-start !important;
  }

  .align-items-sm-end {
    align-items: flex-end !important;
  }

  .align-items-sm-center {
    align-items: center !important;
  }

  .align-items-sm-baseline {
    align-items: baseline !important;
  }

  .align-items-sm-stretch {
    align-items: stretch !important;
  }

  .align-content-sm-start {
    align-content: flex-start !important;
  }

  .align-content-sm-end {
    align-content: flex-end !important;
  }

  .align-content-sm-center {
    align-content: center !important;
  }

  .align-content-sm-between {
    align-content: space-between !important;
  }

  .align-content-sm-around {
    align-content: space-around !important;
  }

  .align-content-sm-stretch {
    align-content: stretch !important;
  }

  .align-self-sm-auto {
    align-self: auto !important;
  }

  .align-self-sm-start {
    align-self: flex-start !important;
  }

  .align-self-sm-end {
    align-self: flex-end !important;
  }

  .align-self-sm-center {
    align-self: center !important;
  }

  .align-self-sm-baseline {
    align-self: baseline !important;
  }

  .align-self-sm-stretch {
    align-self: stretch !important;
  }

  .order-sm-first {
    order: -1 !important;
  }

  .order-sm-0 {
    order: 0 !important;
  }

  .order-sm-1 {
    order: 1 !important;
  }

  .order-sm-2 {
    order: 2 !important;
  }

  .order-sm-3 {
    order: 3 !important;
  }

  .order-sm-4 {
    order: 4 !important;
  }

  .order-sm-5 {
    order: 5 !important;
  }

  .order-sm-last {
    order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-6 {
    margin: 5rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-6 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-6 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-6 {
    margin-top: 5rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-6 {
    margin-right: 5rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-6 {
    margin-bottom: 5rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-6 {
    margin-left: 5rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .p-sm-6 {
    padding: 5rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-sm-6 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-sm-6 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pt-sm-6 {
    padding-top: 5rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pe-sm-6 {
    padding-right: 5rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .pb-sm-6 {
    padding-bottom: 5rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .ps-sm-6 {
    padding-left: 5rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: flex !important;
  }

  .d-md-inline-flex {
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    flex: 1 1 auto !important;
  }

  .flex-md-row {
    flex-direction: row !important;
  }

  .flex-md-column {
    flex-direction: column !important;
  }

  .flex-md-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .gap-md-6 {
    gap: 5rem !important;
  }

  .justify-content-md-start {
    justify-content: flex-start !important;
  }

  .justify-content-md-end {
    justify-content: flex-end !important;
  }

  .justify-content-md-center {
    justify-content: center !important;
  }

  .justify-content-md-between {
    justify-content: space-between !important;
  }

  .justify-content-md-around {
    justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-md-start {
    align-items: flex-start !important;
  }

  .align-items-md-end {
    align-items: flex-end !important;
  }

  .align-items-md-center {
    align-items: center !important;
  }

  .align-items-md-baseline {
    align-items: baseline !important;
  }

  .align-items-md-stretch {
    align-items: stretch !important;
  }

  .align-content-md-start {
    align-content: flex-start !important;
  }

  .align-content-md-end {
    align-content: flex-end !important;
  }

  .align-content-md-center {
    align-content: center !important;
  }

  .align-content-md-between {
    align-content: space-between !important;
  }

  .align-content-md-around {
    align-content: space-around !important;
  }

  .align-content-md-stretch {
    align-content: stretch !important;
  }

  .align-self-md-auto {
    align-self: auto !important;
  }

  .align-self-md-start {
    align-self: flex-start !important;
  }

  .align-self-md-end {
    align-self: flex-end !important;
  }

  .align-self-md-center {
    align-self: center !important;
  }

  .align-self-md-baseline {
    align-self: baseline !important;
  }

  .align-self-md-stretch {
    align-self: stretch !important;
  }

  .order-md-first {
    order: -1 !important;
  }

  .order-md-0 {
    order: 0 !important;
  }

  .order-md-1 {
    order: 1 !important;
  }

  .order-md-2 {
    order: 2 !important;
  }

  .order-md-3 {
    order: 3 !important;
  }

  .order-md-4 {
    order: 4 !important;
  }

  .order-md-5 {
    order: 5 !important;
  }

  .order-md-last {
    order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-6 {
    margin: 5rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-6 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-6 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-6 {
    margin-top: 5rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-6 {
    margin-right: 5rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-6 {
    margin-bottom: 5rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-6 {
    margin-left: 5rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .p-md-6 {
    padding: 5rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-md-6 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-md-6 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pt-md-6 {
    padding-top: 5rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pe-md-6 {
    padding-right: 5rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .pb-md-6 {
    padding-bottom: 5rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }

  .ps-md-6 {
    padding-left: 5rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    flex: 1 1 auto !important;
  }

  .flex-lg-row {
    flex-direction: row !important;
  }

  .flex-lg-column {
    flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .gap-lg-6 {
    gap: 5rem !important;
  }

  .justify-content-lg-start {
    justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    justify-content: center !important;
  }

  .justify-content-lg-between {
    justify-content: space-between !important;
  }

  .justify-content-lg-around {
    justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    align-items: flex-start !important;
  }

  .align-items-lg-end {
    align-items: flex-end !important;
  }

  .align-items-lg-center {
    align-items: center !important;
  }

  .align-items-lg-baseline {
    align-items: baseline !important;
  }

  .align-items-lg-stretch {
    align-items: stretch !important;
  }

  .align-content-lg-start {
    align-content: flex-start !important;
  }

  .align-content-lg-end {
    align-content: flex-end !important;
  }

  .align-content-lg-center {
    align-content: center !important;
  }

  .align-content-lg-between {
    align-content: space-between !important;
  }

  .align-content-lg-around {
    align-content: space-around !important;
  }

  .align-content-lg-stretch {
    align-content: stretch !important;
  }

  .align-self-lg-auto {
    align-self: auto !important;
  }

  .align-self-lg-start {
    align-self: flex-start !important;
  }

  .align-self-lg-end {
    align-self: flex-end !important;
  }

  .align-self-lg-center {
    align-self: center !important;
  }

  .align-self-lg-baseline {
    align-self: baseline !important;
  }

  .align-self-lg-stretch {
    align-self: stretch !important;
  }

  .order-lg-first {
    order: -1 !important;
  }

  .order-lg-0 {
    order: 0 !important;
  }

  .order-lg-1 {
    order: 1 !important;
  }

  .order-lg-2 {
    order: 2 !important;
  }

  .order-lg-3 {
    order: 3 !important;
  }

  .order-lg-4 {
    order: 4 !important;
  }

  .order-lg-5 {
    order: 5 !important;
  }

  .order-lg-last {
    order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-6 {
    margin: 5rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-6 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-6 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-6 {
    margin-top: 5rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-6 {
    margin-right: 5rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-6 {
    margin-bottom: 5rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-6 {
    margin-left: 5rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .p-lg-6 {
    padding: 5rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-lg-6 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-lg-6 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pt-lg-6 {
    padding-top: 5rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pe-lg-6 {
    padding-right: 5rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .pb-lg-6 {
    padding-bottom: 5rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .ps-lg-6 {
    padding-left: 5rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xl-row {
    flex-direction: row !important;
  }

  .flex-xl-column {
    flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .gap-xl-6 {
    gap: 5rem !important;
  }

  .justify-content-xl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    justify-content: center !important;
  }

  .justify-content-xl-between {
    justify-content: space-between !important;
  }

  .justify-content-xl-around {
    justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    align-items: flex-start !important;
  }

  .align-items-xl-end {
    align-items: flex-end !important;
  }

  .align-items-xl-center {
    align-items: center !important;
  }

  .align-items-xl-baseline {
    align-items: baseline !important;
  }

  .align-items-xl-stretch {
    align-items: stretch !important;
  }

  .align-content-xl-start {
    align-content: flex-start !important;
  }

  .align-content-xl-end {
    align-content: flex-end !important;
  }

  .align-content-xl-center {
    align-content: center !important;
  }

  .align-content-xl-between {
    align-content: space-between !important;
  }

  .align-content-xl-around {
    align-content: space-around !important;
  }

  .align-content-xl-stretch {
    align-content: stretch !important;
  }

  .align-self-xl-auto {
    align-self: auto !important;
  }

  .align-self-xl-start {
    align-self: flex-start !important;
  }

  .align-self-xl-end {
    align-self: flex-end !important;
  }

  .align-self-xl-center {
    align-self: center !important;
  }

  .align-self-xl-baseline {
    align-self: baseline !important;
  }

  .align-self-xl-stretch {
    align-self: stretch !important;
  }

  .order-xl-first {
    order: -1 !important;
  }

  .order-xl-0 {
    order: 0 !important;
  }

  .order-xl-1 {
    order: 1 !important;
  }

  .order-xl-2 {
    order: 2 !important;
  }

  .order-xl-3 {
    order: 3 !important;
  }

  .order-xl-4 {
    order: 4 !important;
  }

  .order-xl-5 {
    order: 5 !important;
  }

  .order-xl-last {
    order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-6 {
    margin: 5rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-6 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-6 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-6 {
    margin-top: 5rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-6 {
    margin-right: 5rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-6 {
    margin-bottom: 5rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-6 {
    margin-left: 5rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .p-xl-6 {
    padding: 5rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-xl-6 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xl-6 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pt-xl-6 {
    padding-top: 5rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pe-xl-6 {
    padding-right: 5rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .pb-xl-6 {
    padding-bottom: 5rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .ps-xl-6 {
    padding-left: 5rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    flex-direction: row !important;
  }

  .flex-xxl-column {
    flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    flex-wrap: wrap-reverse !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.25rem !important;
  }

  .gap-xxl-2 {
    gap: 0.5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 3rem !important;
  }

  .gap-xxl-6 {
    gap: 5rem !important;
  }

  .justify-content-xxl-start {
    justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    justify-content: center !important;
  }

  .justify-content-xxl-between {
    justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    align-items: flex-start !important;
  }

  .align-items-xxl-end {
    align-items: flex-end !important;
  }

  .align-items-xxl-center {
    align-items: center !important;
  }

  .align-items-xxl-baseline {
    align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    align-items: stretch !important;
  }

  .align-content-xxl-start {
    align-content: flex-start !important;
  }

  .align-content-xxl-end {
    align-content: flex-end !important;
  }

  .align-content-xxl-center {
    align-content: center !important;
  }

  .align-content-xxl-between {
    align-content: space-between !important;
  }

  .align-content-xxl-around {
    align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    align-content: stretch !important;
  }

  .align-self-xxl-auto {
    align-self: auto !important;
  }

  .align-self-xxl-start {
    align-self: flex-start !important;
  }

  .align-self-xxl-end {
    align-self: flex-end !important;
  }

  .align-self-xxl-center {
    align-self: center !important;
  }

  .align-self-xxl-baseline {
    align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    align-self: stretch !important;
  }

  .order-xxl-first {
    order: -1 !important;
  }

  .order-xxl-0 {
    order: 0 !important;
  }

  .order-xxl-1 {
    order: 1 !important;
  }

  .order-xxl-2 {
    order: 2 !important;
  }

  .order-xxl-3 {
    order: 3 !important;
  }

  .order-xxl-4 {
    order: 4 !important;
  }

  .order-xxl-5 {
    order: 5 !important;
  }

  .order-xxl-last {
    order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-6 {
    margin: 5rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-6 {
    margin-right: 5rem !important;
    margin-left: 5rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-6 {
    margin-top: 5rem !important;
    margin-bottom: 5rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-6 {
    margin-top: 5rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-6 {
    margin-right: 5rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-6 {
    margin-bottom: 5rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-6 {
    margin-left: 5rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .p-xxl-6 {
    padding: 5rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .px-xxl-6 {
    padding-right: 5rem !important;
    padding-left: 5rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .py-xxl-6 {
    padding-top: 5rem !important;
    padding-bottom: 5rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pt-xxl-6 {
    padding-top: 5rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pe-xxl-6 {
    padding-right: 5rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .pb-xxl-6 {
    padding-bottom: 5rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .ps-xxl-6 {
    padding-left: 5rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.5rem !important;
  }

  .fs-2 {
    font-size: 2rem !important;
  }

  .fs-3 {
    font-size: 1.75rem !important;
  }

  .fs-4 {
    font-size: 1.5rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: flex !important;
  }

  .d-print-inline-flex {
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}
/* ----------------------------------------------------------------

	Layouts.scss

-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Bootstrap Adjustments
-----------------------------------------------------------------*/
/*	Custom Bootstap Columns */
.col-1-5,
.col-sm-1-5,
.col-md-1-5,
.col-lg-1-5,
.col-xl-1-5,
.col-xxl-1-5 {
  position: relative;
  width: 100%;
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}

.col-1-5 {
  -ms-flex: 0 0 20%;
  flex: 0 0 20%;
  max-width: 20%;
}

@media (min-width: 576px) {
  .col-sm-1-5 {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}
@media (min-width: 768px) {
  .col-md-1-5 {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}
@media (min-width: 992px) {
  .col-lg-1-5 {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}
@media (min-width: 1200px) {
  .col-xl-1-5 {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}
@media (min-width: 1400px) {
  .col-xxl-1-5 {
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}
#wrapper {
  position: relative;
  float: none;
  width: 100%;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -moz-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
@media (min-width: 1200px) {
  body:not(.stretched) #wrapper {
    max-width: 1200px;
  }
}
@media (min-width: 1400px) {
  body:not(.stretched) #wrapper {
    max-width: 1400px;
  }
}
.stretched #wrapper {
  width: 100%;
  margin: 0;
  box-shadow: none;
}

/* ----------------------------------------------------------------
	Sections
-----------------------------------------------------------------*/
.section {
  position: relative;
  width: 100%;
  margin: 4rem 0;
  padding: 4rem 0;
  background-color: #f9f9f9;
  overflow: hidden;
}
.section .container {
  z-index: 2;
}
.section .container + .video-wrap {
  z-index: 1;
}

.parallax {
  background-color: transparent;
  background-attachment: fixed;
  background-position: 50% 0;
  background-repeat: no-repeat;
  overflow: hidden;
  will-change: transform;
}

.mobile-parallax,
.video-placeholder {
  background-size: cover !important;
  background-attachment: scroll !important;
  background-position: center center !important;
}

.revealer-image {
  position: relative;
  bottom: -100px;
  transition: bottom 0.3s ease-in-out;
  -webkit-transition: bottom 0.3s ease-in-out;
  -o-transition: bottom 0.3s ease-in-out;
}
.section:hover .revealer-image {
  bottom: -50px;
}

/* ----------------------------------------------------------------
	Columns & Grids
-----------------------------------------------------------------*/
.postcontent,
.sidebar {
  position: relative;
}

/*-----------------------------------------------------------------------------------
	typography.scss
-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Basic
-----------------------------------------------------------------*/
dl, dt, dd, ol, ul, li {
  margin: 0;
  padding: 0;
}

em {
  font-family: "PT Serif", serif;
}

.clear {
  clear: both;
  display: block;
  font-size: 0px;
  height: 0px;
  line-height: 0;
  width: 100%;
  overflow: hidden;
}

::selection {
  background: #2a93c9;
  color: #fff;
  text-shadow: none;
}

::-moz-selection {
  background: #2a93c9;
  color: #fff;
  text-shadow: none;
}

::-webkit-selection {
  background: #2a93c9;
  color: #fff;
  text-shadow: none;
}

:active,
:focus {
  outline: none !important;
}

/* ----------------------------------------------------------------
	Typography
-----------------------------------------------------------------*/
body {
  line-height: 1.5;
  color: #555;
  font-family: "Lato", sans-serif;
}

a {
  text-decoration: none !important;
  color: #2a93c9;
}
a:hover {
  color: #222;
}
a img {
  border: none;
}

img {
  max-width: 100%;
}

iframe {
  width: 100%;
  border: 0 !important;
  overflow: hidden !important;
}

/* ----------------------------------------------------------------
	Basic Layout Styles
-----------------------------------------------------------------*/
h1, .h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6 {
  color: #444;
  font-weight: 600;
  line-height: 1.5;
  margin: 0 0 30px 0;
  font-family: "Poppins", sans-serif;
}

h1, .h1 {
  font-size: 2.25rem;
}

h2, .h2 {
  font-size: 1.875rem;
}

h3, .h3 {
  font-size: 1.5rem;
}

h4, .h4 {
  font-size: 1.125rem;
  font-weight: 600;
}

h5, .h5 {
  font-size: 0.875rem;
}

h6, .h6 {
  font-size: 0.75rem;
}

h5, .h5,
h6,
.h6 {
  font-weight: bold;
  margin-bottom: 20px;
}

h1 > span:not(.nocolor):not(.badge), .h1 > span:not(.nocolor):not(.badge),
h2 > span:not(.nocolor):not(.badge),
.h2 > span:not(.nocolor):not(.badge),
h3 > span:not(.nocolor):not(.badge),
.h3 > span:not(.nocolor):not(.badge),
h4 > span:not(.nocolor):not(.badge),
.h4 > span:not(.nocolor):not(.badge),
h5 > span:not(.nocolor):not(.badge),
.h5 > span:not(.nocolor):not(.badge),
h6 > span:not(.nocolor):not(.badge),
.h6 > span:not(.nocolor):not(.badge) {
  color: #2a93c9;
}

p,
pre,
ul,
ol,
dl,
dd,
blockquote,
address,
table,
fieldset,
form {
  margin-bottom: 30px;
}

small, .small {
  font-family: "Lato", sans-serif;
}

/*-----------------------------------------------------------------------------------

	Utilities.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Helper Classes
-----------------------------------------------------------------*/
.line,
.double-line {
  clear: both;
  position: relative;
  width: 100%;
  margin: 4rem 0;
  border-top: 1px solid #eeeeee;
}

.line.line-sm {
  margin: 2rem 0;
}

span.middot {
  display: inline-block;
  margin: 0 5px;
}

.double-line {
  border-top: 3px double #E5E5E5;
}

.emptydiv {
  display: block !important;
  position: relative !important;
}

/* ----------------------------------------------------------------
	Margin Utility Classes
-----------------------------------------------------------------*/
.allmargin {
  margin: 3rem !important;
}

.allmargin-sm {
  margin: 2rem !important;
}

.allmargin-lg {
  margin: 5rem !important;
}

.leftmargin {
  margin-left: 3rem !important;
}

.leftmargin-sm {
  margin-left: 2rem !important;
}

.leftmargin-lg {
  margin-left: 5rem !important;
}

.rightmargin {
  margin-right: 3rem !important;
}

.rightmargin-sm {
  margin-right: 2rem !important;
}

.rightmargin-lg {
  margin-right: 5rem !important;
}

.topmargin {
  margin-top: 3rem !important;
}

.topmargin-sm {
  margin-top: 2rem !important;
}

.topmargin-lg {
  margin-top: 5rem !important;
}

.bottommargin {
  margin-bottom: 3rem !important;
}

.bottommargin-sm {
  margin-bottom: 2rem !important;
}

.bottommargin-lg {
  margin-bottom: 5rem !important;
}

.header-stick {
  margin-top: -3rem !important;
}
.content-wrap .header-stick {
  margin-top: -5rem !important;
}

.footer-stick {
  margin-bottom: -3rem !important;
}
.content-wrap .footer-stick {
  margin-bottom: -5rem !important;
}

.border-f5 {
  border-color: #F5F5F5 !important;
}

.col-padding {
  padding: 4rem;
}

.notextshadow {
  text-shadow: none !important;
}

.inline-block {
  float: none !important;
  display: inline-block !important;
}

.center {
  text-align: center !important;
}

.mx-auto {
  position: relative !important;
  float: none !important;
}

.bg-color,
.bg-color #header-wrap {
  background-color: #2a93c9 !important;
}

.color {
  color: #2a93c9 !important;
}

.border-color {
  border-color: #2a93c9 !important;
}

.nott {
  text-transform: none !important;
}

.overflow-visible {
  overflow: visible !important;
}

/* ----------------------------------------------------------------
	Font Weight Utility Classes
-----------------------------------------------------------------*/
.fw-extralight {
  font-weight: 100 !important;
}

.fw-medium {
  font-weight: 500 !important;
}

.fw-semibold {
  font-weight: 600 !important;
}

.fst-normal {
  font-style: normal !important;
}

/* ----------------------------------------------------------------
	Letter Spacing Utility Classes
-----------------------------------------------------------------*/
.ls1 {
  letter-spacing: 1px !important;
}

.ls2 {
  letter-spacing: 2px !important;
}

.ls3 {
  letter-spacing: 3px !important;
}

.ls4 {
  letter-spacing: 4px !important;
}

.ls5 {
  letter-spacing: 5px !important;
}

.nols {
  letter-spacing: 0 !important;
}

.noheight {
  height: 0 !important;
}

.nolineheight {
  line-height: 0 !important;
}

.font-body {
  font-family: "Lato", sans-serif;
}

.font-primary {
  font-family: "Poppins", sans-serif;
}

.font-secondary {
  font-family: "PT Serif", serif;
}

.bgicon {
  display: block;
  position: absolute;
  bottom: -60px;
  right: -50px;
  font-size: 210px;
  color: rgba(0, 0, 0, 0.1);
}

/* ----------------------------------------------------------------
	Image Scale Utility Classes
-----------------------------------------------------------------*/
.imagescale,
.imagescalein {
  display: block;
  overflow: hidden;
}
.imagescale img,
.imagescalein img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
  -webkit-transition: all 1s ease;
  transition: all 1s ease;
}

.imagescale:hover img {
  -webkit-transform: scale(1);
  transform: scale(1);
}

.imagescalein img {
  -webkit-transform: scale(1);
  transform: scale(1);
}
.imagescalein:hover img {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.grayscale {
  filter: brightness(80%) grayscale(1) contrast(90%);
  -webkit-filter: brightness(80%) grayscale(1) contrast(90%);
  -moz-filter: brightness(80%) grayscale(1) contrast(90%);
  -o-filter: brightness(80%) grayscale(1) contrast(90%);
  -ms-filter: brightness(80%) grayscale(1) contrast(90%);
  transition: 1s filter ease;
  -webkit-transition: 1s -webkit-filter ease;
  -moz-transition: 1s -moz-filter ease;
  -ms-transition: 1s -ms-filter ease;
  -o-transition: 1s -o-filter ease;
}
.grayscale:hover {
  filter: brightness(100%) grayscale(0);
  -webkit-filter: brightness(100%) grayscale(0);
  -moz-filter: brightness(100%) grayscale(0);
  -o-filter: brightness(100%) grayscale(0);
  -ms-filter: brightness(100%) grayscale(0);
}

.bganimate {
  -webkit-animation: BgAnimated 30s infinite linear 30s infinite linear;
  -moz-animation: BgAnimated 30s infinite linear 30s infinite linear;
  -ms-animation: BgAnimated 30s infinite linear 30s infinite linear;
  -o-animation: BgAnimated 30s infinite linear 30s infinite linear;
  animation: BgAnimated 30s infinite linear 30s infinite linear;
}

@-webkit-keyframes BgAnimated {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 0 400px;
  }
}
@keyframes BgAnimated {
  from {
    background-position: 0 0;
  }
  to {
    background-position: 0 400px;
  }
}
.input-block-level {
  display: block;
  width: 100% !important;
  min-height: 30px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.vertical-middle {
  z-index: 3;
  position: relative;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  min-height: 100%;
  justify-content: center;
  flex-flow: column;
}
.vertical-middle-overlay {
  position: absolute;
  top: 0;
  left: 0;
}
.vertical-middle + .video-wrap {
  z-index: 2 !important;
}

.magnific-max-width .mfp-content {
  max-width: 800px;
}

/* ----------------------------------------------------------------
	Opacity Utility Classes
-----------------------------------------------------------------*/
.op-ts {
  -webkit-transition: opacity 0.5s ease;
  transition: opacity 0.5s ease;
}

.op-0,
.h-op-0:hover {
  opacity: 0 !important;
}

.op-1,
.h-op-1:hover {
  opacity: 1 !important;
}

.op-01,
.h-op-01:hover {
  opacity: 0.1 !important;
}

.op-02,
.h-op-02:hover {
  opacity: 0.2 !important;
}

.op-03,
.h-op-03:hover {
  opacity: 0.3 !important;
}

.op-04,
.h-op-04:hover {
  opacity: 0.4 !important;
}

.op-05,
.h-op-05:hover {
  opacity: 0.5 !important;
}

.op-06,
.h-op-06:hover {
  opacity: 0.6 !important;
}

.op-07,
.h-op-07:hover {
  opacity: 0.7 !important;
}

.op-08,
.h-op-08:hover {
  opacity: 0.8 !important;
}

.op-09,
.h-op-09:hover {
  opacity: 0.9 !important;
}

/* ----------------------------------------------------------------
	Text Sizing Utility
-----------------------------------------------------------------*/
.text-smaller {
  font-size: 80% !important;
}
.text-smaller small, .text-smaller .small {
  font-size: 90% !important;
}
.text-larger {
  font-size: 120% !important;
}

/* ----------------------------------------------------------------
	Text Color Utility Classes
-----------------------------------------------------------------*/
.text-ts {
  -webkit-transition: color 0.5s ease;
  transition: color 0.5s ease;
}

.h-text-color:hover,
a.h-text-color:hover {
  color: #2a93c9 !important;
}

/* ----------------------------------------------------------------
	BG Color Utility Classes
-----------------------------------------------------------------*/
.bg-ts {
  -webkit-transition: background-color 0.5s ease;
  transition: background-color 0.5s ease;
}

.h-bg-color:hover {
  background-color: #2a93c9 !important;
}

.h-nobg:hover {
  background-color: transparent !important;
}

.h-text-primary:hover,
a.h-text-primary:hover {
  color: #0d6efd !important;
}

.h-bg-primary:hover {
  background-color: #0d6efd !important;
}

.h-text-secondary:hover,
a.h-text-secondary:hover {
  color: #6c757d !important;
}

.h-bg-secondary:hover {
  background-color: #6c757d !important;
}

.h-text-success:hover,
a.h-text-success:hover {
  color: #198754 !important;
}

.h-bg-success:hover {
  background-color: #198754 !important;
}

.h-text-info:hover,
a.h-text-info:hover {
  color: #0dcaf0 !important;
}

.h-bg-info:hover {
  background-color: #0dcaf0 !important;
}

.h-text-warning:hover,
a.h-text-warning:hover {
  color: #ffc107 !important;
}

.h-bg-warning:hover {
  background-color: #ffc107 !important;
}

.h-text-danger:hover,
a.h-text-danger:hover {
  color: #dc3545 !important;
}

.h-bg-danger:hover {
  background-color: #dc3545 !important;
}

.h-text-light:hover,
a.h-text-light:hover {
  color: #f8f9fa !important;
}

.h-bg-light:hover {
  background-color: #f8f9fa !important;
}

.h-text-dark:hover,
a.h-text-dark:hover {
  color: #212529 !important;
}

.h-bg-dark:hover {
  background-color: #212529 !important;
}

.bg-clip {
  border: 1rem solid transparent;
  -moz-background-clip: padding;
  -webkit-background-clip: padding;
  background-clip: padding-box;
  transform: translate3d(0, 0, 0);
}

.bg-clip-sm {
  border-width: 0.5rem;
}

.bg-clip-lg {
  border-width: 2rem;
}

/* ----------------------------------------------------------------
	Gradient Utility Classes
-----------------------------------------------------------------*/
.gradient-light-grey,
.h-gradient-light-grey:hover {
  background-image: -webkit-linear-gradient(to right, #2c3e50, #bdc3c7) !important;
  background-image: linear-gradient(to right, #2c3e50, #bdc3c7) !important;
}

.gradient-green-dark,
.h-gradient-green-dark:hover {
  background-image: -webkit-linear-gradient(to right, #99f2c8, #1f4037) !important;
  background-image: linear-gradient(to right, #99f2c8, #1f4037) !important;
}

.gradient-grey-brown,
.h-gradient-grey-brown:hover {
  background-image: -webkit-linear-gradient(to right, #C06C84, #6C5B7B, #355C7D) !important;
  background-image: linear-gradient(to right, #C06C84, #6C5B7B, #355C7D) !important;
}

.gradient-light-pink,
.h-gradient-light-pink:hover {
  background-image: -webkit-linear-gradient(to right, #ffdde1, #ee9ca7) !important;
  background-image: linear-gradient(to right, #ffdde1, #ee9ca7) !important;
}

.gradient-blue-purple,
.h-gradient-blue-purple:hover {
  background-image: -webkit-linear-gradient(to right, #4A00E0, #8E2DE2) !important;
  background-image: linear-gradient(to right, #4A00E0, #8E2DE2) !important;
}

.gradient-multiples,
.h-gradient-multiples:hover {
  background-image: -webkit-linear-gradient(to right, #eea2a2 0%, #bbc1bf 19%, #57c6e1 42%, #b49fda 79%, #7ac5d8 100%) !important;
  background-image: linear-gradient(to right, #eea2a2 0%, #bbc1bf 19%, #57c6e1 42%, #b49fda 79%, #7ac5d8 100%) !important;
}

.gradient-blue-green,
.h-gradient-blue-green:hover {
  background-image: -webkit-linear-gradient(to right, #2af598 0%, #009efd 100%) !important;
  background-image: linear-gradient(to right, #2af598 0%, #009efd 100%) !important;
}

.gradient-light,
.h-gradient-light:hover {
  background-image: -webkit-linear-gradient(to top, #DDD 0%, #FFF 100%) !important;
  background-image: linear-gradient(to top, #DDD 0%, #FFF 100%) !important;
}

.gradient-grey-orange,
.h-gradient-grey-orange:hover {
  background-image: -webkit-linear-gradient(to right, #3B4371, #F3904F) !important;
  background-image: linear-gradient(to right, #3B4371, #F3904F) !important;
}

.gradient-sunset,
.h-gradient-sunset:hover {
  background-image: -webkit-linear-gradient(to top, #a8edea 0%, #fed6e3 100%) !important;
  background-image: linear-gradient(to top, #a8edea 0%, #fed6e3 100%) !important;
}

.gradient-dark,
.h-gradient-dark:hover {
  background-image: -webkit-linear-gradient(to right, #535353, #000) !important;
  background-image: linear-gradient(to right, #535353, #000) !important;
}

.gradient-lemon,
.h-gradient-lemon:hover {
  background-image: -webkit-linear-gradient(to right, #cac531, #f3f9a7) !important;
  background-image: linear-gradient(to right, #cac531, #f3f9a7) !important;
}

.gradient-earth,
.h-gradient-earth:hover {
  background-image: -webkit-linear-gradient(to right, #649173, #dbd5a4) !important;
  background-image: linear-gradient(to right, #649173, #dbd5a4) !important;
}

.gradient-sky,
.h-gradient-sky:hover {
  background-image: -webkit-linear-gradient(to right, #2980b9, #6dd5fa, #F5F5F5) !important;
  background-image: linear-gradient(to right, #2980b9, #6dd5fa, #F5F5F5) !important;
}

.gradient-ocean,
.h-gradient-ocean:hover {
  background-image: -webkit-linear-gradient(to right, #000046, #1cb5e0) !important;
  background-image: linear-gradient(to right, #000046, #1cb5e0) !important;
}

.gradient-horizon,
.h-gradient-horizon:hover {
  background-image: -webkit-linear-gradient(90deg, #833ab4 0%, #fd1d1d 50%, #fcb045 100%) !important;
  background-image: linear-gradient(90deg, #833ab4 0%, #fd1d1d 50%, #fcb045 100%) !important;
}

.gradient-green-blue,
.h-gradient-green-blue:hover {
  background-image: -webkit-linear-gradient(to right, #43cea2, #185a9d) !important;
  background-image: linear-gradient(to right, #43cea2, #185a9d) !important;
}

.gradient-purple-blue,
.h-gradient-purple-blue:hover {
  background-image: -webkit-linear-gradient(19deg, #21d4fd 0%, #b721ff 100%) !important;
  background-image: linear-gradient(19deg, #21d4fd 0%, #b721ff 100%) !important;
}

.gradient-text {
  text-shadow: none !important;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.gradient-underline {
  background-repeat: no-repeat;
  background-size: 100% 8px;
  background-position: 0 88%;
}

/* ----------------------------------------------------------------
	Bootstrap Utility Classes: Extended
-----------------------------------------------------------------*/
.gutter-10 {
  margin-right: -5px;
  margin-left: -5px;
  margin-bottom: -10px;
}

.gutter-10 > .col,
.gutter-10 > [class*=col-] {
  padding-right: 5px;
  padding-left: 5px;
  padding-bottom: 10px;
}

.gutter-20 {
  margin-right: -10px;
  margin-left: -10px;
  margin-bottom: -20px;
}

.gutter-20 > .col,
.gutter-20 > [class*=col-] {
  padding-right: 10px;
  padding-left: 10px;
  padding-bottom: 20px;
}

.gutter-30 {
  margin-right: -15px;
  margin-left: -15px;
  margin-bottom: -30px;
}

.gutter-30 > .col,
.gutter-30 > [class*=col-] {
  padding-right: 15px;
  padding-left: 15px;
  padding-bottom: 30px;
}

.gutter-40 {
  margin-right: -20px;
  margin-left: -20px;
  margin-bottom: -40px;
}

.gutter-40 > .col,
.gutter-40 > [class*=col-] {
  padding-right: 20px;
  padding-left: 20px;
  padding-bottom: 40px;
}

.gutter-50 {
  margin-right: -25px;
  margin-left: -25px;
  margin-bottom: -50px;
}

.gutter-50 > .col,
.gutter-50 > [class*=col-] {
  padding-right: 25px;
  padding-left: 25px;
  padding-bottom: 50px;
}

@media (min-width: 576px) {
  .gutter-sm-10 {
    margin-right: -5px;
    margin-left: -5px;
    margin-bottom: -10px;
  }

  .gutter-sm-10 > .col,
.gutter-sm-10 > [class*=col-] {
    padding-right: 5px;
    padding-left: 5px;
    padding-bottom: 10px;
  }

  .gutter-sm-20 {
    margin-right: -10px;
    margin-left: -10px;
    margin-bottom: -20px;
  }

  .gutter-sm-20 > .col,
.gutter-sm-20 > [class*=col-] {
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 20px;
  }

  .gutter-sm-30 {
    margin-right: -15px;
    margin-left: -15px;
    margin-bottom: -30px;
  }

  .gutter-sm-30 > .col,
.gutter-sm-30 > [class*=col-] {
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 30px;
  }

  .gutter-sm-40 {
    margin-right: -20px;
    margin-left: -20px;
    margin-bottom: -40px;
  }

  .gutter-sm-40 > .col,
.gutter-sm-40 > [class*=col-] {
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 40px;
  }

  .gutter-sm-50 {
    margin-right: -25px;
    margin-left: -25px;
    margin-bottom: -50px;
  }

  .gutter-sm-50 > .col,
.gutter-sm-50 > [class*=col-] {
    padding-right: 25px;
    padding-left: 25px;
    padding-bottom: 50px;
  }
}
@media (min-width: 768px) {
  .gutter-md-10 {
    margin-right: -5px;
    margin-left: -5px;
    margin-bottom: -10px;
  }

  .gutter-md-10 > .col,
.gutter-md-10 > [class*=col-] {
    padding-right: 5px;
    padding-left: 5px;
    padding-bottom: 10px;
  }

  .gutter-md-20 {
    margin-right: -10px;
    margin-left: -10px;
    margin-bottom: -20px;
  }

  .gutter-md-20 > .col,
.gutter-md-20 > [class*=col-] {
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 20px;
  }

  .gutter-md-30 {
    margin-right: -15px;
    margin-left: -15px;
    margin-bottom: -30px;
  }

  .gutter-md-30 > .col,
.gutter-md-30 > [class*=col-] {
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 30px;
  }

  .gutter-md-40 {
    margin-right: -20px;
    margin-left: -20px;
    margin-bottom: -40px;
  }

  .gutter-md-40 > .col,
.gutter-md-40 > [class*=col-] {
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 40px;
  }

  .gutter-md-50 {
    margin-right: -25px;
    margin-left: -25px;
    margin-bottom: -50px;
  }

  .gutter-md-50 > .col,
.gutter-md-50 > [class*=col-] {
    padding-right: 25px;
    padding-left: 25px;
    padding-bottom: 50px;
  }
}
@media (min-width: 992px) {
  .gutter-lg-10 {
    margin-right: -5px;
    margin-left: -5px;
    margin-bottom: -10px;
  }

  .gutter-lg-10 > .col,
.gutter-lg-10 > [class*=col-] {
    padding-right: 5px;
    padding-left: 5px;
    padding-bottom: 10px;
  }

  .gutter-lg-20 {
    margin-right: -10px;
    margin-left: -10px;
    margin-bottom: -20px;
  }

  .gutter-lg-20 > .col,
.gutter-lg-20 > [class*=col-] {
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 20px;
  }

  .gutter-lg-30 {
    margin-right: -15px;
    margin-left: -15px;
    margin-bottom: -30px;
  }

  .gutter-lg-30 > .col,
.gutter-lg-30 > [class*=col-] {
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 30px;
  }

  .gutter-lg-40 {
    margin-right: -20px;
    margin-left: -20px;
    margin-bottom: -40px;
  }

  .gutter-lg-40 > .col,
.gutter-lg-40 > [class*=col-] {
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 40px;
  }

  .gutter-lg-50 {
    margin-right: -25px;
    margin-left: -25px;
    margin-bottom: -50px;
  }

  .gutter-lg-50 > .col,
.gutter-lg-50 > [class*=col-] {
    padding-right: 25px;
    padding-left: 25px;
    padding-bottom: 50px;
  }
}
@media (min-width: 1200px) {
  .gutter-xl-10 {
    margin-right: -5px;
    margin-left: -5px;
    margin-bottom: -10px;
  }

  .gutter-xl-10 > .col,
.gutter-xl-10 > [class*=col-] {
    padding-right: 5px;
    padding-left: 5px;
    padding-bottom: 10px;
  }

  .gutter-xl-20 {
    margin-right: -10px;
    margin-left: -10px;
    margin-bottom: -20px;
  }

  .gutter-xl-20 > .col,
.gutter-xl-20 > [class*=col-] {
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 20px;
  }

  .gutter-xl-30 {
    margin-right: -15px;
    margin-left: -15px;
    margin-bottom: -30px;
  }

  .gutter-xl-30 > .col,
.gutter-xl-30 > [class*=col-] {
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 30px;
  }

  .gutter-xl-40 {
    margin-right: -20px;
    margin-left: -20px;
    margin-bottom: -40px;
  }

  .gutter-xl-40 > .col,
.gutter-xl-40 > [class*=col-] {
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 40px;
  }

  .gutter-xl-50 {
    margin-right: -25px;
    margin-left: -25px;
    margin-bottom: -50px;
  }

  .gutter-xl-50 > .col,
.gutter-xl-50 > [class*=col-] {
    padding-right: 25px;
    padding-left: 25px;
    padding-bottom: 50px;
  }
}
@media (min-width: 1400px) {
  .gutter-xxl-10 {
    margin-right: -5px;
    margin-left: -5px;
    margin-bottom: -10px;
  }

  .gutter-xxl-10 > .col,
.gutter-xxl-10 > [class*=col-] {
    padding-right: 5px;
    padding-left: 5px;
    padding-bottom: 10px;
  }

  .gutter-xxl-20 {
    margin-right: -10px;
    margin-left: -10px;
    margin-bottom: -20px;
  }

  .gutter-xxl-20 > .col,
.gutter-xxl-20 > [class*=col-] {
    padding-right: 10px;
    padding-left: 10px;
    padding-bottom: 20px;
  }

  .gutter-xxl-30 {
    margin-right: -15px;
    margin-left: -15px;
    margin-bottom: -30px;
  }

  .gutter-xxl-30 > .col,
.gutter-xxl-30 > [class*=col-] {
    padding-right: 15px;
    padding-left: 15px;
    padding-bottom: 30px;
  }

  .gutter-xxl-40 {
    margin-right: -20px;
    margin-left: -20px;
    margin-bottom: -40px;
  }

  .gutter-xxl-40 > .col,
.gutter-xxl-40 > [class*=col-] {
    padding-right: 20px;
    padding-left: 20px;
    padding-bottom: 40px;
  }

  .gutter-xxl-50 {
    margin-right: -25px;
    margin-left: -25px;
    margin-bottom: -50px;
  }

  .gutter-xxl-50 > .col,
.gutter-xxl-50 > [class*=col-] {
    padding-right: 25px;
    padding-left: 25px;
    padding-bottom: 50px;
  }
}
/* ----------------------------------------------------------------
	col-mb-classes & Bootstrap Utility Classes: Extended
-----------------------------------------------------------------*/
.col-mb-30 {
  margin-bottom: -30px;
}

.col-mb-30 > .col,
.col-mb-30 > [class*=col-] {
  padding-bottom: 30px;
}

.col-mb-50 {
  margin-bottom: -50px;
}

.col-mb-50 > .col,
.col-mb-50 > [class*=col-] {
  padding-bottom: 50px;
}

.col-mb-80 {
  margin-bottom: -80px;
}

.col-mb-80 > .col,
.col-mb-80 > [class*=col-] {
  padding-bottom: 80px;
}

@media (min-width: 576px) {
  .col-mb-sm-30 {
    margin-bottom: -30px;
  }

  .col-mb-sm-30 > .col,
.col-mb-sm-30 > [class*=col-] {
    padding-bottom: 30px;
  }

  .col-mb-sm-50 {
    margin-bottom: -50px;
  }

  .col-mb-sm-50 > .col,
.col-mb-sm-50 > [class*=col-] {
    padding-bottom: 50px;
  }

  .col-mb-sm-80 {
    margin-bottom: -80px;
  }

  .col-mb-sm-80 > .col,
.col-mb-sm-80 > [class*=col-] {
    padding-bottom: 80px;
  }
}
@media (min-width: 768px) {
  .col-mb-md-30 {
    margin-bottom: -30px;
  }

  .col-mb-md-30 > .col,
.col-mb-md-30 > [class*=col-] {
    padding-bottom: 30px;
  }

  .col-mb-md-50 {
    margin-bottom: -50px;
  }

  .col-mb-md-50 > .col,
.col-mb-md-50 > [class*=col-] {
    padding-bottom: 50px;
  }

  .col-mb-md-80 {
    margin-bottom: -80px;
  }

  .col-mb-md-80 > .col,
.col-mb-md-80 > [class*=col-] {
    padding-bottom: 80px;
  }
}
@media (min-width: 992px) {
  .col-mb-lg-30 {
    margin-bottom: -30px;
  }

  .col-mb-lg-30 > .col,
.col-mb-lg-30 > [class*=col-] {
    padding-bottom: 30px;
  }

  .col-mb-lg-50 {
    margin-bottom: -50px;
  }

  .col-mb-lg-50 > .col,
.col-mb-lg-50 > [class*=col-] {
    padding-bottom: 50px;
  }

  .col-mb-lg-80 {
    margin-bottom: -80px;
  }

  .col-mb-lg-80 > .col,
.col-mb-lg-80 > [class*=col-] {
    padding-bottom: 80px;
  }
}
@media (min-width: 1200px) {
  .col-mb-xl-30 {
    margin-bottom: -30px;
  }

  .col-mb-xl-30 > .col,
.col-mb-xl-30 > [class*=col-] {
    padding-bottom: 30px;
  }

  .col-mb-xl-50 {
    margin-bottom: -50px;
  }

  .col-mb-xl-50 > .col,
.col-mb-xl-50 > [class*=col-] {
    padding-bottom: 50px;
  }

  .col-mb-xl-80 {
    margin-bottom: -80px;
  }

  .col-mb-xl-80 > .col,
.col-mb-xl-80 > [class*=col-] {
    padding-bottom: 80px;
  }
}
@media (min-width: 1400px) {
  .col-mb-xxl-30 {
    margin-bottom: -30px;
  }

  .col-mb-xxl-30 > .col,
.col-mb-xxl-30 > [class*=col-] {
    padding-bottom: 30px;
  }

  .col-mb-xxl-50 {
    margin-bottom: -50px;
  }

  .col-mb-xxl-50 > .col,
.col-mb-xxl-50 > [class*=col-] {
    padding-bottom: 50px;
  }

  .col-mb-xxl-80 {
    margin-bottom: -80px;
  }

  .col-mb-xxl-80 > .col,
.col-mb-xxl-80 > [class*=col-] {
    padding-bottom: 80px;
  }
}
/* ----------------------------------------------------------------
	Height Utility Classes
-----------------------------------------------------------------*/
.min-vh-100 {
  min-height: 100vh !important;
}

.vh-100 {
  height: 100vh !important;
}

.max-vh-100 {
  max-height: 100vh !important;
}

.h-100 {
  height: 100% !important;
}

.min-vh-75 {
  min-height: 75vh !important;
}

.vh-75 {
  height: 75vh !important;
}

.max-vh-75 {
  max-height: 75vh !important;
}

.h-75 {
  height: 75% !important;
}

.min-vh-60 {
  min-height: 60vh !important;
}

.vh-60 {
  height: 60vh !important;
}

.max-vh-60 {
  max-height: 60vh !important;
}

.h-60 {
  height: 60% !important;
}

.min-vh-50 {
  min-height: 50vh !important;
}

.vh-50 {
  height: 50vh !important;
}

.max-vh-50 {
  max-height: 50vh !important;
}

.h-50 {
  height: 50% !important;
}

.min-vh-40 {
  min-height: 40vh !important;
}

.vh-40 {
  height: 40vh !important;
}

.max-vh-40 {
  max-height: 40vh !important;
}

.h-40 {
  height: 40% !important;
}

.min-vh-25 {
  min-height: 25vh !important;
}

.vh-25 {
  height: 25vh !important;
}

.max-vh-25 {
  max-height: 25vh !important;
}

.h-25 {
  height: 25% !important;
}

.min-vh-0 {
  min-height: 0vh !important;
}

.vh-0 {
  height: 0vh !important;
}

.max-vh-0 {
  max-height: 0vh !important;
}

.h-0 {
  height: 0% !important;
}

.max-vh-none {
  max-height: none !important;
}

.h-auto {
  height: auto !important;
}

@media (min-width: 576px) {
  .min-vh-sm-100 {
    min-height: 100vh !important;
  }

  .vh-sm-100 {
    height: 100vh !important;
  }

  .max-vh-sm-100 {
    max-height: 100vh !important;
  }

  .h-sm-100 {
    height: 100% !important;
  }

  .min-vh-sm-75 {
    min-height: 75vh !important;
  }

  .vh-sm-75 {
    height: 75vh !important;
  }

  .max-vh-sm-75 {
    max-height: 75vh !important;
  }

  .h-sm-75 {
    height: 75% !important;
  }

  .min-vh-sm-60 {
    min-height: 60vh !important;
  }

  .vh-sm-60 {
    height: 60vh !important;
  }

  .max-vh-sm-60 {
    max-height: 60vh !important;
  }

  .h-sm-60 {
    height: 60% !important;
  }

  .min-vh-sm-50 {
    min-height: 50vh !important;
  }

  .vh-sm-50 {
    height: 50vh !important;
  }

  .max-vh-sm-50 {
    max-height: 50vh !important;
  }

  .h-sm-50 {
    height: 50% !important;
  }

  .min-vh-sm-40 {
    min-height: 40vh !important;
  }

  .vh-sm-40 {
    height: 40vh !important;
  }

  .max-vh-sm-40 {
    max-height: 40vh !important;
  }

  .h-sm-40 {
    height: 40% !important;
  }

  .min-vh-sm-25 {
    min-height: 25vh !important;
  }

  .vh-sm-25 {
    height: 25vh !important;
  }

  .max-vh-sm-25 {
    max-height: 25vh !important;
  }

  .h-sm-25 {
    height: 25% !important;
  }

  .min-vh-sm-0 {
    min-height: 0vh !important;
  }

  .vh-sm-0 {
    height: 0vh !important;
  }

  .max-vh-sm-0 {
    max-height: 0vh !important;
  }

  .h-sm-0 {
    height: 0% !important;
  }

  .max-vh-sm-none {
    max-height: none !important;
  }

  .h-sm-auto {
    height: auto !important;
  }
}
@media (min-width: 768px) {
  .min-vh-md-100 {
    min-height: 100vh !important;
  }

  .vh-md-100 {
    height: 100vh !important;
  }

  .max-vh-md-100 {
    max-height: 100vh !important;
  }

  .h-md-100 {
    height: 100% !important;
  }

  .min-vh-md-75 {
    min-height: 75vh !important;
  }

  .vh-md-75 {
    height: 75vh !important;
  }

  .max-vh-md-75 {
    max-height: 75vh !important;
  }

  .h-md-75 {
    height: 75% !important;
  }

  .min-vh-md-60 {
    min-height: 60vh !important;
  }

  .vh-md-60 {
    height: 60vh !important;
  }

  .max-vh-md-60 {
    max-height: 60vh !important;
  }

  .h-md-60 {
    height: 60% !important;
  }

  .min-vh-md-50 {
    min-height: 50vh !important;
  }

  .vh-md-50 {
    height: 50vh !important;
  }

  .max-vh-md-50 {
    max-height: 50vh !important;
  }

  .h-md-50 {
    height: 50% !important;
  }

  .min-vh-md-40 {
    min-height: 40vh !important;
  }

  .vh-md-40 {
    height: 40vh !important;
  }

  .max-vh-md-40 {
    max-height: 40vh !important;
  }

  .h-md-40 {
    height: 40% !important;
  }

  .min-vh-md-25 {
    min-height: 25vh !important;
  }

  .vh-md-25 {
    height: 25vh !important;
  }

  .max-vh-md-25 {
    max-height: 25vh !important;
  }

  .h-md-25 {
    height: 25% !important;
  }

  .min-vh-md-0 {
    min-height: 0vh !important;
  }

  .vh-md-0 {
    height: 0vh !important;
  }

  .max-vh-md-0 {
    max-height: 0vh !important;
  }

  .h-md-0 {
    height: 0% !important;
  }

  .max-vh-md-none {
    max-height: none !important;
  }

  .h-md-auto {
    height: auto !important;
  }
}
@media (min-width: 992px) {
  .min-vh-lg-100 {
    min-height: 100vh !important;
  }

  .vh-lg-100 {
    height: 100vh !important;
  }

  .max-vh-lg-100 {
    max-height: 100vh !important;
  }

  .h-lg-100 {
    height: 100% !important;
  }

  .min-vh-lg-75 {
    min-height: 75vh !important;
  }

  .vh-lg-75 {
    height: 75vh !important;
  }

  .max-vh-lg-75 {
    max-height: 75vh !important;
  }

  .h-lg-75 {
    height: 75% !important;
  }

  .min-vh-lg-60 {
    min-height: 60vh !important;
  }

  .vh-lg-60 {
    height: 60vh !important;
  }

  .max-vh-lg-60 {
    max-height: 60vh !important;
  }

  .h-lg-60 {
    height: 60% !important;
  }

  .min-vh-lg-50 {
    min-height: 50vh !important;
  }

  .vh-lg-50 {
    height: 50vh !important;
  }

  .max-vh-lg-50 {
    max-height: 50vh !important;
  }

  .h-lg-50 {
    height: 50% !important;
  }

  .min-vh-lg-40 {
    min-height: 40vh !important;
  }

  .vh-lg-40 {
    height: 40vh !important;
  }

  .max-vh-lg-40 {
    max-height: 40vh !important;
  }

  .h-lg-40 {
    height: 40% !important;
  }

  .min-vh-lg-25 {
    min-height: 25vh !important;
  }

  .vh-lg-25 {
    height: 25vh !important;
  }

  .max-vh-lg-25 {
    max-height: 25vh !important;
  }

  .h-lg-25 {
    height: 25% !important;
  }

  .min-vh-lg-0 {
    min-height: 0vh !important;
  }

  .vh-lg-0 {
    height: 0vh !important;
  }

  .max-vh-lg-0 {
    max-height: 0vh !important;
  }

  .h-lg-0 {
    height: 0% !important;
  }

  .max-vh-lg-none {
    max-height: none !important;
  }

  .h-lg-auto {
    height: auto !important;
  }
}
@media (min-width: 1200px) {
  .min-vh-xl-100 {
    min-height: 100vh !important;
  }

  .vh-xl-100 {
    height: 100vh !important;
  }

  .max-vh-xl-100 {
    max-height: 100vh !important;
  }

  .h-xl-100 {
    height: 100% !important;
  }

  .min-vh-xl-75 {
    min-height: 75vh !important;
  }

  .vh-xl-75 {
    height: 75vh !important;
  }

  .max-vh-xl-75 {
    max-height: 75vh !important;
  }

  .h-xl-75 {
    height: 75% !important;
  }

  .min-vh-xl-60 {
    min-height: 60vh !important;
  }

  .vh-xl-60 {
    height: 60vh !important;
  }

  .max-vh-xl-60 {
    max-height: 60vh !important;
  }

  .h-xl-60 {
    height: 60% !important;
  }

  .min-vh-xl-50 {
    min-height: 50vh !important;
  }

  .vh-xl-50 {
    height: 50vh !important;
  }

  .max-vh-xl-50 {
    max-height: 50vh !important;
  }

  .h-xl-50 {
    height: 50% !important;
  }

  .min-vh-xl-40 {
    min-height: 40vh !important;
  }

  .vh-xl-40 {
    height: 40vh !important;
  }

  .max-vh-xl-40 {
    max-height: 40vh !important;
  }

  .h-xl-40 {
    height: 40% !important;
  }

  .min-vh-xl-25 {
    min-height: 25vh !important;
  }

  .vh-xl-25 {
    height: 25vh !important;
  }

  .max-vh-xl-25 {
    max-height: 25vh !important;
  }

  .h-xl-25 {
    height: 25% !important;
  }

  .min-vh-xl-0 {
    min-height: 0vh !important;
  }

  .vh-xl-0 {
    height: 0vh !important;
  }

  .max-vh-xl-0 {
    max-height: 0vh !important;
  }

  .h-xl-0 {
    height: 0% !important;
  }

  .max-vh-xl-none {
    max-height: none !important;
  }

  .h-xl-auto {
    height: auto !important;
  }
}
@media (min-width: 1400px) {
  .min-vh-xxl-100 {
    min-height: 100vh !important;
  }

  .vh-xxl-100 {
    height: 100vh !important;
  }

  .max-vh-xxl-100 {
    max-height: 100vh !important;
  }

  .h-xxl-100 {
    height: 100% !important;
  }

  .min-vh-xxl-75 {
    min-height: 75vh !important;
  }

  .vh-xxl-75 {
    height: 75vh !important;
  }

  .max-vh-xxl-75 {
    max-height: 75vh !important;
  }

  .h-xxl-75 {
    height: 75% !important;
  }

  .min-vh-xxl-60 {
    min-height: 60vh !important;
  }

  .vh-xxl-60 {
    height: 60vh !important;
  }

  .max-vh-xxl-60 {
    max-height: 60vh !important;
  }

  .h-xxl-60 {
    height: 60% !important;
  }

  .min-vh-xxl-50 {
    min-height: 50vh !important;
  }

  .vh-xxl-50 {
    height: 50vh !important;
  }

  .max-vh-xxl-50 {
    max-height: 50vh !important;
  }

  .h-xxl-50 {
    height: 50% !important;
  }

  .min-vh-xxl-40 {
    min-height: 40vh !important;
  }

  .vh-xxl-40 {
    height: 40vh !important;
  }

  .max-vh-xxl-40 {
    max-height: 40vh !important;
  }

  .h-xxl-40 {
    height: 40% !important;
  }

  .min-vh-xxl-25 {
    min-height: 25vh !important;
  }

  .vh-xxl-25 {
    height: 25vh !important;
  }

  .max-vh-xxl-25 {
    max-height: 25vh !important;
  }

  .h-xxl-25 {
    height: 25% !important;
  }

  .min-vh-xxl-0 {
    min-height: 0vh !important;
  }

  .vh-xxl-0 {
    height: 0vh !important;
  }

  .max-vh-xxl-0 {
    max-height: 0vh !important;
  }

  .h-xxl-0 {
    height: 0% !important;
  }

  .max-vh-xxl-none {
    max-height: none !important;
  }

  .h-xxl-auto {
    height: auto !important;
  }
}
/* ----------------------------------------------------------------
	Position Utility Classes: Extended
-----------------------------------------------------------------*/
.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

@media (min-width: 576px) {
  .position-sm-static {
    position: static !important;
  }

  .position-sm-relative {
    position: relative !important;
  }

  .position-sm-absolute {
    position: absolute !important;
  }

  .position-sm-fixed {
    position: fixed !important;
  }

  .position-sm-sticky {
    position: sticky !important;
  }
}
@media (min-width: 768px) {
  .position-md-static {
    position: static !important;
  }

  .position-md-relative {
    position: relative !important;
  }

  .position-md-absolute {
    position: absolute !important;
  }

  .position-md-fixed {
    position: fixed !important;
  }

  .position-md-sticky {
    position: sticky !important;
  }
}
@media (min-width: 992px) {
  .position-lg-static {
    position: static !important;
  }

  .position-lg-relative {
    position: relative !important;
  }

  .position-lg-absolute {
    position: absolute !important;
  }

  .position-lg-fixed {
    position: fixed !important;
  }

  .position-lg-sticky {
    position: sticky !important;
  }
}
@media (min-width: 1200px) {
  .position-xl-static {
    position: static !important;
  }

  .position-xl-relative {
    position: relative !important;
  }

  .position-xl-absolute {
    position: absolute !important;
  }

  .position-xl-fixed {
    position: fixed !important;
  }

  .position-xl-sticky {
    position: sticky !important;
  }
}
@media (min-width: 1400px) {
  .position-xxl-static {
    position: static !important;
  }

  .position-xxl-relative {
    position: relative !important;
  }

  .position-xxl-absolute {
    position: absolute !important;
  }

  .position-xxl-fixed {
    position: fixed !important;
  }

  .position-xxl-sticky {
    position: sticky !important;
  }
}
/* ----------------------------------------------------------------
	Lazy Loading
-----------------------------------------------------------------*/
.lazy {
  opacity: 0;
}
.lazy:not(.initial) {
  transition: opacity 1s;
}
.lazy.initial, .lazy-loaded, .lazy-error {
  opacity: 1;
}
img.lazy {
  max-width: 100%;
  height: auto;
}
img.lazy:not([src]) {
  visibility: hidden;
}

/* ----------------------------------------------------------------
	Borders
-----------------------------------------------------------------*/
.border-width-1,
.h-border-width-1:hover {
  border-width: 1px !important;
}

.border-width-2,
.h-border-width-2:hover {
  border-width: 2px !important;
}

.border-width-3,
.h-border-width-3:hover {
  border-width: 3px !important;
}

.border-width-4,
.h-border-width-4:hover {
  border-width: 4px !important;
}

.border-width-5,
.h-border-width-5:hover {
  border-width: 5px !important;
}

.border-width-6,
.h-border-width-6:hover {
  border-width: 6px !important;
}

.border-dotted {
  border-style: dotted !important;
}

.border-dashed {
  border-style: dashed !important;
}

.border-solid {
  border-style: solid !important;
}

.border-double {
  border-style: double !important;
}

.h-border-primary:hover {
  border-color: #0d6efd !important;
}

.h-border-secondary:hover {
  border-color: #6c757d !important;
}

.h-border-success:hover {
  border-color: #198754 !important;
}

.h-border-info:hover {
  border-color: #0dcaf0 !important;
}

.h-border-warning:hover {
  border-color: #ffc107 !important;
}

.h-border-danger:hover {
  border-color: #dc3545 !important;
}

.h-border-light:hover {
  border-color: #f8f9fa !important;
}

.h-border-dark:hover {
  border-color: #212529 !important;
}

.border-transparent,
.h-border-transparent:hover {
  border-color: transparent !important;
}

.border-default,
.h-border-default:hover {
  border-color: #EEE !important;
}

.dark.border-default,
.dark .border-default,
.dark.h-border-default:hover .dark .h-border-default:hover {
  border-color: rgba(255, 255, 255, 0.15) !important;
}

.border-ts {
  -webkit-transition: border 0.5s ease;
  transition: border 0.5s ease;
}

.rounded-xl {
  border-radius: 0.4rem !important;
}
.rounded-xxl {
  border-radius: 0.5rem !important;
}
.rounded-xxxl {
  border-radius: 0.5rem !important;
}

/* ----------------------------------------------------------------
	Max-width Utility Classes:
-----------------------------------------------------------------*/
.mw-xs {
  max-width: 38rem !important;
}

.mw-sm {
  max-width: 48rem !important;
}

.mw-md {
  max-width: 64rem !important;
}

.mw-lg {
  max-width: 80rem !important;
}

.mw-xl {
  max-width: 90rem !important;
}

.mw-xxl {
  max-width: 100rem !important;
}

/* ----------------------------------------------------------------
	Text Sizes Utility Classes:
-----------------------------------------------------------------*/
.text-size-xs {
  font-size: 0.875rem !important;
}

.text-size-sm {
  font-size: 1rem !important;
}

.text-size-md {
  font-size: 1.5rem !important;
}

.text-size-lg {
  font-size: 2rem !important;
}

.text-size-xl {
  font-size: calc(1rem + 2.25vw) !important;
}

.text-size-xxl {
  font-size: calc(1rem + 3.5vw) !important;
}

/* ----------------------------------------------------------------
	Square Sizes Utility Classes:
-----------------------------------------------------------------*/
.square-xs {
  width: 1rem !important;
  height: 1rem !important;
}

.square-sm {
  width: 2rem !important;
  height: 2rem !important;
}

.square-md {
  width: 4rem !important;
  height: 4rem !important;
}

.square-lg {
  width: 6rem !important;
  height: 6rem !important;
}

.square-xl {
  width: 8rem !important;
  height: 8rem !important;
}

/* ----------------------------------------------------------------
	Max-width Utility Classes:
-----------------------------------------------------------------*/
.align-wide-lg {
  margin-left: calc(50% - 480px) !important;
  margin-right: calc(50% - 480px) !important;
  max-width: 960px !important;
  width: 960px !important;
}

.align-wide-xl {
  margin-left: calc(50% - 570px) !important;
  margin-right: calc(50% - 570px) !important;
  max-width: 1140px !important;
  width: 1140px !important;
}

.align-wide-xxl {
  margin-left: calc(50% - 660px) !important;
  margin-right: calc(50% - 660px) !important;
  max-width: 1320px !important;
  width: 1320px !important;
}

@media (max-width: 575.98px) {
  .align-wide-lg,
.align-wide-xl,
.align-wide-xxl {
    margin-left: calc(50% - 50vw) !important;
    margin-right: calc(50% - 50vw) !important;
    max-width: 100vw !important;
    width: 100vw !important;
  }
}
.align-full {
  margin-left: calc(50% - 50vw) !important;
  margin-right: calc(50% - 50vw) !important;
  max-width: 100vw !important;
  width: 100vw !important;
}

@media (max-width: 575.98px) {
  .align-wide-lg,
.align-wide-xl,
.align-wide-xxl {
    margin-left: calc(50% - 50vw) !important;
    margin-right: calc(50% - 50vw) !important;
    max-width: 100vw !important;
    width: 100vw !important;
  }
}
/* ----------------------------------------------------------------
	Shadow
-----------------------------------------------------------------*/
.h-shadow-sm:hover {
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075) !important;
}

.h-shadow:hover {
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15) !important;
}

.h-shadow-lg:hover {
  box-shadow: 0 1rem 3rem rgba(0, 0, 0, 0.175) !important;
}

.h-shadow-none:hover {
  box-shadow: none !important;
}

.shadow-ts {
  -webkit-transition: box-shadow 0.5s ease;
  transition: box-shadow 0.5s ease;
}

.all-ts {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

/* ----------------------------------------------------------------
	translate
-----------------------------------------------------------------*/
.translate-y-sm,
.h-translate-y-sm:hover {
  transform: translateY(-3px) !important;
}

.translate-y,
.h-translate-y:hover {
  transform: translateY(-6px) !important;
}

.translate-y-lg,
.h-translate-y-lg:hover {
  transform: translateY(-10px) !important;
}

.translate-x-sm,
.h-translate-x-sm:hover {
  transform: translateX(-3px) !important;
}

.translate-x,
.h-translate-x:hover {
  transform: translateX(-6px) !important;
}

.translate-x-lg,
.h-translate-x-lg:hover {
  transform: translateX(-10px) !important;
}

.translate-x-n-sm,
.h-translate-x-n-sm:hover {
  transform: translateX(3px) !important;
}

.translate-x-n,
.h-translate-x-n:hover {
  transform: translateX(6px) !important;
}

.translate-x-n-lg,
.h-translate-x-n-lg:hover {
  transform: translateX(10px) !important;
}

.scale-sm,
.h-scale-sm:hover {
  transform: scale(1.025) !important;
}

.scale,
.h-scale:hover {
  transform: scale(1.05) !important;
}

.scale-lg,
.h-scale-lg:hover {
  transform: scale(1.1) !important;
}

.scale-n-sm,
.h-scale-n-sm:hover {
  transform: scale(0.975) !important;
}

.scale-n,
.h-scale-n:hover {
  transform: scale(0.95) !important;
}

.scale-n-lg,
.h-scale-n-lg:hover {
  transform: scale(0.9) !important;
}

.transform-ts {
  -webkit-transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: transform 0.5s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/* ----------------------------------------------------------------
	z-index
-----------------------------------------------------------------*/
.z-0 {
  z-index: 0 !important;
}

.z-1 {
  z-index: 1 !important;
}

.z-2 {
  z-index: 2 !important;
}

.z-3 {
  z-index: 3 !important;
}

.z-4 {
  z-index: 4 !important;
}

.z-5 {
  z-index: 5 !important;
}

.z-6 {
  z-index: 6 !important;
}

.z-7 {
  z-index: 7 !important;
}

.z-8 {
  z-index: 8 !important;
}

.z-9 {
  z-index: 9 !important;
}

.z-10 {
  z-index: 10 !important;
}

.z-auto {
  z-index: auto !important;
}

/* ----------------------------------------------------------------
	width
-----------------------------------------------------------------*/
.wd-5 {
  width: 5px;
}

.wd-5p {
  width: 5%;
}

.mx-wd-5p {
  max-width: 5%;
}

.mn-wd-5p {
  min-width: 5%;
}

.wd-5-f {
  width: 5px !important;
}

.wd-5p-f {
  width: 5% !important;
}

.mx-wd-5p-f {
  max-width: 5% !important;
}

.mn-wd-5p-f {
  min-width: 5% !important;
}

.wd-10 {
  width: 10px;
}

.wd-10p {
  width: 10%;
}

.mx-wd-10p {
  max-width: 10%;
}

.mn-wd-10p {
  min-width: 10%;
}

.wd-10-f {
  width: 10px !important;
}

.wd-10p-f {
  width: 10% !important;
}

.mx-wd-10p-f {
  max-width: 10% !important;
}

.mn-wd-10p-f {
  min-width: 10% !important;
}

.wd-15 {
  width: 15px;
}

.wd-15p {
  width: 15%;
}

.mx-wd-15p {
  max-width: 15%;
}

.mn-wd-15p {
  min-width: 15%;
}

.wd-15-f {
  width: 15px !important;
}

.wd-15p-f {
  width: 15% !important;
}

.mx-wd-15p-f {
  max-width: 15% !important;
}

.mn-wd-15p-f {
  min-width: 15% !important;
}

.wd-20 {
  width: 20px;
}

.wd-20p {
  width: 20%;
}

.mx-wd-20p {
  max-width: 20%;
}

.mn-wd-20p {
  min-width: 20%;
}

.wd-20-f {
  width: 20px !important;
}

.wd-20p-f {
  width: 20% !important;
}

.mx-wd-20p-f {
  max-width: 20% !important;
}

.mn-wd-20p-f {
  min-width: 20% !important;
}

.wd-25 {
  width: 25px;
}

.wd-25p {
  width: 25%;
}

.mx-wd-25p {
  max-width: 25%;
}

.mn-wd-25p {
  min-width: 25%;
}

.wd-25-f {
  width: 25px !important;
}

.wd-25p-f {
  width: 25% !important;
}

.mx-wd-25p-f {
  max-width: 25% !important;
}

.mn-wd-25p-f {
  min-width: 25% !important;
}

.wd-30 {
  width: 30px;
}

.wd-30p {
  width: 30%;
}

.mx-wd-30p {
  max-width: 30%;
}

.mn-wd-30p {
  min-width: 30%;
}

.wd-30-f {
  width: 30px !important;
}

.wd-30p-f {
  width: 30% !important;
}

.mx-wd-30p-f {
  max-width: 30% !important;
}

.mn-wd-30p-f {
  min-width: 30% !important;
}

.wd-35 {
  width: 35px;
}

.wd-35p {
  width: 35%;
}

.mx-wd-35p {
  max-width: 35%;
}

.mn-wd-35p {
  min-width: 35%;
}

.wd-35-f {
  width: 35px !important;
}

.wd-35p-f {
  width: 35% !important;
}

.mx-wd-35p-f {
  max-width: 35% !important;
}

.mn-wd-35p-f {
  min-width: 35% !important;
}

.wd-40 {
  width: 40px;
}

.wd-40p {
  width: 40%;
}

.mx-wd-40p {
  max-width: 40%;
}

.mn-wd-40p {
  min-width: 40%;
}

.wd-40-f {
  width: 40px !important;
}

.wd-40p-f {
  width: 40% !important;
}

.mx-wd-40p-f {
  max-width: 40% !important;
}

.mn-wd-40p-f {
  min-width: 40% !important;
}

.wd-45 {
  width: 45px;
}

.wd-45p {
  width: 45%;
}

.mx-wd-45p {
  max-width: 45%;
}

.mn-wd-45p {
  min-width: 45%;
}

.wd-45-f {
  width: 45px !important;
}

.wd-45p-f {
  width: 45% !important;
}

.mx-wd-45p-f {
  max-width: 45% !important;
}

.mn-wd-45p-f {
  min-width: 45% !important;
}

.wd-50 {
  width: 50px;
}

.wd-50p {
  width: 50%;
}

.mx-wd-50p {
  max-width: 50%;
}

.mn-wd-50p {
  min-width: 50%;
}

.wd-50-f {
  width: 50px !important;
}

.wd-50p-f {
  width: 50% !important;
}

.mx-wd-50p-f {
  max-width: 50% !important;
}

.mn-wd-50p-f {
  min-width: 50% !important;
}

.wd-55 {
  width: 55px;
}

.wd-55p {
  width: 55%;
}

.mx-wd-55p {
  max-width: 55%;
}

.mn-wd-55p {
  min-width: 55%;
}

.wd-55-f {
  width: 55px !important;
}

.wd-55p-f {
  width: 55% !important;
}

.mx-wd-55p-f {
  max-width: 55% !important;
}

.mn-wd-55p-f {
  min-width: 55% !important;
}

.wd-60 {
  width: 60px;
}

.wd-60p {
  width: 60%;
}

.mx-wd-60p {
  max-width: 60%;
}

.mn-wd-60p {
  min-width: 60%;
}

.wd-60-f {
  width: 60px !important;
}

.wd-60p-f {
  width: 60% !important;
}

.mx-wd-60p-f {
  max-width: 60% !important;
}

.mn-wd-60p-f {
  min-width: 60% !important;
}

.wd-65 {
  width: 65px;
}

.wd-65p {
  width: 65%;
}

.mx-wd-65p {
  max-width: 65%;
}

.mn-wd-65p {
  min-width: 65%;
}

.wd-65-f {
  width: 65px !important;
}

.wd-65p-f {
  width: 65% !important;
}

.mx-wd-65p-f {
  max-width: 65% !important;
}

.mn-wd-65p-f {
  min-width: 65% !important;
}

.wd-70 {
  width: 70px;
}

.wd-70p {
  width: 70%;
}

.mx-wd-70p {
  max-width: 70%;
}

.mn-wd-70p {
  min-width: 70%;
}

.wd-70-f {
  width: 70px !important;
}

.wd-70p-f {
  width: 70% !important;
}

.mx-wd-70p-f {
  max-width: 70% !important;
}

.mn-wd-70p-f {
  min-width: 70% !important;
}

.wd-75 {
  width: 75px;
}

.wd-75p {
  width: 75%;
}

.mx-wd-75p {
  max-width: 75%;
}

.mn-wd-75p {
  min-width: 75%;
}

.wd-75-f {
  width: 75px !important;
}

.wd-75p-f {
  width: 75% !important;
}

.mx-wd-75p-f {
  max-width: 75% !important;
}

.mn-wd-75p-f {
  min-width: 75% !important;
}

.wd-80 {
  width: 80px;
}

.wd-80p {
  width: 80%;
}

.mx-wd-80p {
  max-width: 80%;
}

.mn-wd-80p {
  min-width: 80%;
}

.wd-80-f {
  width: 80px !important;
}

.wd-80p-f {
  width: 80% !important;
}

.mx-wd-80p-f {
  max-width: 80% !important;
}

.mn-wd-80p-f {
  min-width: 80% !important;
}

.wd-85 {
  width: 85px;
}

.wd-85p {
  width: 85%;
}

.mx-wd-85p {
  max-width: 85%;
}

.mn-wd-85p {
  min-width: 85%;
}

.wd-85-f {
  width: 85px !important;
}

.wd-85p-f {
  width: 85% !important;
}

.mx-wd-85p-f {
  max-width: 85% !important;
}

.mn-wd-85p-f {
  min-width: 85% !important;
}

.wd-90 {
  width: 90px;
}

.wd-90p {
  width: 90%;
}

.mx-wd-90p {
  max-width: 90%;
}

.mn-wd-90p {
  min-width: 90%;
}

.wd-90-f {
  width: 90px !important;
}

.wd-90p-f {
  width: 90% !important;
}

.mx-wd-90p-f {
  max-width: 90% !important;
}

.mn-wd-90p-f {
  min-width: 90% !important;
}

.wd-95 {
  width: 95px;
}

.wd-95p {
  width: 95%;
}

.mx-wd-95p {
  max-width: 95%;
}

.mn-wd-95p {
  min-width: 95%;
}

.wd-95-f {
  width: 95px !important;
}

.wd-95p-f {
  width: 95% !important;
}

.mx-wd-95p-f {
  max-width: 95% !important;
}

.mn-wd-95p-f {
  min-width: 95% !important;
}

.wd-100 {
  width: 100px;
}

.wd-100p {
  width: 100%;
}

.mx-wd-100p {
  max-width: 100%;
}

.mn-wd-100p {
  min-width: 100%;
}

.wd-100-f {
  width: 100px !important;
}

.wd-100p-f {
  width: 100% !important;
}

.mx-wd-100p-f {
  max-width: 100% !important;
}

.mn-wd-100p-f {
  min-width: 100% !important;
}

.wd-150 {
  width: 150px;
}

.wd-150-f {
  width: 150px !important;
}

.mn-wd-150 {
  min-width: 150px;
}

.mx-wd-150 {
  max-width: 150px;
}

.mn-wd-150-f {
  min-width: 150px !important;
}

.mx-wd-150-f {
  max-width: 150px !important;
}

.wd-200 {
  width: 200px;
}

.wd-200-f {
  width: 200px !important;
}

.mn-wd-200 {
  min-width: 200px;
}

.mx-wd-200 {
  max-width: 200px;
}

.mn-wd-200-f {
  min-width: 200px !important;
}

.mx-wd-200-f {
  max-width: 200px !important;
}

.wd-250 {
  width: 250px;
}

.wd-250-f {
  width: 250px !important;
}

.mn-wd-250 {
  min-width: 250px;
}

.mx-wd-250 {
  max-width: 250px;
}

.mn-wd-250-f {
  min-width: 250px !important;
}

.mx-wd-250-f {
  max-width: 250px !important;
}

.wd-300 {
  width: 300px;
}

.wd-300-f {
  width: 300px !important;
}

.mn-wd-300 {
  min-width: 300px;
}

.mx-wd-300 {
  max-width: 300px;
}

.mn-wd-300-f {
  min-width: 300px !important;
}

.mx-wd-300-f {
  max-width: 300px !important;
}

.wd-350 {
  width: 350px;
}

.wd-350-f {
  width: 350px !important;
}

.mn-wd-350 {
  min-width: 350px;
}

.mx-wd-350 {
  max-width: 350px;
}

.mn-wd-350-f {
  min-width: 350px !important;
}

.mx-wd-350-f {
  max-width: 350px !important;
}

.wd-400 {
  width: 400px;
}

.wd-400-f {
  width: 400px !important;
}

.mn-wd-400 {
  min-width: 400px;
}

.mx-wd-400 {
  max-width: 400px;
}

.mn-wd-400-f {
  min-width: 400px !important;
}

.mx-wd-400-f {
  max-width: 400px !important;
}

.wd-450 {
  width: 450px;
}

.wd-450-f {
  width: 450px !important;
}

.mn-wd-450 {
  min-width: 450px;
}

.mx-wd-450 {
  max-width: 450px;
}

.mn-wd-450-f {
  min-width: 450px !important;
}

.mx-wd-450-f {
  max-width: 450px !important;
}

.wd-500 {
  width: 500px;
}

.wd-500-f {
  width: 500px !important;
}

.mn-wd-500 {
  min-width: 500px;
}

.mx-wd-500 {
  max-width: 500px;
}

.mn-wd-500-f {
  min-width: 500px !important;
}

.mx-wd-500-f {
  max-width: 500px !important;
}

.wd-550 {
  width: 550px;
}

.wd-550-f {
  width: 550px !important;
}

.mn-wd-550 {
  min-width: 550px;
}

.mx-wd-550 {
  max-width: 550px;
}

.mn-wd-550-f {
  min-width: 550px !important;
}

.mx-wd-550-f {
  max-width: 550px !important;
}

.wd-600 {
  width: 600px;
}

.wd-600-f {
  width: 600px !important;
}

.mn-wd-600 {
  min-width: 600px;
}

.mx-wd-600 {
  max-width: 600px;
}

.mn-wd-600-f {
  min-width: 600px !important;
}

.mx-wd-600-f {
  max-width: 600px !important;
}

.wd-650 {
  width: 650px;
}

.wd-650-f {
  width: 650px !important;
}

.mn-wd-650 {
  min-width: 650px;
}

.mx-wd-650 {
  max-width: 650px;
}

.mn-wd-650-f {
  min-width: 650px !important;
}

.mx-wd-650-f {
  max-width: 650px !important;
}

.wd-700 {
  width: 700px;
}

.wd-700-f {
  width: 700px !important;
}

.mn-wd-700 {
  min-width: 700px;
}

.mx-wd-700 {
  max-width: 700px;
}

.mn-wd-700-f {
  min-width: 700px !important;
}

.mx-wd-700-f {
  max-width: 700px !important;
}

.wd-750 {
  width: 750px;
}

.wd-750-f {
  width: 750px !important;
}

.mn-wd-750 {
  min-width: 750px;
}

.mx-wd-750 {
  max-width: 750px;
}

.mn-wd-750-f {
  min-width: 750px !important;
}

.mx-wd-750-f {
  max-width: 750px !important;
}

.wd-800 {
  width: 800px;
}

.wd-800-f {
  width: 800px !important;
}

.mn-wd-800 {
  min-width: 800px;
}

.mx-wd-800 {
  max-width: 800px;
}

.mn-wd-800-f {
  min-width: 800px !important;
}

.mx-wd-800-f {
  max-width: 800px !important;
}

.wd-850 {
  width: 850px;
}

.wd-850-f {
  width: 850px !important;
}

.mn-wd-850 {
  min-width: 850px;
}

.mx-wd-850 {
  max-width: 850px;
}

.mn-wd-850-f {
  min-width: 850px !important;
}

.mx-wd-850-f {
  max-width: 850px !important;
}

.wd-900 {
  width: 900px;
}

.wd-900-f {
  width: 900px !important;
}

.mn-wd-900 {
  min-width: 900px;
}

.mx-wd-900 {
  max-width: 900px;
}

.mn-wd-900-f {
  min-width: 900px !important;
}

.mx-wd-900-f {
  max-width: 900px !important;
}

.wd-950 {
  width: 950px;
}

.wd-950-f {
  width: 950px !important;
}

.mn-wd-950 {
  min-width: 950px;
}

.mx-wd-950 {
  max-width: 950px;
}

.mn-wd-950-f {
  min-width: 950px !important;
}

.mx-wd-950-f {
  max-width: 950px !important;
}

.wd-1000 {
  width: 1000px;
}

.wd-1000-f {
  width: 1000px !important;
}

.mn-wd-1000 {
  min-width: 1000px;
}

.mx-wd-1000 {
  max-width: 1000px;
}

.mn-wd-1000-f {
  min-width: 1000px !important;
}

.mx-wd-1000-f {
  max-width: 1000px !important;
}

.wd-1050 {
  width: 1050px;
}

.wd-1050-f {
  width: 1050px !important;
}

.mn-wd-1050 {
  min-width: 1050px;
}

.mx-wd-1050 {
  max-width: 1050px;
}

.mn-wd-1050-f {
  min-width: 1050px !important;
}

.mx-wd-1050-f {
  max-width: 1050px !important;
}

.wd-1100 {
  width: 1100px;
}

.wd-1100-f {
  width: 1100px !important;
}

.mn-wd-1100 {
  min-width: 1100px;
}

.mx-wd-1100 {
  max-width: 1100px;
}

.mn-wd-1100-f {
  min-width: 1100px !important;
}

.mx-wd-1100-f {
  max-width: 1100px !important;
}

.wd-1150 {
  width: 1150px;
}

.wd-1150-f {
  width: 1150px !important;
}

.mn-wd-1150 {
  min-width: 1150px;
}

.mx-wd-1150 {
  max-width: 1150px;
}

.mn-wd-1150-f {
  min-width: 1150px !important;
}

.mx-wd-1150-f {
  max-width: 1150px !important;
}

.wd-1200 {
  width: 1200px;
}

.wd-1200-f {
  width: 1200px !important;
}

.mn-wd-1200 {
  min-width: 1200px;
}

.mx-wd-1200 {
  max-width: 1200px;
}

.mn-wd-1200-f {
  min-width: 1200px !important;
}

.mx-wd-1200-f {
  max-width: 1200px !important;
}

.wd-1250 {
  width: 1250px;
}

.wd-1250-f {
  width: 1250px !important;
}

.mn-wd-1250 {
  min-width: 1250px;
}

.mx-wd-1250 {
  max-width: 1250px;
}

.mn-wd-1250-f {
  min-width: 1250px !important;
}

.mx-wd-1250-f {
  max-width: 1250px !important;
}

.wd-1300 {
  width: 1300px;
}

.wd-1300-f {
  width: 1300px !important;
}

.mn-wd-1300 {
  min-width: 1300px;
}

.mx-wd-1300 {
  max-width: 1300px;
}

.mn-wd-1300-f {
  min-width: 1300px !important;
}

.mx-wd-1300-f {
  max-width: 1300px !important;
}

.wd-1350 {
  width: 1350px;
}

.wd-1350-f {
  width: 1350px !important;
}

.mn-wd-1350 {
  min-width: 1350px;
}

.mx-wd-1350 {
  max-width: 1350px;
}

.mn-wd-1350-f {
  min-width: 1350px !important;
}

.mx-wd-1350-f {
  max-width: 1350px !important;
}

.wd-1400 {
  width: 1400px;
}

.wd-1400-f {
  width: 1400px !important;
}

.mn-wd-1400 {
  min-width: 1400px;
}

.mx-wd-1400 {
  max-width: 1400px;
}

.mn-wd-1400-f {
  min-width: 1400px !important;
}

.mx-wd-1400-f {
  max-width: 1400px !important;
}

.wd-1450 {
  width: 1450px;
}

.wd-1450-f {
  width: 1450px !important;
}

.mn-wd-1450 {
  min-width: 1450px;
}

.mx-wd-1450 {
  max-width: 1450px;
}

.mn-wd-1450-f {
  min-width: 1450px !important;
}

.mx-wd-1450-f {
  max-width: 1450px !important;
}

.wd-1500 {
  width: 1500px;
}

.wd-1500-f {
  width: 1500px !important;
}

.mn-wd-1500 {
  min-width: 1500px;
}

.mx-wd-1500 {
  max-width: 1500px;
}

.mn-wd-1500-f {
  min-width: 1500px !important;
}

.mx-wd-1500-f {
  max-width: 1500px !important;
}

.wd-1550 {
  width: 1550px;
}

.wd-1550-f {
  width: 1550px !important;
}

.mn-wd-1550 {
  min-width: 1550px;
}

.mx-wd-1550 {
  max-width: 1550px;
}

.mn-wd-1550-f {
  min-width: 1550px !important;
}

.mx-wd-1550-f {
  max-width: 1550px !important;
}

.wd-1600 {
  width: 1600px;
}

.wd-1600-f {
  width: 1600px !important;
}

.mn-wd-1600 {
  min-width: 1600px;
}

.mx-wd-1600 {
  max-width: 1600px;
}

.mn-wd-1600-f {
  min-width: 1600px !important;
}

.mx-wd-1600-f {
  max-width: 1600px !important;
}

.wd-1650 {
  width: 1650px;
}

.wd-1650-f {
  width: 1650px !important;
}

.mn-wd-1650 {
  min-width: 1650px;
}

.mx-wd-1650 {
  max-width: 1650px;
}

.mn-wd-1650-f {
  min-width: 1650px !important;
}

.mx-wd-1650-f {
  max-width: 1650px !important;
}

.wd-1700 {
  width: 1700px;
}

.wd-1700-f {
  width: 1700px !important;
}

.mn-wd-1700 {
  min-width: 1700px;
}

.mx-wd-1700 {
  max-width: 1700px;
}

.mn-wd-1700-f {
  min-width: 1700px !important;
}

.mx-wd-1700-f {
  max-width: 1700px !important;
}

.wd-1750 {
  width: 1750px;
}

.wd-1750-f {
  width: 1750px !important;
}

.mn-wd-1750 {
  min-width: 1750px;
}

.mx-wd-1750 {
  max-width: 1750px;
}

.mn-wd-1750-f {
  min-width: 1750px !important;
}

.mx-wd-1750-f {
  max-width: 1750px !important;
}

.wd-1800 {
  width: 1800px;
}

.wd-1800-f {
  width: 1800px !important;
}

.mn-wd-1800 {
  min-width: 1800px;
}

.mx-wd-1800 {
  max-width: 1800px;
}

.mn-wd-1800-f {
  min-width: 1800px !important;
}

.mx-wd-1800-f {
  max-width: 1800px !important;
}

.wd-1850 {
  width: 1850px;
}

.wd-1850-f {
  width: 1850px !important;
}

.mn-wd-1850 {
  min-width: 1850px;
}

.mx-wd-1850 {
  max-width: 1850px;
}

.mn-wd-1850-f {
  min-width: 1850px !important;
}

.mx-wd-1850-f {
  max-width: 1850px !important;
}

.wd-1900 {
  width: 1900px;
}

.wd-1900-f {
  width: 1900px !important;
}

.mn-wd-1900 {
  min-width: 1900px;
}

.mx-wd-1900 {
  max-width: 1900px;
}

.mn-wd-1900-f {
  min-width: 1900px !important;
}

.mx-wd-1900-f {
  max-width: 1900px !important;
}

.wd-1950 {
  width: 1950px;
}

.wd-1950-f {
  width: 1950px !important;
}

.mn-wd-1950 {
  min-width: 1950px;
}

.mx-wd-1950 {
  max-width: 1950px;
}

.mn-wd-1950-f {
  min-width: 1950px !important;
}

.mx-wd-1950-f {
  max-width: 1950px !important;
}

.wd-2000 {
  width: 2000px;
}

.wd-2000-f {
  width: 2000px !important;
}

.mn-wd-2000 {
  min-width: 2000px;
}

.mx-wd-2000 {
  max-width: 2000px;
}

.mn-wd-2000-f {
  min-width: 2000px !important;
}

.mx-wd-2000-f {
  max-width: 2000px !important;
}

.wd-2050 {
  width: 2050px;
}

.wd-2050-f {
  width: 2050px !important;
}

.mn-wd-2050 {
  min-width: 2050px;
}

.mx-wd-2050 {
  max-width: 2050px;
}

.mn-wd-2050-f {
  min-width: 2050px !important;
}

.mx-wd-2050-f {
  max-width: 2050px !important;
}

.wd-2100 {
  width: 2100px;
}

.wd-2100-f {
  width: 2100px !important;
}

.mn-wd-2100 {
  min-width: 2100px;
}

.mx-wd-2100 {
  max-width: 2100px;
}

.mn-wd-2100-f {
  min-width: 2100px !important;
}

.mx-wd-2100-f {
  max-width: 2100px !important;
}

.wd-2150 {
  width: 2150px;
}

.wd-2150-f {
  width: 2150px !important;
}

.mn-wd-2150 {
  min-width: 2150px;
}

.mx-wd-2150 {
  max-width: 2150px;
}

.mn-wd-2150-f {
  min-width: 2150px !important;
}

.mx-wd-2150-f {
  max-width: 2150px !important;
}

.wd-2200 {
  width: 2200px;
}

.wd-2200-f {
  width: 2200px !important;
}

.mn-wd-2200 {
  min-width: 2200px;
}

.mx-wd-2200 {
  max-width: 2200px;
}

.mn-wd-2200-f {
  min-width: 2200px !important;
}

.mx-wd-2200-f {
  max-width: 2200px !important;
}

.wd-2250 {
  width: 2250px;
}

.wd-2250-f {
  width: 2250px !important;
}

.mn-wd-2250 {
  min-width: 2250px;
}

.mx-wd-2250 {
  max-width: 2250px;
}

.mn-wd-2250-f {
  min-width: 2250px !important;
}

.mx-wd-2250-f {
  max-width: 2250px !important;
}

.wd-2300 {
  width: 2300px;
}

.wd-2300-f {
  width: 2300px !important;
}

.mn-wd-2300 {
  min-width: 2300px;
}

.mx-wd-2300 {
  max-width: 2300px;
}

.mn-wd-2300-f {
  min-width: 2300px !important;
}

.mx-wd-2300-f {
  max-width: 2300px !important;
}

.wd-2350 {
  width: 2350px;
}

.wd-2350-f {
  width: 2350px !important;
}

.mn-wd-2350 {
  min-width: 2350px;
}

.mx-wd-2350 {
  max-width: 2350px;
}

.mn-wd-2350-f {
  min-width: 2350px !important;
}

.mx-wd-2350-f {
  max-width: 2350px !important;
}

.wd-2400 {
  width: 2400px;
}

.wd-2400-f {
  width: 2400px !important;
}

.mn-wd-2400 {
  min-width: 2400px;
}

.mx-wd-2400 {
  max-width: 2400px;
}

.mn-wd-2400-f {
  min-width: 2400px !important;
}

.mx-wd-2400-f {
  max-width: 2400px !important;
}

.wd-2450 {
  width: 2450px;
}

.wd-2450-f {
  width: 2450px !important;
}

.mn-wd-2450 {
  min-width: 2450px;
}

.mx-wd-2450 {
  max-width: 2450px;
}

.mn-wd-2450-f {
  min-width: 2450px !important;
}

.mx-wd-2450-f {
  max-width: 2450px !important;
}

.wd-2500 {
  width: 2500px;
}

.wd-2500-f {
  width: 2500px !important;
}

.mn-wd-2500 {
  min-width: 2500px;
}

.mx-wd-2500 {
  max-width: 2500px;
}

.mn-wd-2500-f {
  min-width: 2500px !important;
}

.mx-wd-2500-f {
  max-width: 2500px !important;
}

/* ----------------------------------------------------------------
	excerpt
-----------------------------------------------------------------*/
.excerpt-1 {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
}

@media (max-width: 767.98px) {
  .excerpt-sm-1 {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

@media (max-width: 991.98px) {
  .excerpt-md-1 {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

@media (max-width: 1199.98px) {
  .excerpt-lg-1 {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
  }
}

.excerpt-2 {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

@media (max-width: 767.98px) {
  .excerpt-sm-2 {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

@media (max-width: 991.98px) {
  .excerpt-md-2 {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

@media (max-width: 1199.98px) {
  .excerpt-lg-2 {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

.excerpt-3 {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

@media (max-width: 767.98px) {
  .excerpt-sm-3 {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

@media (max-width: 991.98px) {
  .excerpt-md-3 {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

@media (max-width: 1199.98px) {
  .excerpt-lg-3 {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
  }
}

.excerpt-4 {
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

@media (max-width: 767.98px) {
  .excerpt-sm-4 {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
}

@media (max-width: 991.98px) {
  .excerpt-md-4 {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
}

@media (max-width: 1199.98px) {
  .excerpt-lg-4 {
    text-overflow: ellipsis;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 4;
    -webkit-box-orient: vertical;
  }
}

/* ----------------------------------------------------------------
	font-size
-----------------------------------------------------------------*/
.fs-5 {
  font-size: 5px;
}

.fs-5-f {
  font-size: 5px !important;
}

.fs-sm-5 {
  font-size: 5px;
}

.fs-sm-5-f {
  font-size: 5px !important;
}

@media (min-width: 576px) {
  .fs-md-5 {
    font-size: 5px;
  }
}

@media (min-width: 576px) {
  .fs-md-5-f {
    font-size: 5px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-5 {
    font-size: 5px;
  }
}

@media (min-width: 768px) {
  .fs-lg-5-f {
    font-size: 5px !important;
  }
}

.fs-6 {
  font-size: 6px;
}

.fs-6-f {
  font-size: 6px !important;
}

.fs-sm-6 {
  font-size: 6px;
}

.fs-sm-6-f {
  font-size: 6px !important;
}

@media (min-width: 576px) {
  .fs-md-6 {
    font-size: 6px;
  }
}

@media (min-width: 576px) {
  .fs-md-6-f {
    font-size: 6px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-6 {
    font-size: 6px;
  }
}

@media (min-width: 768px) {
  .fs-lg-6-f {
    font-size: 6px !important;
  }
}

.fs-7 {
  font-size: 7px;
}

.fs-7-f {
  font-size: 7px !important;
}

.fs-sm-7 {
  font-size: 7px;
}

.fs-sm-7-f {
  font-size: 7px !important;
}

@media (min-width: 576px) {
  .fs-md-7 {
    font-size: 7px;
  }
}

@media (min-width: 576px) {
  .fs-md-7-f {
    font-size: 7px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-7 {
    font-size: 7px;
  }
}

@media (min-width: 768px) {
  .fs-lg-7-f {
    font-size: 7px !important;
  }
}

.fs-8 {
  font-size: 8px;
}

.fs-8-f {
  font-size: 8px !important;
}

.fs-sm-8 {
  font-size: 8px;
}

.fs-sm-8-f {
  font-size: 8px !important;
}

@media (min-width: 576px) {
  .fs-md-8 {
    font-size: 8px;
  }
}

@media (min-width: 576px) {
  .fs-md-8-f {
    font-size: 8px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-8 {
    font-size: 8px;
  }
}

@media (min-width: 768px) {
  .fs-lg-8-f {
    font-size: 8px !important;
  }
}

.fs-9 {
  font-size: 9px;
}

.fs-9-f {
  font-size: 9px !important;
}

.fs-sm-9 {
  font-size: 9px;
}

.fs-sm-9-f {
  font-size: 9px !important;
}

@media (min-width: 576px) {
  .fs-md-9 {
    font-size: 9px;
  }
}

@media (min-width: 576px) {
  .fs-md-9-f {
    font-size: 9px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-9 {
    font-size: 9px;
  }
}

@media (min-width: 768px) {
  .fs-lg-9-f {
    font-size: 9px !important;
  }
}

.fs-10 {
  font-size: 10px;
}

.fs-10-f {
  font-size: 10px !important;
}

.fs-sm-10 {
  font-size: 10px;
}

.fs-sm-10-f {
  font-size: 10px !important;
}

@media (min-width: 576px) {
  .fs-md-10 {
    font-size: 10px;
  }
}

@media (min-width: 576px) {
  .fs-md-10-f {
    font-size: 10px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-10 {
    font-size: 10px;
  }
}

@media (min-width: 768px) {
  .fs-lg-10-f {
    font-size: 10px !important;
  }
}

.fs-11 {
  font-size: 11px;
}

.fs-11-f {
  font-size: 11px !important;
}

.fs-sm-11 {
  font-size: 11px;
}

.fs-sm-11-f {
  font-size: 11px !important;
}

@media (min-width: 576px) {
  .fs-md-11 {
    font-size: 11px;
  }
}

@media (min-width: 576px) {
  .fs-md-11-f {
    font-size: 11px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-11 {
    font-size: 11px;
  }
}

@media (min-width: 768px) {
  .fs-lg-11-f {
    font-size: 11px !important;
  }
}

.fs-12 {
  font-size: 12px;
}

.fs-12-f {
  font-size: 12px !important;
}

.fs-sm-12 {
  font-size: 12px;
}

.fs-sm-12-f {
  font-size: 12px !important;
}

@media (min-width: 576px) {
  .fs-md-12 {
    font-size: 12px;
  }
}

@media (min-width: 576px) {
  .fs-md-12-f {
    font-size: 12px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-12 {
    font-size: 12px;
  }
}

@media (min-width: 768px) {
  .fs-lg-12-f {
    font-size: 12px !important;
  }
}

.fs-13 {
  font-size: 13px;
}

.fs-13-f {
  font-size: 13px !important;
}

.fs-sm-13 {
  font-size: 13px;
}

.fs-sm-13-f {
  font-size: 13px !important;
}

@media (min-width: 576px) {
  .fs-md-13 {
    font-size: 13px;
  }
}

@media (min-width: 576px) {
  .fs-md-13-f {
    font-size: 13px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-13 {
    font-size: 13px;
  }
}

@media (min-width: 768px) {
  .fs-lg-13-f {
    font-size: 13px !important;
  }
}

.fs-14 {
  font-size: 14px;
}

.fs-14-f {
  font-size: 14px !important;
}

.fs-sm-14 {
  font-size: 14px;
}

.fs-sm-14-f {
  font-size: 14px !important;
}

@media (min-width: 576px) {
  .fs-md-14 {
    font-size: 14px;
  }
}

@media (min-width: 576px) {
  .fs-md-14-f {
    font-size: 14px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-14 {
    font-size: 14px;
  }
}

@media (min-width: 768px) {
  .fs-lg-14-f {
    font-size: 14px !important;
  }
}

.fs-15 {
  font-size: 15px;
}

.fs-15-f {
  font-size: 15px !important;
}

.fs-sm-15 {
  font-size: 15px;
}

.fs-sm-15-f {
  font-size: 15px !important;
}

@media (min-width: 576px) {
  .fs-md-15 {
    font-size: 15px;
  }
}

@media (min-width: 576px) {
  .fs-md-15-f {
    font-size: 15px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-15 {
    font-size: 15px;
  }
}

@media (min-width: 768px) {
  .fs-lg-15-f {
    font-size: 15px !important;
  }
}

.fs-16 {
  font-size: 16px;
}

.fs-16-f {
  font-size: 16px !important;
}

.fs-sm-16 {
  font-size: 16px;
}

.fs-sm-16-f {
  font-size: 16px !important;
}

@media (min-width: 576px) {
  .fs-md-16 {
    font-size: 16px;
  }
}

@media (min-width: 576px) {
  .fs-md-16-f {
    font-size: 16px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-16 {
    font-size: 16px;
  }
}

@media (min-width: 768px) {
  .fs-lg-16-f {
    font-size: 16px !important;
  }
}

.fs-17 {
  font-size: 17px;
}

.fs-17-f {
  font-size: 17px !important;
}

.fs-sm-17 {
  font-size: 17px;
}

.fs-sm-17-f {
  font-size: 17px !important;
}

@media (min-width: 576px) {
  .fs-md-17 {
    font-size: 17px;
  }
}

@media (min-width: 576px) {
  .fs-md-17-f {
    font-size: 17px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-17 {
    font-size: 17px;
  }
}

@media (min-width: 768px) {
  .fs-lg-17-f {
    font-size: 17px !important;
  }
}

.fs-18 {
  font-size: 18px;
}

.fs-18-f {
  font-size: 18px !important;
}

.fs-sm-18 {
  font-size: 18px;
}

.fs-sm-18-f {
  font-size: 18px !important;
}

@media (min-width: 576px) {
  .fs-md-18 {
    font-size: 18px;
  }
}

@media (min-width: 576px) {
  .fs-md-18-f {
    font-size: 18px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-18 {
    font-size: 18px;
  }
}

@media (min-width: 768px) {
  .fs-lg-18-f {
    font-size: 18px !important;
  }
}

.fs-19 {
  font-size: 19px;
}

.fs-19-f {
  font-size: 19px !important;
}

.fs-sm-19 {
  font-size: 19px;
}

.fs-sm-19-f {
  font-size: 19px !important;
}

@media (min-width: 576px) {
  .fs-md-19 {
    font-size: 19px;
  }
}

@media (min-width: 576px) {
  .fs-md-19-f {
    font-size: 19px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-19 {
    font-size: 19px;
  }
}

@media (min-width: 768px) {
  .fs-lg-19-f {
    font-size: 19px !important;
  }
}

.fs-20 {
  font-size: 20px;
}

.fs-20-f {
  font-size: 20px !important;
}

.fs-sm-20 {
  font-size: 20px;
}

.fs-sm-20-f {
  font-size: 20px !important;
}

@media (min-width: 576px) {
  .fs-md-20 {
    font-size: 20px;
  }
}

@media (min-width: 576px) {
  .fs-md-20-f {
    font-size: 20px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-20 {
    font-size: 20px;
  }
}

@media (min-width: 768px) {
  .fs-lg-20-f {
    font-size: 20px !important;
  }
}

.fs-21 {
  font-size: 21px;
}

.fs-21-f {
  font-size: 21px !important;
}

.fs-sm-21 {
  font-size: 21px;
}

.fs-sm-21-f {
  font-size: 21px !important;
}

@media (min-width: 576px) {
  .fs-md-21 {
    font-size: 21px;
  }
}

@media (min-width: 576px) {
  .fs-md-21-f {
    font-size: 21px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-21 {
    font-size: 21px;
  }
}

@media (min-width: 768px) {
  .fs-lg-21-f {
    font-size: 21px !important;
  }
}

.fs-22 {
  font-size: 22px;
}

.fs-22-f {
  font-size: 22px !important;
}

.fs-sm-22 {
  font-size: 22px;
}

.fs-sm-22-f {
  font-size: 22px !important;
}

@media (min-width: 576px) {
  .fs-md-22 {
    font-size: 22px;
  }
}

@media (min-width: 576px) {
  .fs-md-22-f {
    font-size: 22px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-22 {
    font-size: 22px;
  }
}

@media (min-width: 768px) {
  .fs-lg-22-f {
    font-size: 22px !important;
  }
}

.fs-23 {
  font-size: 23px;
}

.fs-23-f {
  font-size: 23px !important;
}

.fs-sm-23 {
  font-size: 23px;
}

.fs-sm-23-f {
  font-size: 23px !important;
}

@media (min-width: 576px) {
  .fs-md-23 {
    font-size: 23px;
  }
}

@media (min-width: 576px) {
  .fs-md-23-f {
    font-size: 23px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-23 {
    font-size: 23px;
  }
}

@media (min-width: 768px) {
  .fs-lg-23-f {
    font-size: 23px !important;
  }
}

.fs-24 {
  font-size: 24px;
}

.fs-24-f {
  font-size: 24px !important;
}

.fs-sm-24 {
  font-size: 24px;
}

.fs-sm-24-f {
  font-size: 24px !important;
}

@media (min-width: 576px) {
  .fs-md-24 {
    font-size: 24px;
  }
}

@media (min-width: 576px) {
  .fs-md-24-f {
    font-size: 24px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-24 {
    font-size: 24px;
  }
}

@media (min-width: 768px) {
  .fs-lg-24-f {
    font-size: 24px !important;
  }
}

.fs-25 {
  font-size: 25px;
}

.fs-25-f {
  font-size: 25px !important;
}

.fs-sm-25 {
  font-size: 25px;
}

.fs-sm-25-f {
  font-size: 25px !important;
}

@media (min-width: 576px) {
  .fs-md-25 {
    font-size: 25px;
  }
}

@media (min-width: 576px) {
  .fs-md-25-f {
    font-size: 25px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-25 {
    font-size: 25px;
  }
}

@media (min-width: 768px) {
  .fs-lg-25-f {
    font-size: 25px !important;
  }
}

.fs-26 {
  font-size: 26px;
}

.fs-26-f {
  font-size: 26px !important;
}

.fs-sm-26 {
  font-size: 26px;
}

.fs-sm-26-f {
  font-size: 26px !important;
}

@media (min-width: 576px) {
  .fs-md-26 {
    font-size: 26px;
  }
}

@media (min-width: 576px) {
  .fs-md-26-f {
    font-size: 26px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-26 {
    font-size: 26px;
  }
}

@media (min-width: 768px) {
  .fs-lg-26-f {
    font-size: 26px !important;
  }
}

.fs-27 {
  font-size: 27px;
}

.fs-27-f {
  font-size: 27px !important;
}

.fs-sm-27 {
  font-size: 27px;
}

.fs-sm-27-f {
  font-size: 27px !important;
}

@media (min-width: 576px) {
  .fs-md-27 {
    font-size: 27px;
  }
}

@media (min-width: 576px) {
  .fs-md-27-f {
    font-size: 27px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-27 {
    font-size: 27px;
  }
}

@media (min-width: 768px) {
  .fs-lg-27-f {
    font-size: 27px !important;
  }
}

.fs-28 {
  font-size: 28px;
}

.fs-28-f {
  font-size: 28px !important;
}

.fs-sm-28 {
  font-size: 28px;
}

.fs-sm-28-f {
  font-size: 28px !important;
}

@media (min-width: 576px) {
  .fs-md-28 {
    font-size: 28px;
  }
}

@media (min-width: 576px) {
  .fs-md-28-f {
    font-size: 28px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-28 {
    font-size: 28px;
  }
}

@media (min-width: 768px) {
  .fs-lg-28-f {
    font-size: 28px !important;
  }
}

.fs-29 {
  font-size: 29px;
}

.fs-29-f {
  font-size: 29px !important;
}

.fs-sm-29 {
  font-size: 29px;
}

.fs-sm-29-f {
  font-size: 29px !important;
}

@media (min-width: 576px) {
  .fs-md-29 {
    font-size: 29px;
  }
}

@media (min-width: 576px) {
  .fs-md-29-f {
    font-size: 29px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-29 {
    font-size: 29px;
  }
}

@media (min-width: 768px) {
  .fs-lg-29-f {
    font-size: 29px !important;
  }
}

.fs-30 {
  font-size: 30px;
}

.fs-30-f {
  font-size: 30px !important;
}

.fs-sm-30 {
  font-size: 30px;
}

.fs-sm-30-f {
  font-size: 30px !important;
}

@media (min-width: 576px) {
  .fs-md-30 {
    font-size: 30px;
  }
}

@media (min-width: 576px) {
  .fs-md-30-f {
    font-size: 30px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-30 {
    font-size: 30px;
  }
}

@media (min-width: 768px) {
  .fs-lg-30-f {
    font-size: 30px !important;
  }
}

.fs-31 {
  font-size: 31px;
}

.fs-31-f {
  font-size: 31px !important;
}

.fs-sm-31 {
  font-size: 31px;
}

.fs-sm-31-f {
  font-size: 31px !important;
}

@media (min-width: 576px) {
  .fs-md-31 {
    font-size: 31px;
  }
}

@media (min-width: 576px) {
  .fs-md-31-f {
    font-size: 31px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-31 {
    font-size: 31px;
  }
}

@media (min-width: 768px) {
  .fs-lg-31-f {
    font-size: 31px !important;
  }
}

.fs-32 {
  font-size: 32px;
}

.fs-32-f {
  font-size: 32px !important;
}

.fs-sm-32 {
  font-size: 32px;
}

.fs-sm-32-f {
  font-size: 32px !important;
}

@media (min-width: 576px) {
  .fs-md-32 {
    font-size: 32px;
  }
}

@media (min-width: 576px) {
  .fs-md-32-f {
    font-size: 32px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-32 {
    font-size: 32px;
  }
}

@media (min-width: 768px) {
  .fs-lg-32-f {
    font-size: 32px !important;
  }
}

.fs-33 {
  font-size: 33px;
}

.fs-33-f {
  font-size: 33px !important;
}

.fs-sm-33 {
  font-size: 33px;
}

.fs-sm-33-f {
  font-size: 33px !important;
}

@media (min-width: 576px) {
  .fs-md-33 {
    font-size: 33px;
  }
}

@media (min-width: 576px) {
  .fs-md-33-f {
    font-size: 33px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-33 {
    font-size: 33px;
  }
}

@media (min-width: 768px) {
  .fs-lg-33-f {
    font-size: 33px !important;
  }
}

.fs-34 {
  font-size: 34px;
}

.fs-34-f {
  font-size: 34px !important;
}

.fs-sm-34 {
  font-size: 34px;
}

.fs-sm-34-f {
  font-size: 34px !important;
}

@media (min-width: 576px) {
  .fs-md-34 {
    font-size: 34px;
  }
}

@media (min-width: 576px) {
  .fs-md-34-f {
    font-size: 34px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-34 {
    font-size: 34px;
  }
}

@media (min-width: 768px) {
  .fs-lg-34-f {
    font-size: 34px !important;
  }
}

.fs-35 {
  font-size: 35px;
}

.fs-35-f {
  font-size: 35px !important;
}

.fs-sm-35 {
  font-size: 35px;
}

.fs-sm-35-f {
  font-size: 35px !important;
}

@media (min-width: 576px) {
  .fs-md-35 {
    font-size: 35px;
  }
}

@media (min-width: 576px) {
  .fs-md-35-f {
    font-size: 35px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-35 {
    font-size: 35px;
  }
}

@media (min-width: 768px) {
  .fs-lg-35-f {
    font-size: 35px !important;
  }
}

.fs-36 {
  font-size: 36px;
}

.fs-36-f {
  font-size: 36px !important;
}

.fs-sm-36 {
  font-size: 36px;
}

.fs-sm-36-f {
  font-size: 36px !important;
}

@media (min-width: 576px) {
  .fs-md-36 {
    font-size: 36px;
  }
}

@media (min-width: 576px) {
  .fs-md-36-f {
    font-size: 36px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-36 {
    font-size: 36px;
  }
}

@media (min-width: 768px) {
  .fs-lg-36-f {
    font-size: 36px !important;
  }
}

.fs-37 {
  font-size: 37px;
}

.fs-37-f {
  font-size: 37px !important;
}

.fs-sm-37 {
  font-size: 37px;
}

.fs-sm-37-f {
  font-size: 37px !important;
}

@media (min-width: 576px) {
  .fs-md-37 {
    font-size: 37px;
  }
}

@media (min-width: 576px) {
  .fs-md-37-f {
    font-size: 37px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-37 {
    font-size: 37px;
  }
}

@media (min-width: 768px) {
  .fs-lg-37-f {
    font-size: 37px !important;
  }
}

.fs-38 {
  font-size: 38px;
}

.fs-38-f {
  font-size: 38px !important;
}

.fs-sm-38 {
  font-size: 38px;
}

.fs-sm-38-f {
  font-size: 38px !important;
}

@media (min-width: 576px) {
  .fs-md-38 {
    font-size: 38px;
  }
}

@media (min-width: 576px) {
  .fs-md-38-f {
    font-size: 38px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-38 {
    font-size: 38px;
  }
}

@media (min-width: 768px) {
  .fs-lg-38-f {
    font-size: 38px !important;
  }
}

.fs-39 {
  font-size: 39px;
}

.fs-39-f {
  font-size: 39px !important;
}

.fs-sm-39 {
  font-size: 39px;
}

.fs-sm-39-f {
  font-size: 39px !important;
}

@media (min-width: 576px) {
  .fs-md-39 {
    font-size: 39px;
  }
}

@media (min-width: 576px) {
  .fs-md-39-f {
    font-size: 39px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-39 {
    font-size: 39px;
  }
}

@media (min-width: 768px) {
  .fs-lg-39-f {
    font-size: 39px !important;
  }
}

.fs-40 {
  font-size: 40px;
}

.fs-40-f {
  font-size: 40px !important;
}

.fs-sm-40 {
  font-size: 40px;
}

.fs-sm-40-f {
  font-size: 40px !important;
}

@media (min-width: 576px) {
  .fs-md-40 {
    font-size: 40px;
  }
}

@media (min-width: 576px) {
  .fs-md-40-f {
    font-size: 40px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-40 {
    font-size: 40px;
  }
}

@media (min-width: 768px) {
  .fs-lg-40-f {
    font-size: 40px !important;
  }
}

.fs-41 {
  font-size: 41px;
}

.fs-41-f {
  font-size: 41px !important;
}

.fs-sm-41 {
  font-size: 41px;
}

.fs-sm-41-f {
  font-size: 41px !important;
}

@media (min-width: 576px) {
  .fs-md-41 {
    font-size: 41px;
  }
}

@media (min-width: 576px) {
  .fs-md-41-f {
    font-size: 41px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-41 {
    font-size: 41px;
  }
}

@media (min-width: 768px) {
  .fs-lg-41-f {
    font-size: 41px !important;
  }
}

.fs-42 {
  font-size: 42px;
}

.fs-42-f {
  font-size: 42px !important;
}

.fs-sm-42 {
  font-size: 42px;
}

.fs-sm-42-f {
  font-size: 42px !important;
}

@media (min-width: 576px) {
  .fs-md-42 {
    font-size: 42px;
  }
}

@media (min-width: 576px) {
  .fs-md-42-f {
    font-size: 42px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-42 {
    font-size: 42px;
  }
}

@media (min-width: 768px) {
  .fs-lg-42-f {
    font-size: 42px !important;
  }
}

.fs-43 {
  font-size: 43px;
}

.fs-43-f {
  font-size: 43px !important;
}

.fs-sm-43 {
  font-size: 43px;
}

.fs-sm-43-f {
  font-size: 43px !important;
}

@media (min-width: 576px) {
  .fs-md-43 {
    font-size: 43px;
  }
}

@media (min-width: 576px) {
  .fs-md-43-f {
    font-size: 43px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-43 {
    font-size: 43px;
  }
}

@media (min-width: 768px) {
  .fs-lg-43-f {
    font-size: 43px !important;
  }
}

.fs-44 {
  font-size: 44px;
}

.fs-44-f {
  font-size: 44px !important;
}

.fs-sm-44 {
  font-size: 44px;
}

.fs-sm-44-f {
  font-size: 44px !important;
}

@media (min-width: 576px) {
  .fs-md-44 {
    font-size: 44px;
  }
}

@media (min-width: 576px) {
  .fs-md-44-f {
    font-size: 44px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-44 {
    font-size: 44px;
  }
}

@media (min-width: 768px) {
  .fs-lg-44-f {
    font-size: 44px !important;
  }
}

.fs-45 {
  font-size: 45px;
}

.fs-45-f {
  font-size: 45px !important;
}

.fs-sm-45 {
  font-size: 45px;
}

.fs-sm-45-f {
  font-size: 45px !important;
}

@media (min-width: 576px) {
  .fs-md-45 {
    font-size: 45px;
  }
}

@media (min-width: 576px) {
  .fs-md-45-f {
    font-size: 45px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-45 {
    font-size: 45px;
  }
}

@media (min-width: 768px) {
  .fs-lg-45-f {
    font-size: 45px !important;
  }
}

.fs-46 {
  font-size: 46px;
}

.fs-46-f {
  font-size: 46px !important;
}

.fs-sm-46 {
  font-size: 46px;
}

.fs-sm-46-f {
  font-size: 46px !important;
}

@media (min-width: 576px) {
  .fs-md-46 {
    font-size: 46px;
  }
}

@media (min-width: 576px) {
  .fs-md-46-f {
    font-size: 46px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-46 {
    font-size: 46px;
  }
}

@media (min-width: 768px) {
  .fs-lg-46-f {
    font-size: 46px !important;
  }
}

.fs-47 {
  font-size: 47px;
}

.fs-47-f {
  font-size: 47px !important;
}

.fs-sm-47 {
  font-size: 47px;
}

.fs-sm-47-f {
  font-size: 47px !important;
}

@media (min-width: 576px) {
  .fs-md-47 {
    font-size: 47px;
  }
}

@media (min-width: 576px) {
  .fs-md-47-f {
    font-size: 47px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-47 {
    font-size: 47px;
  }
}

@media (min-width: 768px) {
  .fs-lg-47-f {
    font-size: 47px !important;
  }
}

.fs-48 {
  font-size: 48px;
}

.fs-48-f {
  font-size: 48px !important;
}

.fs-sm-48 {
  font-size: 48px;
}

.fs-sm-48-f {
  font-size: 48px !important;
}

@media (min-width: 576px) {
  .fs-md-48 {
    font-size: 48px;
  }
}

@media (min-width: 576px) {
  .fs-md-48-f {
    font-size: 48px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-48 {
    font-size: 48px;
  }
}

@media (min-width: 768px) {
  .fs-lg-48-f {
    font-size: 48px !important;
  }
}

.fs-49 {
  font-size: 49px;
}

.fs-49-f {
  font-size: 49px !important;
}

.fs-sm-49 {
  font-size: 49px;
}

.fs-sm-49-f {
  font-size: 49px !important;
}

@media (min-width: 576px) {
  .fs-md-49 {
    font-size: 49px;
  }
}

@media (min-width: 576px) {
  .fs-md-49-f {
    font-size: 49px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-49 {
    font-size: 49px;
  }
}

@media (min-width: 768px) {
  .fs-lg-49-f {
    font-size: 49px !important;
  }
}

.fs-50 {
  font-size: 50px;
}

.fs-50-f {
  font-size: 50px !important;
}

.fs-sm-50 {
  font-size: 50px;
}

.fs-sm-50-f {
  font-size: 50px !important;
}

@media (min-width: 576px) {
  .fs-md-50 {
    font-size: 50px;
  }
}

@media (min-width: 576px) {
  .fs-md-50-f {
    font-size: 50px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-50 {
    font-size: 50px;
  }
}

@media (min-width: 768px) {
  .fs-lg-50-f {
    font-size: 50px !important;
  }
}

.fs-51 {
  font-size: 51px;
}

.fs-51-f {
  font-size: 51px !important;
}

.fs-sm-51 {
  font-size: 51px;
}

.fs-sm-51-f {
  font-size: 51px !important;
}

@media (min-width: 576px) {
  .fs-md-51 {
    font-size: 51px;
  }
}

@media (min-width: 576px) {
  .fs-md-51-f {
    font-size: 51px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-51 {
    font-size: 51px;
  }
}

@media (min-width: 768px) {
  .fs-lg-51-f {
    font-size: 51px !important;
  }
}

.fs-52 {
  font-size: 52px;
}

.fs-52-f {
  font-size: 52px !important;
}

.fs-sm-52 {
  font-size: 52px;
}

.fs-sm-52-f {
  font-size: 52px !important;
}

@media (min-width: 576px) {
  .fs-md-52 {
    font-size: 52px;
  }
}

@media (min-width: 576px) {
  .fs-md-52-f {
    font-size: 52px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-52 {
    font-size: 52px;
  }
}

@media (min-width: 768px) {
  .fs-lg-52-f {
    font-size: 52px !important;
  }
}

.fs-53 {
  font-size: 53px;
}

.fs-53-f {
  font-size: 53px !important;
}

.fs-sm-53 {
  font-size: 53px;
}

.fs-sm-53-f {
  font-size: 53px !important;
}

@media (min-width: 576px) {
  .fs-md-53 {
    font-size: 53px;
  }
}

@media (min-width: 576px) {
  .fs-md-53-f {
    font-size: 53px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-53 {
    font-size: 53px;
  }
}

@media (min-width: 768px) {
  .fs-lg-53-f {
    font-size: 53px !important;
  }
}

.fs-54 {
  font-size: 54px;
}

.fs-54-f {
  font-size: 54px !important;
}

.fs-sm-54 {
  font-size: 54px;
}

.fs-sm-54-f {
  font-size: 54px !important;
}

@media (min-width: 576px) {
  .fs-md-54 {
    font-size: 54px;
  }
}

@media (min-width: 576px) {
  .fs-md-54-f {
    font-size: 54px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-54 {
    font-size: 54px;
  }
}

@media (min-width: 768px) {
  .fs-lg-54-f {
    font-size: 54px !important;
  }
}

.fs-55 {
  font-size: 55px;
}

.fs-55-f {
  font-size: 55px !important;
}

.fs-sm-55 {
  font-size: 55px;
}

.fs-sm-55-f {
  font-size: 55px !important;
}

@media (min-width: 576px) {
  .fs-md-55 {
    font-size: 55px;
  }
}

@media (min-width: 576px) {
  .fs-md-55-f {
    font-size: 55px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-55 {
    font-size: 55px;
  }
}

@media (min-width: 768px) {
  .fs-lg-55-f {
    font-size: 55px !important;
  }
}

.fs-56 {
  font-size: 56px;
}

.fs-56-f {
  font-size: 56px !important;
}

.fs-sm-56 {
  font-size: 56px;
}

.fs-sm-56-f {
  font-size: 56px !important;
}

@media (min-width: 576px) {
  .fs-md-56 {
    font-size: 56px;
  }
}

@media (min-width: 576px) {
  .fs-md-56-f {
    font-size: 56px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-56 {
    font-size: 56px;
  }
}

@media (min-width: 768px) {
  .fs-lg-56-f {
    font-size: 56px !important;
  }
}

.fs-57 {
  font-size: 57px;
}

.fs-57-f {
  font-size: 57px !important;
}

.fs-sm-57 {
  font-size: 57px;
}

.fs-sm-57-f {
  font-size: 57px !important;
}

@media (min-width: 576px) {
  .fs-md-57 {
    font-size: 57px;
  }
}

@media (min-width: 576px) {
  .fs-md-57-f {
    font-size: 57px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-57 {
    font-size: 57px;
  }
}

@media (min-width: 768px) {
  .fs-lg-57-f {
    font-size: 57px !important;
  }
}

.fs-58 {
  font-size: 58px;
}

.fs-58-f {
  font-size: 58px !important;
}

.fs-sm-58 {
  font-size: 58px;
}

.fs-sm-58-f {
  font-size: 58px !important;
}

@media (min-width: 576px) {
  .fs-md-58 {
    font-size: 58px;
  }
}

@media (min-width: 576px) {
  .fs-md-58-f {
    font-size: 58px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-58 {
    font-size: 58px;
  }
}

@media (min-width: 768px) {
  .fs-lg-58-f {
    font-size: 58px !important;
  }
}

.fs-59 {
  font-size: 59px;
}

.fs-59-f {
  font-size: 59px !important;
}

.fs-sm-59 {
  font-size: 59px;
}

.fs-sm-59-f {
  font-size: 59px !important;
}

@media (min-width: 576px) {
  .fs-md-59 {
    font-size: 59px;
  }
}

@media (min-width: 576px) {
  .fs-md-59-f {
    font-size: 59px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-59 {
    font-size: 59px;
  }
}

@media (min-width: 768px) {
  .fs-lg-59-f {
    font-size: 59px !important;
  }
}

.fs-60 {
  font-size: 60px;
}

.fs-60-f {
  font-size: 60px !important;
}

.fs-sm-60 {
  font-size: 60px;
}

.fs-sm-60-f {
  font-size: 60px !important;
}

@media (min-width: 576px) {
  .fs-md-60 {
    font-size: 60px;
  }
}

@media (min-width: 576px) {
  .fs-md-60-f {
    font-size: 60px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-60 {
    font-size: 60px;
  }
}

@media (min-width: 768px) {
  .fs-lg-60-f {
    font-size: 60px !important;
  }
}

.fs-61 {
  font-size: 61px;
}

.fs-61-f {
  font-size: 61px !important;
}

.fs-sm-61 {
  font-size: 61px;
}

.fs-sm-61-f {
  font-size: 61px !important;
}

@media (min-width: 576px) {
  .fs-md-61 {
    font-size: 61px;
  }
}

@media (min-width: 576px) {
  .fs-md-61-f {
    font-size: 61px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-61 {
    font-size: 61px;
  }
}

@media (min-width: 768px) {
  .fs-lg-61-f {
    font-size: 61px !important;
  }
}

.fs-62 {
  font-size: 62px;
}

.fs-62-f {
  font-size: 62px !important;
}

.fs-sm-62 {
  font-size: 62px;
}

.fs-sm-62-f {
  font-size: 62px !important;
}

@media (min-width: 576px) {
  .fs-md-62 {
    font-size: 62px;
  }
}

@media (min-width: 576px) {
  .fs-md-62-f {
    font-size: 62px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-62 {
    font-size: 62px;
  }
}

@media (min-width: 768px) {
  .fs-lg-62-f {
    font-size: 62px !important;
  }
}

.fs-63 {
  font-size: 63px;
}

.fs-63-f {
  font-size: 63px !important;
}

.fs-sm-63 {
  font-size: 63px;
}

.fs-sm-63-f {
  font-size: 63px !important;
}

@media (min-width: 576px) {
  .fs-md-63 {
    font-size: 63px;
  }
}

@media (min-width: 576px) {
  .fs-md-63-f {
    font-size: 63px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-63 {
    font-size: 63px;
  }
}

@media (min-width: 768px) {
  .fs-lg-63-f {
    font-size: 63px !important;
  }
}

.fs-64 {
  font-size: 64px;
}

.fs-64-f {
  font-size: 64px !important;
}

.fs-sm-64 {
  font-size: 64px;
}

.fs-sm-64-f {
  font-size: 64px !important;
}

@media (min-width: 576px) {
  .fs-md-64 {
    font-size: 64px;
  }
}

@media (min-width: 576px) {
  .fs-md-64-f {
    font-size: 64px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-64 {
    font-size: 64px;
  }
}

@media (min-width: 768px) {
  .fs-lg-64-f {
    font-size: 64px !important;
  }
}

.fs-65 {
  font-size: 65px;
}

.fs-65-f {
  font-size: 65px !important;
}

.fs-sm-65 {
  font-size: 65px;
}

.fs-sm-65-f {
  font-size: 65px !important;
}

@media (min-width: 576px) {
  .fs-md-65 {
    font-size: 65px;
  }
}

@media (min-width: 576px) {
  .fs-md-65-f {
    font-size: 65px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-65 {
    font-size: 65px;
  }
}

@media (min-width: 768px) {
  .fs-lg-65-f {
    font-size: 65px !important;
  }
}

.fs-66 {
  font-size: 66px;
}

.fs-66-f {
  font-size: 66px !important;
}

.fs-sm-66 {
  font-size: 66px;
}

.fs-sm-66-f {
  font-size: 66px !important;
}

@media (min-width: 576px) {
  .fs-md-66 {
    font-size: 66px;
  }
}

@media (min-width: 576px) {
  .fs-md-66-f {
    font-size: 66px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-66 {
    font-size: 66px;
  }
}

@media (min-width: 768px) {
  .fs-lg-66-f {
    font-size: 66px !important;
  }
}

.fs-67 {
  font-size: 67px;
}

.fs-67-f {
  font-size: 67px !important;
}

.fs-sm-67 {
  font-size: 67px;
}

.fs-sm-67-f {
  font-size: 67px !important;
}

@media (min-width: 576px) {
  .fs-md-67 {
    font-size: 67px;
  }
}

@media (min-width: 576px) {
  .fs-md-67-f {
    font-size: 67px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-67 {
    font-size: 67px;
  }
}

@media (min-width: 768px) {
  .fs-lg-67-f {
    font-size: 67px !important;
  }
}

.fs-68 {
  font-size: 68px;
}

.fs-68-f {
  font-size: 68px !important;
}

.fs-sm-68 {
  font-size: 68px;
}

.fs-sm-68-f {
  font-size: 68px !important;
}

@media (min-width: 576px) {
  .fs-md-68 {
    font-size: 68px;
  }
}

@media (min-width: 576px) {
  .fs-md-68-f {
    font-size: 68px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-68 {
    font-size: 68px;
  }
}

@media (min-width: 768px) {
  .fs-lg-68-f {
    font-size: 68px !important;
  }
}

.fs-69 {
  font-size: 69px;
}

.fs-69-f {
  font-size: 69px !important;
}

.fs-sm-69 {
  font-size: 69px;
}

.fs-sm-69-f {
  font-size: 69px !important;
}

@media (min-width: 576px) {
  .fs-md-69 {
    font-size: 69px;
  }
}

@media (min-width: 576px) {
  .fs-md-69-f {
    font-size: 69px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-69 {
    font-size: 69px;
  }
}

@media (min-width: 768px) {
  .fs-lg-69-f {
    font-size: 69px !important;
  }
}

.fs-70 {
  font-size: 70px;
}

.fs-70-f {
  font-size: 70px !important;
}

.fs-sm-70 {
  font-size: 70px;
}

.fs-sm-70-f {
  font-size: 70px !important;
}

@media (min-width: 576px) {
  .fs-md-70 {
    font-size: 70px;
  }
}

@media (min-width: 576px) {
  .fs-md-70-f {
    font-size: 70px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-70 {
    font-size: 70px;
  }
}

@media (min-width: 768px) {
  .fs-lg-70-f {
    font-size: 70px !important;
  }
}

.fs-71 {
  font-size: 71px;
}

.fs-71-f {
  font-size: 71px !important;
}

.fs-sm-71 {
  font-size: 71px;
}

.fs-sm-71-f {
  font-size: 71px !important;
}

@media (min-width: 576px) {
  .fs-md-71 {
    font-size: 71px;
  }
}

@media (min-width: 576px) {
  .fs-md-71-f {
    font-size: 71px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-71 {
    font-size: 71px;
  }
}

@media (min-width: 768px) {
  .fs-lg-71-f {
    font-size: 71px !important;
  }
}

.fs-72 {
  font-size: 72px;
}

.fs-72-f {
  font-size: 72px !important;
}

.fs-sm-72 {
  font-size: 72px;
}

.fs-sm-72-f {
  font-size: 72px !important;
}

@media (min-width: 576px) {
  .fs-md-72 {
    font-size: 72px;
  }
}

@media (min-width: 576px) {
  .fs-md-72-f {
    font-size: 72px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-72 {
    font-size: 72px;
  }
}

@media (min-width: 768px) {
  .fs-lg-72-f {
    font-size: 72px !important;
  }
}

.fs-73 {
  font-size: 73px;
}

.fs-73-f {
  font-size: 73px !important;
}

.fs-sm-73 {
  font-size: 73px;
}

.fs-sm-73-f {
  font-size: 73px !important;
}

@media (min-width: 576px) {
  .fs-md-73 {
    font-size: 73px;
  }
}

@media (min-width: 576px) {
  .fs-md-73-f {
    font-size: 73px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-73 {
    font-size: 73px;
  }
}

@media (min-width: 768px) {
  .fs-lg-73-f {
    font-size: 73px !important;
  }
}

.fs-74 {
  font-size: 74px;
}

.fs-74-f {
  font-size: 74px !important;
}

.fs-sm-74 {
  font-size: 74px;
}

.fs-sm-74-f {
  font-size: 74px !important;
}

@media (min-width: 576px) {
  .fs-md-74 {
    font-size: 74px;
  }
}

@media (min-width: 576px) {
  .fs-md-74-f {
    font-size: 74px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-74 {
    font-size: 74px;
  }
}

@media (min-width: 768px) {
  .fs-lg-74-f {
    font-size: 74px !important;
  }
}

.fs-75 {
  font-size: 75px;
}

.fs-75-f {
  font-size: 75px !important;
}

.fs-sm-75 {
  font-size: 75px;
}

.fs-sm-75-f {
  font-size: 75px !important;
}

@media (min-width: 576px) {
  .fs-md-75 {
    font-size: 75px;
  }
}

@media (min-width: 576px) {
  .fs-md-75-f {
    font-size: 75px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-75 {
    font-size: 75px;
  }
}

@media (min-width: 768px) {
  .fs-lg-75-f {
    font-size: 75px !important;
  }
}

.fs-76 {
  font-size: 76px;
}

.fs-76-f {
  font-size: 76px !important;
}

.fs-sm-76 {
  font-size: 76px;
}

.fs-sm-76-f {
  font-size: 76px !important;
}

@media (min-width: 576px) {
  .fs-md-76 {
    font-size: 76px;
  }
}

@media (min-width: 576px) {
  .fs-md-76-f {
    font-size: 76px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-76 {
    font-size: 76px;
  }
}

@media (min-width: 768px) {
  .fs-lg-76-f {
    font-size: 76px !important;
  }
}

.fs-77 {
  font-size: 77px;
}

.fs-77-f {
  font-size: 77px !important;
}

.fs-sm-77 {
  font-size: 77px;
}

.fs-sm-77-f {
  font-size: 77px !important;
}

@media (min-width: 576px) {
  .fs-md-77 {
    font-size: 77px;
  }
}

@media (min-width: 576px) {
  .fs-md-77-f {
    font-size: 77px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-77 {
    font-size: 77px;
  }
}

@media (min-width: 768px) {
  .fs-lg-77-f {
    font-size: 77px !important;
  }
}

.fs-78 {
  font-size: 78px;
}

.fs-78-f {
  font-size: 78px !important;
}

.fs-sm-78 {
  font-size: 78px;
}

.fs-sm-78-f {
  font-size: 78px !important;
}

@media (min-width: 576px) {
  .fs-md-78 {
    font-size: 78px;
  }
}

@media (min-width: 576px) {
  .fs-md-78-f {
    font-size: 78px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-78 {
    font-size: 78px;
  }
}

@media (min-width: 768px) {
  .fs-lg-78-f {
    font-size: 78px !important;
  }
}

.fs-79 {
  font-size: 79px;
}

.fs-79-f {
  font-size: 79px !important;
}

.fs-sm-79 {
  font-size: 79px;
}

.fs-sm-79-f {
  font-size: 79px !important;
}

@media (min-width: 576px) {
  .fs-md-79 {
    font-size: 79px;
  }
}

@media (min-width: 576px) {
  .fs-md-79-f {
    font-size: 79px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-79 {
    font-size: 79px;
  }
}

@media (min-width: 768px) {
  .fs-lg-79-f {
    font-size: 79px !important;
  }
}

.fs-80 {
  font-size: 80px;
}

.fs-80-f {
  font-size: 80px !important;
}

.fs-sm-80 {
  font-size: 80px;
}

.fs-sm-80-f {
  font-size: 80px !important;
}

@media (min-width: 576px) {
  .fs-md-80 {
    font-size: 80px;
  }
}

@media (min-width: 576px) {
  .fs-md-80-f {
    font-size: 80px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-80 {
    font-size: 80px;
  }
}

@media (min-width: 768px) {
  .fs-lg-80-f {
    font-size: 80px !important;
  }
}

.fs-81 {
  font-size: 81px;
}

.fs-81-f {
  font-size: 81px !important;
}

.fs-sm-81 {
  font-size: 81px;
}

.fs-sm-81-f {
  font-size: 81px !important;
}

@media (min-width: 576px) {
  .fs-md-81 {
    font-size: 81px;
  }
}

@media (min-width: 576px) {
  .fs-md-81-f {
    font-size: 81px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-81 {
    font-size: 81px;
  }
}

@media (min-width: 768px) {
  .fs-lg-81-f {
    font-size: 81px !important;
  }
}

.fs-82 {
  font-size: 82px;
}

.fs-82-f {
  font-size: 82px !important;
}

.fs-sm-82 {
  font-size: 82px;
}

.fs-sm-82-f {
  font-size: 82px !important;
}

@media (min-width: 576px) {
  .fs-md-82 {
    font-size: 82px;
  }
}

@media (min-width: 576px) {
  .fs-md-82-f {
    font-size: 82px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-82 {
    font-size: 82px;
  }
}

@media (min-width: 768px) {
  .fs-lg-82-f {
    font-size: 82px !important;
  }
}

.fs-83 {
  font-size: 83px;
}

.fs-83-f {
  font-size: 83px !important;
}

.fs-sm-83 {
  font-size: 83px;
}

.fs-sm-83-f {
  font-size: 83px !important;
}

@media (min-width: 576px) {
  .fs-md-83 {
    font-size: 83px;
  }
}

@media (min-width: 576px) {
  .fs-md-83-f {
    font-size: 83px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-83 {
    font-size: 83px;
  }
}

@media (min-width: 768px) {
  .fs-lg-83-f {
    font-size: 83px !important;
  }
}

.fs-84 {
  font-size: 84px;
}

.fs-84-f {
  font-size: 84px !important;
}

.fs-sm-84 {
  font-size: 84px;
}

.fs-sm-84-f {
  font-size: 84px !important;
}

@media (min-width: 576px) {
  .fs-md-84 {
    font-size: 84px;
  }
}

@media (min-width: 576px) {
  .fs-md-84-f {
    font-size: 84px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-84 {
    font-size: 84px;
  }
}

@media (min-width: 768px) {
  .fs-lg-84-f {
    font-size: 84px !important;
  }
}

.fs-85 {
  font-size: 85px;
}

.fs-85-f {
  font-size: 85px !important;
}

.fs-sm-85 {
  font-size: 85px;
}

.fs-sm-85-f {
  font-size: 85px !important;
}

@media (min-width: 576px) {
  .fs-md-85 {
    font-size: 85px;
  }
}

@media (min-width: 576px) {
  .fs-md-85-f {
    font-size: 85px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-85 {
    font-size: 85px;
  }
}

@media (min-width: 768px) {
  .fs-lg-85-f {
    font-size: 85px !important;
  }
}

.fs-86 {
  font-size: 86px;
}

.fs-86-f {
  font-size: 86px !important;
}

.fs-sm-86 {
  font-size: 86px;
}

.fs-sm-86-f {
  font-size: 86px !important;
}

@media (min-width: 576px) {
  .fs-md-86 {
    font-size: 86px;
  }
}

@media (min-width: 576px) {
  .fs-md-86-f {
    font-size: 86px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-86 {
    font-size: 86px;
  }
}

@media (min-width: 768px) {
  .fs-lg-86-f {
    font-size: 86px !important;
  }
}

.fs-87 {
  font-size: 87px;
}

.fs-87-f {
  font-size: 87px !important;
}

.fs-sm-87 {
  font-size: 87px;
}

.fs-sm-87-f {
  font-size: 87px !important;
}

@media (min-width: 576px) {
  .fs-md-87 {
    font-size: 87px;
  }
}

@media (min-width: 576px) {
  .fs-md-87-f {
    font-size: 87px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-87 {
    font-size: 87px;
  }
}

@media (min-width: 768px) {
  .fs-lg-87-f {
    font-size: 87px !important;
  }
}

.fs-88 {
  font-size: 88px;
}

.fs-88-f {
  font-size: 88px !important;
}

.fs-sm-88 {
  font-size: 88px;
}

.fs-sm-88-f {
  font-size: 88px !important;
}

@media (min-width: 576px) {
  .fs-md-88 {
    font-size: 88px;
  }
}

@media (min-width: 576px) {
  .fs-md-88-f {
    font-size: 88px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-88 {
    font-size: 88px;
  }
}

@media (min-width: 768px) {
  .fs-lg-88-f {
    font-size: 88px !important;
  }
}

.fs-89 {
  font-size: 89px;
}

.fs-89-f {
  font-size: 89px !important;
}

.fs-sm-89 {
  font-size: 89px;
}

.fs-sm-89-f {
  font-size: 89px !important;
}

@media (min-width: 576px) {
  .fs-md-89 {
    font-size: 89px;
  }
}

@media (min-width: 576px) {
  .fs-md-89-f {
    font-size: 89px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-89 {
    font-size: 89px;
  }
}

@media (min-width: 768px) {
  .fs-lg-89-f {
    font-size: 89px !important;
  }
}

.fs-90 {
  font-size: 90px;
}

.fs-90-f {
  font-size: 90px !important;
}

.fs-sm-90 {
  font-size: 90px;
}

.fs-sm-90-f {
  font-size: 90px !important;
}

@media (min-width: 576px) {
  .fs-md-90 {
    font-size: 90px;
  }
}

@media (min-width: 576px) {
  .fs-md-90-f {
    font-size: 90px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-90 {
    font-size: 90px;
  }
}

@media (min-width: 768px) {
  .fs-lg-90-f {
    font-size: 90px !important;
  }
}

.fs-91 {
  font-size: 91px;
}

.fs-91-f {
  font-size: 91px !important;
}

.fs-sm-91 {
  font-size: 91px;
}

.fs-sm-91-f {
  font-size: 91px !important;
}

@media (min-width: 576px) {
  .fs-md-91 {
    font-size: 91px;
  }
}

@media (min-width: 576px) {
  .fs-md-91-f {
    font-size: 91px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-91 {
    font-size: 91px;
  }
}

@media (min-width: 768px) {
  .fs-lg-91-f {
    font-size: 91px !important;
  }
}

.fs-92 {
  font-size: 92px;
}

.fs-92-f {
  font-size: 92px !important;
}

.fs-sm-92 {
  font-size: 92px;
}

.fs-sm-92-f {
  font-size: 92px !important;
}

@media (min-width: 576px) {
  .fs-md-92 {
    font-size: 92px;
  }
}

@media (min-width: 576px) {
  .fs-md-92-f {
    font-size: 92px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-92 {
    font-size: 92px;
  }
}

@media (min-width: 768px) {
  .fs-lg-92-f {
    font-size: 92px !important;
  }
}

.fs-93 {
  font-size: 93px;
}

.fs-93-f {
  font-size: 93px !important;
}

.fs-sm-93 {
  font-size: 93px;
}

.fs-sm-93-f {
  font-size: 93px !important;
}

@media (min-width: 576px) {
  .fs-md-93 {
    font-size: 93px;
  }
}

@media (min-width: 576px) {
  .fs-md-93-f {
    font-size: 93px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-93 {
    font-size: 93px;
  }
}

@media (min-width: 768px) {
  .fs-lg-93-f {
    font-size: 93px !important;
  }
}

.fs-94 {
  font-size: 94px;
}

.fs-94-f {
  font-size: 94px !important;
}

.fs-sm-94 {
  font-size: 94px;
}

.fs-sm-94-f {
  font-size: 94px !important;
}

@media (min-width: 576px) {
  .fs-md-94 {
    font-size: 94px;
  }
}

@media (min-width: 576px) {
  .fs-md-94-f {
    font-size: 94px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-94 {
    font-size: 94px;
  }
}

@media (min-width: 768px) {
  .fs-lg-94-f {
    font-size: 94px !important;
  }
}

.fs-95 {
  font-size: 95px;
}

.fs-95-f {
  font-size: 95px !important;
}

.fs-sm-95 {
  font-size: 95px;
}

.fs-sm-95-f {
  font-size: 95px !important;
}

@media (min-width: 576px) {
  .fs-md-95 {
    font-size: 95px;
  }
}

@media (min-width: 576px) {
  .fs-md-95-f {
    font-size: 95px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-95 {
    font-size: 95px;
  }
}

@media (min-width: 768px) {
  .fs-lg-95-f {
    font-size: 95px !important;
  }
}

.fs-96 {
  font-size: 96px;
}

.fs-96-f {
  font-size: 96px !important;
}

.fs-sm-96 {
  font-size: 96px;
}

.fs-sm-96-f {
  font-size: 96px !important;
}

@media (min-width: 576px) {
  .fs-md-96 {
    font-size: 96px;
  }
}

@media (min-width: 576px) {
  .fs-md-96-f {
    font-size: 96px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-96 {
    font-size: 96px;
  }
}

@media (min-width: 768px) {
  .fs-lg-96-f {
    font-size: 96px !important;
  }
}

.fs-97 {
  font-size: 97px;
}

.fs-97-f {
  font-size: 97px !important;
}

.fs-sm-97 {
  font-size: 97px;
}

.fs-sm-97-f {
  font-size: 97px !important;
}

@media (min-width: 576px) {
  .fs-md-97 {
    font-size: 97px;
  }
}

@media (min-width: 576px) {
  .fs-md-97-f {
    font-size: 97px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-97 {
    font-size: 97px;
  }
}

@media (min-width: 768px) {
  .fs-lg-97-f {
    font-size: 97px !important;
  }
}

.fs-98 {
  font-size: 98px;
}

.fs-98-f {
  font-size: 98px !important;
}

.fs-sm-98 {
  font-size: 98px;
}

.fs-sm-98-f {
  font-size: 98px !important;
}

@media (min-width: 576px) {
  .fs-md-98 {
    font-size: 98px;
  }
}

@media (min-width: 576px) {
  .fs-md-98-f {
    font-size: 98px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-98 {
    font-size: 98px;
  }
}

@media (min-width: 768px) {
  .fs-lg-98-f {
    font-size: 98px !important;
  }
}

.fs-99 {
  font-size: 99px;
}

.fs-99-f {
  font-size: 99px !important;
}

.fs-sm-99 {
  font-size: 99px;
}

.fs-sm-99-f {
  font-size: 99px !important;
}

@media (min-width: 576px) {
  .fs-md-99 {
    font-size: 99px;
  }
}

@media (min-width: 576px) {
  .fs-md-99-f {
    font-size: 99px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-99 {
    font-size: 99px;
  }
}

@media (min-width: 768px) {
  .fs-lg-99-f {
    font-size: 99px !important;
  }
}

.fs-100 {
  font-size: 100px;
}

.fs-100-f {
  font-size: 100px !important;
}

.fs-sm-100 {
  font-size: 100px;
}

.fs-sm-100-f {
  font-size: 100px !important;
}

@media (min-width: 576px) {
  .fs-md-100 {
    font-size: 100px;
  }
}

@media (min-width: 576px) {
  .fs-md-100-f {
    font-size: 100px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-100 {
    font-size: 100px;
  }
}

@media (min-width: 768px) {
  .fs-lg-100-f {
    font-size: 100px !important;
  }
}

.fs-101 {
  font-size: 101px;
}

.fs-101-f {
  font-size: 101px !important;
}

.fs-sm-101 {
  font-size: 101px;
}

.fs-sm-101-f {
  font-size: 101px !important;
}

@media (min-width: 576px) {
  .fs-md-101 {
    font-size: 101px;
  }
}

@media (min-width: 576px) {
  .fs-md-101-f {
    font-size: 101px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-101 {
    font-size: 101px;
  }
}

@media (min-width: 768px) {
  .fs-lg-101-f {
    font-size: 101px !important;
  }
}

.fs-102 {
  font-size: 102px;
}

.fs-102-f {
  font-size: 102px !important;
}

.fs-sm-102 {
  font-size: 102px;
}

.fs-sm-102-f {
  font-size: 102px !important;
}

@media (min-width: 576px) {
  .fs-md-102 {
    font-size: 102px;
  }
}

@media (min-width: 576px) {
  .fs-md-102-f {
    font-size: 102px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-102 {
    font-size: 102px;
  }
}

@media (min-width: 768px) {
  .fs-lg-102-f {
    font-size: 102px !important;
  }
}

.fs-103 {
  font-size: 103px;
}

.fs-103-f {
  font-size: 103px !important;
}

.fs-sm-103 {
  font-size: 103px;
}

.fs-sm-103-f {
  font-size: 103px !important;
}

@media (min-width: 576px) {
  .fs-md-103 {
    font-size: 103px;
  }
}

@media (min-width: 576px) {
  .fs-md-103-f {
    font-size: 103px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-103 {
    font-size: 103px;
  }
}

@media (min-width: 768px) {
  .fs-lg-103-f {
    font-size: 103px !important;
  }
}

.fs-104 {
  font-size: 104px;
}

.fs-104-f {
  font-size: 104px !important;
}

.fs-sm-104 {
  font-size: 104px;
}

.fs-sm-104-f {
  font-size: 104px !important;
}

@media (min-width: 576px) {
  .fs-md-104 {
    font-size: 104px;
  }
}

@media (min-width: 576px) {
  .fs-md-104-f {
    font-size: 104px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-104 {
    font-size: 104px;
  }
}

@media (min-width: 768px) {
  .fs-lg-104-f {
    font-size: 104px !important;
  }
}

.fs-105 {
  font-size: 105px;
}

.fs-105-f {
  font-size: 105px !important;
}

.fs-sm-105 {
  font-size: 105px;
}

.fs-sm-105-f {
  font-size: 105px !important;
}

@media (min-width: 576px) {
  .fs-md-105 {
    font-size: 105px;
  }
}

@media (min-width: 576px) {
  .fs-md-105-f {
    font-size: 105px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-105 {
    font-size: 105px;
  }
}

@media (min-width: 768px) {
  .fs-lg-105-f {
    font-size: 105px !important;
  }
}

.fs-106 {
  font-size: 106px;
}

.fs-106-f {
  font-size: 106px !important;
}

.fs-sm-106 {
  font-size: 106px;
}

.fs-sm-106-f {
  font-size: 106px !important;
}

@media (min-width: 576px) {
  .fs-md-106 {
    font-size: 106px;
  }
}

@media (min-width: 576px) {
  .fs-md-106-f {
    font-size: 106px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-106 {
    font-size: 106px;
  }
}

@media (min-width: 768px) {
  .fs-lg-106-f {
    font-size: 106px !important;
  }
}

.fs-107 {
  font-size: 107px;
}

.fs-107-f {
  font-size: 107px !important;
}

.fs-sm-107 {
  font-size: 107px;
}

.fs-sm-107-f {
  font-size: 107px !important;
}

@media (min-width: 576px) {
  .fs-md-107 {
    font-size: 107px;
  }
}

@media (min-width: 576px) {
  .fs-md-107-f {
    font-size: 107px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-107 {
    font-size: 107px;
  }
}

@media (min-width: 768px) {
  .fs-lg-107-f {
    font-size: 107px !important;
  }
}

.fs-108 {
  font-size: 108px;
}

.fs-108-f {
  font-size: 108px !important;
}

.fs-sm-108 {
  font-size: 108px;
}

.fs-sm-108-f {
  font-size: 108px !important;
}

@media (min-width: 576px) {
  .fs-md-108 {
    font-size: 108px;
  }
}

@media (min-width: 576px) {
  .fs-md-108-f {
    font-size: 108px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-108 {
    font-size: 108px;
  }
}

@media (min-width: 768px) {
  .fs-lg-108-f {
    font-size: 108px !important;
  }
}

.fs-109 {
  font-size: 109px;
}

.fs-109-f {
  font-size: 109px !important;
}

.fs-sm-109 {
  font-size: 109px;
}

.fs-sm-109-f {
  font-size: 109px !important;
}

@media (min-width: 576px) {
  .fs-md-109 {
    font-size: 109px;
  }
}

@media (min-width: 576px) {
  .fs-md-109-f {
    font-size: 109px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-109 {
    font-size: 109px;
  }
}

@media (min-width: 768px) {
  .fs-lg-109-f {
    font-size: 109px !important;
  }
}

.fs-110 {
  font-size: 110px;
}

.fs-110-f {
  font-size: 110px !important;
}

.fs-sm-110 {
  font-size: 110px;
}

.fs-sm-110-f {
  font-size: 110px !important;
}

@media (min-width: 576px) {
  .fs-md-110 {
    font-size: 110px;
  }
}

@media (min-width: 576px) {
  .fs-md-110-f {
    font-size: 110px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-110 {
    font-size: 110px;
  }
}

@media (min-width: 768px) {
  .fs-lg-110-f {
    font-size: 110px !important;
  }
}

.fs-111 {
  font-size: 111px;
}

.fs-111-f {
  font-size: 111px !important;
}

.fs-sm-111 {
  font-size: 111px;
}

.fs-sm-111-f {
  font-size: 111px !important;
}

@media (min-width: 576px) {
  .fs-md-111 {
    font-size: 111px;
  }
}

@media (min-width: 576px) {
  .fs-md-111-f {
    font-size: 111px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-111 {
    font-size: 111px;
  }
}

@media (min-width: 768px) {
  .fs-lg-111-f {
    font-size: 111px !important;
  }
}

.fs-112 {
  font-size: 112px;
}

.fs-112-f {
  font-size: 112px !important;
}

.fs-sm-112 {
  font-size: 112px;
}

.fs-sm-112-f {
  font-size: 112px !important;
}

@media (min-width: 576px) {
  .fs-md-112 {
    font-size: 112px;
  }
}

@media (min-width: 576px) {
  .fs-md-112-f {
    font-size: 112px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-112 {
    font-size: 112px;
  }
}

@media (min-width: 768px) {
  .fs-lg-112-f {
    font-size: 112px !important;
  }
}

.fs-113 {
  font-size: 113px;
}

.fs-113-f {
  font-size: 113px !important;
}

.fs-sm-113 {
  font-size: 113px;
}

.fs-sm-113-f {
  font-size: 113px !important;
}

@media (min-width: 576px) {
  .fs-md-113 {
    font-size: 113px;
  }
}

@media (min-width: 576px) {
  .fs-md-113-f {
    font-size: 113px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-113 {
    font-size: 113px;
  }
}

@media (min-width: 768px) {
  .fs-lg-113-f {
    font-size: 113px !important;
  }
}

.fs-114 {
  font-size: 114px;
}

.fs-114-f {
  font-size: 114px !important;
}

.fs-sm-114 {
  font-size: 114px;
}

.fs-sm-114-f {
  font-size: 114px !important;
}

@media (min-width: 576px) {
  .fs-md-114 {
    font-size: 114px;
  }
}

@media (min-width: 576px) {
  .fs-md-114-f {
    font-size: 114px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-114 {
    font-size: 114px;
  }
}

@media (min-width: 768px) {
  .fs-lg-114-f {
    font-size: 114px !important;
  }
}

.fs-115 {
  font-size: 115px;
}

.fs-115-f {
  font-size: 115px !important;
}

.fs-sm-115 {
  font-size: 115px;
}

.fs-sm-115-f {
  font-size: 115px !important;
}

@media (min-width: 576px) {
  .fs-md-115 {
    font-size: 115px;
  }
}

@media (min-width: 576px) {
  .fs-md-115-f {
    font-size: 115px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-115 {
    font-size: 115px;
  }
}

@media (min-width: 768px) {
  .fs-lg-115-f {
    font-size: 115px !important;
  }
}

.fs-116 {
  font-size: 116px;
}

.fs-116-f {
  font-size: 116px !important;
}

.fs-sm-116 {
  font-size: 116px;
}

.fs-sm-116-f {
  font-size: 116px !important;
}

@media (min-width: 576px) {
  .fs-md-116 {
    font-size: 116px;
  }
}

@media (min-width: 576px) {
  .fs-md-116-f {
    font-size: 116px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-116 {
    font-size: 116px;
  }
}

@media (min-width: 768px) {
  .fs-lg-116-f {
    font-size: 116px !important;
  }
}

.fs-117 {
  font-size: 117px;
}

.fs-117-f {
  font-size: 117px !important;
}

.fs-sm-117 {
  font-size: 117px;
}

.fs-sm-117-f {
  font-size: 117px !important;
}

@media (min-width: 576px) {
  .fs-md-117 {
    font-size: 117px;
  }
}

@media (min-width: 576px) {
  .fs-md-117-f {
    font-size: 117px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-117 {
    font-size: 117px;
  }
}

@media (min-width: 768px) {
  .fs-lg-117-f {
    font-size: 117px !important;
  }
}

.fs-118 {
  font-size: 118px;
}

.fs-118-f {
  font-size: 118px !important;
}

.fs-sm-118 {
  font-size: 118px;
}

.fs-sm-118-f {
  font-size: 118px !important;
}

@media (min-width: 576px) {
  .fs-md-118 {
    font-size: 118px;
  }
}

@media (min-width: 576px) {
  .fs-md-118-f {
    font-size: 118px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-118 {
    font-size: 118px;
  }
}

@media (min-width: 768px) {
  .fs-lg-118-f {
    font-size: 118px !important;
  }
}

.fs-119 {
  font-size: 119px;
}

.fs-119-f {
  font-size: 119px !important;
}

.fs-sm-119 {
  font-size: 119px;
}

.fs-sm-119-f {
  font-size: 119px !important;
}

@media (min-width: 576px) {
  .fs-md-119 {
    font-size: 119px;
  }
}

@media (min-width: 576px) {
  .fs-md-119-f {
    font-size: 119px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-119 {
    font-size: 119px;
  }
}

@media (min-width: 768px) {
  .fs-lg-119-f {
    font-size: 119px !important;
  }
}

.fs-120 {
  font-size: 120px;
}

.fs-120-f {
  font-size: 120px !important;
}

.fs-sm-120 {
  font-size: 120px;
}

.fs-sm-120-f {
  font-size: 120px !important;
}

@media (min-width: 576px) {
  .fs-md-120 {
    font-size: 120px;
  }
}

@media (min-width: 576px) {
  .fs-md-120-f {
    font-size: 120px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-120 {
    font-size: 120px;
  }
}

@media (min-width: 768px) {
  .fs-lg-120-f {
    font-size: 120px !important;
  }
}

.fs-121 {
  font-size: 121px;
}

.fs-121-f {
  font-size: 121px !important;
}

.fs-sm-121 {
  font-size: 121px;
}

.fs-sm-121-f {
  font-size: 121px !important;
}

@media (min-width: 576px) {
  .fs-md-121 {
    font-size: 121px;
  }
}

@media (min-width: 576px) {
  .fs-md-121-f {
    font-size: 121px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-121 {
    font-size: 121px;
  }
}

@media (min-width: 768px) {
  .fs-lg-121-f {
    font-size: 121px !important;
  }
}

.fs-122 {
  font-size: 122px;
}

.fs-122-f {
  font-size: 122px !important;
}

.fs-sm-122 {
  font-size: 122px;
}

.fs-sm-122-f {
  font-size: 122px !important;
}

@media (min-width: 576px) {
  .fs-md-122 {
    font-size: 122px;
  }
}

@media (min-width: 576px) {
  .fs-md-122-f {
    font-size: 122px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-122 {
    font-size: 122px;
  }
}

@media (min-width: 768px) {
  .fs-lg-122-f {
    font-size: 122px !important;
  }
}

.fs-123 {
  font-size: 123px;
}

.fs-123-f {
  font-size: 123px !important;
}

.fs-sm-123 {
  font-size: 123px;
}

.fs-sm-123-f {
  font-size: 123px !important;
}

@media (min-width: 576px) {
  .fs-md-123 {
    font-size: 123px;
  }
}

@media (min-width: 576px) {
  .fs-md-123-f {
    font-size: 123px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-123 {
    font-size: 123px;
  }
}

@media (min-width: 768px) {
  .fs-lg-123-f {
    font-size: 123px !important;
  }
}

.fs-124 {
  font-size: 124px;
}

.fs-124-f {
  font-size: 124px !important;
}

.fs-sm-124 {
  font-size: 124px;
}

.fs-sm-124-f {
  font-size: 124px !important;
}

@media (min-width: 576px) {
  .fs-md-124 {
    font-size: 124px;
  }
}

@media (min-width: 576px) {
  .fs-md-124-f {
    font-size: 124px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-124 {
    font-size: 124px;
  }
}

@media (min-width: 768px) {
  .fs-lg-124-f {
    font-size: 124px !important;
  }
}

.fs-125 {
  font-size: 125px;
}

.fs-125-f {
  font-size: 125px !important;
}

.fs-sm-125 {
  font-size: 125px;
}

.fs-sm-125-f {
  font-size: 125px !important;
}

@media (min-width: 576px) {
  .fs-md-125 {
    font-size: 125px;
  }
}

@media (min-width: 576px) {
  .fs-md-125-f {
    font-size: 125px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-125 {
    font-size: 125px;
  }
}

@media (min-width: 768px) {
  .fs-lg-125-f {
    font-size: 125px !important;
  }
}

.fs-126 {
  font-size: 126px;
}

.fs-126-f {
  font-size: 126px !important;
}

.fs-sm-126 {
  font-size: 126px;
}

.fs-sm-126-f {
  font-size: 126px !important;
}

@media (min-width: 576px) {
  .fs-md-126 {
    font-size: 126px;
  }
}

@media (min-width: 576px) {
  .fs-md-126-f {
    font-size: 126px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-126 {
    font-size: 126px;
  }
}

@media (min-width: 768px) {
  .fs-lg-126-f {
    font-size: 126px !important;
  }
}

.fs-127 {
  font-size: 127px;
}

.fs-127-f {
  font-size: 127px !important;
}

.fs-sm-127 {
  font-size: 127px;
}

.fs-sm-127-f {
  font-size: 127px !important;
}

@media (min-width: 576px) {
  .fs-md-127 {
    font-size: 127px;
  }
}

@media (min-width: 576px) {
  .fs-md-127-f {
    font-size: 127px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-127 {
    font-size: 127px;
  }
}

@media (min-width: 768px) {
  .fs-lg-127-f {
    font-size: 127px !important;
  }
}

.fs-128 {
  font-size: 128px;
}

.fs-128-f {
  font-size: 128px !important;
}

.fs-sm-128 {
  font-size: 128px;
}

.fs-sm-128-f {
  font-size: 128px !important;
}

@media (min-width: 576px) {
  .fs-md-128 {
    font-size: 128px;
  }
}

@media (min-width: 576px) {
  .fs-md-128-f {
    font-size: 128px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-128 {
    font-size: 128px;
  }
}

@media (min-width: 768px) {
  .fs-lg-128-f {
    font-size: 128px !important;
  }
}

.fs-129 {
  font-size: 129px;
}

.fs-129-f {
  font-size: 129px !important;
}

.fs-sm-129 {
  font-size: 129px;
}

.fs-sm-129-f {
  font-size: 129px !important;
}

@media (min-width: 576px) {
  .fs-md-129 {
    font-size: 129px;
  }
}

@media (min-width: 576px) {
  .fs-md-129-f {
    font-size: 129px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-129 {
    font-size: 129px;
  }
}

@media (min-width: 768px) {
  .fs-lg-129-f {
    font-size: 129px !important;
  }
}

.fs-130 {
  font-size: 130px;
}

.fs-130-f {
  font-size: 130px !important;
}

.fs-sm-130 {
  font-size: 130px;
}

.fs-sm-130-f {
  font-size: 130px !important;
}

@media (min-width: 576px) {
  .fs-md-130 {
    font-size: 130px;
  }
}

@media (min-width: 576px) {
  .fs-md-130-f {
    font-size: 130px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-130 {
    font-size: 130px;
  }
}

@media (min-width: 768px) {
  .fs-lg-130-f {
    font-size: 130px !important;
  }
}

.fs-131 {
  font-size: 131px;
}

.fs-131-f {
  font-size: 131px !important;
}

.fs-sm-131 {
  font-size: 131px;
}

.fs-sm-131-f {
  font-size: 131px !important;
}

@media (min-width: 576px) {
  .fs-md-131 {
    font-size: 131px;
  }
}

@media (min-width: 576px) {
  .fs-md-131-f {
    font-size: 131px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-131 {
    font-size: 131px;
  }
}

@media (min-width: 768px) {
  .fs-lg-131-f {
    font-size: 131px !important;
  }
}

.fs-132 {
  font-size: 132px;
}

.fs-132-f {
  font-size: 132px !important;
}

.fs-sm-132 {
  font-size: 132px;
}

.fs-sm-132-f {
  font-size: 132px !important;
}

@media (min-width: 576px) {
  .fs-md-132 {
    font-size: 132px;
  }
}

@media (min-width: 576px) {
  .fs-md-132-f {
    font-size: 132px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-132 {
    font-size: 132px;
  }
}

@media (min-width: 768px) {
  .fs-lg-132-f {
    font-size: 132px !important;
  }
}

.fs-133 {
  font-size: 133px;
}

.fs-133-f {
  font-size: 133px !important;
}

.fs-sm-133 {
  font-size: 133px;
}

.fs-sm-133-f {
  font-size: 133px !important;
}

@media (min-width: 576px) {
  .fs-md-133 {
    font-size: 133px;
  }
}

@media (min-width: 576px) {
  .fs-md-133-f {
    font-size: 133px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-133 {
    font-size: 133px;
  }
}

@media (min-width: 768px) {
  .fs-lg-133-f {
    font-size: 133px !important;
  }
}

.fs-134 {
  font-size: 134px;
}

.fs-134-f {
  font-size: 134px !important;
}

.fs-sm-134 {
  font-size: 134px;
}

.fs-sm-134-f {
  font-size: 134px !important;
}

@media (min-width: 576px) {
  .fs-md-134 {
    font-size: 134px;
  }
}

@media (min-width: 576px) {
  .fs-md-134-f {
    font-size: 134px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-134 {
    font-size: 134px;
  }
}

@media (min-width: 768px) {
  .fs-lg-134-f {
    font-size: 134px !important;
  }
}

.fs-135 {
  font-size: 135px;
}

.fs-135-f {
  font-size: 135px !important;
}

.fs-sm-135 {
  font-size: 135px;
}

.fs-sm-135-f {
  font-size: 135px !important;
}

@media (min-width: 576px) {
  .fs-md-135 {
    font-size: 135px;
  }
}

@media (min-width: 576px) {
  .fs-md-135-f {
    font-size: 135px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-135 {
    font-size: 135px;
  }
}

@media (min-width: 768px) {
  .fs-lg-135-f {
    font-size: 135px !important;
  }
}

.fs-136 {
  font-size: 136px;
}

.fs-136-f {
  font-size: 136px !important;
}

.fs-sm-136 {
  font-size: 136px;
}

.fs-sm-136-f {
  font-size: 136px !important;
}

@media (min-width: 576px) {
  .fs-md-136 {
    font-size: 136px;
  }
}

@media (min-width: 576px) {
  .fs-md-136-f {
    font-size: 136px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-136 {
    font-size: 136px;
  }
}

@media (min-width: 768px) {
  .fs-lg-136-f {
    font-size: 136px !important;
  }
}

.fs-137 {
  font-size: 137px;
}

.fs-137-f {
  font-size: 137px !important;
}

.fs-sm-137 {
  font-size: 137px;
}

.fs-sm-137-f {
  font-size: 137px !important;
}

@media (min-width: 576px) {
  .fs-md-137 {
    font-size: 137px;
  }
}

@media (min-width: 576px) {
  .fs-md-137-f {
    font-size: 137px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-137 {
    font-size: 137px;
  }
}

@media (min-width: 768px) {
  .fs-lg-137-f {
    font-size: 137px !important;
  }
}

.fs-138 {
  font-size: 138px;
}

.fs-138-f {
  font-size: 138px !important;
}

.fs-sm-138 {
  font-size: 138px;
}

.fs-sm-138-f {
  font-size: 138px !important;
}

@media (min-width: 576px) {
  .fs-md-138 {
    font-size: 138px;
  }
}

@media (min-width: 576px) {
  .fs-md-138-f {
    font-size: 138px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-138 {
    font-size: 138px;
  }
}

@media (min-width: 768px) {
  .fs-lg-138-f {
    font-size: 138px !important;
  }
}

.fs-139 {
  font-size: 139px;
}

.fs-139-f {
  font-size: 139px !important;
}

.fs-sm-139 {
  font-size: 139px;
}

.fs-sm-139-f {
  font-size: 139px !important;
}

@media (min-width: 576px) {
  .fs-md-139 {
    font-size: 139px;
  }
}

@media (min-width: 576px) {
  .fs-md-139-f {
    font-size: 139px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-139 {
    font-size: 139px;
  }
}

@media (min-width: 768px) {
  .fs-lg-139-f {
    font-size: 139px !important;
  }
}

.fs-140 {
  font-size: 140px;
}

.fs-140-f {
  font-size: 140px !important;
}

.fs-sm-140 {
  font-size: 140px;
}

.fs-sm-140-f {
  font-size: 140px !important;
}

@media (min-width: 576px) {
  .fs-md-140 {
    font-size: 140px;
  }
}

@media (min-width: 576px) {
  .fs-md-140-f {
    font-size: 140px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-140 {
    font-size: 140px;
  }
}

@media (min-width: 768px) {
  .fs-lg-140-f {
    font-size: 140px !important;
  }
}

.fs-141 {
  font-size: 141px;
}

.fs-141-f {
  font-size: 141px !important;
}

.fs-sm-141 {
  font-size: 141px;
}

.fs-sm-141-f {
  font-size: 141px !important;
}

@media (min-width: 576px) {
  .fs-md-141 {
    font-size: 141px;
  }
}

@media (min-width: 576px) {
  .fs-md-141-f {
    font-size: 141px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-141 {
    font-size: 141px;
  }
}

@media (min-width: 768px) {
  .fs-lg-141-f {
    font-size: 141px !important;
  }
}

.fs-142 {
  font-size: 142px;
}

.fs-142-f {
  font-size: 142px !important;
}

.fs-sm-142 {
  font-size: 142px;
}

.fs-sm-142-f {
  font-size: 142px !important;
}

@media (min-width: 576px) {
  .fs-md-142 {
    font-size: 142px;
  }
}

@media (min-width: 576px) {
  .fs-md-142-f {
    font-size: 142px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-142 {
    font-size: 142px;
  }
}

@media (min-width: 768px) {
  .fs-lg-142-f {
    font-size: 142px !important;
  }
}

.fs-143 {
  font-size: 143px;
}

.fs-143-f {
  font-size: 143px !important;
}

.fs-sm-143 {
  font-size: 143px;
}

.fs-sm-143-f {
  font-size: 143px !important;
}

@media (min-width: 576px) {
  .fs-md-143 {
    font-size: 143px;
  }
}

@media (min-width: 576px) {
  .fs-md-143-f {
    font-size: 143px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-143 {
    font-size: 143px;
  }
}

@media (min-width: 768px) {
  .fs-lg-143-f {
    font-size: 143px !important;
  }
}

.fs-144 {
  font-size: 144px;
}

.fs-144-f {
  font-size: 144px !important;
}

.fs-sm-144 {
  font-size: 144px;
}

.fs-sm-144-f {
  font-size: 144px !important;
}

@media (min-width: 576px) {
  .fs-md-144 {
    font-size: 144px;
  }
}

@media (min-width: 576px) {
  .fs-md-144-f {
    font-size: 144px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-144 {
    font-size: 144px;
  }
}

@media (min-width: 768px) {
  .fs-lg-144-f {
    font-size: 144px !important;
  }
}

.fs-145 {
  font-size: 145px;
}

.fs-145-f {
  font-size: 145px !important;
}

.fs-sm-145 {
  font-size: 145px;
}

.fs-sm-145-f {
  font-size: 145px !important;
}

@media (min-width: 576px) {
  .fs-md-145 {
    font-size: 145px;
  }
}

@media (min-width: 576px) {
  .fs-md-145-f {
    font-size: 145px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-145 {
    font-size: 145px;
  }
}

@media (min-width: 768px) {
  .fs-lg-145-f {
    font-size: 145px !important;
  }
}

.fs-146 {
  font-size: 146px;
}

.fs-146-f {
  font-size: 146px !important;
}

.fs-sm-146 {
  font-size: 146px;
}

.fs-sm-146-f {
  font-size: 146px !important;
}

@media (min-width: 576px) {
  .fs-md-146 {
    font-size: 146px;
  }
}

@media (min-width: 576px) {
  .fs-md-146-f {
    font-size: 146px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-146 {
    font-size: 146px;
  }
}

@media (min-width: 768px) {
  .fs-lg-146-f {
    font-size: 146px !important;
  }
}

.fs-147 {
  font-size: 147px;
}

.fs-147-f {
  font-size: 147px !important;
}

.fs-sm-147 {
  font-size: 147px;
}

.fs-sm-147-f {
  font-size: 147px !important;
}

@media (min-width: 576px) {
  .fs-md-147 {
    font-size: 147px;
  }
}

@media (min-width: 576px) {
  .fs-md-147-f {
    font-size: 147px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-147 {
    font-size: 147px;
  }
}

@media (min-width: 768px) {
  .fs-lg-147-f {
    font-size: 147px !important;
  }
}

.fs-148 {
  font-size: 148px;
}

.fs-148-f {
  font-size: 148px !important;
}

.fs-sm-148 {
  font-size: 148px;
}

.fs-sm-148-f {
  font-size: 148px !important;
}

@media (min-width: 576px) {
  .fs-md-148 {
    font-size: 148px;
  }
}

@media (min-width: 576px) {
  .fs-md-148-f {
    font-size: 148px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-148 {
    font-size: 148px;
  }
}

@media (min-width: 768px) {
  .fs-lg-148-f {
    font-size: 148px !important;
  }
}

.fs-149 {
  font-size: 149px;
}

.fs-149-f {
  font-size: 149px !important;
}

.fs-sm-149 {
  font-size: 149px;
}

.fs-sm-149-f {
  font-size: 149px !important;
}

@media (min-width: 576px) {
  .fs-md-149 {
    font-size: 149px;
  }
}

@media (min-width: 576px) {
  .fs-md-149-f {
    font-size: 149px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-149 {
    font-size: 149px;
  }
}

@media (min-width: 768px) {
  .fs-lg-149-f {
    font-size: 149px !important;
  }
}

.fs-150 {
  font-size: 150px;
}

.fs-150-f {
  font-size: 150px !important;
}

.fs-sm-150 {
  font-size: 150px;
}

.fs-sm-150-f {
  font-size: 150px !important;
}

@media (min-width: 576px) {
  .fs-md-150 {
    font-size: 150px;
  }
}

@media (min-width: 576px) {
  .fs-md-150-f {
    font-size: 150px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-150 {
    font-size: 150px;
  }
}

@media (min-width: 768px) {
  .fs-lg-150-f {
    font-size: 150px !important;
  }
}

.fs-151 {
  font-size: 151px;
}

.fs-151-f {
  font-size: 151px !important;
}

.fs-sm-151 {
  font-size: 151px;
}

.fs-sm-151-f {
  font-size: 151px !important;
}

@media (min-width: 576px) {
  .fs-md-151 {
    font-size: 151px;
  }
}

@media (min-width: 576px) {
  .fs-md-151-f {
    font-size: 151px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-151 {
    font-size: 151px;
  }
}

@media (min-width: 768px) {
  .fs-lg-151-f {
    font-size: 151px !important;
  }
}

.fs-152 {
  font-size: 152px;
}

.fs-152-f {
  font-size: 152px !important;
}

.fs-sm-152 {
  font-size: 152px;
}

.fs-sm-152-f {
  font-size: 152px !important;
}

@media (min-width: 576px) {
  .fs-md-152 {
    font-size: 152px;
  }
}

@media (min-width: 576px) {
  .fs-md-152-f {
    font-size: 152px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-152 {
    font-size: 152px;
  }
}

@media (min-width: 768px) {
  .fs-lg-152-f {
    font-size: 152px !important;
  }
}

.fs-153 {
  font-size: 153px;
}

.fs-153-f {
  font-size: 153px !important;
}

.fs-sm-153 {
  font-size: 153px;
}

.fs-sm-153-f {
  font-size: 153px !important;
}

@media (min-width: 576px) {
  .fs-md-153 {
    font-size: 153px;
  }
}

@media (min-width: 576px) {
  .fs-md-153-f {
    font-size: 153px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-153 {
    font-size: 153px;
  }
}

@media (min-width: 768px) {
  .fs-lg-153-f {
    font-size: 153px !important;
  }
}

.fs-154 {
  font-size: 154px;
}

.fs-154-f {
  font-size: 154px !important;
}

.fs-sm-154 {
  font-size: 154px;
}

.fs-sm-154-f {
  font-size: 154px !important;
}

@media (min-width: 576px) {
  .fs-md-154 {
    font-size: 154px;
  }
}

@media (min-width: 576px) {
  .fs-md-154-f {
    font-size: 154px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-154 {
    font-size: 154px;
  }
}

@media (min-width: 768px) {
  .fs-lg-154-f {
    font-size: 154px !important;
  }
}

.fs-155 {
  font-size: 155px;
}

.fs-155-f {
  font-size: 155px !important;
}

.fs-sm-155 {
  font-size: 155px;
}

.fs-sm-155-f {
  font-size: 155px !important;
}

@media (min-width: 576px) {
  .fs-md-155 {
    font-size: 155px;
  }
}

@media (min-width: 576px) {
  .fs-md-155-f {
    font-size: 155px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-155 {
    font-size: 155px;
  }
}

@media (min-width: 768px) {
  .fs-lg-155-f {
    font-size: 155px !important;
  }
}

.fs-156 {
  font-size: 156px;
}

.fs-156-f {
  font-size: 156px !important;
}

.fs-sm-156 {
  font-size: 156px;
}

.fs-sm-156-f {
  font-size: 156px !important;
}

@media (min-width: 576px) {
  .fs-md-156 {
    font-size: 156px;
  }
}

@media (min-width: 576px) {
  .fs-md-156-f {
    font-size: 156px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-156 {
    font-size: 156px;
  }
}

@media (min-width: 768px) {
  .fs-lg-156-f {
    font-size: 156px !important;
  }
}

.fs-157 {
  font-size: 157px;
}

.fs-157-f {
  font-size: 157px !important;
}

.fs-sm-157 {
  font-size: 157px;
}

.fs-sm-157-f {
  font-size: 157px !important;
}

@media (min-width: 576px) {
  .fs-md-157 {
    font-size: 157px;
  }
}

@media (min-width: 576px) {
  .fs-md-157-f {
    font-size: 157px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-157 {
    font-size: 157px;
  }
}

@media (min-width: 768px) {
  .fs-lg-157-f {
    font-size: 157px !important;
  }
}

.fs-158 {
  font-size: 158px;
}

.fs-158-f {
  font-size: 158px !important;
}

.fs-sm-158 {
  font-size: 158px;
}

.fs-sm-158-f {
  font-size: 158px !important;
}

@media (min-width: 576px) {
  .fs-md-158 {
    font-size: 158px;
  }
}

@media (min-width: 576px) {
  .fs-md-158-f {
    font-size: 158px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-158 {
    font-size: 158px;
  }
}

@media (min-width: 768px) {
  .fs-lg-158-f {
    font-size: 158px !important;
  }
}

.fs-159 {
  font-size: 159px;
}

.fs-159-f {
  font-size: 159px !important;
}

.fs-sm-159 {
  font-size: 159px;
}

.fs-sm-159-f {
  font-size: 159px !important;
}

@media (min-width: 576px) {
  .fs-md-159 {
    font-size: 159px;
  }
}

@media (min-width: 576px) {
  .fs-md-159-f {
    font-size: 159px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-159 {
    font-size: 159px;
  }
}

@media (min-width: 768px) {
  .fs-lg-159-f {
    font-size: 159px !important;
  }
}

.fs-160 {
  font-size: 160px;
}

.fs-160-f {
  font-size: 160px !important;
}

.fs-sm-160 {
  font-size: 160px;
}

.fs-sm-160-f {
  font-size: 160px !important;
}

@media (min-width: 576px) {
  .fs-md-160 {
    font-size: 160px;
  }
}

@media (min-width: 576px) {
  .fs-md-160-f {
    font-size: 160px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-160 {
    font-size: 160px;
  }
}

@media (min-width: 768px) {
  .fs-lg-160-f {
    font-size: 160px !important;
  }
}

.fs-161 {
  font-size: 161px;
}

.fs-161-f {
  font-size: 161px !important;
}

.fs-sm-161 {
  font-size: 161px;
}

.fs-sm-161-f {
  font-size: 161px !important;
}

@media (min-width: 576px) {
  .fs-md-161 {
    font-size: 161px;
  }
}

@media (min-width: 576px) {
  .fs-md-161-f {
    font-size: 161px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-161 {
    font-size: 161px;
  }
}

@media (min-width: 768px) {
  .fs-lg-161-f {
    font-size: 161px !important;
  }
}

.fs-162 {
  font-size: 162px;
}

.fs-162-f {
  font-size: 162px !important;
}

.fs-sm-162 {
  font-size: 162px;
}

.fs-sm-162-f {
  font-size: 162px !important;
}

@media (min-width: 576px) {
  .fs-md-162 {
    font-size: 162px;
  }
}

@media (min-width: 576px) {
  .fs-md-162-f {
    font-size: 162px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-162 {
    font-size: 162px;
  }
}

@media (min-width: 768px) {
  .fs-lg-162-f {
    font-size: 162px !important;
  }
}

.fs-163 {
  font-size: 163px;
}

.fs-163-f {
  font-size: 163px !important;
}

.fs-sm-163 {
  font-size: 163px;
}

.fs-sm-163-f {
  font-size: 163px !important;
}

@media (min-width: 576px) {
  .fs-md-163 {
    font-size: 163px;
  }
}

@media (min-width: 576px) {
  .fs-md-163-f {
    font-size: 163px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-163 {
    font-size: 163px;
  }
}

@media (min-width: 768px) {
  .fs-lg-163-f {
    font-size: 163px !important;
  }
}

.fs-164 {
  font-size: 164px;
}

.fs-164-f {
  font-size: 164px !important;
}

.fs-sm-164 {
  font-size: 164px;
}

.fs-sm-164-f {
  font-size: 164px !important;
}

@media (min-width: 576px) {
  .fs-md-164 {
    font-size: 164px;
  }
}

@media (min-width: 576px) {
  .fs-md-164-f {
    font-size: 164px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-164 {
    font-size: 164px;
  }
}

@media (min-width: 768px) {
  .fs-lg-164-f {
    font-size: 164px !important;
  }
}

.fs-165 {
  font-size: 165px;
}

.fs-165-f {
  font-size: 165px !important;
}

.fs-sm-165 {
  font-size: 165px;
}

.fs-sm-165-f {
  font-size: 165px !important;
}

@media (min-width: 576px) {
  .fs-md-165 {
    font-size: 165px;
  }
}

@media (min-width: 576px) {
  .fs-md-165-f {
    font-size: 165px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-165 {
    font-size: 165px;
  }
}

@media (min-width: 768px) {
  .fs-lg-165-f {
    font-size: 165px !important;
  }
}

.fs-166 {
  font-size: 166px;
}

.fs-166-f {
  font-size: 166px !important;
}

.fs-sm-166 {
  font-size: 166px;
}

.fs-sm-166-f {
  font-size: 166px !important;
}

@media (min-width: 576px) {
  .fs-md-166 {
    font-size: 166px;
  }
}

@media (min-width: 576px) {
  .fs-md-166-f {
    font-size: 166px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-166 {
    font-size: 166px;
  }
}

@media (min-width: 768px) {
  .fs-lg-166-f {
    font-size: 166px !important;
  }
}

.fs-167 {
  font-size: 167px;
}

.fs-167-f {
  font-size: 167px !important;
}

.fs-sm-167 {
  font-size: 167px;
}

.fs-sm-167-f {
  font-size: 167px !important;
}

@media (min-width: 576px) {
  .fs-md-167 {
    font-size: 167px;
  }
}

@media (min-width: 576px) {
  .fs-md-167-f {
    font-size: 167px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-167 {
    font-size: 167px;
  }
}

@media (min-width: 768px) {
  .fs-lg-167-f {
    font-size: 167px !important;
  }
}

.fs-168 {
  font-size: 168px;
}

.fs-168-f {
  font-size: 168px !important;
}

.fs-sm-168 {
  font-size: 168px;
}

.fs-sm-168-f {
  font-size: 168px !important;
}

@media (min-width: 576px) {
  .fs-md-168 {
    font-size: 168px;
  }
}

@media (min-width: 576px) {
  .fs-md-168-f {
    font-size: 168px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-168 {
    font-size: 168px;
  }
}

@media (min-width: 768px) {
  .fs-lg-168-f {
    font-size: 168px !important;
  }
}

.fs-169 {
  font-size: 169px;
}

.fs-169-f {
  font-size: 169px !important;
}

.fs-sm-169 {
  font-size: 169px;
}

.fs-sm-169-f {
  font-size: 169px !important;
}

@media (min-width: 576px) {
  .fs-md-169 {
    font-size: 169px;
  }
}

@media (min-width: 576px) {
  .fs-md-169-f {
    font-size: 169px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-169 {
    font-size: 169px;
  }
}

@media (min-width: 768px) {
  .fs-lg-169-f {
    font-size: 169px !important;
  }
}

.fs-170 {
  font-size: 170px;
}

.fs-170-f {
  font-size: 170px !important;
}

.fs-sm-170 {
  font-size: 170px;
}

.fs-sm-170-f {
  font-size: 170px !important;
}

@media (min-width: 576px) {
  .fs-md-170 {
    font-size: 170px;
  }
}

@media (min-width: 576px) {
  .fs-md-170-f {
    font-size: 170px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-170 {
    font-size: 170px;
  }
}

@media (min-width: 768px) {
  .fs-lg-170-f {
    font-size: 170px !important;
  }
}

.fs-171 {
  font-size: 171px;
}

.fs-171-f {
  font-size: 171px !important;
}

.fs-sm-171 {
  font-size: 171px;
}

.fs-sm-171-f {
  font-size: 171px !important;
}

@media (min-width: 576px) {
  .fs-md-171 {
    font-size: 171px;
  }
}

@media (min-width: 576px) {
  .fs-md-171-f {
    font-size: 171px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-171 {
    font-size: 171px;
  }
}

@media (min-width: 768px) {
  .fs-lg-171-f {
    font-size: 171px !important;
  }
}

.fs-172 {
  font-size: 172px;
}

.fs-172-f {
  font-size: 172px !important;
}

.fs-sm-172 {
  font-size: 172px;
}

.fs-sm-172-f {
  font-size: 172px !important;
}

@media (min-width: 576px) {
  .fs-md-172 {
    font-size: 172px;
  }
}

@media (min-width: 576px) {
  .fs-md-172-f {
    font-size: 172px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-172 {
    font-size: 172px;
  }
}

@media (min-width: 768px) {
  .fs-lg-172-f {
    font-size: 172px !important;
  }
}

.fs-173 {
  font-size: 173px;
}

.fs-173-f {
  font-size: 173px !important;
}

.fs-sm-173 {
  font-size: 173px;
}

.fs-sm-173-f {
  font-size: 173px !important;
}

@media (min-width: 576px) {
  .fs-md-173 {
    font-size: 173px;
  }
}

@media (min-width: 576px) {
  .fs-md-173-f {
    font-size: 173px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-173 {
    font-size: 173px;
  }
}

@media (min-width: 768px) {
  .fs-lg-173-f {
    font-size: 173px !important;
  }
}

.fs-174 {
  font-size: 174px;
}

.fs-174-f {
  font-size: 174px !important;
}

.fs-sm-174 {
  font-size: 174px;
}

.fs-sm-174-f {
  font-size: 174px !important;
}

@media (min-width: 576px) {
  .fs-md-174 {
    font-size: 174px;
  }
}

@media (min-width: 576px) {
  .fs-md-174-f {
    font-size: 174px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-174 {
    font-size: 174px;
  }
}

@media (min-width: 768px) {
  .fs-lg-174-f {
    font-size: 174px !important;
  }
}

.fs-175 {
  font-size: 175px;
}

.fs-175-f {
  font-size: 175px !important;
}

.fs-sm-175 {
  font-size: 175px;
}

.fs-sm-175-f {
  font-size: 175px !important;
}

@media (min-width: 576px) {
  .fs-md-175 {
    font-size: 175px;
  }
}

@media (min-width: 576px) {
  .fs-md-175-f {
    font-size: 175px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-175 {
    font-size: 175px;
  }
}

@media (min-width: 768px) {
  .fs-lg-175-f {
    font-size: 175px !important;
  }
}

.fs-176 {
  font-size: 176px;
}

.fs-176-f {
  font-size: 176px !important;
}

.fs-sm-176 {
  font-size: 176px;
}

.fs-sm-176-f {
  font-size: 176px !important;
}

@media (min-width: 576px) {
  .fs-md-176 {
    font-size: 176px;
  }
}

@media (min-width: 576px) {
  .fs-md-176-f {
    font-size: 176px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-176 {
    font-size: 176px;
  }
}

@media (min-width: 768px) {
  .fs-lg-176-f {
    font-size: 176px !important;
  }
}

.fs-177 {
  font-size: 177px;
}

.fs-177-f {
  font-size: 177px !important;
}

.fs-sm-177 {
  font-size: 177px;
}

.fs-sm-177-f {
  font-size: 177px !important;
}

@media (min-width: 576px) {
  .fs-md-177 {
    font-size: 177px;
  }
}

@media (min-width: 576px) {
  .fs-md-177-f {
    font-size: 177px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-177 {
    font-size: 177px;
  }
}

@media (min-width: 768px) {
  .fs-lg-177-f {
    font-size: 177px !important;
  }
}

.fs-178 {
  font-size: 178px;
}

.fs-178-f {
  font-size: 178px !important;
}

.fs-sm-178 {
  font-size: 178px;
}

.fs-sm-178-f {
  font-size: 178px !important;
}

@media (min-width: 576px) {
  .fs-md-178 {
    font-size: 178px;
  }
}

@media (min-width: 576px) {
  .fs-md-178-f {
    font-size: 178px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-178 {
    font-size: 178px;
  }
}

@media (min-width: 768px) {
  .fs-lg-178-f {
    font-size: 178px !important;
  }
}

.fs-179 {
  font-size: 179px;
}

.fs-179-f {
  font-size: 179px !important;
}

.fs-sm-179 {
  font-size: 179px;
}

.fs-sm-179-f {
  font-size: 179px !important;
}

@media (min-width: 576px) {
  .fs-md-179 {
    font-size: 179px;
  }
}

@media (min-width: 576px) {
  .fs-md-179-f {
    font-size: 179px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-179 {
    font-size: 179px;
  }
}

@media (min-width: 768px) {
  .fs-lg-179-f {
    font-size: 179px !important;
  }
}

.fs-180 {
  font-size: 180px;
}

.fs-180-f {
  font-size: 180px !important;
}

.fs-sm-180 {
  font-size: 180px;
}

.fs-sm-180-f {
  font-size: 180px !important;
}

@media (min-width: 576px) {
  .fs-md-180 {
    font-size: 180px;
  }
}

@media (min-width: 576px) {
  .fs-md-180-f {
    font-size: 180px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-180 {
    font-size: 180px;
  }
}

@media (min-width: 768px) {
  .fs-lg-180-f {
    font-size: 180px !important;
  }
}

.fs-181 {
  font-size: 181px;
}

.fs-181-f {
  font-size: 181px !important;
}

.fs-sm-181 {
  font-size: 181px;
}

.fs-sm-181-f {
  font-size: 181px !important;
}

@media (min-width: 576px) {
  .fs-md-181 {
    font-size: 181px;
  }
}

@media (min-width: 576px) {
  .fs-md-181-f {
    font-size: 181px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-181 {
    font-size: 181px;
  }
}

@media (min-width: 768px) {
  .fs-lg-181-f {
    font-size: 181px !important;
  }
}

.fs-182 {
  font-size: 182px;
}

.fs-182-f {
  font-size: 182px !important;
}

.fs-sm-182 {
  font-size: 182px;
}

.fs-sm-182-f {
  font-size: 182px !important;
}

@media (min-width: 576px) {
  .fs-md-182 {
    font-size: 182px;
  }
}

@media (min-width: 576px) {
  .fs-md-182-f {
    font-size: 182px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-182 {
    font-size: 182px;
  }
}

@media (min-width: 768px) {
  .fs-lg-182-f {
    font-size: 182px !important;
  }
}

.fs-183 {
  font-size: 183px;
}

.fs-183-f {
  font-size: 183px !important;
}

.fs-sm-183 {
  font-size: 183px;
}

.fs-sm-183-f {
  font-size: 183px !important;
}

@media (min-width: 576px) {
  .fs-md-183 {
    font-size: 183px;
  }
}

@media (min-width: 576px) {
  .fs-md-183-f {
    font-size: 183px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-183 {
    font-size: 183px;
  }
}

@media (min-width: 768px) {
  .fs-lg-183-f {
    font-size: 183px !important;
  }
}

.fs-184 {
  font-size: 184px;
}

.fs-184-f {
  font-size: 184px !important;
}

.fs-sm-184 {
  font-size: 184px;
}

.fs-sm-184-f {
  font-size: 184px !important;
}

@media (min-width: 576px) {
  .fs-md-184 {
    font-size: 184px;
  }
}

@media (min-width: 576px) {
  .fs-md-184-f {
    font-size: 184px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-184 {
    font-size: 184px;
  }
}

@media (min-width: 768px) {
  .fs-lg-184-f {
    font-size: 184px !important;
  }
}

.fs-185 {
  font-size: 185px;
}

.fs-185-f {
  font-size: 185px !important;
}

.fs-sm-185 {
  font-size: 185px;
}

.fs-sm-185-f {
  font-size: 185px !important;
}

@media (min-width: 576px) {
  .fs-md-185 {
    font-size: 185px;
  }
}

@media (min-width: 576px) {
  .fs-md-185-f {
    font-size: 185px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-185 {
    font-size: 185px;
  }
}

@media (min-width: 768px) {
  .fs-lg-185-f {
    font-size: 185px !important;
  }
}

.fs-186 {
  font-size: 186px;
}

.fs-186-f {
  font-size: 186px !important;
}

.fs-sm-186 {
  font-size: 186px;
}

.fs-sm-186-f {
  font-size: 186px !important;
}

@media (min-width: 576px) {
  .fs-md-186 {
    font-size: 186px;
  }
}

@media (min-width: 576px) {
  .fs-md-186-f {
    font-size: 186px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-186 {
    font-size: 186px;
  }
}

@media (min-width: 768px) {
  .fs-lg-186-f {
    font-size: 186px !important;
  }
}

.fs-187 {
  font-size: 187px;
}

.fs-187-f {
  font-size: 187px !important;
}

.fs-sm-187 {
  font-size: 187px;
}

.fs-sm-187-f {
  font-size: 187px !important;
}

@media (min-width: 576px) {
  .fs-md-187 {
    font-size: 187px;
  }
}

@media (min-width: 576px) {
  .fs-md-187-f {
    font-size: 187px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-187 {
    font-size: 187px;
  }
}

@media (min-width: 768px) {
  .fs-lg-187-f {
    font-size: 187px !important;
  }
}

.fs-188 {
  font-size: 188px;
}

.fs-188-f {
  font-size: 188px !important;
}

.fs-sm-188 {
  font-size: 188px;
}

.fs-sm-188-f {
  font-size: 188px !important;
}

@media (min-width: 576px) {
  .fs-md-188 {
    font-size: 188px;
  }
}

@media (min-width: 576px) {
  .fs-md-188-f {
    font-size: 188px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-188 {
    font-size: 188px;
  }
}

@media (min-width: 768px) {
  .fs-lg-188-f {
    font-size: 188px !important;
  }
}

.fs-189 {
  font-size: 189px;
}

.fs-189-f {
  font-size: 189px !important;
}

.fs-sm-189 {
  font-size: 189px;
}

.fs-sm-189-f {
  font-size: 189px !important;
}

@media (min-width: 576px) {
  .fs-md-189 {
    font-size: 189px;
  }
}

@media (min-width: 576px) {
  .fs-md-189-f {
    font-size: 189px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-189 {
    font-size: 189px;
  }
}

@media (min-width: 768px) {
  .fs-lg-189-f {
    font-size: 189px !important;
  }
}

.fs-190 {
  font-size: 190px;
}

.fs-190-f {
  font-size: 190px !important;
}

.fs-sm-190 {
  font-size: 190px;
}

.fs-sm-190-f {
  font-size: 190px !important;
}

@media (min-width: 576px) {
  .fs-md-190 {
    font-size: 190px;
  }
}

@media (min-width: 576px) {
  .fs-md-190-f {
    font-size: 190px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-190 {
    font-size: 190px;
  }
}

@media (min-width: 768px) {
  .fs-lg-190-f {
    font-size: 190px !important;
  }
}

.fs-191 {
  font-size: 191px;
}

.fs-191-f {
  font-size: 191px !important;
}

.fs-sm-191 {
  font-size: 191px;
}

.fs-sm-191-f {
  font-size: 191px !important;
}

@media (min-width: 576px) {
  .fs-md-191 {
    font-size: 191px;
  }
}

@media (min-width: 576px) {
  .fs-md-191-f {
    font-size: 191px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-191 {
    font-size: 191px;
  }
}

@media (min-width: 768px) {
  .fs-lg-191-f {
    font-size: 191px !important;
  }
}

.fs-192 {
  font-size: 192px;
}

.fs-192-f {
  font-size: 192px !important;
}

.fs-sm-192 {
  font-size: 192px;
}

.fs-sm-192-f {
  font-size: 192px !important;
}

@media (min-width: 576px) {
  .fs-md-192 {
    font-size: 192px;
  }
}

@media (min-width: 576px) {
  .fs-md-192-f {
    font-size: 192px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-192 {
    font-size: 192px;
  }
}

@media (min-width: 768px) {
  .fs-lg-192-f {
    font-size: 192px !important;
  }
}

.fs-193 {
  font-size: 193px;
}

.fs-193-f {
  font-size: 193px !important;
}

.fs-sm-193 {
  font-size: 193px;
}

.fs-sm-193-f {
  font-size: 193px !important;
}

@media (min-width: 576px) {
  .fs-md-193 {
    font-size: 193px;
  }
}

@media (min-width: 576px) {
  .fs-md-193-f {
    font-size: 193px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-193 {
    font-size: 193px;
  }
}

@media (min-width: 768px) {
  .fs-lg-193-f {
    font-size: 193px !important;
  }
}

.fs-194 {
  font-size: 194px;
}

.fs-194-f {
  font-size: 194px !important;
}

.fs-sm-194 {
  font-size: 194px;
}

.fs-sm-194-f {
  font-size: 194px !important;
}

@media (min-width: 576px) {
  .fs-md-194 {
    font-size: 194px;
  }
}

@media (min-width: 576px) {
  .fs-md-194-f {
    font-size: 194px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-194 {
    font-size: 194px;
  }
}

@media (min-width: 768px) {
  .fs-lg-194-f {
    font-size: 194px !important;
  }
}

.fs-195 {
  font-size: 195px;
}

.fs-195-f {
  font-size: 195px !important;
}

.fs-sm-195 {
  font-size: 195px;
}

.fs-sm-195-f {
  font-size: 195px !important;
}

@media (min-width: 576px) {
  .fs-md-195 {
    font-size: 195px;
  }
}

@media (min-width: 576px) {
  .fs-md-195-f {
    font-size: 195px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-195 {
    font-size: 195px;
  }
}

@media (min-width: 768px) {
  .fs-lg-195-f {
    font-size: 195px !important;
  }
}

.fs-196 {
  font-size: 196px;
}

.fs-196-f {
  font-size: 196px !important;
}

.fs-sm-196 {
  font-size: 196px;
}

.fs-sm-196-f {
  font-size: 196px !important;
}

@media (min-width: 576px) {
  .fs-md-196 {
    font-size: 196px;
  }
}

@media (min-width: 576px) {
  .fs-md-196-f {
    font-size: 196px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-196 {
    font-size: 196px;
  }
}

@media (min-width: 768px) {
  .fs-lg-196-f {
    font-size: 196px !important;
  }
}

.fs-197 {
  font-size: 197px;
}

.fs-197-f {
  font-size: 197px !important;
}

.fs-sm-197 {
  font-size: 197px;
}

.fs-sm-197-f {
  font-size: 197px !important;
}

@media (min-width: 576px) {
  .fs-md-197 {
    font-size: 197px;
  }
}

@media (min-width: 576px) {
  .fs-md-197-f {
    font-size: 197px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-197 {
    font-size: 197px;
  }
}

@media (min-width: 768px) {
  .fs-lg-197-f {
    font-size: 197px !important;
  }
}

.fs-198 {
  font-size: 198px;
}

.fs-198-f {
  font-size: 198px !important;
}

.fs-sm-198 {
  font-size: 198px;
}

.fs-sm-198-f {
  font-size: 198px !important;
}

@media (min-width: 576px) {
  .fs-md-198 {
    font-size: 198px;
  }
}

@media (min-width: 576px) {
  .fs-md-198-f {
    font-size: 198px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-198 {
    font-size: 198px;
  }
}

@media (min-width: 768px) {
  .fs-lg-198-f {
    font-size: 198px !important;
  }
}

.fs-199 {
  font-size: 199px;
}

.fs-199-f {
  font-size: 199px !important;
}

.fs-sm-199 {
  font-size: 199px;
}

.fs-sm-199-f {
  font-size: 199px !important;
}

@media (min-width: 576px) {
  .fs-md-199 {
    font-size: 199px;
  }
}

@media (min-width: 576px) {
  .fs-md-199-f {
    font-size: 199px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-199 {
    font-size: 199px;
  }
}

@media (min-width: 768px) {
  .fs-lg-199-f {
    font-size: 199px !important;
  }
}

.fs-200 {
  font-size: 200px;
}

.fs-200-f {
  font-size: 200px !important;
}

.fs-sm-200 {
  font-size: 200px;
}

.fs-sm-200-f {
  font-size: 200px !important;
}

@media (min-width: 576px) {
  .fs-md-200 {
    font-size: 200px;
  }
}

@media (min-width: 576px) {
  .fs-md-200-f {
    font-size: 200px !important;
  }
}

@media (min-width: 768px) {
  .fs-lg-200 {
    font-size: 200px;
  }
}

@media (min-width: 768px) {
  .fs-lg-200-f {
    font-size: 200px !important;
  }
}

/* ----------------------------------------------------------------
	height
-----------------------------------------------------------------*/
.ht-5 {
  height: 5px;
}

.ht-5-f {
  height: 5px !important;
}

.ht-sm-5 {
  height: 5px;
}

.ht-sm-5-f {
  height: 5px !important;
}

@media (min-width: 576px) {
  .ht-md-5 {
    height: 5px;
  }
}

@media (min-width: 576px) {
  .ht-md-5-f {
    height: 5px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-5 {
    height: 5px;
  }
}

@media (min-width: 768px) {
  .ht-lg-5-f {
    height: 5px !important;
  }
}

.ht-6 {
  height: 6px;
}

.ht-6-f {
  height: 6px !important;
}

.ht-sm-6 {
  height: 6px;
}

.ht-sm-6-f {
  height: 6px !important;
}

@media (min-width: 576px) {
  .ht-md-6 {
    height: 6px;
  }
}

@media (min-width: 576px) {
  .ht-md-6-f {
    height: 6px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-6 {
    height: 6px;
  }
}

@media (min-width: 768px) {
  .ht-lg-6-f {
    height: 6px !important;
  }
}

.ht-7 {
  height: 7px;
}

.ht-7-f {
  height: 7px !important;
}

.ht-sm-7 {
  height: 7px;
}

.ht-sm-7-f {
  height: 7px !important;
}

@media (min-width: 576px) {
  .ht-md-7 {
    height: 7px;
  }
}

@media (min-width: 576px) {
  .ht-md-7-f {
    height: 7px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-7 {
    height: 7px;
  }
}

@media (min-width: 768px) {
  .ht-lg-7-f {
    height: 7px !important;
  }
}

.ht-8 {
  height: 8px;
}

.ht-8-f {
  height: 8px !important;
}

.ht-sm-8 {
  height: 8px;
}

.ht-sm-8-f {
  height: 8px !important;
}

@media (min-width: 576px) {
  .ht-md-8 {
    height: 8px;
  }
}

@media (min-width: 576px) {
  .ht-md-8-f {
    height: 8px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-8 {
    height: 8px;
  }
}

@media (min-width: 768px) {
  .ht-lg-8-f {
    height: 8px !important;
  }
}

.ht-9 {
  height: 9px;
}

.ht-9-f {
  height: 9px !important;
}

.ht-sm-9 {
  height: 9px;
}

.ht-sm-9-f {
  height: 9px !important;
}

@media (min-width: 576px) {
  .ht-md-9 {
    height: 9px;
  }
}

@media (min-width: 576px) {
  .ht-md-9-f {
    height: 9px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-9 {
    height: 9px;
  }
}

@media (min-width: 768px) {
  .ht-lg-9-f {
    height: 9px !important;
  }
}

.ht-10 {
  height: 10px;
}

.ht-10-f {
  height: 10px !important;
}

.ht-sm-10 {
  height: 10px;
}

.ht-sm-10-f {
  height: 10px !important;
}

@media (min-width: 576px) {
  .ht-md-10 {
    height: 10px;
  }
}

@media (min-width: 576px) {
  .ht-md-10-f {
    height: 10px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-10 {
    height: 10px;
  }
}

@media (min-width: 768px) {
  .ht-lg-10-f {
    height: 10px !important;
  }
}

.ht-11 {
  height: 11px;
}

.ht-11-f {
  height: 11px !important;
}

.ht-sm-11 {
  height: 11px;
}

.ht-sm-11-f {
  height: 11px !important;
}

@media (min-width: 576px) {
  .ht-md-11 {
    height: 11px;
  }
}

@media (min-width: 576px) {
  .ht-md-11-f {
    height: 11px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-11 {
    height: 11px;
  }
}

@media (min-width: 768px) {
  .ht-lg-11-f {
    height: 11px !important;
  }
}

.ht-12 {
  height: 12px;
}

.ht-12-f {
  height: 12px !important;
}

.ht-sm-12 {
  height: 12px;
}

.ht-sm-12-f {
  height: 12px !important;
}

@media (min-width: 576px) {
  .ht-md-12 {
    height: 12px;
  }
}

@media (min-width: 576px) {
  .ht-md-12-f {
    height: 12px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-12 {
    height: 12px;
  }
}

@media (min-width: 768px) {
  .ht-lg-12-f {
    height: 12px !important;
  }
}

.ht-13 {
  height: 13px;
}

.ht-13-f {
  height: 13px !important;
}

.ht-sm-13 {
  height: 13px;
}

.ht-sm-13-f {
  height: 13px !important;
}

@media (min-width: 576px) {
  .ht-md-13 {
    height: 13px;
  }
}

@media (min-width: 576px) {
  .ht-md-13-f {
    height: 13px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-13 {
    height: 13px;
  }
}

@media (min-width: 768px) {
  .ht-lg-13-f {
    height: 13px !important;
  }
}

.ht-14 {
  height: 14px;
}

.ht-14-f {
  height: 14px !important;
}

.ht-sm-14 {
  height: 14px;
}

.ht-sm-14-f {
  height: 14px !important;
}

@media (min-width: 576px) {
  .ht-md-14 {
    height: 14px;
  }
}

@media (min-width: 576px) {
  .ht-md-14-f {
    height: 14px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-14 {
    height: 14px;
  }
}

@media (min-width: 768px) {
  .ht-lg-14-f {
    height: 14px !important;
  }
}

.ht-15 {
  height: 15px;
}

.ht-15-f {
  height: 15px !important;
}

.ht-sm-15 {
  height: 15px;
}

.ht-sm-15-f {
  height: 15px !important;
}

@media (min-width: 576px) {
  .ht-md-15 {
    height: 15px;
  }
}

@media (min-width: 576px) {
  .ht-md-15-f {
    height: 15px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-15 {
    height: 15px;
  }
}

@media (min-width: 768px) {
  .ht-lg-15-f {
    height: 15px !important;
  }
}

.ht-16 {
  height: 16px;
}

.ht-16-f {
  height: 16px !important;
}

.ht-sm-16 {
  height: 16px;
}

.ht-sm-16-f {
  height: 16px !important;
}

@media (min-width: 576px) {
  .ht-md-16 {
    height: 16px;
  }
}

@media (min-width: 576px) {
  .ht-md-16-f {
    height: 16px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-16 {
    height: 16px;
  }
}

@media (min-width: 768px) {
  .ht-lg-16-f {
    height: 16px !important;
  }
}

.ht-17 {
  height: 17px;
}

.ht-17-f {
  height: 17px !important;
}

.ht-sm-17 {
  height: 17px;
}

.ht-sm-17-f {
  height: 17px !important;
}

@media (min-width: 576px) {
  .ht-md-17 {
    height: 17px;
  }
}

@media (min-width: 576px) {
  .ht-md-17-f {
    height: 17px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-17 {
    height: 17px;
  }
}

@media (min-width: 768px) {
  .ht-lg-17-f {
    height: 17px !important;
  }
}

.ht-18 {
  height: 18px;
}

.ht-18-f {
  height: 18px !important;
}

.ht-sm-18 {
  height: 18px;
}

.ht-sm-18-f {
  height: 18px !important;
}

@media (min-width: 576px) {
  .ht-md-18 {
    height: 18px;
  }
}

@media (min-width: 576px) {
  .ht-md-18-f {
    height: 18px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-18 {
    height: 18px;
  }
}

@media (min-width: 768px) {
  .ht-lg-18-f {
    height: 18px !important;
  }
}

.ht-19 {
  height: 19px;
}

.ht-19-f {
  height: 19px !important;
}

.ht-sm-19 {
  height: 19px;
}

.ht-sm-19-f {
  height: 19px !important;
}

@media (min-width: 576px) {
  .ht-md-19 {
    height: 19px;
  }
}

@media (min-width: 576px) {
  .ht-md-19-f {
    height: 19px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-19 {
    height: 19px;
  }
}

@media (min-width: 768px) {
  .ht-lg-19-f {
    height: 19px !important;
  }
}

.ht-20 {
  height: 20px;
}

.ht-20-f {
  height: 20px !important;
}

.ht-sm-20 {
  height: 20px;
}

.ht-sm-20-f {
  height: 20px !important;
}

@media (min-width: 576px) {
  .ht-md-20 {
    height: 20px;
  }
}

@media (min-width: 576px) {
  .ht-md-20-f {
    height: 20px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-20 {
    height: 20px;
  }
}

@media (min-width: 768px) {
  .ht-lg-20-f {
    height: 20px !important;
  }
}

.ht-21 {
  height: 21px;
}

.ht-21-f {
  height: 21px !important;
}

.ht-sm-21 {
  height: 21px;
}

.ht-sm-21-f {
  height: 21px !important;
}

@media (min-width: 576px) {
  .ht-md-21 {
    height: 21px;
  }
}

@media (min-width: 576px) {
  .ht-md-21-f {
    height: 21px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-21 {
    height: 21px;
  }
}

@media (min-width: 768px) {
  .ht-lg-21-f {
    height: 21px !important;
  }
}

.ht-22 {
  height: 22px;
}

.ht-22-f {
  height: 22px !important;
}

.ht-sm-22 {
  height: 22px;
}

.ht-sm-22-f {
  height: 22px !important;
}

@media (min-width: 576px) {
  .ht-md-22 {
    height: 22px;
  }
}

@media (min-width: 576px) {
  .ht-md-22-f {
    height: 22px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-22 {
    height: 22px;
  }
}

@media (min-width: 768px) {
  .ht-lg-22-f {
    height: 22px !important;
  }
}

.ht-23 {
  height: 23px;
}

.ht-23-f {
  height: 23px !important;
}

.ht-sm-23 {
  height: 23px;
}

.ht-sm-23-f {
  height: 23px !important;
}

@media (min-width: 576px) {
  .ht-md-23 {
    height: 23px;
  }
}

@media (min-width: 576px) {
  .ht-md-23-f {
    height: 23px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-23 {
    height: 23px;
  }
}

@media (min-width: 768px) {
  .ht-lg-23-f {
    height: 23px !important;
  }
}

.ht-24 {
  height: 24px;
}

.ht-24-f {
  height: 24px !important;
}

.ht-sm-24 {
  height: 24px;
}

.ht-sm-24-f {
  height: 24px !important;
}

@media (min-width: 576px) {
  .ht-md-24 {
    height: 24px;
  }
}

@media (min-width: 576px) {
  .ht-md-24-f {
    height: 24px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-24 {
    height: 24px;
  }
}

@media (min-width: 768px) {
  .ht-lg-24-f {
    height: 24px !important;
  }
}

.ht-25 {
  height: 25px;
}

.ht-25-f {
  height: 25px !important;
}

.ht-sm-25 {
  height: 25px;
}

.ht-sm-25-f {
  height: 25px !important;
}

@media (min-width: 576px) {
  .ht-md-25 {
    height: 25px;
  }
}

@media (min-width: 576px) {
  .ht-md-25-f {
    height: 25px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-25 {
    height: 25px;
  }
}

@media (min-width: 768px) {
  .ht-lg-25-f {
    height: 25px !important;
  }
}

.ht-26 {
  height: 26px;
}

.ht-26-f {
  height: 26px !important;
}

.ht-sm-26 {
  height: 26px;
}

.ht-sm-26-f {
  height: 26px !important;
}

@media (min-width: 576px) {
  .ht-md-26 {
    height: 26px;
  }
}

@media (min-width: 576px) {
  .ht-md-26-f {
    height: 26px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-26 {
    height: 26px;
  }
}

@media (min-width: 768px) {
  .ht-lg-26-f {
    height: 26px !important;
  }
}

.ht-27 {
  height: 27px;
}

.ht-27-f {
  height: 27px !important;
}

.ht-sm-27 {
  height: 27px;
}

.ht-sm-27-f {
  height: 27px !important;
}

@media (min-width: 576px) {
  .ht-md-27 {
    height: 27px;
  }
}

@media (min-width: 576px) {
  .ht-md-27-f {
    height: 27px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-27 {
    height: 27px;
  }
}

@media (min-width: 768px) {
  .ht-lg-27-f {
    height: 27px !important;
  }
}

.ht-28 {
  height: 28px;
}

.ht-28-f {
  height: 28px !important;
}

.ht-sm-28 {
  height: 28px;
}

.ht-sm-28-f {
  height: 28px !important;
}

@media (min-width: 576px) {
  .ht-md-28 {
    height: 28px;
  }
}

@media (min-width: 576px) {
  .ht-md-28-f {
    height: 28px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-28 {
    height: 28px;
  }
}

@media (min-width: 768px) {
  .ht-lg-28-f {
    height: 28px !important;
  }
}

.ht-29 {
  height: 29px;
}

.ht-29-f {
  height: 29px !important;
}

.ht-sm-29 {
  height: 29px;
}

.ht-sm-29-f {
  height: 29px !important;
}

@media (min-width: 576px) {
  .ht-md-29 {
    height: 29px;
  }
}

@media (min-width: 576px) {
  .ht-md-29-f {
    height: 29px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-29 {
    height: 29px;
  }
}

@media (min-width: 768px) {
  .ht-lg-29-f {
    height: 29px !important;
  }
}

.ht-30 {
  height: 30px;
}

.ht-30-f {
  height: 30px !important;
}

.ht-sm-30 {
  height: 30px;
}

.ht-sm-30-f {
  height: 30px !important;
}

@media (min-width: 576px) {
  .ht-md-30 {
    height: 30px;
  }
}

@media (min-width: 576px) {
  .ht-md-30-f {
    height: 30px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-30 {
    height: 30px;
  }
}

@media (min-width: 768px) {
  .ht-lg-30-f {
    height: 30px !important;
  }
}

.ht-31 {
  height: 31px;
}

.ht-31-f {
  height: 31px !important;
}

.ht-sm-31 {
  height: 31px;
}

.ht-sm-31-f {
  height: 31px !important;
}

@media (min-width: 576px) {
  .ht-md-31 {
    height: 31px;
  }
}

@media (min-width: 576px) {
  .ht-md-31-f {
    height: 31px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-31 {
    height: 31px;
  }
}

@media (min-width: 768px) {
  .ht-lg-31-f {
    height: 31px !important;
  }
}

.ht-32 {
  height: 32px;
}

.ht-32-f {
  height: 32px !important;
}

.ht-sm-32 {
  height: 32px;
}

.ht-sm-32-f {
  height: 32px !important;
}

@media (min-width: 576px) {
  .ht-md-32 {
    height: 32px;
  }
}

@media (min-width: 576px) {
  .ht-md-32-f {
    height: 32px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-32 {
    height: 32px;
  }
}

@media (min-width: 768px) {
  .ht-lg-32-f {
    height: 32px !important;
  }
}

.ht-33 {
  height: 33px;
}

.ht-33-f {
  height: 33px !important;
}

.ht-sm-33 {
  height: 33px;
}

.ht-sm-33-f {
  height: 33px !important;
}

@media (min-width: 576px) {
  .ht-md-33 {
    height: 33px;
  }
}

@media (min-width: 576px) {
  .ht-md-33-f {
    height: 33px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-33 {
    height: 33px;
  }
}

@media (min-width: 768px) {
  .ht-lg-33-f {
    height: 33px !important;
  }
}

.ht-34 {
  height: 34px;
}

.ht-34-f {
  height: 34px !important;
}

.ht-sm-34 {
  height: 34px;
}

.ht-sm-34-f {
  height: 34px !important;
}

@media (min-width: 576px) {
  .ht-md-34 {
    height: 34px;
  }
}

@media (min-width: 576px) {
  .ht-md-34-f {
    height: 34px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-34 {
    height: 34px;
  }
}

@media (min-width: 768px) {
  .ht-lg-34-f {
    height: 34px !important;
  }
}

.ht-35 {
  height: 35px;
}

.ht-35-f {
  height: 35px !important;
}

.ht-sm-35 {
  height: 35px;
}

.ht-sm-35-f {
  height: 35px !important;
}

@media (min-width: 576px) {
  .ht-md-35 {
    height: 35px;
  }
}

@media (min-width: 576px) {
  .ht-md-35-f {
    height: 35px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-35 {
    height: 35px;
  }
}

@media (min-width: 768px) {
  .ht-lg-35-f {
    height: 35px !important;
  }
}

.ht-36 {
  height: 36px;
}

.ht-36-f {
  height: 36px !important;
}

.ht-sm-36 {
  height: 36px;
}

.ht-sm-36-f {
  height: 36px !important;
}

@media (min-width: 576px) {
  .ht-md-36 {
    height: 36px;
  }
}

@media (min-width: 576px) {
  .ht-md-36-f {
    height: 36px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-36 {
    height: 36px;
  }
}

@media (min-width: 768px) {
  .ht-lg-36-f {
    height: 36px !important;
  }
}

.ht-37 {
  height: 37px;
}

.ht-37-f {
  height: 37px !important;
}

.ht-sm-37 {
  height: 37px;
}

.ht-sm-37-f {
  height: 37px !important;
}

@media (min-width: 576px) {
  .ht-md-37 {
    height: 37px;
  }
}

@media (min-width: 576px) {
  .ht-md-37-f {
    height: 37px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-37 {
    height: 37px;
  }
}

@media (min-width: 768px) {
  .ht-lg-37-f {
    height: 37px !important;
  }
}

.ht-38 {
  height: 38px;
}

.ht-38-f {
  height: 38px !important;
}

.ht-sm-38 {
  height: 38px;
}

.ht-sm-38-f {
  height: 38px !important;
}

@media (min-width: 576px) {
  .ht-md-38 {
    height: 38px;
  }
}

@media (min-width: 576px) {
  .ht-md-38-f {
    height: 38px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-38 {
    height: 38px;
  }
}

@media (min-width: 768px) {
  .ht-lg-38-f {
    height: 38px !important;
  }
}

.ht-39 {
  height: 39px;
}

.ht-39-f {
  height: 39px !important;
}

.ht-sm-39 {
  height: 39px;
}

.ht-sm-39-f {
  height: 39px !important;
}

@media (min-width: 576px) {
  .ht-md-39 {
    height: 39px;
  }
}

@media (min-width: 576px) {
  .ht-md-39-f {
    height: 39px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-39 {
    height: 39px;
  }
}

@media (min-width: 768px) {
  .ht-lg-39-f {
    height: 39px !important;
  }
}

.ht-40 {
  height: 40px;
}

.ht-40-f {
  height: 40px !important;
}

.ht-sm-40 {
  height: 40px;
}

.ht-sm-40-f {
  height: 40px !important;
}

@media (min-width: 576px) {
  .ht-md-40 {
    height: 40px;
  }
}

@media (min-width: 576px) {
  .ht-md-40-f {
    height: 40px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-40 {
    height: 40px;
  }
}

@media (min-width: 768px) {
  .ht-lg-40-f {
    height: 40px !important;
  }
}

.ht-41 {
  height: 41px;
}

.ht-41-f {
  height: 41px !important;
}

.ht-sm-41 {
  height: 41px;
}

.ht-sm-41-f {
  height: 41px !important;
}

@media (min-width: 576px) {
  .ht-md-41 {
    height: 41px;
  }
}

@media (min-width: 576px) {
  .ht-md-41-f {
    height: 41px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-41 {
    height: 41px;
  }
}

@media (min-width: 768px) {
  .ht-lg-41-f {
    height: 41px !important;
  }
}

.ht-42 {
  height: 42px;
}

.ht-42-f {
  height: 42px !important;
}

.ht-sm-42 {
  height: 42px;
}

.ht-sm-42-f {
  height: 42px !important;
}

@media (min-width: 576px) {
  .ht-md-42 {
    height: 42px;
  }
}

@media (min-width: 576px) {
  .ht-md-42-f {
    height: 42px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-42 {
    height: 42px;
  }
}

@media (min-width: 768px) {
  .ht-lg-42-f {
    height: 42px !important;
  }
}

.ht-43 {
  height: 43px;
}

.ht-43-f {
  height: 43px !important;
}

.ht-sm-43 {
  height: 43px;
}

.ht-sm-43-f {
  height: 43px !important;
}

@media (min-width: 576px) {
  .ht-md-43 {
    height: 43px;
  }
}

@media (min-width: 576px) {
  .ht-md-43-f {
    height: 43px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-43 {
    height: 43px;
  }
}

@media (min-width: 768px) {
  .ht-lg-43-f {
    height: 43px !important;
  }
}

.ht-44 {
  height: 44px;
}

.ht-44-f {
  height: 44px !important;
}

.ht-sm-44 {
  height: 44px;
}

.ht-sm-44-f {
  height: 44px !important;
}

@media (min-width: 576px) {
  .ht-md-44 {
    height: 44px;
  }
}

@media (min-width: 576px) {
  .ht-md-44-f {
    height: 44px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-44 {
    height: 44px;
  }
}

@media (min-width: 768px) {
  .ht-lg-44-f {
    height: 44px !important;
  }
}

.ht-45 {
  height: 45px;
}

.ht-45-f {
  height: 45px !important;
}

.ht-sm-45 {
  height: 45px;
}

.ht-sm-45-f {
  height: 45px !important;
}

@media (min-width: 576px) {
  .ht-md-45 {
    height: 45px;
  }
}

@media (min-width: 576px) {
  .ht-md-45-f {
    height: 45px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-45 {
    height: 45px;
  }
}

@media (min-width: 768px) {
  .ht-lg-45-f {
    height: 45px !important;
  }
}

.ht-46 {
  height: 46px;
}

.ht-46-f {
  height: 46px !important;
}

.ht-sm-46 {
  height: 46px;
}

.ht-sm-46-f {
  height: 46px !important;
}

@media (min-width: 576px) {
  .ht-md-46 {
    height: 46px;
  }
}

@media (min-width: 576px) {
  .ht-md-46-f {
    height: 46px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-46 {
    height: 46px;
  }
}

@media (min-width: 768px) {
  .ht-lg-46-f {
    height: 46px !important;
  }
}

.ht-47 {
  height: 47px;
}

.ht-47-f {
  height: 47px !important;
}

.ht-sm-47 {
  height: 47px;
}

.ht-sm-47-f {
  height: 47px !important;
}

@media (min-width: 576px) {
  .ht-md-47 {
    height: 47px;
  }
}

@media (min-width: 576px) {
  .ht-md-47-f {
    height: 47px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-47 {
    height: 47px;
  }
}

@media (min-width: 768px) {
  .ht-lg-47-f {
    height: 47px !important;
  }
}

.ht-48 {
  height: 48px;
}

.ht-48-f {
  height: 48px !important;
}

.ht-sm-48 {
  height: 48px;
}

.ht-sm-48-f {
  height: 48px !important;
}

@media (min-width: 576px) {
  .ht-md-48 {
    height: 48px;
  }
}

@media (min-width: 576px) {
  .ht-md-48-f {
    height: 48px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-48 {
    height: 48px;
  }
}

@media (min-width: 768px) {
  .ht-lg-48-f {
    height: 48px !important;
  }
}

.ht-49 {
  height: 49px;
}

.ht-49-f {
  height: 49px !important;
}

.ht-sm-49 {
  height: 49px;
}

.ht-sm-49-f {
  height: 49px !important;
}

@media (min-width: 576px) {
  .ht-md-49 {
    height: 49px;
  }
}

@media (min-width: 576px) {
  .ht-md-49-f {
    height: 49px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-49 {
    height: 49px;
  }
}

@media (min-width: 768px) {
  .ht-lg-49-f {
    height: 49px !important;
  }
}

.ht-50 {
  height: 50px;
}

.ht-50-f {
  height: 50px !important;
}

.ht-sm-50 {
  height: 50px;
}

.ht-sm-50-f {
  height: 50px !important;
}

@media (min-width: 576px) {
  .ht-md-50 {
    height: 50px;
  }
}

@media (min-width: 576px) {
  .ht-md-50-f {
    height: 50px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-50 {
    height: 50px;
  }
}

@media (min-width: 768px) {
  .ht-lg-50-f {
    height: 50px !important;
  }
}

.ht-51 {
  height: 51px;
}

.ht-51-f {
  height: 51px !important;
}

.ht-sm-51 {
  height: 51px;
}

.ht-sm-51-f {
  height: 51px !important;
}

@media (min-width: 576px) {
  .ht-md-51 {
    height: 51px;
  }
}

@media (min-width: 576px) {
  .ht-md-51-f {
    height: 51px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-51 {
    height: 51px;
  }
}

@media (min-width: 768px) {
  .ht-lg-51-f {
    height: 51px !important;
  }
}

.ht-52 {
  height: 52px;
}

.ht-52-f {
  height: 52px !important;
}

.ht-sm-52 {
  height: 52px;
}

.ht-sm-52-f {
  height: 52px !important;
}

@media (min-width: 576px) {
  .ht-md-52 {
    height: 52px;
  }
}

@media (min-width: 576px) {
  .ht-md-52-f {
    height: 52px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-52 {
    height: 52px;
  }
}

@media (min-width: 768px) {
  .ht-lg-52-f {
    height: 52px !important;
  }
}

.ht-53 {
  height: 53px;
}

.ht-53-f {
  height: 53px !important;
}

.ht-sm-53 {
  height: 53px;
}

.ht-sm-53-f {
  height: 53px !important;
}

@media (min-width: 576px) {
  .ht-md-53 {
    height: 53px;
  }
}

@media (min-width: 576px) {
  .ht-md-53-f {
    height: 53px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-53 {
    height: 53px;
  }
}

@media (min-width: 768px) {
  .ht-lg-53-f {
    height: 53px !important;
  }
}

.ht-54 {
  height: 54px;
}

.ht-54-f {
  height: 54px !important;
}

.ht-sm-54 {
  height: 54px;
}

.ht-sm-54-f {
  height: 54px !important;
}

@media (min-width: 576px) {
  .ht-md-54 {
    height: 54px;
  }
}

@media (min-width: 576px) {
  .ht-md-54-f {
    height: 54px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-54 {
    height: 54px;
  }
}

@media (min-width: 768px) {
  .ht-lg-54-f {
    height: 54px !important;
  }
}

.ht-55 {
  height: 55px;
}

.ht-55-f {
  height: 55px !important;
}

.ht-sm-55 {
  height: 55px;
}

.ht-sm-55-f {
  height: 55px !important;
}

@media (min-width: 576px) {
  .ht-md-55 {
    height: 55px;
  }
}

@media (min-width: 576px) {
  .ht-md-55-f {
    height: 55px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-55 {
    height: 55px;
  }
}

@media (min-width: 768px) {
  .ht-lg-55-f {
    height: 55px !important;
  }
}

.ht-56 {
  height: 56px;
}

.ht-56-f {
  height: 56px !important;
}

.ht-sm-56 {
  height: 56px;
}

.ht-sm-56-f {
  height: 56px !important;
}

@media (min-width: 576px) {
  .ht-md-56 {
    height: 56px;
  }
}

@media (min-width: 576px) {
  .ht-md-56-f {
    height: 56px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-56 {
    height: 56px;
  }
}

@media (min-width: 768px) {
  .ht-lg-56-f {
    height: 56px !important;
  }
}

.ht-57 {
  height: 57px;
}

.ht-57-f {
  height: 57px !important;
}

.ht-sm-57 {
  height: 57px;
}

.ht-sm-57-f {
  height: 57px !important;
}

@media (min-width: 576px) {
  .ht-md-57 {
    height: 57px;
  }
}

@media (min-width: 576px) {
  .ht-md-57-f {
    height: 57px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-57 {
    height: 57px;
  }
}

@media (min-width: 768px) {
  .ht-lg-57-f {
    height: 57px !important;
  }
}

.ht-58 {
  height: 58px;
}

.ht-58-f {
  height: 58px !important;
}

.ht-sm-58 {
  height: 58px;
}

.ht-sm-58-f {
  height: 58px !important;
}

@media (min-width: 576px) {
  .ht-md-58 {
    height: 58px;
  }
}

@media (min-width: 576px) {
  .ht-md-58-f {
    height: 58px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-58 {
    height: 58px;
  }
}

@media (min-width: 768px) {
  .ht-lg-58-f {
    height: 58px !important;
  }
}

.ht-59 {
  height: 59px;
}

.ht-59-f {
  height: 59px !important;
}

.ht-sm-59 {
  height: 59px;
}

.ht-sm-59-f {
  height: 59px !important;
}

@media (min-width: 576px) {
  .ht-md-59 {
    height: 59px;
  }
}

@media (min-width: 576px) {
  .ht-md-59-f {
    height: 59px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-59 {
    height: 59px;
  }
}

@media (min-width: 768px) {
  .ht-lg-59-f {
    height: 59px !important;
  }
}

.ht-60 {
  height: 60px;
}

.ht-60-f {
  height: 60px !important;
}

.ht-sm-60 {
  height: 60px;
}

.ht-sm-60-f {
  height: 60px !important;
}

@media (min-width: 576px) {
  .ht-md-60 {
    height: 60px;
  }
}

@media (min-width: 576px) {
  .ht-md-60-f {
    height: 60px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-60 {
    height: 60px;
  }
}

@media (min-width: 768px) {
  .ht-lg-60-f {
    height: 60px !important;
  }
}

.ht-61 {
  height: 61px;
}

.ht-61-f {
  height: 61px !important;
}

.ht-sm-61 {
  height: 61px;
}

.ht-sm-61-f {
  height: 61px !important;
}

@media (min-width: 576px) {
  .ht-md-61 {
    height: 61px;
  }
}

@media (min-width: 576px) {
  .ht-md-61-f {
    height: 61px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-61 {
    height: 61px;
  }
}

@media (min-width: 768px) {
  .ht-lg-61-f {
    height: 61px !important;
  }
}

.ht-62 {
  height: 62px;
}

.ht-62-f {
  height: 62px !important;
}

.ht-sm-62 {
  height: 62px;
}

.ht-sm-62-f {
  height: 62px !important;
}

@media (min-width: 576px) {
  .ht-md-62 {
    height: 62px;
  }
}

@media (min-width: 576px) {
  .ht-md-62-f {
    height: 62px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-62 {
    height: 62px;
  }
}

@media (min-width: 768px) {
  .ht-lg-62-f {
    height: 62px !important;
  }
}

.ht-63 {
  height: 63px;
}

.ht-63-f {
  height: 63px !important;
}

.ht-sm-63 {
  height: 63px;
}

.ht-sm-63-f {
  height: 63px !important;
}

@media (min-width: 576px) {
  .ht-md-63 {
    height: 63px;
  }
}

@media (min-width: 576px) {
  .ht-md-63-f {
    height: 63px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-63 {
    height: 63px;
  }
}

@media (min-width: 768px) {
  .ht-lg-63-f {
    height: 63px !important;
  }
}

.ht-64 {
  height: 64px;
}

.ht-64-f {
  height: 64px !important;
}

.ht-sm-64 {
  height: 64px;
}

.ht-sm-64-f {
  height: 64px !important;
}

@media (min-width: 576px) {
  .ht-md-64 {
    height: 64px;
  }
}

@media (min-width: 576px) {
  .ht-md-64-f {
    height: 64px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-64 {
    height: 64px;
  }
}

@media (min-width: 768px) {
  .ht-lg-64-f {
    height: 64px !important;
  }
}

.ht-65 {
  height: 65px;
}

.ht-65-f {
  height: 65px !important;
}

.ht-sm-65 {
  height: 65px;
}

.ht-sm-65-f {
  height: 65px !important;
}

@media (min-width: 576px) {
  .ht-md-65 {
    height: 65px;
  }
}

@media (min-width: 576px) {
  .ht-md-65-f {
    height: 65px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-65 {
    height: 65px;
  }
}

@media (min-width: 768px) {
  .ht-lg-65-f {
    height: 65px !important;
  }
}

.ht-66 {
  height: 66px;
}

.ht-66-f {
  height: 66px !important;
}

.ht-sm-66 {
  height: 66px;
}

.ht-sm-66-f {
  height: 66px !important;
}

@media (min-width: 576px) {
  .ht-md-66 {
    height: 66px;
  }
}

@media (min-width: 576px) {
  .ht-md-66-f {
    height: 66px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-66 {
    height: 66px;
  }
}

@media (min-width: 768px) {
  .ht-lg-66-f {
    height: 66px !important;
  }
}

.ht-67 {
  height: 67px;
}

.ht-67-f {
  height: 67px !important;
}

.ht-sm-67 {
  height: 67px;
}

.ht-sm-67-f {
  height: 67px !important;
}

@media (min-width: 576px) {
  .ht-md-67 {
    height: 67px;
  }
}

@media (min-width: 576px) {
  .ht-md-67-f {
    height: 67px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-67 {
    height: 67px;
  }
}

@media (min-width: 768px) {
  .ht-lg-67-f {
    height: 67px !important;
  }
}

.ht-68 {
  height: 68px;
}

.ht-68-f {
  height: 68px !important;
}

.ht-sm-68 {
  height: 68px;
}

.ht-sm-68-f {
  height: 68px !important;
}

@media (min-width: 576px) {
  .ht-md-68 {
    height: 68px;
  }
}

@media (min-width: 576px) {
  .ht-md-68-f {
    height: 68px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-68 {
    height: 68px;
  }
}

@media (min-width: 768px) {
  .ht-lg-68-f {
    height: 68px !important;
  }
}

.ht-69 {
  height: 69px;
}

.ht-69-f {
  height: 69px !important;
}

.ht-sm-69 {
  height: 69px;
}

.ht-sm-69-f {
  height: 69px !important;
}

@media (min-width: 576px) {
  .ht-md-69 {
    height: 69px;
  }
}

@media (min-width: 576px) {
  .ht-md-69-f {
    height: 69px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-69 {
    height: 69px;
  }
}

@media (min-width: 768px) {
  .ht-lg-69-f {
    height: 69px !important;
  }
}

.ht-70 {
  height: 70px;
}

.ht-70-f {
  height: 70px !important;
}

.ht-sm-70 {
  height: 70px;
}

.ht-sm-70-f {
  height: 70px !important;
}

@media (min-width: 576px) {
  .ht-md-70 {
    height: 70px;
  }
}

@media (min-width: 576px) {
  .ht-md-70-f {
    height: 70px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-70 {
    height: 70px;
  }
}

@media (min-width: 768px) {
  .ht-lg-70-f {
    height: 70px !important;
  }
}

.ht-71 {
  height: 71px;
}

.ht-71-f {
  height: 71px !important;
}

.ht-sm-71 {
  height: 71px;
}

.ht-sm-71-f {
  height: 71px !important;
}

@media (min-width: 576px) {
  .ht-md-71 {
    height: 71px;
  }
}

@media (min-width: 576px) {
  .ht-md-71-f {
    height: 71px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-71 {
    height: 71px;
  }
}

@media (min-width: 768px) {
  .ht-lg-71-f {
    height: 71px !important;
  }
}

.ht-72 {
  height: 72px;
}

.ht-72-f {
  height: 72px !important;
}

.ht-sm-72 {
  height: 72px;
}

.ht-sm-72-f {
  height: 72px !important;
}

@media (min-width: 576px) {
  .ht-md-72 {
    height: 72px;
  }
}

@media (min-width: 576px) {
  .ht-md-72-f {
    height: 72px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-72 {
    height: 72px;
  }
}

@media (min-width: 768px) {
  .ht-lg-72-f {
    height: 72px !important;
  }
}

.ht-73 {
  height: 73px;
}

.ht-73-f {
  height: 73px !important;
}

.ht-sm-73 {
  height: 73px;
}

.ht-sm-73-f {
  height: 73px !important;
}

@media (min-width: 576px) {
  .ht-md-73 {
    height: 73px;
  }
}

@media (min-width: 576px) {
  .ht-md-73-f {
    height: 73px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-73 {
    height: 73px;
  }
}

@media (min-width: 768px) {
  .ht-lg-73-f {
    height: 73px !important;
  }
}

.ht-74 {
  height: 74px;
}

.ht-74-f {
  height: 74px !important;
}

.ht-sm-74 {
  height: 74px;
}

.ht-sm-74-f {
  height: 74px !important;
}

@media (min-width: 576px) {
  .ht-md-74 {
    height: 74px;
  }
}

@media (min-width: 576px) {
  .ht-md-74-f {
    height: 74px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-74 {
    height: 74px;
  }
}

@media (min-width: 768px) {
  .ht-lg-74-f {
    height: 74px !important;
  }
}

.ht-75 {
  height: 75px;
}

.ht-75-f {
  height: 75px !important;
}

.ht-sm-75 {
  height: 75px;
}

.ht-sm-75-f {
  height: 75px !important;
}

@media (min-width: 576px) {
  .ht-md-75 {
    height: 75px;
  }
}

@media (min-width: 576px) {
  .ht-md-75-f {
    height: 75px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-75 {
    height: 75px;
  }
}

@media (min-width: 768px) {
  .ht-lg-75-f {
    height: 75px !important;
  }
}

.ht-76 {
  height: 76px;
}

.ht-76-f {
  height: 76px !important;
}

.ht-sm-76 {
  height: 76px;
}

.ht-sm-76-f {
  height: 76px !important;
}

@media (min-width: 576px) {
  .ht-md-76 {
    height: 76px;
  }
}

@media (min-width: 576px) {
  .ht-md-76-f {
    height: 76px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-76 {
    height: 76px;
  }
}

@media (min-width: 768px) {
  .ht-lg-76-f {
    height: 76px !important;
  }
}

.ht-77 {
  height: 77px;
}

.ht-77-f {
  height: 77px !important;
}

.ht-sm-77 {
  height: 77px;
}

.ht-sm-77-f {
  height: 77px !important;
}

@media (min-width: 576px) {
  .ht-md-77 {
    height: 77px;
  }
}

@media (min-width: 576px) {
  .ht-md-77-f {
    height: 77px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-77 {
    height: 77px;
  }
}

@media (min-width: 768px) {
  .ht-lg-77-f {
    height: 77px !important;
  }
}

.ht-78 {
  height: 78px;
}

.ht-78-f {
  height: 78px !important;
}

.ht-sm-78 {
  height: 78px;
}

.ht-sm-78-f {
  height: 78px !important;
}

@media (min-width: 576px) {
  .ht-md-78 {
    height: 78px;
  }
}

@media (min-width: 576px) {
  .ht-md-78-f {
    height: 78px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-78 {
    height: 78px;
  }
}

@media (min-width: 768px) {
  .ht-lg-78-f {
    height: 78px !important;
  }
}

.ht-79 {
  height: 79px;
}

.ht-79-f {
  height: 79px !important;
}

.ht-sm-79 {
  height: 79px;
}

.ht-sm-79-f {
  height: 79px !important;
}

@media (min-width: 576px) {
  .ht-md-79 {
    height: 79px;
  }
}

@media (min-width: 576px) {
  .ht-md-79-f {
    height: 79px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-79 {
    height: 79px;
  }
}

@media (min-width: 768px) {
  .ht-lg-79-f {
    height: 79px !important;
  }
}

.ht-80 {
  height: 80px;
}

.ht-80-f {
  height: 80px !important;
}

.ht-sm-80 {
  height: 80px;
}

.ht-sm-80-f {
  height: 80px !important;
}

@media (min-width: 576px) {
  .ht-md-80 {
    height: 80px;
  }
}

@media (min-width: 576px) {
  .ht-md-80-f {
    height: 80px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-80 {
    height: 80px;
  }
}

@media (min-width: 768px) {
  .ht-lg-80-f {
    height: 80px !important;
  }
}

.ht-81 {
  height: 81px;
}

.ht-81-f {
  height: 81px !important;
}

.ht-sm-81 {
  height: 81px;
}

.ht-sm-81-f {
  height: 81px !important;
}

@media (min-width: 576px) {
  .ht-md-81 {
    height: 81px;
  }
}

@media (min-width: 576px) {
  .ht-md-81-f {
    height: 81px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-81 {
    height: 81px;
  }
}

@media (min-width: 768px) {
  .ht-lg-81-f {
    height: 81px !important;
  }
}

.ht-82 {
  height: 82px;
}

.ht-82-f {
  height: 82px !important;
}

.ht-sm-82 {
  height: 82px;
}

.ht-sm-82-f {
  height: 82px !important;
}

@media (min-width: 576px) {
  .ht-md-82 {
    height: 82px;
  }
}

@media (min-width: 576px) {
  .ht-md-82-f {
    height: 82px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-82 {
    height: 82px;
  }
}

@media (min-width: 768px) {
  .ht-lg-82-f {
    height: 82px !important;
  }
}

.ht-83 {
  height: 83px;
}

.ht-83-f {
  height: 83px !important;
}

.ht-sm-83 {
  height: 83px;
}

.ht-sm-83-f {
  height: 83px !important;
}

@media (min-width: 576px) {
  .ht-md-83 {
    height: 83px;
  }
}

@media (min-width: 576px) {
  .ht-md-83-f {
    height: 83px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-83 {
    height: 83px;
  }
}

@media (min-width: 768px) {
  .ht-lg-83-f {
    height: 83px !important;
  }
}

.ht-84 {
  height: 84px;
}

.ht-84-f {
  height: 84px !important;
}

.ht-sm-84 {
  height: 84px;
}

.ht-sm-84-f {
  height: 84px !important;
}

@media (min-width: 576px) {
  .ht-md-84 {
    height: 84px;
  }
}

@media (min-width: 576px) {
  .ht-md-84-f {
    height: 84px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-84 {
    height: 84px;
  }
}

@media (min-width: 768px) {
  .ht-lg-84-f {
    height: 84px !important;
  }
}

.ht-85 {
  height: 85px;
}

.ht-85-f {
  height: 85px !important;
}

.ht-sm-85 {
  height: 85px;
}

.ht-sm-85-f {
  height: 85px !important;
}

@media (min-width: 576px) {
  .ht-md-85 {
    height: 85px;
  }
}

@media (min-width: 576px) {
  .ht-md-85-f {
    height: 85px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-85 {
    height: 85px;
  }
}

@media (min-width: 768px) {
  .ht-lg-85-f {
    height: 85px !important;
  }
}

.ht-86 {
  height: 86px;
}

.ht-86-f {
  height: 86px !important;
}

.ht-sm-86 {
  height: 86px;
}

.ht-sm-86-f {
  height: 86px !important;
}

@media (min-width: 576px) {
  .ht-md-86 {
    height: 86px;
  }
}

@media (min-width: 576px) {
  .ht-md-86-f {
    height: 86px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-86 {
    height: 86px;
  }
}

@media (min-width: 768px) {
  .ht-lg-86-f {
    height: 86px !important;
  }
}

.ht-87 {
  height: 87px;
}

.ht-87-f {
  height: 87px !important;
}

.ht-sm-87 {
  height: 87px;
}

.ht-sm-87-f {
  height: 87px !important;
}

@media (min-width: 576px) {
  .ht-md-87 {
    height: 87px;
  }
}

@media (min-width: 576px) {
  .ht-md-87-f {
    height: 87px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-87 {
    height: 87px;
  }
}

@media (min-width: 768px) {
  .ht-lg-87-f {
    height: 87px !important;
  }
}

.ht-88 {
  height: 88px;
}

.ht-88-f {
  height: 88px !important;
}

.ht-sm-88 {
  height: 88px;
}

.ht-sm-88-f {
  height: 88px !important;
}

@media (min-width: 576px) {
  .ht-md-88 {
    height: 88px;
  }
}

@media (min-width: 576px) {
  .ht-md-88-f {
    height: 88px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-88 {
    height: 88px;
  }
}

@media (min-width: 768px) {
  .ht-lg-88-f {
    height: 88px !important;
  }
}

.ht-89 {
  height: 89px;
}

.ht-89-f {
  height: 89px !important;
}

.ht-sm-89 {
  height: 89px;
}

.ht-sm-89-f {
  height: 89px !important;
}

@media (min-width: 576px) {
  .ht-md-89 {
    height: 89px;
  }
}

@media (min-width: 576px) {
  .ht-md-89-f {
    height: 89px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-89 {
    height: 89px;
  }
}

@media (min-width: 768px) {
  .ht-lg-89-f {
    height: 89px !important;
  }
}

.ht-90 {
  height: 90px;
}

.ht-90-f {
  height: 90px !important;
}

.ht-sm-90 {
  height: 90px;
}

.ht-sm-90-f {
  height: 90px !important;
}

@media (min-width: 576px) {
  .ht-md-90 {
    height: 90px;
  }
}

@media (min-width: 576px) {
  .ht-md-90-f {
    height: 90px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-90 {
    height: 90px;
  }
}

@media (min-width: 768px) {
  .ht-lg-90-f {
    height: 90px !important;
  }
}

.ht-91 {
  height: 91px;
}

.ht-91-f {
  height: 91px !important;
}

.ht-sm-91 {
  height: 91px;
}

.ht-sm-91-f {
  height: 91px !important;
}

@media (min-width: 576px) {
  .ht-md-91 {
    height: 91px;
  }
}

@media (min-width: 576px) {
  .ht-md-91-f {
    height: 91px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-91 {
    height: 91px;
  }
}

@media (min-width: 768px) {
  .ht-lg-91-f {
    height: 91px !important;
  }
}

.ht-92 {
  height: 92px;
}

.ht-92-f {
  height: 92px !important;
}

.ht-sm-92 {
  height: 92px;
}

.ht-sm-92-f {
  height: 92px !important;
}

@media (min-width: 576px) {
  .ht-md-92 {
    height: 92px;
  }
}

@media (min-width: 576px) {
  .ht-md-92-f {
    height: 92px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-92 {
    height: 92px;
  }
}

@media (min-width: 768px) {
  .ht-lg-92-f {
    height: 92px !important;
  }
}

.ht-93 {
  height: 93px;
}

.ht-93-f {
  height: 93px !important;
}

.ht-sm-93 {
  height: 93px;
}

.ht-sm-93-f {
  height: 93px !important;
}

@media (min-width: 576px) {
  .ht-md-93 {
    height: 93px;
  }
}

@media (min-width: 576px) {
  .ht-md-93-f {
    height: 93px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-93 {
    height: 93px;
  }
}

@media (min-width: 768px) {
  .ht-lg-93-f {
    height: 93px !important;
  }
}

.ht-94 {
  height: 94px;
}

.ht-94-f {
  height: 94px !important;
}

.ht-sm-94 {
  height: 94px;
}

.ht-sm-94-f {
  height: 94px !important;
}

@media (min-width: 576px) {
  .ht-md-94 {
    height: 94px;
  }
}

@media (min-width: 576px) {
  .ht-md-94-f {
    height: 94px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-94 {
    height: 94px;
  }
}

@media (min-width: 768px) {
  .ht-lg-94-f {
    height: 94px !important;
  }
}

.ht-95 {
  height: 95px;
}

.ht-95-f {
  height: 95px !important;
}

.ht-sm-95 {
  height: 95px;
}

.ht-sm-95-f {
  height: 95px !important;
}

@media (min-width: 576px) {
  .ht-md-95 {
    height: 95px;
  }
}

@media (min-width: 576px) {
  .ht-md-95-f {
    height: 95px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-95 {
    height: 95px;
  }
}

@media (min-width: 768px) {
  .ht-lg-95-f {
    height: 95px !important;
  }
}

.ht-96 {
  height: 96px;
}

.ht-96-f {
  height: 96px !important;
}

.ht-sm-96 {
  height: 96px;
}

.ht-sm-96-f {
  height: 96px !important;
}

@media (min-width: 576px) {
  .ht-md-96 {
    height: 96px;
  }
}

@media (min-width: 576px) {
  .ht-md-96-f {
    height: 96px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-96 {
    height: 96px;
  }
}

@media (min-width: 768px) {
  .ht-lg-96-f {
    height: 96px !important;
  }
}

.ht-97 {
  height: 97px;
}

.ht-97-f {
  height: 97px !important;
}

.ht-sm-97 {
  height: 97px;
}

.ht-sm-97-f {
  height: 97px !important;
}

@media (min-width: 576px) {
  .ht-md-97 {
    height: 97px;
  }
}

@media (min-width: 576px) {
  .ht-md-97-f {
    height: 97px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-97 {
    height: 97px;
  }
}

@media (min-width: 768px) {
  .ht-lg-97-f {
    height: 97px !important;
  }
}

.ht-98 {
  height: 98px;
}

.ht-98-f {
  height: 98px !important;
}

.ht-sm-98 {
  height: 98px;
}

.ht-sm-98-f {
  height: 98px !important;
}

@media (min-width: 576px) {
  .ht-md-98 {
    height: 98px;
  }
}

@media (min-width: 576px) {
  .ht-md-98-f {
    height: 98px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-98 {
    height: 98px;
  }
}

@media (min-width: 768px) {
  .ht-lg-98-f {
    height: 98px !important;
  }
}

.ht-99 {
  height: 99px;
}

.ht-99-f {
  height: 99px !important;
}

.ht-sm-99 {
  height: 99px;
}

.ht-sm-99-f {
  height: 99px !important;
}

@media (min-width: 576px) {
  .ht-md-99 {
    height: 99px;
  }
}

@media (min-width: 576px) {
  .ht-md-99-f {
    height: 99px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-99 {
    height: 99px;
  }
}

@media (min-width: 768px) {
  .ht-lg-99-f {
    height: 99px !important;
  }
}

.ht-100 {
  height: 100px;
}

.ht-100-f {
  height: 100px !important;
}

.ht-sm-100 {
  height: 100px;
}

.ht-sm-100-f {
  height: 100px !important;
}

@media (min-width: 576px) {
  .ht-md-100 {
    height: 100px;
  }
}

@media (min-width: 576px) {
  .ht-md-100-f {
    height: 100px !important;
  }
}

@media (min-width: 768px) {
  .ht-lg-100 {
    height: 100px;
  }
}

@media (min-width: 768px) {
  .ht-lg-100-f {
    height: 100px !important;
  }
}

/* ----------------------------------------------------------------

	topbar.scss

-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Top Bar
-----------------------------------------------------------------*/
#top-bar {
  position: relative;
  border-bottom: 1px solid #eeeeee;
  font-size: 0.815rem;
  background-color: #fff;
}

/* Top Links
---------------------------------*/
.top-links {
  position: relative;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  -ms-flex-positive: 0;
  flex-grow: 0;
  border-bottom: 1px solid #eeeeee;
}
@media (min-width: 768px) {
  .top-links {
    border-bottom: 0;
  }
}
.top-links-container {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  list-style: none;
  margin-bottom: 0;
}
.top-links-item {
  position: relative;
  border-left: 1px solid #eeeeee;
}
.top-links-item:first-child, .top-links-sub-menu .top-links-item {
  border-left: 0 !important;
}
.top-links-item > a {
  display: block;
  padding: 12px;
  font-size: 0.75rem;
  line-height: 20px;
  font-weight: 700;
  text-transform: uppercase;
  color: #666666;
}
.top-links-item > a > i {
  vertical-align: top;
}
.top-links-item > a > i.icon-angle-down {
  margin: 0 0 0 5px !important;
}
.top-links-item > a > i:first-child {
  margin-right: 3px;
}
.top-links-item.full-icon > a > i {
  top: 2px;
  font-size: 0.875rem;
  margin: 0;
}
.top-links-item:hover {
  background-color: #EEE;
}
.top-links-sub-menu, .top-links-section {
  position: absolute;
  visibility: hidden;
  pointer-events: none;
  opacity: 0;
  list-style: none;
  z-index: -1;
  line-height: 1.5;
  background: #fff;
  border: 0;
  top: 100%;
  left: -1px;
  width: 140px;
  margin-top: 10px;
  border: 1px solid #eeeeee;
  border-top: 1px solid #2a93c9;
  box-shadow: 0px 13px 42px 11px rgba(0, 0, 0, 0.05);
  transition: opacity 0.25s ease, margin 0.2s ease;
}
.top-links:not(.on-click) .top-links-item:hover > .top-links-sub-menu, .top-links:not(.on-click) .top-links-item:hover > .top-links-section, .top-links.on-click .top-links-sub-menu, .top-links.on-click .top-links-section {
  opacity: 1;
  visibility: visible;
  margin-top: 0;
  pointer-events: auto;
  z-index: 499;
}
.top-links.on-click .top-links-sub-menu, .top-links.on-click .top-links-section {
  display: none;
}
.top-links-sub-menu .top-links-sub-menu {
  top: -1px;
  left: 100%;
}
.top-links-sub-menu .top-links-item:not(:first-child) {
  border-top: 1px solid whitesmoke;
}
.top-links-sub-menu .top-links-item:hover {
  background-color: #f9f9f9;
}
.top-links-sub-menu .top-links-item > a {
  padding-top: 9px;
  padding-bottom: 9px;
  font-size: 0.6875rem;
}
.top-links-sub-menu .top-links-item > a > img {
  display: inline-block;
  position: relative;
  top: -1px;
  width: 16px;
  height: 16px;
  margin-right: 4px;
}
.top-links-sub-menu .top-links-item > a i.icon-angle-down {
  margin: 0 !important;
  position: absolute;
  top: 50%;
  left: auto;
  right: 10px;
  transform: translateY(-50%) rotate(-90deg);
}
.top-links-sub-menu.top-demo-lang .top-links-item > a > img {
  top: 4px;
  width: 16px;
  height: 16px;
}
.top-links-section {
  padding: 25px;
  left: 0;
  width: 280px;
}
.top-links-section.menu-pos-invert {
  left: auto;
  right: 0;
}

/* Top Social
-----------------------------------------------------------------*/
#top-social {
  display: -ms-flexbox;
  display: flex;
  justify-content: center;
  margin: 0;
}
#top-social li {
  position: relative;
  list-style: none;
  border-left: 1px solid #eeeeee;
}
#top-social li:first-child {
  border-left: 0 !important;
}
#top-social li .ts-icon,
#top-social li .ts-text {
  display: block;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  height: 44px;
  line-height: 44px;
}
#top-social li .ts-icon {
  width: 40px;
  text-align: center;
  font-size: 0.875rem;
}
#top-social li .ts-text {
  max-width: 0;
  white-space: nowrap;
  -webkit-transition: all 0.2s ease;
  transition: all 0.2s ease;
}
#top-social li a:hover .ts-text {
  max-width: 200px;
  padding-right: 12px;
  -webkit-transition: all 0.4s ease;
  transition: all 0.4s ease;
}
#top-social a {
  display: -ms-flexbox;
  display: flex;
  width: auto;
  overflow: hidden;
  font-weight: bold;
  color: #666;
}
#top-social a:hover {
  color: #FFF !important;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}

/* Top Login
-----------------------------------------------------------------*/
#top-login {
  margin-bottom: 0;
}
#top-login .checkbox {
  margin-bottom: 10px;
}
#top-login .form-control {
  position: relative;
}
#top-login .form-control:focus {
  border-color: #CCC;
}
#top-login .input-group#top-login-username {
  margin-bottom: -1px;
}
#top-login #top-login-username input,
#top-login #top-login-username .input-group-addon {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
#top-login .input-group#top-login-password {
  margin-bottom: 10px;
}
#top-login #top-login-password input,
#top-login #top-login-password .input-group-addon {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

/* ----------------------------------------------------------------

	header.scss

-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Header
-----------------------------------------------------------------*/
#header {
  position: relative;
  background-color: #fff;
  border-bottom: 1px solid #eeeeee;
}
#header-wrap {
  position: relative;
  width: 100%;
  background-color: #fff;
}
@media (min-width: 992px) {
  #header-wrap {
    position: fixed;
    height: auto;
    z-index: 299;
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.1);
    transition: height 0.2s ease 0s, background-color 0.3s ease 0s;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    will-change: left, top, transform;
  }
  #top-bar + #header:not(.sticky-header) #header-wrap, #slider + #header:not(.sticky-header) #header-wrap, .floating-header:not(.sticky-header) #header-wrap, div + #header-wrap, .no-sticky #header-wrap, [data-sticky-offset]:not([data-sticky-offset=full]):not(.sticky-header) #header-wrap, body:not(.stretched) #header:not(.sticky-header) #header-wrap {
    position: absolute;
  }
}

.header-wrap-clone {
  display: none;
}
@media (min-width: 992px) {
  .header-wrap-clone {
    display: block;
    height: 101px;
  }
  .header-size-sm .header-wrap-clone {
    height: 61px;
  }
  .header-size-md .header-wrap-clone {
    height: 81px;
  }
  .header-size-lg .header-wrap-clone {
    height: 121px;
  }
}

.header-row {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}
@media (min-width: 992px) {
  .header-row {
    position: initial;
  }
}

@media (min-width: 992px) {
  #header.transparent-header {
    background: transparent;
    border-bottom: 1px solid transparent;
    z-index: 199;
  }
  #header.transparent-header:not(.sticky-header) #header-wrap {
    background: transparent;
    border-bottom: 1px solid transparent;
    z-index: 299;
  }
  #header.transparent-header.floating-header {
    margin-top: 60px;
  }
  #header.transparent-header.floating-header .container {
    padding: 0 30px;
    background-color: #fff;
  }
  #header.transparent-header.floating-header:not(.sticky-header) .container {
    position: relative;
  }
  #header.floating-header + .include-header {
    margin-top: -162px;
  }

  #header.transparent-header + .page-title-parallax.include-header .container {
    z-index: 5;
    padding-top: 100px;
  }
  #header + .include-header {
    margin-top: -102px;
    display: inline-block;
    vertical-align: bottom;
    width: 100%;
  }
  #header.transparent-header.semi-transparent #header-wrap {
    background-color: rgba(255, 255, 255, 0.8);
  }
  #header.full-header:not(.transparent-header) {
    border-bottom-color: #eeeeee;
  }
  .stretched #header.full-header #header-wrap > .container {
    width: 100%;
    max-width: none;
    padding: 0 30px;
  }

  #header.full-header.transparent-header #header-wrap {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
  #slider + #header.full-header.transparent-header #header-wrap {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    border-bottom: none;
  }

  /* ----------------------------------------------------------------
  	Sticky Header
  -----------------------------------------------------------------*/
  .sub-menu-container,
.mega-menu-content {
    -webkit-transition: top 0.4s ease;
    transition: top 0.4s ease;
  }

  .sticky-header #header-wrap {
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  .sticky-header.semi-transparent {
    background-color: transparent;
  }
  .sticky-header.semi-transparent #header-wrap {
    background-color: rgba(255, 255, 255, 0.8);
  }

  /* Primary Menu - Style 3
  -----------------------------------------------------------------*/
  .sticky-header .style-3 .menu-container > .menu-item > .menu-link {
    margin: 0;
    border-radius: 0;
  }

  /* Primary Menu - Style 4
  -----------------------------------------------------------------*/
  .sticky-header .style-4 .menu-container > .menu-item > .menu-link {
    padding-top: 8px;
    padding-bottom: 8px;
    margin: 10px 0;
  }

  /* Primary Menu - Style 5
  -----------------------------------------------------------------*/
  .sticky-header-shrink .style-5 .menu-container > .menu-item {
    margin-left: 2px;
  }
  .sticky-header-shrink .style-5 .menu-container > .menu-item:first-child {
    margin-left: 0;
  }
  .sticky-header-shrink .style-5 .menu-container > .menu-item > .menu-link {
    line-height: 22px;
    padding-top: 19px;
    padding-bottom: 19px;
  }
  .sticky-header-shrink .style-5 .menu-container > .menu-item > .menu-link > div {
    padding: 0;
  }
  .sticky-header-shrink .style-5 .menu-container > .menu-item > .menu-link i:not(.icon-angle-down) {
    display: inline-block;
    width: 16px;
    top: -1px;
    margin: 0 6px 0 0;
    font-size: 0.875rem;
    line-height: 22px;
  }
}
@media (max-width: 767.98px) {
  .sticky-header #header-wrap {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 299;
    background-color: #fff;
    border-bottom: 1px solid #eeeeee;
    -webkit-transform: translateZ(0);
    -webkit-backface-visibility: hidden;
  }
  .sticky-header .header-wrap-clone {
    display: block;
  }
  .sticky-header .menu-container {
    max-height: 60vh;
    margin: 1rem 0;
    overflow-x: hidden;
    overflow-y: scroll;
  }

  body:not(.primary-menu-open) .dark.transparent-header-responsive,
body:not(.primary-menu-open) .transparent-header-responsive {
    background-color: transparent;
    border: none;
  }
  body:not(.primary-menu-open) .semi-transparent.transparent-header-responsive {
    background-color: rgba(255, 255, 255, 0.8);
    z-index: 1;
  }
}
/* ----------------------------------------------------------------
	Logo
-----------------------------------------------------------------*/
#logo {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  -ms-flex-align: center;
  margin-right: auto;
  max-height: 100%;
}
#logo a {
  color: #000;
  font-family: "Poppins", sans-serif;
  font-size: 36px;
}
#logo a.standard-logo {
  display: -ms-flexbox;
  display: flex;
}
#logo a.retina-logo {
  display: none;
}
#logo img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  height: 100px;
}
@media (min-width: 992px) {
  #logo img {
    -webkit-transition: height 0.4s ease, padding 0.4s ease, margin 0.4s ease, opacity 0.3s ease;
    transition: height 0.4s ease, padding 0.4s ease, margin 0.4s ease, opacity 0.3s ease;
    max-height: 100%;
  }
}
.header-size-sm #header-wrap #logo img {
  height: 60px;
}
.header-size-md #header-wrap #logo img {
  height: 80px;
}
.header-size-lg #header-wrap #logo img {
  height: 120px;
}

@media (min-width: 992px) {
  .full-header #logo {
    padding-right: 30px;
    border-right: 1px solid #eeeeee;
  }
  #header.transparent-header.full-header #logo {
    border-right-color: rgba(0, 0, 0, 0.1);
  }
  .sticky-header-shrink #header-wrap #logo img {
    height: 60px;
  }
}

/* ----------------------------------------------------------------
	Retina Logo
-----------------------------------------------------------------*/
@media only screen and (-webkit-min-device-pixel-ratio: 2), only screen and (min--moz-device-pixel-ratio: 2), only screen and (-o-min-device-pixel-ratio: 2), only screen and (min-device-pixel-ratio: 2), only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
  #logo a.standard-logo {
    display: none;
  }
  #logo a.retina-logo {
    display: -ms-flexbox;
    display: flex;
  }
}
/* ----------------------------------------------------------------
	Header Right Area
-----------------------------------------------------------------*/
.header-extras {
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  margin-bottom: 0;
}
.header-extras li {
  display: -ms-flexbox;
  display: flex;
  margin-left: 1.25rem;
  overflow: hidden;
  list-style: none;
}
.header-extras li:first-child {
  margin-left: 0;
}
.header-extras li .he-text {
  padding-left: 10px;
  font-weight: bold;
  font-size: 0.875rem;
}
.header-extras li .he-text span {
  display: block;
  font-weight: 400;
  color: #2a93c9;
}

/* ----------------------------------------------------------------
	Primary Menu
-----------------------------------------------------------------*/
.primary-menu {
  display: -ms-flexbox;
  display: flex;
  flex-basis: 100%;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -ms-flex-align: center;
  align-items: center;
}
@media (min-width: 992px) {
  .primary-menu {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
.primary-menu + .primary-menu {
  border-top: 1px solid #eeeeee;
}
@media (min-width: 992px) {
  .primary-menu + .primary-menu {
    border-top: 0;
  }
}

#primary-menu-trigger,
#page-menu-trigger {
  display: -ms-flex;
  display: flex;
  opacity: 1;
  pointer-events: auto;
  z-index: 1;
  cursor: pointer;
  font-size: 0.875rem;
  width: 50px;
  height: 50px;
  line-height: 50px;
  justify-content: center;
  align-items: center;
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}
@media (min-width: 992px) {
  #primary-menu-trigger,
#page-menu-trigger {
    display: none;
    opacity: 0;
    pointer-events: none;
    z-index: 0;
  }
}

body:not(.top-search-open) .top-search-parent #primary-menu-trigger {
  opacity: 1;
  -webkit-transition: opacity 0.2s 0.2s ease, top 0.4s ease;
  -o-transition: opacity 0.2s 0.2s ease, top 0.4s ease;
  transition: opacity 0.2s 0.2s ease, top 0.4s ease;
}
.top-search-open .top-search-parent #primary-menu-trigger {
  opacity: 0;
}

.svg-trigger {
  width: 30px;
  height: 30px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transition: transform 400ms;
  -moz-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.svg-trigger path {
  fill: none;
  transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
  stroke: #333;
  stroke-width: 5;
  stroke-linecap: round;
}
.svg-trigger path:nth-child(1) {
  stroke-dasharray: 40 160;
}
.primary-menu-open .svg-trigger path:nth-child(1) {
  stroke-dashoffset: -64px;
}
.svg-trigger path:nth-child(2) {
  stroke-dasharray: 40 142;
  transform-origin: 50%;
  transition: transform 400ms;
}
.primary-menu-open .svg-trigger path:nth-child(2) {
  transform: rotate(90deg);
}
.svg-trigger path:nth-child(3) {
  stroke-dasharray: 40 85;
  transform-origin: 50%;
  transition: transform 400ms, stroke-dashoffset 400ms;
}
.primary-menu-open .svg-trigger path:nth-child(3) {
  stroke-dashoffset: -64px;
}
.primary-menu-open .svg-trigger {
  transform: rotate(45deg);
}

.menu-container {
  display: none;
  list-style: none;
  margin: 0;
  width: 100%;
}

.menu-item {
  position: relative;
}
.menu-item:not(:first-child) {
  border-top: 1px solid #eeeeee;
}
@media (min-width: 992px) {
  .menu-item:not(:first-child) {
    border-top: 0;
  }
}
.menu-item:hover > .menu-link, .menu-item.current > .menu-link {
  color: #2a93c9;
}
.menu-item .sub-menu-trigger {
  display: block;
  position: absolute;
  cursor: pointer;
  outline: none;
  border: none;
  background: none;
  padding: 0;
  top: 9px;
  right: 0;
  width: 32px;
  height: 32px;
  font-size: 12px;
  line-height: 32px;
  text-align: center;
  -webkit-transition: transform 0.3s ease;
  transition: transform 0.3s ease;
}
@media (min-width: 992px) {
  .menu-item.menu-item-important .menu-link > div::after {
    content: "";
    display: inline-block;
    position: relative;
    top: -5px;
    left: 5px;
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #dc3545;
  }
}

.menu-link {
  display: block;
  line-height: 22px;
  padding: 14px 5px;
  color: #444;
  font-weight: 700;
  font-size: 0.8125rem;
  /*letter-spacing: $primary-menu-font-spacing;
  text-transform: $primary-menu-font-tt;*/
  font-family: "Lato", sans-serif;
}
@media (min-width: 992px) {
  .menu-link {
    padding: 39px 15px;
    -webkit-transition: margin 0.4s ease, padding 0.4s ease;
    -o-transition: margin 0.4s ease, padding 0.4s ease;
    transition: margin 0.4s ease, padding 0.4s ease;
  }
  .header-size-sm .menu-container > .menu-item > .menu-link {
    padding-top: 19px;
    padding-bottom: 19px;
  }
  .header-size-md .menu-container > .menu-item > .menu-link {
    padding-top: 29px;
    padding-bottom: 29px;
  }
  .header-size-lg .menu-container > .menu-item > .menu-link {
    padding-top: 49px;
    padding-bottom: 49px;
  }
  .sticky-header-shrink .menu-container > .menu-item > .menu-link {
    padding-top: 19px;
    padding-bottom: 19px;
  }
}
.menu-link span {
  display: none;
}
.menu-link i {
  position: relative;
  top: -1px;
  font-size: 0.875rem;
  width: 16px;
  text-align: center;
  margin-right: 6px;
  vertical-align: top;
}
.menu-link div > i.icon-angle-down {
  display: none;
}

.sub-menu-container,
.mega-menu-content {
  position: relative;
  padding-left: 15px;
  border-top: 1px solid #eeeeee;
  list-style: none;
  margin-bottom: 0;
}
@media (min-width: 992px) {
  .sub-menu-container,
.mega-menu-content {
    position: absolute;
    visibility: hidden;
    background-color: #fff;
    box-shadow: 0px 13px 42px 11px rgba(0, 0, 0, 0.05);
    border: 1px solid #eeeeee;
    border-top: 2px solid #2a93c9;
    z-index: 399;
    top: 100%;
    left: 0;
    padding: 0;
  }
}

@media (min-width: 992px) {
  .menu-container > .menu-item:not(.mega-menu) .sub-menu-container:not([class*=col]) {
    width: 220px;
  }
}
.sub-menu-container .menu-item:not(:first-child) {
  border-top: 1px solid #eeeeee;
}
.sub-menu-container .menu-item > .menu-link {
  position: relative;
  padding: 11px 5px;
  font-size: 0.75rem;
  font-weight: 700;
  color: #666666;
  letter-spacing: 0;
  font-family: "Lato", sans-serif;
}
.sub-menu-container .menu-item > .menu-link div {
  position: relative;
}
.sub-menu-container .menu-item:hover > .menu-link {
  font-weight: 700;
}
.sub-menu-container .menu-item .sub-menu-trigger {
  top: 6px;
}
.sub-menu-container .sub-menu-trigger {
  font-size: 10px;
}

/* ----------------------------------------------------------------
	Mega Menu
-----------------------------------------------------------------*/
.mega-menu:not(.mega-menu-full) .mega-menu-content .container {
  max-width: none;
}
@media (min-width: 992px) {
  .mega-menu {
    position: inherit;
  }
}

.mega-menu-content {
  padding-left: 0;
}
@media (min-width: 992px) {
  .primary-menu:not(.on-click) .mega-menu-content {
    display: -ms-flexbox;
    display: flex;
  }
}

@media (min-width: 992px) {
  /* Sub-Menu Dropdown */
  .primary-menu:not(.on-click) .menu-item:not(.mega-menu) .sub-menu-container,
.primary-menu:not(.on-click) .mega-menu-content {
    display: block;
    opacity: 0;
    margin-top: 15px;
    z-index: -9999;
    pointer-events: none;
    transition: opacity 0.3s ease, margin 0.25s ease;
  }

  .primary-menu:not(.on-click) .menu-item.mega-menu .mega-menu-content .sub-menu-container:not(.mega-menu-dropdown) {
    margin-top: 0;
    z-index: auto;
  }

  .primary-menu:not(.on-click) .menu-item.mega-menu:hover > .mega-menu-content .sub-menu-container:not(.mega-menu-dropdown) {
    pointer-events: auto;
  }

  .primary-menu:not(.on-click) .menu-item:hover > .sub-menu-container,
.primary-menu:not(.on-click) .menu-item:hover > .mega-menu-content {
    opacity: 1;
    visibility: visible;
    margin-top: 0;
    z-index: 399;
    pointer-events: auto;
  }

  .primary-menu:not(.on-click) .menu-item:not(:hover) > .sub-menu-container > *,
.primary-menu:not(.on-click) .menu-item:not(:hover) > .mega-menu-content > * {
    display: none;
  }

  .primary-menu:not(.on-click) .menu-item.mega-menu .mega-menu-content .sub-menu-container:not(.mega-menu-dropdown) > * {
    display: block;
  }

  .with-arrows .menu-container > .menu-item > .menu-link div > i.icon-angle-down {
    display: inline-block;
  }
}
@media (max-width: 767.98px) {
  .primary-menu {
    max-width: 100%;
  }

  .primary-menu > .container {
    max-width: none;
    padding: 0;
  }

  .mega-menu-content .sub-menu-container {
    border-top: 1px solid #eeeeee;
  }
  .mega-menu-content .sub-menu-container:not(.mega-menu-dropdown) {
    display: block !important;
  }
  .mega-menu-content .mega-menu-column {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding-right: 0;
  }

  .mega-menu-column .widget {
    padding: 30px 0;
  }
}
.mega-menu-content .mega-menu-column:first-child {
  border-top: 0;
}

/* Off Canvas Menu
-----------------------------------------------------------------*/
@media (max-width: 767.98px) {
  body:not(.sticky-responsive-menu) .mobile-menu-off-canvas .menu-container {
    position: fixed;
    display: block;
    z-index: 499;
    width: 280px;
    height: 100%;
    height: calc(100vh);
    max-height: none !important;
    margin: 0 !important;
    left: 0 !important;
    top: 0 !important;
    padding: 35px 25px 60px !important;
    background-color: #fff;
    border-right: 1px solid #eeeeee !important;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
    -webkit-transform: translate(-280px, 0);
    -o-transform: translate(-280px, 0);
    transform: translate(-280px, 0);
    -webkit-backface-visibility: hidden;
    transform-style: preserve-3d;
    -webkit-transition: -webkit-transform 0.3s ease-in-out, opacity 0.2s ease !important;
    -o-transition: -o-transform 0.3s ease-in-out, opacity 0.2s ease !important;
    transition: transform 0.3s ease-in-out, opacity 0.2s ease !important;
  }
  body:not(.sticky-responsive-menu) .mobile-menu-off-canvas .menu-container.d-block {
    -webkit-transform: translate(0, 0);
    -o-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  body:not(.sticky-responsive-menu) .mobile-menu-off-canvas.from-right .menu-container {
    left: auto !important;
    right: 0;
    border-right: 0 !important;
    border-left: 1px solid #eeeeee !important;
    -webkit-transform: translate(280px, 0);
    -o-transform: translate(280px, 0);
    transform: translate(280px, 0);
  }
}
@media (min-width: 992px) {
  .menu-container:not(.mobile-primary-menu) {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
  }

  .full-header .primary-menu .menu-container {
    padding-right: 0.5rem;
    margin-right: 0.5rem;
    border-right: 1px solid #eeeeee;
  }

  .transparent-header.full-header .primary-menu .menu-container {
    border-right-color: rgba(0, 0, 0, 0.1);
  }

  .menu-container > .menu-item:not(:first-child) {
    margin-left: 2px;
  }

  .sub-menu-container .sub-menu-container {
    list-style: none;
    top: -2px !important;
    left: 100%;
  }

  .sub-menu-container.menu-pos-invert,
.mega-menu-content.menu-pos-invert {
    left: auto;
    right: 0;
  }

  .sub-menu-container .sub-menu-container.menu-pos-invert {
    right: 100%;
  }

  .sub-menu-container .menu-item:not(:first-child) {
    border-top: 0;
  }

  .sub-menu-container .menu-item > .menu-link {
    padding: 10px 15px;
    font-weight: 700;
    border: 0;
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  .sub-menu-container .menu-item > .menu-link i {
    vertical-align: middle;
  }

  .sub-menu-container .menu-item:hover > .menu-link {
    background-color: #f9f9f9;
    padding-left: 18px;
    color: #2a93c9;
  }

  .menu-link div > i.icon-angle-down {
    top: 0;
    font-size: 0.875rem;
    margin: 0 0 0 5px;
  }

  .sub-menu-container .menu-link div > i.icon-angle-down,
.side-header .primary-menu:not(.on-click) .menu-link i.icon-angle-down {
    display: block;
    position: absolute;
    top: 50%;
    right: 0;
    font-size: 0.875rem;
    line-height: 1;
    margin: 0;
    transform: translateY(-50%) rotate(-90deg);
  }

  .menu-item .sub-menu-trigger,
.side-header .on-click .sub-menu-container .menu-link i.icon-angle-down:last-child {
    display: none;
  }

  /* ----------------------------------------------------------------
  	Mega Menu
  -----------------------------------------------------------------*/
  .mega-menu:not(.mega-menu-full) .mega-menu-content .container {
    max-width: none;
    padding: 0 var(--bs-gutter-x, 0.75rem) !important;
    margin: 0 auto !important;
  }

  .mega-menu-content {
    margin: 0;
    width: 100%;
    max-width: none;
    left: 50%;
    transform: translateX(-50%);
  }

  .mega-menu-full .mega-menu-content {
    width: 100%;
    left: 0;
    transform: none;
  }

  .mega-menu-small {
    position: relative;
  }
  .mega-menu-small .mega-menu-content {
    left: 0;
    width: 400px;
    transform: none;
  }

  .floating-header:not(.sticky-header) .mega-menu .mega-menu-content {
    max-width: 100%;
  }

  .sub-menu-container .mega-menu {
    position: relative;
  }

  .sub-menu-container .mega-menu-small .mega-menu-content {
    top: 0;
    left: 100%;
  }

  .sub-menu-container .mega-menu-small .mega-menu-content.menu-pos-invert {
    left: auto;
    right: 100%;
  }

  .mega-menu-content.mega-menu-style-2 {
    padding: 0 10px;
  }

  .mega-menu-content .sub-menu-container:not(.mega-menu-dropdown) {
    position: relative;
    display: block !important;
    visibility: visible !important;
    top: 0 !important;
    left: 0;
    min-width: inherit;
    height: auto !important;
    list-style: none;
    border: 0;
    box-shadow: none;
    background-color: transparent;
    opacity: 1 !important;
    z-index: auto;
  }

  .mega-menu-content .sub-menu-container.mega-menu-dropdown {
    padding: 0.75rem;
    width: 220px;
  }

  .mega-menu-column {
    display: block !important;
    padding-left: 0;
    padding-right: 0;
  }

  .sub-menu-container.mega-menu-column:not(:first-child) {
    border-left: 1px solid #f2f2f2;
  }

  .mega-menu-style-2 .mega-menu-column {
    padding: 30px 20px;
  }

  .mega-menu-style-2 .mega-menu-title:not(:first-child) {
    margin-top: 20px;
  }

  .mega-menu-style-2 .mega-menu-title > .menu-link {
    font-size: 0.8125rem;
    font-weight: 700;
    font-family: "Poppins", sans-serif;
    letter-spacing: 1px;
    text-transform: uppercase !important;
    margin-bottom: 15px;
    color: #444;
    padding: 0 !important;
    line-height: 1.3 !important;
  }

  .mega-menu-style-2 .mega-menu-title:hover > .menu-link {
    background-color: transparent;
  }

  .mega-menu-style-2 .mega-menu-title > .menu-link:hover {
    color: #2a93c9;
  }

  .mega-menu-style-2 .sub-menu-container:not(.mega-menu-dropdown) .menu-item {
    border: 0;
  }

  .mega-menu-style-2 .sub-menu-container .menu-link {
    padding-left: 5px;
    padding-top: 7px;
    padding-bottom: 7px;
    will-change: transform;
  }

  .mega-menu-style-2 .sub-menu-container .menu-item:hover > .menu-link {
    padding-left: 12px;
  }

  /* Sub-Menu On-Click Dropdown */
  .on-click .sub-menu-container,
.on-click .mega-menu-content {
    display: none;
    visibility: visible;
  }

  .on-click .menu-item:not(.mega-menu-small) .mega-menu-content .sub-menu-container:not(.mega-menu-dropdown) {
    display: block;
  }

  /* Primary Menu - Style 2
  -----------------------------------------------------------------*/
  .style-2 #logo {
    margin-right: 1.5rem;
  }

  .style-2 .primary-menu {
    margin-right: auto;
  }

  .style-2.menu-center .primary-menu {
    margin-right: auto;
    margin-left: auto;
  }

  /* Primary Menu - Style 3
  -----------------------------------------------------------------*/
  .style-3 .menu-container > .menu-item > .menu-link {
    margin: 28px 0;
    padding-top: 11px;
    padding-bottom: 11px;
    border-radius: 2px;
  }

  .style-3 .menu-container > .menu-item:hover > .menu-link {
    color: #444;
    background-color: whitesmoke;
  }

  .style-3 .menu-container > .menu-item.current > .menu-link {
    color: #fff;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
    background-color: #2a93c9;
  }

  /* Primary Menu - Style 4
  -----------------------------------------------------------------*/
  .style-4 .menu-container > .menu-item > .menu-link {
    margin: 28px 0;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 2px;
    border: 1px solid transparent;
  }

  .style-4 .menu-container > .menu-item:hover > .menu-link,
.style-4 .menu-container > .menu-item.current > .menu-link {
    border-color: #2a93c9;
  }

  /* Primary Menu - Style 5
  -----------------------------------------------------------------*/
  .style-5 .menu-container {
    padding-right: 10px;
    margin-right: 5px;
    border-right: 1px solid #eeeeee;
  }

  .style-5 .menu-container > .menu-item:not(:first-child) {
    margin-left: 15px;
  }

  .style-5 .menu-container > .menu-item > .menu-link {
    padding-top: 27px;
    padding-bottom: 27px;
    line-height: 1;
  }

  .style-5 .menu-container > .menu-item > .menu-link i:not(.icon-angle-down) {
    display: block;
    width: auto;
    margin: 0 0 12px;
    font-size: 20px;
    line-height: 1;
    transition: all 0.3s ease;
  }

  /* Primary Menu - Style 6
  -----------------------------------------------------------------*/
  .style-6 .menu-container > .menu-item > .menu-link {
    position: relative;
  }

  .style-6 .menu-container > .menu-item > .menu-link::after,
.style-6 .menu-container > .menu-item.current > .menu-link::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 2px;
    width: 0;
    border-top: 2px solid #2a93c9;
    -webkit-transition: width 0.3s ease;
    -o-transition: width 0.3s ease;
    transition: width 0.3s ease;
  }

  .style-6 .menu-container > .menu-item.current > .menu-link::after,
.style-6 .menu-container > .menu-item:hover > .menu-link::after {
    width: 100%;
  }

  /* Primary Menu - Sub Title
  -----------------------------------------------------------------*/
  .sub-title .menu-container > .menu-item {
    margin-left: 0;
  }

  .sub-title .menu-container > .menu-item > .menu-link {
    position: relative;
    line-height: 14px;
    padding: 32px 20px;
    text-transform: uppercase;
  }

  .sub-title .menu-container > .menu-item:not(:first-child) > .menu-link::before {
    content: "";
    position: absolute;
    top: 50%;
    left: 0;
    z-index: -2;
    width: 1px;
    height: 36px;
    background-color: #EEE;
    transform: translateY(-50%);
  }

  .sub-title .menu-container > .menu-item > .menu-link span {
    display: block;
    margin-top: 10px;
    line-height: 12px;
    font-size: 11px;
    font-weight: 400;
    color: #888;
    text-transform: capitalize;
  }

  .sub-title .menu-container > .menu-item:hover > .menu-link,
.sub-title .menu-container > .menu-item.current > .menu-link {
    color: #FFF;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  }

  .sub-title .menu-container > .menu-item:hover > .menu-link span,
.sub-title .menu-container > .menu-item.current > .menu-link span {
    color: #EEE;
  }

  .sub-title .menu-container > .menu-item:hover > .menu-link::after,
.sub-title .menu-container > .menu-item.current > .menu-link::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-color: #2a93c9;
    border-top: 5px solid rgba(0, 0, 0, 0.1);
  }

  /* ----------------------------------------------------------------
  	Side Header
  -----------------------------------------------------------------*/
  .side-header:not(.open-header) #wrapper {
    margin: 0 0 0 260px !important;
    width: auto !important;
  }

  .side-header .container {
    padding-left: 30px;
    padding-right: 30px;
  }

  .side-header #header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 10;
    width: 260px;
    height: 100%;
    height: calc(100vh);
    background-color: #fff;
    border: none;
    border-right: 1px solid #eeeeee;
    overflow: hidden;
    box-shadow: 0 0 15px rgba(0, 0, 0, 0.065);
  }

  .side-header #header-wrap {
    position: relative;
    width: 300px;
    height: 100%;
    overflow-y: scroll;
    padding-right: 40px;
  }

  .side-header #header-wrap > .container {
    width: 260px;
    max-width: none;
    padding: 0 30px;
    margin: 0 !important;
    min-height: 100%;
    display: -ms-flexbox;
    display: flex;
  }

  .side-header .header-wrap-clone {
    display: none;
  }

  .side-header .header-row {
    -ms-flex-direction: column;
    flex-direction: column;
    -ms-flex-positive: 1 !important;
    flex-grow: 1 !important;
  }

  .side-header #logo {
    margin: 3rem 0;
    width: 100%;
  }

  .side-header #logo img {
    max-width: 100%;
  }

  .side-header .header-misc {
    width: 100%;
    margin: 0;
  }

  .side-header .primary-menu {
    margin-bottom: 30px;
    -ms-flex-positive: 0 !important;
    flex-grow: 0 !important;
    width: 100%;
  }

  .side-header .primary-menu:not(.border-bottom-0)::after {
    margin-top: 40px;
  }

  .side-header .menu-container {
    display: block;
    height: auto;
    width: 100%;
  }

  .no-triggers .menu-container .sub-menu-trigger {
    display: none;
  }

  .side-header .on-click .menu-item .sub-menu-trigger {
    display: block;
    top: 0;
    right: -5px;
    font-size: 10px;
  }

  .side-header .primary-menu:not(.on-click) .text-center i.icon-angle-down {
    display: inline-block;
    position: relative;
    top: 2px;
    margin-left: 5px;
    transform: none;
  }

  .side-header .primary-menu:not(.on-click) .text-center .sub-menu-container i.icon-angle-down {
    top: -1px;
  }

  .side-header .menu-item {
    width: 100%;
  }

  .side-header .menu-item:not(:first-child) {
    margin: 10px 0 0;
    border-top: 0;
  }

  .side-header .menu-link {
    position: relative;
    padding: 5px 0 !important;
    margin: 0 !important;
    text-transform: uppercase;
  }

  .side-header .sub-menu-container,
.side-header .mega-menu-content {
    position: relative;
    width: 100%;
    background-color: transparent;
    box-shadow: none;
    border: none;
    height: auto;
    z-index: 1;
    top: 0;
    left: 0;
    margin: 5px 0;
    padding: 0 0 0 12px !important;
  }

  .side-header .mega-menu-content {
    padding: 0 !important;
    margin-bottom: 0 !important;
  }

  .side-header .mega-menu-column {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    border: none !important;
  }

  .side-header .sub-menu-container .sub-menu-container {
    top: 0 !important;
    left: 0;
  }

  .side-header .sub-menu-container.menu-pos-invert {
    left: 0;
    right: auto;
  }

  .side-header .sub-menu-container .sub-menu-container.menu-pos-invert {
    right: auto;
  }

  .side-header .sub-menu-container .menu-item {
    margin: 0;
    border: 0;
  }

  .side-header .sub-menu-container .menu-item:first-child {
    border-top: 0;
  }

  .side-header .menu-item:hover > .menu-link {
    background-color: transparent !important;
  }

  .side-header .menu-container > .menu-item.sub-menu > .menu-link,
.side-header .menu-container > .menu-item.sub-menu:hover > .menu-link {
    background-image: none !important;
  }

  /* Side Header - Push Header
  -----------------------------------------------------------------*/
  .side-header.open-header.push-wrapper {
    overflow-x: hidden;
  }

  .side-header.open-header #header {
    left: -260px;
  }

  .side-header.open-header.side-header-open #header {
    left: 0;
  }

  .side-header.open-header #wrapper {
    left: 0;
  }

  .side-header.open-header.push-wrapper.side-header-open #wrapper {
    left: 260px;
  }

  #header-trigger {
    display: none;
  }

  .side-header.open-header #header-trigger {
    display: block;
    position: fixed;
    cursor: pointer;
    top: 20px;
    left: 20px;
    z-index: 11;
    font-size: 1rem;
    width: 32px;
    height: 32px;
    line-height: 32px;
    background-color: #FFF;
    text-align: center;
    border-radius: 2px;
  }

  .side-header.open-header.side-header-open #header-trigger {
    left: 280px;
  }

  .side-header.open-header #header-trigger i:nth-child(1),
.side-header.open-header.side-header-open #header-trigger i:nth-child(2) {
    display: block;
  }

  .side-header.open-header.side-header-open #header-trigger i:nth-child(1),
.side-header.open-header #header-trigger i:nth-child(2) {
    display: none;
  }

  .side-header.open-header #header,
.side-header.open-header #header-trigger,
.side-header.open-header.push-wrapper #wrapper {
    -webkit-transition: left 0.4s ease;
    -o-transition: left 0.4s ease;
    transition: left 0.4s ease;
  }

  /* Side Header - Right Aligned
  -----------------------------------------------------------------*/
  .side-header.side-header-right #header {
    left: auto;
    right: 0;
    border: none;
    border-left: 1px solid #eeeeee;
  }

  .side-header.side-header-right:not(.open-header) #wrapper {
    margin: 0 260px 0 0 !important;
  }

  .side-header.side-header-right.open-header #header {
    left: auto;
    right: -260px;
  }

  .side-header.side-header-right.open-header.side-header-open #header {
    left: auto;
    right: 0;
  }

  .side-header.side-header-right.open-header.push-wrapper.side-header-open #wrapper {
    left: -260px;
  }

  .side-header.side-header-right.open-header #header-trigger {
    left: auto;
    right: 20px;
  }

  .side-header.side-header-right.open-header.side-header-open #header-trigger {
    left: auto;
    right: 280px;
  }

  .side-header.side-header-right.open-header #header,
.side-header.side-header-right.open-header #header-trigger {
    -webkit-transition: right 0.4s ease;
    -o-transition: right 0.4s ease;
    transition: right 0.4s ease;
  }

  /* Primary Menu - Overlay Menu
  -----------------------------------------------------------------*/
  .overlay-menu:not(.top-search-open) #primary-menu-trigger {
    display: -ms-flexbox;
    display: flex;
    opacity: 1;
    pointer-events: auto;
    width: 30px;
    height: 30px;
    line-height: 30px;
  }

  .overlay-menu .primary-menu > #overlay-menu-close {
    position: fixed;
    top: 25px;
    left: auto;
    right: 25px;
    width: 48px;
    height: 48px;
    line-height: 48px;
    font-size: 1.5rem;
    text-align: center;
    color: #444;
  }

  .overlay-menu #primary-menu-trigger {
    z-index: 599;
  }

  .overlay-menu .primary-menu {
    position: fixed;
    top: 0;
    left: 0;
    padding: 0 !important;
    margin: 0 !important;
    border: 0 !important;
    width: 100%;
    height: 100%;
    height: calc(100vh);
    background-color: #fff;
    background: rgba(255, 255, 255, 0.95);
  }

  .overlay-menu .primary-menu,
.overlay-menu .primary-menu > #overlay-menu-close {
    opacity: 0 !important;
    pointer-events: none;
    z-index: -2;
    -webkit-transform: translateY(-80px);
    transform: translateY(-80px);
    -webkit-transition: opacity 0.4s ease, transform 0.45s ease !important;
    transition: opacity 0.4s ease, transform 0.45s ease !important;
  }

  .overlay-menu.primary-menu-open .primary-menu,
.overlay-menu.primary-menu-open #overlay-menu-close {
    opacity: 1 !important;
    pointer-events: auto;
    z-index: 499;
    -webkit-transform: translateY(0);
    transform: translateY(0);
    -webkit-transition: opacity 0.3s ease, transform 0.45s ease !important;
    transition: opacity 0.3s ease, transform 0.45s ease !important;
  }

  .overlay-menu .primary-menu,
.overlay-menu.primary-menu-open .primary-menu {
    -webkit-transform: translateY(0px);
    transform: translateY(0px);
    -webkit-transition: opacity 0.3s ease !important;
    transition: opacity 0.3s ease !important;
  }

  .overlay-menu .primary-menu > .container {
    max-height: 90%;
    overflow-y: scroll;
  }

  .overlay-menu .menu-container {
    display: -ms-flexbox !important;
    display: flex !important;
    flex-direction: column;
    max-width: 250px;
    padding: 0 !important;
    margin: 0 auto !important;
    border: 0 !important;
  }

  .overlay-menu .menu-container > .menu-item > .menu-link {
    font-size: 0.9375rem;
  }

  .overlay-menu .menu-item {
    width: 100%;
  }

  .overlay-menu .menu-link {
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 0;
    padding-right: 0;
  }

  .overlay-menu .sub-menu-container,
.overlay-menu .mega-menu-content {
    display: none;
    position: relative;
    top: 0 !important;
    left: 0 !important;
    right: auto;
    width: 100%;
    max-width: 100%;
    box-shadow: none;
    background: transparent;
    border: 0;
    padding: 10px 0 !important;
    transform: translateX(0);
  }

  .overlay-menu .mega-menu-content .sub-menu-container {
    padding: 5px 0 !important;
  }

  .overlay-menu .mega-menu-title .sub-menu-container {
    padding-top: 0 !important;
  }

  .overlay-menu .mega-menu-content .mega-menu-column {
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
    padding-left: 0;
    padding-right: 0;
    border: 0 !important;
  }

  .overlay-menu .sub-menu-container .menu-link {
    padding: 5px 0;
  }

  .overlay-menu .sub-menu-container .menu-item:hover > .menu-link {
    padding-left: 0;
    padding-right: 0;
    background: transparent;
  }

  .overlay-menu .sub-menu-container .menu-link i.icon-angle-down {
    position: relative;
    display: inline-block;
    top: -1px;
    left: 0;
    margin-left: 5px;
    transform: rotate(0deg);
  }
}
@media (max-width: 767.98px) {
  .side-push-panel #side-panel-trigger-close a {
    display: block;
    position: absolute;
    z-index: 12;
    top: 0;
    left: auto;
    right: 0;
    width: 40px;
    height: 40px;
    font-size: 18px;
    line-height: 40px;
    color: #444;
    text-align: center;
    background-color: rgba(0, 0, 0, 0.1);
    border-radius: 0 0 0 2px;
  }
}
/* ----------------------------------------------------------------
	Top Search
-----------------------------------------------------------------*/
.header-misc {
  position: initial;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  margin-left: 15px;
}
@media (min-width: 992px) {
  .header-misc {
    -ms-flex-order: 6;
    order: 6;
  }
}
.header-misc-element, .header-misc-icon {
  margin: 0 8px;
}
.header-misc-icon > a {
  display: block;
  position: relative;
  width: 1.25rem;
  height: 1.25rem;
  font-size: 1.125rem;
  line-height: 1.25rem;
  text-align: center;
  color: #333333;
  -webkit-transition: color 0.3s ease-in-out;
  -o-transition: color 0.3s ease-in-out;
  transition: color 0.3s ease-in-out;
}

#top-search {
  -webkit-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}
#top-search a {
  z-index: 11;
}
#top-search a i {
  position: absolute;
  top: 0;
  left: 3px;
  -webkit-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}

.top-search-open #top-search a i.icon-line-search,
#top-search a i.icon-line-cross {
  opacity: 0;
}

.top-search-open #top-search a i.icon-line-cross {
  opacity: 1;
  z-index: 11;
  font-size: 1rem;
}

.top-search-form {
  opacity: 0;
  z-index: -2;
  pointer-events: none;
  position: absolute;
  width: 100% !important;
  height: 100%;
  padding: 0;
  margin: 0;
  top: 0;
  left: 0;
  -webkit-transition: opacity 0.3s ease-in-out;
  -o-transition: opacity 0.3s ease-in-out;
  transition: opacity 0.3s ease-in-out;
}
.top-search-open .top-search-form {
  opacity: 1;
  z-index: 10;
  pointer-events: auto;
}
.top-search-form input {
  box-shadow: none !important;
  pointer-events: none;
  border-radius: 0;
  border: 0;
  outline: 0 !important;
  font-size: 24px;
  padding: 10px 80px 10px 0;
  height: 100%;
  background-color: transparent !important;
  color: #333333;
  font-weight: 700;
  margin-top: 0 !important;
  font-family: "Poppins", sans-serif;
  letter-spacing: 2px;
}

@media (min-width: 992px) {
  .top-search-form input {
    font-size: 32px;
  }

  .full-header .top-search-form input,
.container-fluid .top-search-form input,
.container-sm .top-search-form input,
.container-md .top-search-form input,
.container-lg .top-search-form input,
.container-xl .top-search-form input,
.container-xxl .top-search-form input {
    padding-left: 30px;
  }

  .transparent-header:not(.sticky-header):not(.full-header):not(.floating-header) .top-search-form input {
    border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  }
}
.search-overlay .top-search-form {
  position: fixed;
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  display: -ms-flexbox;
  display: flex;
  background-color: #fff;
  align-items: center;
  justify-content: center;
}
.search-overlay .top-search-form input {
  max-width: 580px;
  margin: 0 auto;
  text-align: center;
  padding: 0 40px;
}

.top-search-form input::-moz-placeholder {
  color: #555;
  opacity: 1;
  text-transform: uppercase;
}

.top-search-form input:-ms-input-placeholder {
  color: #555;
  opacity: 1;
  text-transform: uppercase;
}

.top-search-form input::-webkit-input-placeholder {
  color: #555;
  opacity: 1;
  text-transform: uppercase;
}

.primary-menu .container .top-search-form input,
.sticky-header .top-search-form input {
  border: none !important;
}

/* Top Search Animation */
.top-search-open .top-search-parent #logo,
.top-search-open .top-search-parent .primary-menu,
.top-search-open .top-search-parent .header-misc > *:not(#top-search) {
  opacity: 0;
}

/* ----------------------------------------------------------------
	Top Cart
-----------------------------------------------------------------*/
.top-cart-number {
  display: block;
  position: absolute;
  top: -7px;
  left: auto;
  right: -8px;
  font-size: 0.625rem;
  color: #FFF;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  width: 1.125rem;
  height: 1.125rem;
  line-height: 1.125rem;
  text-align: center;
  background-color: #2a93c9;
  opacity: 0.8;
  border-radius: 50%;
}
@media (min-width: 992px) {
  #top-cart {
    position: relative;
  }
}
.top-cart-content {
  position: fixed;
  top: 0;
  left: auto;
  width: 260px;
  background-color: #fff;
  border-left: 1px solid #eeeeee;
  box-shadow: 0px 20px 50px 10px rgba(0, 0, 0, 0.05);
  -webkit-transition: right 0.3s ease;
  -o-transition: right 0.3s ease;
  transition: right 0.3s ease;
}
@media (max-width: 991.98px) {
  .top-cart-content {
    right: -260px !important;
    height: 100vh;
    z-index: 499;
  }
}
@media (min-width: 992px) {
  .top-cart-content {
    opacity: 0;
    position: absolute;
    width: 280px;
    border: 1px solid #eeeeee;
    border-top: 2px solid #2a93c9;
    top: calc( 100% + 20px );
    right: 0;
    margin: -10000px 0 0;
    z-index: auto;
    -webkit-transition: opacity 0.5s ease, top 0.4s ease;
    -o-transition: opacity 0.5s ease, top 0.4s ease;
    transition: opacity 0.5s ease, top 0.4s ease;
  }
}
.top-cart-title {
  padding: 0.75rem 1rem;
  border-bottom: 1px solid #eeeeee;
}
.top-cart-title h4, .top-cart-title .h4 {
  margin-bottom: 0;
  font-size: 0.875rem;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.top-cart-items {
  padding: 1rem;
}
@media (min-width: 992px) {
  .top-cart-items {
    padding: 1rem;
  }
}
.top-cart-item, .top-cart-action, .top-cart-item-desc {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -ms-flex-align: start;
  align-items: flex-start;
}
.top-cart-item {
  -ms-flex-pack: start;
  justify-content: flex-start;
}
.top-cart-item:not(:first-child) {
  padding-top: 1rem;
  margin-top: 1rem;
  border-top: 1px solid #eeeeee;
}
.top-cart-item-image {
  width: 48px;
  height: 48px;
  border: 2px solid #eeeeee;
  -webkit-transition: border-color 0.2s linear;
  -o-transition: border-color 0.2s linear;
  transition: border-color 0.2s linear;
}
.top-cart-item-image a,
.top-cart-item-image img {
  display: block;
  width: 44px;
  height: 44px;
}
.top-cart-item-image:hover {
  border-color: #2a93c9;
}
.top-cart-item-desc, .top-cart-item-desc-title {
  padding-left: 1rem;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
}
.top-cart-item-desc {
  -ms-flex-align: start;
  align-items: flex-start;
}
.top-cart-item-desc-title {
  padding-left: 0;
}
.top-cart-item-desc a {
  display: block;
  font-size: 0.8125rem;
  line-height: 1.25rem;
  color: #333;
  font-weight: 700;
  margin-bottom: 5px;
}
.top-cart-item-desc a:hover {
  color: #2a93c9;
}
.top-cart-item-price {
  font-size: 0.75rem;
  color: black;
}
.top-cart-item-quantity {
  padding-top: 5px;
  font-size: 0.75rem;
  line-height: 1.25rem;
  color: #444;
}
.top-cart-action {
  -ms-flex-align: center;
  align-items: center;
  padding: 1rem;
  border-top: 1px solid #eeeeee;
}
.top-cart-action .top-checkout-price {
  font-size: 1.25rem;
  color: #2a93c9;
}
@media (max-width: 991.98px) {
  .top-cart-open .top-cart-content {
    right: 0 !important;
  }
}
@media (min-width: 992px) {
  .top-cart-open .top-cart-content {
    opacity: 1;
    z-index: 399;
    margin-top: 0;
  }
}

/* ----------------------------------------------------------------
	Page Menu
-----------------------------------------------------------------*/
#page-menu {
  position: relative;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
}
#page-menu-wrap {
  position: absolute;
  z-index: 199;
  top: 0;
  left: 0;
  width: 100%;
  background-color: #2a93c9;
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
}
#page-menu.sticky-page-menu #page-menu-wrap {
  position: fixed;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3);
}

#page-menu-trigger {
  opacity: 1;
  pointer-events: auto;
  top: 0;
  margin-top: 0;
  left: auto;
  right: 15px;
  width: 40px;
  height: 44px;
  line-height: 44px;
  color: #FFF;
}

.page-menu-open #page-menu-trigger {
  background-color: rgba(0, 0, 0, 0.2);
}

.page-menu-row {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
}

.page-menu-title {
  color: #FFF;
  font-size: 1.25rem;
  font-weight: 300;
  margin-right: auto;
}
.page-menu-title span {
  font-weight: 700;
}
.page-menu-nav {
  display: none;
  position: absolute;
  width: 200px;
  top: 100%;
  left: auto;
  right: 0;
  height: auto;
  background-color: #2a93c9;
}
@media (min-width: 992px) {
  .page-menu-nav {
    display: -ms-flex;
    display: flex;
    position: relative;
    width: auto;
    top: 0;
    left: 0;
    background-color: transparent !important;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    -ms-flex-positive: 0;
    flex-grow: 0;
  }
}
#page-menu.page-menu-open .page-menu-nav {
  display: block;
}
.page-menu-nav .page-menu-container {
  margin: 0;
  list-style: none;
  background-color: rgba(0, 0, 0, 0.2);
}
@media (min-width: 992px) {
  .page-menu-nav .page-menu-container {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center;
    list-style: none;
    background-color: transparent;
  }
}
.page-menu-item {
  position: relative;
}
.page-menu-item > a {
  display: block;
  position: relative;
  line-height: 22px;
  padding: 11px 14px;
  color: #FFF;
  font-size: 0.875rem;
}
@media (min-width: 992px) {
  .page-menu-item > a {
    margin: 10px 4px;
    padding-top: 3px;
    padding-bottom: 3px;
    border-radius: 14px;
  }
  body:not(.device-touch) .page-menu-item > a {
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }
}
.page-menu-item > a .icon-angle-down {
  position: absolute;
  top: 50%;
  left: auto;
  right: 14px;
  transform: translateY(-50%);
}
@media (min-width: 992px) {
  .page-menu-item > a .icon-angle-down {
    position: relative;
    top: 1px;
    left: 0;
    right: 0;
    margin-left: 8px;
    transform: none;
  }
}
.page-menu-item:hover > a, .page-menu-item.current > a {
  background-color: rgba(0, 0, 0, 0.15);
}
.page-menu-sub-menu {
  display: none;
  background-color: rgba(0, 0, 0, 0.15);
  list-style: none;
  padding: 1px 10px 5px;
}
@media (min-width: 992px) {
  .page-menu-sub-menu {
    display: none;
    position: absolute;
    list-style: none;
    width: 150px;
    top: 100%;
    left: 0;
    padding: 0;
    background-color: #2a93c9;
  }
}
.page-menu-item:hover > .page-menu-sub-menu {
  display: block;
}
.page-menu-sub-menu .page-menu-item > a {
  margin: 5px 0;
  padding-top: 3px;
  padding-bottom: 3px;
  border-radius: 14px;
}
@media (min-width: 992px) {
  .page-menu-sub-menu .page-menu-item > a {
    height: auto;
    margin: 0;
    padding-top: 10px;
    padding-bottom: 10px;
    border-radius: 0;
    background-color: rgba(0, 0, 0, 0.15);
  }
}
@media (min-width: 992px) {
  .page-menu-sub-menu li:hover a,
.page-menu-sub-menu li.current a {
    background-color: rgba(0, 0, 0, 0.3);
  }
}

/* Page Menu - Dots Style
-----------------------------------------------------------------*/
@media (min-width: 992px) {
  #page-menu.dots-menu {
    position: fixed;
    top: 50%;
    left: auto;
    right: 20px;
    width: 24px;
    line-height: 1.5;
    text-shadow: none;
    transform: translateY(-50%);
    z-index: 299;
  }

  .dots-menu #page-menu-wrap {
    position: relative;
    top: 0 !important;
    box-shadow: none;
    background-color: transparent !important;
  }
  .dots-menu .page-menu-wrap-clone {
    display: none;
  }
  .dots-menu .container {
    padding: 0;
  }
  .dots-menu .page-menu-title {
    display: none;
  }
  .dots-menu .page-menu-nav {
    display: block;
    width: 100%;
  }
  .dots-menu .page-menu-container {
    flex-direction: column;
    -ms-flex-direction: column;
  }
  .dots-menu .page-menu-item {
    width: 10px;
    height: 10px;
    margin: 10px 0;
  }
  .dots-menu .page-menu-item > a {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 0;
    margin: 0;
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 50%;
    color: #777;
  }
  .dots-menu .page-menu-item > a:hover {
    background-color: rgba(0, 0, 0, 0.7);
  }
  .dots-menu .page-menu-item.current > a {
    background-color: #2a93c9;
  }
  .dots-menu .page-menu-item div {
    position: absolute;
    width: auto;
    top: 50%;
    right: 25px;
    background-color: #2a93c9;
    font-size: 0.75rem;
    text-transform: uppercase;
    letter-spacing: 1px;
    color: #FFF;
    padding: 6px 14px;
    white-space: nowrap;
    pointer-events: none;
    text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
    border-radius: 2px;
    opacity: 0;
    transform: translateY(-50%);
  }
  body:not(.device-touch) .dots-menu .page-menu-item div {
    -webkit-transition: all 0.2s ease-in-out;
    -o-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }
  .dots-menu .page-menu-item div::after {
    position: absolute;
    content: "";
    top: 50%;
    left: auto;
    right: -5px;
    width: 0;
    height: 0;
    border-top: 7px solid transparent;
    border-bottom: 7px solid transparent;
    border-left: 6px solid #2a93c9;
    transform: translateY(-50%);
  }
  .dots-menu .page-menu-item > a:hover div {
    opacity: 1;
    right: 30px;
  }
  .dots-menu .dots-menu-border .page-menu-item {
    width: 12px;
    height: 12px;
  }
  .dots-menu .dots-menu-border .page-menu-item > a {
    background-color: transparent !important;
    border: 3px solid rgba(0, 0, 0, 0.4);
  }
  .dots-menu .dots-menu-border .page-menu-item > a:hover {
    border-color: rgba(0, 0, 0, 0.7);
  }
  .dots-menu .dots-menu-border .page-menu-item.current > a {
    border-color: #2a93c9;
  }
}
/* ----------------------------------------------------------------
	One Page
-----------------------------------------------------------------*/
.one-page-arrow {
  position: absolute;
  z-index: 3;
  bottom: 20px;
  left: 50%;
  margin-left: -16px;
  width: 32px;
  height: 32px;
  font-size: 32px;
  text-align: center;
  color: #222;
}
/* ----------------------------------------------------------------
	Side Push Panel
-----------------------------------------------------------------*/
.side-push-panel {
  overflow-x: hidden;
}

#side-panel {
  position: fixed;
  top: 0;
  right: -300px;
  z-index: 699;
  width: 300px;
  height: 100%;
  background-color: whitesmoke;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
}
.side-panel-left #side-panel {
  left: -300px;
  right: auto;
}

.side-push-panel.stretched #wrapper, .side-push-panel.stretched .sticky-header .container {
  right: 0;
}

.side-panel-left.side-push-panel.stretched #wrapper,
.side-panel-left.side-push-panel.stretched .sticky-header .container {
  left: 0;
  right: auto;
}

#side-panel .side-panel-wrap {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 330px;
  height: 100%;
  overflow: auto;
  padding: 50px 70px 50px 40px;
}
.device-touch #side-panel .side-panel-wrap {
  overflow-y: scroll;
}
#side-panel .widget {
  margin-top: 40px;
  padding-top: 40px;
  width: 220px;
  border-top: 1px solid #E5E5E5;
}
#side-panel .widget:first-child {
  padding-top: 0;
  border-top: 0;
  margin: 0;
}
.side-panel-open #side-panel {
  right: 0;
}
.side-panel-left.side-panel-open #side-panel {
  left: 0;
  right: auto;
}

.side-push-panel.side-panel-open.stretched #wrapper,
.side-push-panel.side-panel-open.stretched .sticky-header .container {
  right: 300px;
}

.side-push-panel.side-panel-open.stretched.device-xl .slider-inner,
.side-push-panel.side-panel-open.stretched.device-lg .slider-inner {
  left: -300px;
}

.side-panel-left.side-push-panel.side-panel-open.stretched #wrapper,
.side-panel-left.side-push-panel.side-panel-open.stretched .sticky-header .container,
.side-panel-left.side-push-panel.side-panel-open.stretched .slider-inner {
  left: 300px;
  right: auto;
}

#side-panel-trigger {
  display: block;
  cursor: pointer;
  z-index: 11;
  margin-right: 5px;
}
#side-panel-trigger-close a {
  display: none;
}

#side-panel,
.side-push-panel.stretched #wrapper,
.side-push-panel.stretched #header .container {
  -webkit-transition: right 0.4s ease;
  -o-transition: right 0.4s ease;
  transition: right 0.4s ease;
}

.side-panel-left #side-panel,
.side-panel-left.side-push-panel.stretched #wrapper,
.side-panel-left.side-push-panel.stretched #header .container,
.side-push-panel.stretched .slider-inner {
  -webkit-transition: left 0.4s ease;
  -o-transition: left 0.4s ease;
  transition: left 0.4s ease;
}

.body-overlay {
  opacity: 0;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: -1;
  pointer-events: none;
  -webkit-backface-visibility: hidden;
  -webkit-transition: opacity 0.3s ease;
  -o-transition: opacity 0.3s ease;
  transition: opacity 0.3s ease;
}
.side-panel-open .body-overlay {
  opacity: 1;
  z-index: 599;
  pointer-events: auto;
}

.side-panel-open:not(.device-xs):not(.device-sm):not(.device-md) .body-overlay:hover {
  cursor: url("images/icons/close.png") 15 15, default;
}

/* ----------------------------------------------------------------

	slider.scss

-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Flex Slider
-----------------------------------------------------------------*/
.fslider,
.fslider .flexslider,
.fslider .slider-wrap,
.fslider .slide,
.fslider .slide > a,
.fslider .slide > img,
.fslider .slide > a > img {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
  overflow: hidden;
  -webkit-backface-visibility: hidden;
}

.fslider.h-100 .flexslider,
.fslider.h-100 .slider-wrap,
.fslider.h-100 .slide {
  height: 100% !important;
}

.fslider {
  min-height: 32px;
}

.flex-container a:active,
.flexslider a:active,
.flex-container a:focus,
.flexslider a:focus {
  outline: none;
  border: none;
}

.slider-wrap,
.flex-control-nav,
.flex-direction-nav {
  margin: 0;
  padding: 0;
  list-style: none;
  border: none;
}

.flexslider {
  position: relative;
  margin: 0;
  padding: 0;
}
.flexslider .slider-wrap > .slide {
  display: none;
  -webkit-backface-visibility: hidden;
}

.flexslider .slider-wrap img {
  width: 100%;
  display: block;
}

.flex-pauseplay span {
  text-transform: capitalize;
}

.slider-wrap::after {
  content: " ";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}

html[xmlns] .slider-wrap {
  display: block;
}

* html .slider-wrap {
  height: 1%;
}

.no-js .slider-wrap > .slide:first-child {
  display: block;
}

.flex-viewport {
  max-height: 2000px;
  -webkit-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

/* ----------------------------------------------------------------
	Slider
-----------------------------------------------------------------*/
.slider-element {
  position: relative;
  width: 100%;
  overflow: hidden;
}

.slider-parallax {
  position: relative;
}

.slider-parallax,
.slider-parallax .swiper-container {
  visibility: visible;
  -webkit-backface-visibility: hidden;
}

.slider-inner {
  position: absolute;
  overflow: hidden;
  opacity: 1;
  visibility: visible;
  width: 100%;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}
.slider-parallax .slider-inner {
  position: fixed;
}

.h-auto:not(.slider-parallax) .slider-inner,
.h-auto .swiper-container,
.min-vh-0:not(.slider-parallax) .slider-inner,
.min-vh-0 .swiper-container {
  position: relative !important;
  height: auto !important;
}

.slider-parallax-invisible .slider-inner {
  opacity: 0;
  visibility: hidden;
}

@media (min-width: 992px) {
  .h-auto .swiper-wrapper,
.min-vh-0 .swiper-wrapper {
    height: 75vh;
  }
}
.slider-inner.slider-inner-relative {
  position: relative !important;
  height: auto !important;
}

.device-touch .slider-parallax .slider-inner {
  position: relative;
}

body:not(.side-header) .slider-parallax .slider-inner {
  left: 0;
}

body:not(.stretched) .slider-parallax .slider-inner {
  left: auto;
}

.slider-parallax .slider-inner,
.slider-parallax .slider-caption {
  will-change: transform;
  -webkit-transform: translate3d(0, 0, 0);
  -webkit-transition: transform 0s linear;
  -webkit-backface-visibility: hidden;
}

@media (max-width: 767.98px) {
  #slider {
    display: -ms-flexbox !important;
    display: flex !important;
    flex-direction: column;
    justify-content: center;
  }
  #slider .slider-inner {
    position: relative;
    display: -ms-flexbox;
    display: flex;
    flex-grow: 1;
    align-items: center;
  }
}
/* Boxed Slider
-----------------------------------------------------------------*/
.boxed-slider {
  padding-top: 40px;
}

/* Swiper Slider
-----------------------------------------------------------------*/
.swiper_wrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.full-screen .swiper_wrapper {
  height: auto;
}
.swiper_wrapper .swiper-container {
  position: absolute;
  width: 100%;
  height: 100%;
  margin: 0;
}
.swiper_wrapper .swiper-slide {
  position: relative;
  margin: 0;
  overflow: hidden;
}
.swiper_wrapper .swiper-slide [data-caption-animate] {
  -webkit-backface-visibility: hidden;
}

.swiper-slide-bg {
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
}

.slider-element .video-wrap,
.section .video-wrap,
.swiper-slide .yt-bg-player,
.section .yt-bg-player,
.swiper-slide-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: -1;
  top: 0;
  left: 0;
}

.yt-bg-player .inlinePlayButton,
.yt-bg-player .loading {
  display: none !important;
}

.slider-element .container,
.slider-element .container-fuild {
  position: relative;
  height: 100%;
}

.slider-element.canvas-slider .swiper-slide img {
  width: 100%;
}

.slider-element.canvas-slider a {
  display: block;
  height: 100%;
}

.swiper-pagination {
  position: absolute;
  width: 100%;
  z-index: 20;
  margin: 0;
  top: auto;
  bottom: 20px !important;
  text-align: center;
  line-height: 1;
}
.swiper-pagination span {
  display: inline-block;
  cursor: pointer;
  width: 10px;
  height: 10px;
  margin: 0 4px;
  opacity: 1;
  background-color: transparent;
  border: 1px solid #fff;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.swiper-pagination span:hover, .swiper-pagination span.swiper-pagination-bullet-active {
  background-color: #fff !important;
}

/* Video Overlay
-----------------------------------------------------------------*/
.video-wrap {
  overflow: hidden;
  width: 100%;
  position: relative;
}
.video-wrap video {
  position: relative;
  z-index: 1;
  opacity: 1;
  width: 100%;
  transition: opacity 0.3s 1s ease;
  /*min-width: 100%;
  min-height: 100%;
  top: 50%;
  transform: translateY(-50%);*/
}
body:not(.has-plugin-html5video) .video-wrap video {
  opacity: 0;
}

.video-overlay,
.video-placeholder {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 5;
  background: transparent url("../images/grid.png") repeat;
  transform: translateZ(0);
  -webkit-backface-visibility: hidden;
}

.video-placeholder {
  z-index: 4;
  background-repeat: no-repeat !important;
}

/* Slider Caption
-----------------------------------------------------------------*/
.slider-caption,
.flex-caption {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  height: 100%;
  flex-flow: column;
  justify-content: center;
  z-index: 20;
  max-width: 550px;
  color: #EEE;
  font-size: 300;
  font-weight: 300;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
  transition: top 0.3s ease;
}

.slider-caption:not(.dark), .slider-caption:not(.dark) h2, .slider-caption:not(.dark) .h2 {
  color: #222222;
  text-shadow: none;
}
.slider-caption h2, .slider-caption .h2 {
  font-size: 4rem;
  letter-spacing: 2px;
  text-transform: uppercase;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 0;
}
@media (max-width: 767.98px) {
  .slider-caption h2, .slider-caption .h2 {
    font-size: 5.5vw;
  }
}
.slider-caption p {
  margin-top: 30px;
  margin-bottom: 10px;
  font-size: 1.5rem;
}
@media (max-width: 767.98px) {
  .slider-caption p {
    font-size: 2.6vw;
  }
}

.slider-caption.slider-caption-right {
  margin-left: auto;
}

.slider-caption.slider-caption-center {
  position: relative;
  max-width: 800px;
  text-align: center;
  margin: 0 auto;
  left: 0;
  right: 0;
}

.slider-caption-bg {
  padding: 5px 12px;
  border-radius: 2px;
  background-color: rgba(0, 0, 0, 0.6);
  top: auto;
  bottom: 50px;
  left: 50px;
}

.slider-caption-bg.slider-caption-bg-light {
  background-color: rgba(255, 255, 255, 0.8);
  color: #444;
  font-weight: 400;
  text-shadow: none;
}

.slider-caption-top-left {
  top: 50px;
  bottom: auto;
}

.slider-caption-top-right {
  top: 50px;
  bottom: auto;
  left: auto;
  right: 50px;
}

.slider-caption-bottom-right {
  left: auto;
  right: 50px;
}

/* Slider Arrows
-----------------------------------------------------------------*/
.slider-element .owl-carousel {
  margin: 0;
}

.slider-arrow-left,
.slider-arrow-right,
.flex-prev,
.flex-next,
.slider-arrow-top-sm,
.slider-arrow-bottom-sm {
  position: absolute;
  cursor: pointer;
  z-index: 10;
  top: 50%;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  width: 52px;
  height: 52px;
  border: 0;
  border-radius: 0 3px 3px 0;
  transform: translateY(-50%);
  -webkit-transition: background-color 0.3s ease-in-out;
  -o-transition: background-color 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out;
}

.slider-arrow-top-sm,
.slider-arrow-bottom-sm {
  top: auto;
  bottom: 57px;
  left: auto;
  right: 20px;
  margin: 0;
  width: 32px;
  height: 32px;
  border-radius: 3px 3px 0 0;
}

.slider-arrow-bottom-sm {
  bottom: 20px;
  border-radius: 0 0 3px 3px;
}

.fslider[data-thumbs=true] .flex-prev,
.fslider[data-thumbs=true] .flex-next {
  margin-top: -20px;
}

.slider-arrow-right,
.slider-element .owl-next,
.flex-next {
  left: auto;
  right: 0;
  border-radius: 3px 0 0 3px;
}

.slider-arrow-left i,
.slider-arrow-right i,
.flex-prev i,
.flex-next i,
.slider-arrow-top-sm i,
.slider-arrow-bottom-sm i {
  line-height: 50px;
  width: 100%;
  height: 100%;
  color: #DDD;
  color: rgba(255, 255, 255, 0.8);
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
  font-size: 34px;
  text-align: center;
  transition: color 0.3s ease-in-out;
}

.slider-arrow-top-sm i,
.slider-arrow-bottom-sm i {
  width: 32px;
  height: 32px;
  margin: 0;
  line-height: 30px;
  font-size: 18px;
  color: #fff;
}

.slider-element .owl-prev i {
  margin-left: 0;
}

.slider-element .owl-next i {
  margin-right: 4px;
}

.slider-arrow-left:hover,
.slider-arrow-right:hover,
.flex-prev:hover,
.flex-next:hover,
.slider-arrow-top-sm:hover,
.slider-arrow-bottom-sm:hover {
  background-color: rgba(0, 0, 0, 0.6) !important;
}

/* Slide Number
-----------------------------------------------------------------*/
.slide-number {
  position: absolute;
  opacity: 0;
  top: auto;
  right: 20px;
  bottom: 20px;
  z-index: 20;
  width: 36px;
  height: 40px;
  text-align: center;
  color: rgba(255, 255, 255, 0.8);
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
  font-size: 18px;
  font-weight: 300;
  -webkit-transition: all 0.3s linear;
  -o-transition: all 0.3s linear;
  transition: all 0.3s linear;
}

.slider-element:hover .slide-number {
  opacity: 1;
}

.slide-number-current,
.slide-number-total {
  position: absolute;
  line-height: 1;
  top: 5px;
  left: 0;
}

.slide-number-total {
  top: auto;
  bottom: 5px;
  left: auto;
  right: 0;
}

.slide-number span {
  display: block;
  font-size: 32px;
  line-height: 40px;
}

/* Pagination
-----------------------------------------------------------------*/
.slider-element .owl-dots {
  position: absolute;
  width: 100%;
  z-index: 20;
  margin: 0;
  top: auto;
  bottom: 15px;
}

.slider-element .owl-dots button {
  width: 0.625rem;
  height: 0.625rem;
  margin: 0 3px;
  opacity: 1 !important;
  background-color: transparent !important;
  border: 1px solid rgba(0, 0, 0, 0.2);
}

.slider-element .owl-dots .owl-dot.active,
.slider-element .owl-dots .owl-dot:hover {
  background-color: #fff !important;
}

/* Flex Slider - Navigation
-----------------------------------------------------------------*/
.nav-offset .flex-prev,
.nav-offset .flex-next {
  border-radius: 3px;
}

.nav-offset .flex-prev {
  left: 20px;
}

.nav-offset .flex-next {
  right: 20px;
}

.nav-circle .flex-prev,
.nav-circle .flex-next {
  border-radius: 50%;
}

.nav-solid .flex-prev,
.nav-solid .flex-next {
  opacity: 1;
  background-color: #fff;
}

.nav-solid .flex-prev:hover,
.nav-solid .flex-next:hover {
  background-color: #333 !important;
}

.nav-solid .flex-prev:not(:hover) i,
.nav-solid .flex-next:not(:hover) i {
  color: #444;
  text-shadow: none;
}

.nav-size-sm .flex-prev,
.nav-size-sm .flex-next {
  width: 32px;
  height: 32px;
}

.nav-size-sm .flex-prev i,
.nav-size-sm .flex-next i {
  line-height: 32px;
  font-size: 1.5rem;
}

.fslider[class*=nav-pos-] .flex-prev,
.fslider[class*=nav-pos-] .flex-next {
  position: relative;
  display: block;
  top: 0;
  left: 0;
  right: 0;
  margin: 0 5px;
  transform: none;
}

.fslider.nav-size-sm[class*=nav-pos-] .flex-prev,
.fslider.nav-size-sm[class*=nav-pos-] .flex-next {
  margin: 0 3px;
}

.fslider[class*=nav-pos-] .flex-direction-nav {
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  width: 100%;
}

.nav-pos-top .flex-direction-nav,
.nav-pos-top-left .flex-direction-nav,
.nav-pos-top-right .flex-direction-nav {
  top: 20px;
  left: 0;
}

.nav-pos-bottom .flex-direction-nav,
.nav-pos-bottom-left .flex-direction-nav,
.nav-pos-bottom-right .flex-direction-nav {
  top: auto;
  bottom: 20px;
}

.nav-pos-top-left .flex-direction-nav,
.nav-pos-bottom-left .flex-direction-nav {
  width: auto !important;
  left: 16px;
}

.nav-pos-top-right .flex-direction-nav,
.nav-pos-bottom-right .flex-direction-nav {
  width: auto !important;
  left: auto;
  right: 16px;
}

/* Flex Slider - Pagination & Thumbs
-----------------------------------------------------------------*/
.fslider[data-animation=fade][data-thumbs=true] .flexslider,
.fslider.testimonial[data-animation=fade] .flexslider {
  height: auto !important;
}

.flex-control-nav {
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  align-items: center;
  justify-content: center;
  width: auto;
  z-index: 10;
  text-align: center;
  top: 14px;
  right: 10px;
  margin: 0;
}

.flex-control-nav li {
  display: block;
  margin: 0 3px;
  width: 0.625rem;
  height: 0.625rem;
}

.flex-control-nav li a {
  display: block;
  cursor: pointer;
  text-indent: -9999px;
  width: 100% !important;
  height: 100% !important;
  border: 1px solid #fff;
  color: transparent;
  border-radius: 50%;
  transition: 0.3s ease-in-out;
}

.flex-control-nav li:hover a,
.flex-control-nav li a.flex-active {
  background-color: #fff;
}

.control-solid .flex-control-nav li a {
  border: none;
  background-color: #fff;
}

.control-solid .flex-control-nav li:hover a,
.control-solid .flex-control-nav li a.flex-active {
  background-color: #2a93c9;
}

.control-square .flex-control-nav li a {
  border-radius: 3px;
}

.control-diamond .flex-control-nav li a {
  border-radius: 3px;
  transform: rotate(45deg);
}

/* Flex Slider: Pagination Positions
-----------------------------------------------------------------*/
.control-offset-lg .flex-control-nav {
  top: 24px;
  right: 20px;
}

.control-pos-top-left .flex-control-nav,
.control-pos-bottom-left .flex-control-nav {
  left: 10px;
  right: auto;
}

.control-offset-lg.control-pos-top-left .flex-control-nav,
.control-offset-lg.control-pos-bottom-left .flex-control-nav {
  left: 20px;
}

.control-pos-bottom-right .flex-control-nav,
.control-pos-bottom-left .flex-control-nav,
.control-pos-bottom .flex-control-nav,
.control-pos-bottom .flex-control-nav {
  top: auto;
  bottom: 14px;
}

.control-offset-lg.control-pos-bottom-right .flex-control-nav,
.control-offset-lg.control-pos-bottom-left .flex-control-nav,
.control-offset-lg.control-pos-bottom .flex-control-nav,
.control-offset-lg.control-pos-bottom .flex-control-nav {
  bottom: 24px;
}

.control-pos-bottom .flex-control-nav,
.control-pos-bottom .flex-control-nav,
.control-pos-top .flex-control-nav,
.control-pos-top .flex-control-nav {
  width: 100%;
  right: 0;
}

.control-size-lg .flex-control-nav li {
  width: 0.9325rem;
  height: 0.9325rem;
  margin: 0 5px;
}

/* Flex Slider: Thumbs
-----------------------------------------------------------------*/
.flex-control-nav.flex-control-thumbs {
  position: relative;
  justify-content: left;
  top: 0;
  left: 0;
  right: 0;
  margin: -2px 0;
  height: auto;
}

.flex-control-nav.flex-control-thumbs li {
  margin: 0 2px 2px 0;
}

.flex-control-nav.flex-control-thumbs li img {
  cursor: pointer;
  text-indent: -9999px;
  border: 0;
  border-radius: 0;
  margin: 0;
  opacity: 0.5;
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

.flex-control-nav.flex-control-thumbs li,
.flex-control-nav.flex-control-thumbs li img {
  display: block;
  width: 100px !important;
  height: auto !important;
}

.flex-control-nav.flex-control-thumbs li:hover img,
.flex-control-nav.flex-control-thumbs li img.flex-active {
  border-width: 0;
  opacity: 1;
}

/* FlexSlider: Thumbs - Flexible
-----------------------------------------------------------------*/
.fslider.flex-thumb-grid .flex-control-nav.flex-control-thumbs {
  margin: -2px 0;
  height: auto;
}

.fslider.flex-thumb-grid .flex-control-nav.flex-control-thumbs li {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
  height: auto !important;
  margin: 0;
  padding: 0 2px 2px 0;
}

.fslider.flex-thumb-grid .flex-control-nav.flex-control-thumbs li img {
  width: 100% !important;
  height: auto !important;
}

/* FlexSlider: Thumbs - Flexible
-----------------------------------------------------------------*/
.fslider.flex-thumb-grid .flex-control-nav.flex-control-thumbs {
  margin: 2px -2px -2px 0;
  height: auto;
  display: flex;
  flex-wrap: wrap;
}

.fslider.flex-thumb-grid .flex-control-nav.flex-control-thumbs li {
  flex-shrink: 0;
  flex: 0 0 auto;
  max-width: 100%;
  height: auto !important;
  margin: 0;
  padding: 0 2px 2px 0;
}

.fslider.flex-thumb-grid .flex-control-nav.flex-control-thumbs li {
  width: 25% !important;
}

.fslider.flex-thumb-grid.grid-3 .flex-control-nav.flex-control-thumbs li {
  width: 33.333333% !important;
}

.fslider.flex-thumb-grid.grid-5 .flex-control-nav.flex-control-thumbs li {
  width: 20% !important;
}

.fslider.flex-thumb-grid.grid-6 .flex-control-nav.flex-control-thumbs li {
  width: 16.666666% !important;
}

.fslider.flex-thumb-grid.grid-8 .flex-control-nav.flex-control-thumbs li {
  width: 12.5% !important;
}

.fslider.flex-thumb-grid.grid-10 .flex-control-nav.flex-control-thumbs li {
  width: 10% !important;
}

.fslider.flex-thumb-grid.grid-12 .flex-control-nav.flex-control-thumbs li {
  width: 8.333333% !important;
}

.fslider.flex-thumb-grid .flex-control-nav.flex-control-thumbs li img {
  width: 100% !important;
  height: auto !important;
}

/*-----------------------------------------------------------------------------------

	banner.scss

-----------------------------------------------------------------------------------*/
#preloader,
#pre-loader {
  position: absolute !important;
  bottom: 0 !important;
  width: 100% !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  text-align: center !important;
  background-color: #fff !important;
  z-index: 99 !important;
}

#preloader #status,
#pre-loader #status {
  padding: 0 10px;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200px;
  height: 200px;
  background-image: url(../images/ajax-loader.gif);
  background-repeat: no-repeat;
  background-position: center;
  margin: -100px 0 0 -100px;
}

#banner, #subbanner {
  margin-bottom: -8px;
  overflow: hidden;
}

#banner img,
#subbanner img {
  width: 100%;
  margin: 0;
}

#banner .hero-slide,
#subbanner .hero-slide {
  overflow: hidden;
}

.banner-overlay-1 {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 5px;
  z-index: 97;
  max-width: 100%;
  opacity: 0.7;
  background-color: white;
}

.banner-overlay-2 {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 5px;
  z-index: 97;
  max-width: 100%;
  opacity: 0.9;
  background-image: linear-gradient(rgba(3, 145, 197, 0) 60%, var(--dark-color) 100%);
}

#banner video {
  /* width: 100%; */
}

/* #banner video + .banner-overlay {
  bottom: 8px;
} */
.banner-caption {
  position: relative;
  z-index: 3;
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -vendor-animation-duration: 3s;
  -vendor-animation-delay: 0s;
  -vendor-animation-iteration-count: infinite;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  height: initial;
  z-index: 98;
  max-width: 100%;
  margin: 0 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  padding: 40px 25px;
}
@media (max-width: 575.98px) {
  .banner-caption {
    padding: 10px 0px;
  }
}

.banner-caption h2, .banner-caption .h2 {
  font-family: "Poppins", sans-serif;
  font-weight: bold !important;
  font-size: 4rem;
  line-height: 1.2;
  padding: 0;
  text-transform: uppercase;
}
@media (max-width: 1199.98px) {
  .banner-caption h2, .banner-caption .h2 {
    font-size: 4rem;
  }
}
@media (max-width: 767.98px) {
  .banner-caption h2, .banner-caption .h2 {
    font-size: 28px;
    margin-bottom: 15px;
  }
}
@media (max-width: 575.98px) {
  .banner-caption h2, .banner-caption .h2 {
    font-size: 20px;
    margin-bottom: 15px;
  }
}

.banner-caption h6, .banner-caption .h6 {
  font-family: "Lato", sans-serif;
  font-weight: normal !important;
  font-size: 20px;
}
@media (max-width: 1199.98px) {
  .banner-caption h6, .banner-caption .h6 {
    font-size: 16px;
  }
}
@media (max-width: 767.98px) {
  .banner-caption h6, .banner-caption .h6 {
    font-size: 14px;
    margin-bottom: 15px;
  }
}
@media (max-width: 575.98px) {
  .banner-caption h6, .banner-caption .h6 {
    font-size: 12px;
    margin-bottom: 10px;
  }
}

.banner-caption hr {
  border-top: 1px solid var(--dark-color);
  width: 100px;
  margin: 1.5em 0;
}

.banner-caption p {
  font-family: var(--secondary-font);
  font-size: 1.5rem !important;
  font-weight: 300;
  color: var(--theme-color);
  padding: 0;
  line-height: 1.5;
  margin-bottom: 10px;
  margin-top: 30px;
}

@media (max-width: 575.98px) {
  .banner-caption .button {
    display: none;
  }
}

.sub-banner-overlay-1 {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 5px;
  z-index: 97;
  opacity: 0.6;
  background-color: #333;
}

.sub-banner-overlay-2 {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 5px;
  z-index: 97;
  max-width: 100%;
  opacity: 0.9;
  background-color: #02647A;
}

.sub-banner-overlay:after {
  content: " ";
  position: absolute;
  left: 99%;
  top: 0;
  bottom: 0;
  z-index: 97;
  width: 100vw;
  opacity: 1;
  background: #287f31;
}

.sub-banner-caption {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -vendor-animation-duration: 3s;
  -vendor-animation-delay: 0s;
  -vendor-animation-iteration-count: infinite;
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 98;
  max-width: 100%;
  margin: 0 auto;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  padding: 0 25px;
}
@media (max-width: 575.98px) {
  .sub-banner-caption {
    padding: 10px 0px;
  }
}

.sub-banner-caption .breadcrumb {
  flex-wrap: nowrap;
}

.sub-banner-caption h2, .sub-banner-caption .h2 {
  font-family: "Poppins", sans-serif;
  font-weight: bold !important;
  font-size: 54px;
  line-height: 1.2;
  padding: 0;
  margin-bottom: 0px;
}
@media (max-width: 1199.98px) {
  .sub-banner-caption h2, .sub-banner-caption .h2 {
    font-size: 40px;
  }
}
@media (max-width: 767.98px) {
  .sub-banner-caption h2, .sub-banner-caption .h2 {
    font-size: 28px;
  }
}
@media (max-width: 575.98px) {
  .sub-banner-caption h2, .sub-banner-caption .h2 {
    font-size: 20px;
  }
}

.sub-banner-caption hr {
  border-top: 2px solid #f7d401;
  width: 45%;
  margin: 1em 0 1em auto;
}

.sub-banner-caption p {
  font-size: 20px;
  color: #555;
  padding: 0;
  line-height: 1.35;
}

.sub-banner-caption .breadcrumb {
  position: relative !important;
  margin: 0 !important;
  right: 0 !important;
  /* justify-content: flex-end; */
  padding-left: 0;
  padding-right: 0;
  align-items: center;
}

.sub-banner-caption .breadcrumb a, .sub-banner-caption .breadcrumb-item + .breadcrumb-item::before {
  font-family: "Lato", sans-serif;
  font-size: 12px;
  font-weight: normal;
  color: #555;
}

.sub-banner-caption .breadcrumb-item.active {
  font-family: "Lato", sans-serif;
  font-size: 12px;
  color: #2a93c9;
  font-weight: normal;
  padding-top: 4px;
}

.sub-banner-caption .sub-banner-flex {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: flex-start;
}

.sub-banner-flex {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
}

/* ----------------------------------------------------------------

	pagetitle.scss

-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Page Title
-----------------------------------------------------------------*/
#page-title {
  position: relative;
  padding: 4rem 0;
  background-color: whitesmoke;
  border-bottom: 1px solid #eeeeee;
}
@media (max-width: 575.98px) {
  #page-title {
    padding: 2rem 0;
    text-align: center;
  }
}
#page-title .container {
  position: relative;
}
#page-title h1, #page-title .h1 {
  padding: 0;
  margin: 0;
  line-height: 1;
  font-weight: 600;
  letter-spacing: 1px;
  color: #333333;
  font-size: 2rem;
  text-transform: uppercase;
}
@media (max-width: 575.98px) {
  #page-title h1, #page-title .h1 {
    line-height: 1.5;
    font-size: calc( 1rem + 2vw );
  }
}
#page-title span {
  display: block;
  margin-top: 10px;
  font-weight: 300;
  color: #777;
  font-size: 18px;
}
#page-title .breadcrumb {
  position: absolute !important;
  width: auto !important;
  top: 50% !important;
  left: auto !important;
  right: 15px !important;
  margin: 0 !important;
  background-color: transparent !important;
  padding: 0 !important;
  font-size: 90%;
  transform: translateY(-50%);
}
#page-title .breadcrumb a {
  color: #555;
}
#page-title .breadcrumb a a:hover {
  color: #2a93c9;
}
#page-title .breadcrumb i {
  width: auto !important;
}
@media (max-width: 575.98px) {
  #page-title .breadcrumb {
    position: relative !important;
    top: 0 !important;
    left: 0 !important;
    right: 0 !important;
    margin: 20px 0 0 !important;
    justify-content: center;
    -webkit-transform: translateY(0);
    transform: translateY(0);
  }
}

#page-title.page-title {
  /* Page Title - Right Aligned
  -----------------------------------------------------------------*/
  /* Page Title - Center Aligned
  -----------------------------------------------------------------*/
  /* Page Title - Background Pattern
  -----------------------------------------------------------------*/
  /* Page Title - Parallax Background
  -----------------------------------------------------------------*/
  /* Page Title - Mini
  -----------------------------------------------------------------*/
  /* Page Title - No Background
  -----------------------------------------------------------------*/
  /* Page Title - Video
  -----------------------------------------------------------------*/
}
#page-title.page-title-dark {
  background-color: #333;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1) !important;
  border-bottom: none;
}
#page-title.page-title-dark h1, #page-title.page-title-dark .h1 {
  color: rgba(255, 255, 255, 0.9) !important;
}
#page-title.page-title-dark span {
  color: rgba(255, 255, 255, 0.7) !important;
}
#page-title.page-title-dark .breadcrumb > .active {
  color: rgba(255, 255, 255, 0.6);
}
#page-title.page-title-dark .breadcrumb a {
  color: rgba(255, 255, 255, 0.8);
}
#page-title.page-title-dark .breadcrumb a:hover {
  color: rgba(255, 255, 255, 0.95);
}
#page-title.page-title-dark .breadcrumb-item + .breadcrumb-item::before {
  color: #CCC;
}
#page-title.page-title-right {
  text-align: right;
}
#page-title.page-title-right .breadcrumb {
  left: 15px !important;
  right: auto !important;
}
#page-title.page-title-center {
  text-align: center;
}
#page-title.page-title-center span {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}
#page-title.page-title-center .breadcrumb {
  position: relative !important;
  top: 0 !important;
  left: 0 !important;
  right: 0 !important;
  margin: 20px 0 0 !important;
  justify-content: center;
  transform: translateY(0);
}
#page-title.page-title-pattern {
  background-image: url("images/pattern.png");
  background-repeat: repeat;
  background-attachment: fixed;
}
#page-title.page-title-parallax {
  padding: 6.25rem 0;
  text-shadow: none;
  border-bottom: none;
  background-color: transparent;
  background-image: url("images/parallax/parallax-bg.jpg");
  background-attachment: fixed;
  background-position: 50% 0;
  background-repeat: no-repeat;
}
#page-title.page-title-parallax h1, #page-title.page-title-parallax .h1 {
  font-size: 40px;
  font-weight: 600;
  letter-spacing: 2px;
}
#page-title.page-title-parallax span {
  font-size: 1.25rem;
}
@media (max-width: 575.98px) {
  #page-title.page-title-parallax span {
    font-size: 1rem;
  }
}
#page-title.page-title-parallax .breadcrumb {
  font-size: 0.875rem;
}
.transparent-header + #page-title.page-title-parallax .breadcrumb {
  margin-top: 35px !important;
}
#page-title.page-title-mini {
  padding: 20px 0;
}
#page-title.page-title-mini h1, #page-title.page-title-mini .h1 {
  font-weight: 600;
  font-size: 18px;
  color: #444;
}
#page-title.page-title-mini span {
  display: none;
}
#page-title.page-title-nobg {
  background: transparent !important;
  border-bottom: 1px solid #eeeeee;
}
#page-title.page-title-video {
  background: none;
  position: relative;
  overflow: hidden;
}
#page-title.page-title-video .container {
  z-index: 3;
}
#page-title.page-title-video .video-wrap {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}
#page-title.page-title-video .video-wrap video {
  width: 100%;
}

@media (max-width: 575.98px) {
  #page-title #portfolio-navigation,
#portfolio-ajax-title #portfolio-navigation {
    position: relative;
    top: 0;
    left: 0;
    margin: 15px auto 0;
  }
}
/* ----------------------------------------------------------------

	content.scss

-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Content
-----------------------------------------------------------------*/
[class*=col-]:not([class*=sticky-]):not([class*=position-]) {
  position: relative;
}

#content {
  position: relative;
  background-color: #fff;
}
#content p {
  line-height: 1.8;
}
#content .content-wrap {
  position: relative;
  padding: 80px 0;
  overflow: hidden;
}
#content .container {
  position: relative;
}

.grid-container,
.grid-inner {
  position: relative;
  overflow: hidden;
  will-change: transform;
}

.grid-inner {
  width: 100%;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
}

body:not(.device-touch) .grid-container {
  -webkit-transition: height 0.4s ease;
  -o-transition: height 0.4s ease;
  transition: height 0.4s ease;
}

/* ----------------------------------------------------------------

	portfolio.scss

-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Portfolio
-----------------------------------------------------------------*/
/* Grid - Filter
-----------------------------------------------------------------*/
.grid-filter {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 3.5rem;
  list-style: none;
  /* Grid - Filter: Style 2
  -----------------------------------------------------------------*/
  /* Grid - Filter: Style 3
  -----------------------------------------------------------------*/
  /* Portfolio - Filter: Style 4
  -----------------------------------------------------------------*/
}
@media (min-width: 768px) {
  .grid-filter {
    border: 1px solid rgba(0, 0, 0, 0.07);
    border-radius: 4px;
  }
}
.grid-filter-wrap {
  display: -ms-flexbox;
  display: flex;
  justify-content: space-between;
}
.grid-filter li {
  position: relative;
  width: 50%;
}
@media (min-width: 576px) {
  .grid-filter li {
    width: 33.3333333333%;
  }
}
@media (min-width: 768px) {
  .grid-filter li {
    width: auto;
  }
}
.grid-filter li a {
  display: block;
  position: relative;
  padding: 10px 20px;
  font-size: 0.9375rem;
  color: #666;
  text-align: center;
  border-radius: 2px;
}
@media (min-width: 768px) {
  .grid-filter li a {
    border-left: 1px solid rgba(0, 0, 0, 0.07);
    border-radius: 0;
  }
  .bothsidebar .grid-filter li a {
    padding: 12px 14px;
  }
}
.grid-filter li a:hover {
  color: #2a93c9;
}
.grid-filter li.activeFilter a {
  color: #FFF;
  background-color: #2a93c9;
  margin: 0;
  font-weight: bold;
}
@media (min-width: 768px) {
  .grid-filter li.activeFilter a {
    margin: -1px 0;
    padding-top: 11px;
    padding-bottom: 11px;
  }
  .bothsidebar .grid-filter li.activeFilter a {
    padding: 13px 18px;
  }
}
@media (min-width: 768px) {
  .grid-filter li:first-child a {
    border-left: none;
    border-radius: 4px 0 0 4px;
  }
  .grid-filter li:last-child a {
    border-radius: 0 4px 4px 0;
  }
}
.grid-filter.style-2, .grid-filter.style-3, .grid-filter.style-4 {
  border: none;
  border-radius: 0;
  margin-bottom: 2.75rem;
}
.grid-filter.style-2 li, .grid-filter.style-3 li, .grid-filter.style-4 li {
  margin-bottom: 0.75rem;
}
.grid-filter.style-2 li a, .grid-filter.style-3 li a, .grid-filter.style-4 li a {
  padding-top: 9px;
  padding-bottom: 9px;
  border-radius: 22px;
  border: none;
}
.grid-filter.style-2 li.activeFilter a {
  color: #FFF !important;
  margin: 0;
}
@media (min-width: 768px) {
  .grid-filter.style-2 li:not(:first-child), .grid-filter.style-3 li:not(:first-child) {
    margin-left: 0.75rem;
  }
}
.grid-filter.style-3 li a {
  border: 1px solid transparent;
}
.grid-filter.style-3 li.activeFilter a {
  color: #2a93c9 !important;
  border-color: #2a93c9;
  background-color: transparent;
  margin: 0;
}
@media (min-width: 768px) {
  .grid-filter.style-4 li:not(:first-child) {
    margin-left: 30px;
  }
}
.grid-filter.style-4 li a {
  padding: 13px 5px;
  border-radius: 0;
}
.grid-filter.style-4 li a::after {
  content: "";
  position: absolute;
  top: auto;
  bottom: 0;
  left: 50%;
  width: 0;
  height: 2px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.grid-filter.style-4 li.activeFilter a {
  color: #444 !important;
  background-color: transparent;
  margin: 0;
}
.grid-filter.style-4 li.activeFilter a::after {
  width: 100%;
  left: 0%;
  background-color: #2a93c9;
}

/* Portfolio - Shuffle Icon
-----------------------------------------------------------------*/
.grid-shuffle {
  width: 42px;
  height: 42px;
  border: 1px solid rgba(0, 0, 0, 0.07);
  font-size: 16px;
  text-align: center;
  line-height: 40px;
  color: #333333;
  cursor: pointer;
  -webkit-transition: all 0.2s linear;
  -o-transition: all 0.2s linear;
  transition: all 0.2s linear;
}
.grid-shuffle:hover {
  background-color: #2a93c9;
  color: #FFF;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}

/* Portfolio - Items
-----------------------------------------------------------------*/
.portfolio {
  position: relative;
}
.portfolio-item {
  position: relative;
}
.portfolio-reveal .portfolio-item {
  overflow: hidden;
}
.portfolio-item .portfolio-image {
  position: relative;
  overflow: hidden;
}
.portfolio-item .portfolio-image,
.portfolio-item .portfolio-image > a,
.portfolio-item .portfolio-image img {
  display: block;
  width: 100%;
  height: auto;
}
.portfolio-rounded .portfolio-image {
  border-radius: 5px;
  overflow: hidden;
}
body:not(.device-touch) .portfolio {
  -webkit-transition: height 0.4s linear;
  -o-transition: height 0.4s linear;
  transition: height 0.4s linear;
}

@media (min-width: 768px) {
  .portfolio-reveal .portfolio-image img,
.portfolio-reveal .bg-overlay {
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
}
/* Portfolio - Item Title
-----------------------------------------------------------------*/
.portfolio-desc {
  position: relative;
  z-index: 6;
  padding: 20px 5px;
}
.portfolio-desc h3, .portfolio-desc .h3 {
  margin: 0;
  padding: 0;
  font-size: 1.25rem;
}
.col-md-9 .portfolio-desc h3, .col-md-9 .portfolio-desc .h3 {
  font-size: 1.125rem;
}
.portfolio-desc h3 a, .portfolio-desc .h3 a {
  color: #222222;
}
.portfolio-desc h3 a:hover, .portfolio-desc .h3 a:hover {
  color: #2a93c9;
}
.portfolio-desc span {
  display: block;
}
.col-md-9 .portfolio-desc span {
  font-size: 0.875rem;
}
.portfolio-desc span,
.portfolio-desc span a {
  color: #888888;
}
.portfolio-desc span a:hover {
  color: #000;
}
.desc-lg .portfolio-desc {
  padding: 20px 8px;
}
.desc-lg .portfolio-desc h3, .desc-lg .portfolio-desc .h3 {
  font-size: 1.5rem;
}
.desc-lg .portfolio-desc span {
  font-size: 110%;
}
.desc-sm .portfolio-desc {
  padding: 0.75rem 5px;
}
.desc-sm .portfolio-desc h3, .desc-sm .portfolio-desc .h3 {
  font-size: 1rem;
}
.desc-sm .portfolio-desc span {
  font-size: 90%;
}
.portfolio.g-0 .portfolio-desc {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.desc-sm.g-0 .portfolio-desc {
  padding-left: -4.25rem;
  padding-right: -4.25rem;
}
.portfolio-reveal .portfolio-desc {
  background-color: #FFF;
  top: -100%;
  -webkit-transform: translateY(100%);
  transform: translateY(100%);
}
@media (min-width: 768px) {
  .portfolio-reveal .portfolio-desc {
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
}
@media (max-width: 575.98px) {
  .portfolio-reveal .portfolio-desc {
    display: none !important;
  }
}

.portfolio-reveal .portfolio-item:hover .portfolio-desc {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
.portfolio-reveal .portfolio-item:hover .portfolio-image img,
.portfolio-reveal .portfolio-item:hover .bg-overlay {
  -webkit-transform: translateY(0);
  transform: translateY(0);
}
@media (min-width: 768px) {
  .portfolio-reveal .portfolio-item:hover .portfolio-image img,
.portfolio-reveal .portfolio-item:hover .bg-overlay {
    -webkit-transform: translateY(-15%);
    transform: translateY(-15%);
  }
}

/* Portfolio - Parallax
-----------------------------------------------------------------*/
.portfolio-parallax .portfolio-image {
  height: 60vh;
  background-attachment: fixed;
}
@media (min-width: 992px) {
  .portfolio-parallax .portfolio-image {
    height: 500px;
  }
}

/* Portfolio Single
-----------------------------------------------------------------*/
/* Portfolio Single - Image
-----------------------------------------------------------------*/
.portfolio-single-image > a,
.portfolio-single-image .slide a,
.portfolio-single-image img,
.portfolio-single-image iframe,
.portfolio-single-image video {
  display: block;
  width: 100%;
}
.portfolio-single-image-full {
  position: relative;
  height: 600px;
  overflow: hidden;
  margin: -80px 0 80px !important;
}
.portfolio-single-video {
  height: auto !important;
}

/* Masonry Thumbs
-----------------------------------------------------------------*/
.masonry-thumbs {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -1px;
  margin-right: -1px;
  margin-bottom: -2px;
}
.masonry-thumbs .grid-item {
  display: block;
  position: relative;
  width: 25%;
  max-width: 100%;
  height: auto;
  overflow: hidden;
  padding-left: 1px;
  padding-right: 1px;
  padding-bottom: 2px;
  padding-top: 0;
}

.masonry-gap-0 {
  margin: 0;
}
.masonry-gap-0 .grid-item {
  padding: 0;
}

.masonry-gap-lg {
  margin-left: -3px;
  margin-right: -3px;
  margin-bottom: -6px;
}
.masonry-gap-lg .grid-item {
  padding-left: 3px;
  padding-right: 3px;
  padding-bottom: 6px;
}

.masonry-gap-xl {
  margin-left: -5px;
  margin-right: -5px;
  margin-bottom: -10px;
}
.masonry-gap-xl .grid-item {
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 10px;
}

.grid-item > img,
.grid-item .grid-inner > img {
  width: 100%;
  height: auto;
  border-radius: 0;
}

.grid-1 > .grid-item {
  width: 100%;
}

.grid-2 > .grid-item {
  width: 50%;
}

.grid-3 > .grid-item {
  width: 33.3333333333%;
}

.grid-4 > .grid-item {
  width: 25%;
}

.grid-5 > .grid-item {
  width: 20%;
}

.grid-6 > .grid-item {
  width: 16.6666666667%;
}

.grid-7 > .grid-item {
  width: 14.2857142857%;
}

.grid-8 > .grid-item {
  width: 12.5%;
}

.grid-9 > .grid-item {
  width: 11.1111111111%;
}

.grid-10 > .grid-item {
  width: 10%;
}

.grid-11 > .grid-item {
  width: 9.0909090909%;
}

.grid-12 > .grid-item {
  width: 8.3333333333%;
}

@media (min-width: 576px) {
  .grid-sm-1 > .grid-item {
    width: 100%;
  }

  .grid-sm-2 > .grid-item {
    width: 50%;
  }

  .grid-sm-3 > .grid-item {
    width: 33.3333333333%;
  }

  .grid-sm-4 > .grid-item {
    width: 25%;
  }

  .grid-sm-5 > .grid-item {
    width: 20%;
  }

  .grid-sm-6 > .grid-item {
    width: 16.6666666667%;
  }

  .grid-sm-7 > .grid-item {
    width: 14.2857142857%;
  }

  .grid-sm-8 > .grid-item {
    width: 12.5%;
  }

  .grid-sm-9 > .grid-item {
    width: 11.1111111111%;
  }

  .grid-sm-10 > .grid-item {
    width: 10%;
  }

  .grid-sm-11 > .grid-item {
    width: 9.0909090909%;
  }

  .grid-sm-12 > .grid-item {
    width: 8.3333333333%;
  }
}
@media (min-width: 768px) {
  .grid-md-1 > .grid-item {
    width: 100%;
  }

  .grid-md-2 > .grid-item {
    width: 50%;
  }

  .grid-md-3 > .grid-item {
    width: 33.3333333333%;
  }

  .grid-md-4 > .grid-item {
    width: 25%;
  }

  .grid-md-5 > .grid-item {
    width: 20%;
  }

  .grid-md-6 > .grid-item {
    width: 16.6666666667%;
  }

  .grid-md-7 > .grid-item {
    width: 14.2857142857%;
  }

  .grid-md-8 > .grid-item {
    width: 12.5%;
  }

  .grid-md-9 > .grid-item {
    width: 11.1111111111%;
  }

  .grid-md-10 > .grid-item {
    width: 10%;
  }

  .grid-md-11 > .grid-item {
    width: 9.0909090909%;
  }

  .grid-md-12 > .grid-item {
    width: 8.3333333333%;
  }
}
@media (min-width: 992px) {
  .grid-lg-1 > .grid-item {
    width: 100%;
  }

  .grid-lg-2 > .grid-item {
    width: 50%;
  }

  .grid-lg-3 > .grid-item {
    width: 33.3333333333%;
  }

  .grid-lg-4 > .grid-item {
    width: 25%;
  }

  .grid-lg-5 > .grid-item {
    width: 20%;
  }

  .grid-lg-6 > .grid-item {
    width: 16.6666666667%;
  }

  .grid-lg-7 > .grid-item {
    width: 14.2857142857%;
  }

  .grid-lg-8 > .grid-item {
    width: 12.5%;
  }

  .grid-lg-9 > .grid-item {
    width: 11.1111111111%;
  }

  .grid-lg-10 > .grid-item {
    width: 10%;
  }

  .grid-lg-11 > .grid-item {
    width: 9.0909090909%;
  }

  .grid-lg-12 > .grid-item {
    width: 8.3333333333%;
  }
}
@media (min-width: 1200px) {
  .grid-xl-1 > .grid-item {
    width: 100%;
  }

  .grid-xl-2 > .grid-item {
    width: 50%;
  }

  .grid-xl-3 > .grid-item {
    width: 33.3333333333%;
  }

  .grid-xl-4 > .grid-item {
    width: 25%;
  }

  .grid-xl-5 > .grid-item {
    width: 20%;
  }

  .grid-xl-6 > .grid-item {
    width: 16.6666666667%;
  }

  .grid-xl-7 > .grid-item {
    width: 14.2857142857%;
  }

  .grid-xl-8 > .grid-item {
    width: 12.5%;
  }

  .grid-xl-9 > .grid-item {
    width: 11.1111111111%;
  }

  .grid-xl-10 > .grid-item {
    width: 10%;
  }

  .grid-xl-11 > .grid-item {
    width: 9.0909090909%;
  }

  .grid-xl-12 > .grid-item {
    width: 8.3333333333%;
  }
}
@media (min-width: 1400px) {
  .grid-xxl-1 > .grid-item {
    width: 100%;
  }

  .grid-xxl-2 > .grid-item {
    width: 50%;
  }

  .grid-xxl-3 > .grid-item {
    width: 33.3333333333%;
  }

  .grid-xxl-4 > .grid-item {
    width: 25%;
  }

  .grid-xxl-5 > .grid-item {
    width: 20%;
  }

  .grid-xxl-6 > .grid-item {
    width: 16.6666666667%;
  }

  .grid-xxl-7 > .grid-item {
    width: 14.2857142857%;
  }

  .grid-xxl-8 > .grid-item {
    width: 12.5%;
  }

  .grid-xxl-9 > .grid-item {
    width: 11.1111111111%;
  }

  .grid-xxl-10 > .grid-item {
    width: 10%;
  }

  .grid-xxl-11 > .grid-item {
    width: 9.0909090909%;
  }

  .grid-xxl-12 > .grid-item {
    width: 8.3333333333%;
  }
}
/* Portfolio Single - Content
-----------------------------------------------------------------*/
.portfolio-single-content h2, .portfolio-single-content .h2 {
  margin: 0 0 20px;
  padding: 0;
  font-size: 1.25rem;
  font-weight: 600 !important;
}

.modal-padding {
  padding: 40px;
}

.ajax-modal-title {
  background-color: #f9f9f9;
  border-bottom: 1px solid #eeeeee;
  padding: 25px 40px;
}

.ajax-modal-title h2, .ajax-modal-title .h2 {
  font-size: 1.75rem;
  margin-bottom: 0;
}

#portfolio-ajax-wrap {
  position: relative;
  max-height: 0;
  overflow: hidden;
  -webkit-transition: max-height 0.4s ease;
  -o-transition: max-height 0.4s ease;
  transition: max-height 0.4s ease;
}

#portfolio-ajax-wrap.portfolio-ajax-opened {
  max-height: 1200px;
}

#portfolio-ajax-container {
  display: none;
  padding-bottom: 60px;
}

#portfolio-ajax-title h2, #portfolio-ajax-title .h2 {
  font-size: 1.75rem;
}

/* Portfolio Single - Meta
-----------------------------------------------------------------*/
.portfolio-meta,
.portfolio-share {
  list-style: none;
  font-size: 0.875rem;
}

.portfolio-meta li {
  margin: 10px 0;
  color: #666666;
}

.portfolio-meta li:first-child {
  margin-top: 0;
}

.portfolio-meta li span {
  display: inline-block;
  width: 150px;
  font-weight: bold;
  color: #333333;
}

.portfolio-meta li span i {
  position: relative;
  top: 1px;
  width: 14px;
  text-align: center;
  margin-right: 7px;
}

.well .line {
  margin: 20px 0;
  border-color: 1px solid #e5e5e5;
}

/* Portfolio Single Navigation
-----------------------------------------------------------------*/
#portfolio-navigation {
  position: absolute;
  top: 50%;
  left: auto;
  right: 10px;
  max-width: 96px;
  height: 24px;
  margin-top: -12px;
}
.page-title-right #portfolio-navigation {
  left: 10px;
  right: auto;
}
.page-title-center #portfolio-navigation {
  position: relative;
  top: 0;
  left: 0;
  margin: 20px auto 0;
}
#portfolio-navigation a {
  display: block;
  float: left;
  margin-left: 12px;
  width: 24px;
  height: 24px;
  text-align: center;
  color: #444;
  transition: color 0.3s linear;
  -webkit-transition: color 0.3s linear;
  -o-transition: color 0.3s linear;
}
.page-title-dark #portfolio-navigation a {
  color: #EEE;
}
.page-title-parallax #portfolio-navigation a {
  color: #FFF;
}
#portfolio-navigation a:first-child {
  margin-left: 0;
}
#portfolio-navigation a:hover {
  color: #2a93c9;
}
#portfolio-navigation a i {
  position: relative;
  top: -1px;
  font-size: 24px;
  line-height: 1;
}
#portfolio-navigation a i.icon-angle-left, #portfolio-navigation a i.icon-angle-right {
  font-size: 32px;
  top: -6px;
}
#portfolio-navigation a i.icon-angle-right {
  left: -1px;
}
#portfolio-ajax-show #portfolio-navigation {
  top: 0;
  margin-top: 0;
}

/* ----------------------------------------------------------------

	blog.scss

-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Blog
-----------------------------------------------------------------*/
#posts {
  position: relative;
}

.entry {
  position: relative;
  margin-bottom: 50px;
}
.posts-md .entry, .posts-sm .entry {
  margin-bottom: 0;
}
.entry::after {
  content: "";
  position: relative;
  height: 2px;
  margin-top: 50px;
  background-color: whitesmoke;
}
.entry-image {
  margin-bottom: 30px;
}

.posts-md .entry-image,
.posts-sm .entry-image {
  margin-bottom: 0;
}

.entry-image,
.entry-image > a,
.entry-image .slide a,
.entry-image img {
  display: block;
  position: relative;
  width: 100%;
  height: auto;
}

.posts-sm .entry-image {
  width: 64px;
}

.entry-image iframe {
  display: block;
}
.entry-image video {
  display: block;
  width: 100%;
}
.entry-image img {
  border-radius: 3px;
}

.posts-md .entry-image + .entry-title {
  margin-top: 0.875rem;
}

.entry-title h2, .entry-title .h2,
.entry-title h3,
.entry-title .h3,
.entry-title h4,
.entry-title .h4 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.entry-title h2 a, .entry-title .h2 a,
.entry-title h3 a,
.entry-title .h3 a,
.entry-title h4 a,
.entry-title .h4 a {
  color: #333333;
}
.entry-title h2 a:hover, .entry-title .h2 a:hover,
.entry-title h3 a:hover,
.entry-title .h3 a:hover,
.entry-title h4 a:hover,
.entry-title .h4 a:hover {
  color: #2a93c9;
}
.entry-title.title-sm h2, .entry-title.title-sm .h2,
.entry-title.title-sm h3,
.entry-title.title-sm .h3 {
  font-size: 1.25rem;
  letter-spacing: 0;
}
.entry-title.title-sm h3, .entry-title.title-sm .h3 {
  font-size: 1.125rem;
}
.entry-title.title-xs h2, .entry-title.title-xs .h2,
.entry-title.title-xs h3,
.entry-title.title-xs .h3 {
  font-size: 1rem;
  letter-spacing: 0;
}
.entry-title h4, .entry-title .h4 {
  font-size: 0.875rem;
  letter-spacing: 0;
}
.entry-title.nott h2, .entry-title.nott .h2, .entry-title.nott h3, .entry-title.nott .h3,
.entry-title h4,
.entry-title .h4 {
  text-transform: none;
}

.entry-meta {
  position: relative;
  overflow: hidden;
  margin-top: 10px;
}
.posts-sm .entry-meta {
  margin-top: 6px;
}
.single-post .entry-meta {
  margin-bottom: 20px;
}
.posts-md .entry-meta, .posts-sm .entry-meta {
  margin-bottom: 0px;
}
.entry-meta ul {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 0 -10px -20px;
  list-style: none;
}

.entry-meta.no-separator ul,
.entry-meta ul.flex-column {
  align-items: flex-start;
  margin-left: 0;
}

.posts-sm .entry-meta ul {
  margin-bottom: -5px;
}

.entry-meta li {
  font-size: 90%;
  margin: 0 15px 10px 0;
  color: #999;
}

.entry-meta ul.flex-column li {
  margin-left: 0;
}

.posts-sm .entry-meta li {
  margin-bottom: 5px;
}

.entry-meta:not(.no-separator) li::before {
  content: "⁞";
  width: 5px;
  text-align: center;
  display: inline-block;
  margin-right: 15px;
  opacity: 0.5;
}

.entry-meta i {
  position: relative;
  top: 1px;
  padding-left: 1px;
  margin-right: 5px;
}

.entry-meta a:not(:hover) {
  color: #999999;
}

.entry-meta ins {
  font-weight: 700;
  text-decoration: none;
}

.entry-content {
  margin-top: 30px;
}

.single-post .entry-content {
  margin-top: 0;
}

.posts-md .entry-content,
.posts-sm .entry-content {
  margin-top: 20px;
}

.single-post .entry-content .entry-image {
  max-width: 350px;
}

.entry-content {
  position: relative;
}

.entry-link {
  display: block;
  width: 100%;
  background-color: whitesmoke;
  padding: 30px 0;
  text-align: center;
  color: #444;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 1.5rem;
  font-weight: 700;
  border-radius: 3px;
}

body:not(.device-touch) .entry-link {
  -webkit-transition: background-color 0.3s ease-in-out;
  -o-transition: background-color 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out;
}

.entry-link:hover {
  color: #FFF;
  background-color: #2a93c9;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}

.entry-link span {
  display: block;
  margin-top: 5px;
  font-family: "PT Serif", serif;
  font-style: italic;
  font-weight: normal;
  text-transform: none;
  letter-spacing: 0;
  font-size: 0.875rem;
  color: #aaaaaa;
}

.entry-link:hover span {
  color: #eeeeee;
}

.entry blockquote p {
  font-weight: 400;
  font-family: "PT Serif", serif;
  font-style: italic;
}

/* Blog - Grid
-----------------------------------------------------------------*/
.post-grid .entry {
  margin-bottom: 30px;
}

.post-grid .entry-title h2, .post-grid .entry-title .h2 {
  font-size: 1.125rem;
  letter-spacing: 0;
  font-weight: 600;
}

.post-grid .entry-link {
  font-size: 1.5rem;
}

.post-grid .entry-link span {
  font-size: 0.875rem;
}

/* Blog - Timeline
-----------------------------------------------------------------*/
.entry-timeline {
  display: none;
}

@media (min-width: 992px) {
  .timeline-border {
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    border-left: 1px dashed #cccccc;
    height: 100%;
    transform: translateX(-50%);
  }

  .postcontent .post-timeline::before {
    content: "";
    position: absolute;
    top: 0;
    left: 50%;
    width: 0;
    border-left: 1px dashed #cccccc;
    height: 100%;
  }

  .post-timeline .entry-timeline {
    display: block;
    position: absolute;
    border: 2px solid #cccccc;
    background-color: #fff;
    padding-top: 0;
    text-indent: -9999px;
    top: 40px;
    left: auto;
    right: -6px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    font-size: 1.75rem;
    font-weight: bold;
    color: #AAA;
    text-align: center;
    line-height: 1;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .post-timeline .entry-timeline .timeline-divider {
    position: absolute;
    top: 4px;
    left: auto;
    right: 15px;
    width: 70px;
    height: 0;
    border-top: 1px dashed #cccccc;
    -webkit-transition: all 0.3s ease-in-out;
    -o-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }

  .post-timeline .entry:not(.entry-date-section) {
    padding-right: 85px;
  }

  .post-timeline .entry.alt:not(.entry-date-section) {
    padding-right: inherit;
    padding-left: 85px;
  }

  .post-timeline .alt .entry-timeline {
    right: auto;
    left: -6px;
  }

  .post-timeline .alt .entry-timeline .timeline-divider {
    left: 15px;
    right: auto;
  }

  .post-timeline .entry-timeline span {
    display: block;
    margin-top: 3px;
    font-size: 13px;
    font-weight: normal;
  }

  .post-timeline .entry:hover .entry-timeline,
.post-timeline .entry:hover .timeline-divider {
    border-color: #2a93c9;
    color: #2a93c9;
  }
}
.entry.entry-date-section {
  margin: 50px 0 80px;
  text-align: center;
}

.entry.entry-date-section:first-child {
  margin-top: 0;
}

.entry.entry-date-section span {
  display: inline-block;
  width: 250px;
  background-color: #fff;
  border: 2px solid #eeeeee;
  font-size: 18px;
  line-height: 50px;
  font-weight: bold;
  letter-spacing: 1px;
  text-transform: uppercase;
  font-family: "Poppins", sans-serif;
}

@media (min-width: 992px) {
  /* Blog - Timeline - Sidebar
  -----------------------------------------------------------------*/
  .postcontent .post-timeline::before {
    left: -68px;
    margin-left: 0;
  }

  .postcontent .post-timeline {
    padding-left: 0;
    margin-left: 100px;
    margin-right: 0 !important;
    overflow: visible;
  }

  .postcontent .post-timeline .entry {
    width: 100% !important;
    padding: 0 !important;
  }

  .postcontent .post-timeline .entry-timeline {
    display: block;
    border: 3px solid #cccccc;
    background-color: #fff;
    padding-top: 10px;
    text-indent: 0;
    top: 20px;
    left: -100px;
    right: auto;
    width: 64px;
    height: 64px;
    font-size: 1.5rem;
  }

  .postcontent .post-timeline .entry-timeline div.timeline-divider {
    top: 29px;
    left: 64px;
    width: 32px;
  }
}
.more-link {
  display: inline-block;
  border-bottom: 1px solid #2a93c9;
  padding: 0 2px;
  font-family: "PT Serif", serif;
  font-style: italic;
}
.more-link:hover {
  border-bottom-color: #555;
}

.post-navigation + .line {
  margin-top: 50px;
}

/* ----------------------------------------------------------------
	Blog - Author
-----------------------------------------------------------------*/
.author-image {
  float: left;
  width: 64px;
  height: 64px;
  margin-right: 15px;
}
.author-image img {
  width: 64px;
  height: 64px;
}
.author-desc {
  position: relative;
  overflow: hidden;
}

/* ----------------------------------------------------------------
	Comments List
-----------------------------------------------------------------*/
#comments {
  position: relative;
  margin-top: 50px;
  padding-top: 50px;
  border-top: 1px solid #eeeeee;
}

.commentlist {
  list-style: none;
  padding-bottom: 50px;
  margin: 0 0 50px;
  border-bottom: 1px solid #eeeeee;
}

#reviews .commentlist {
  padding-bottom: 30px;
  margin: 0 0 20px;
}

.commentlist ul {
  list-style: none;
}

.commentlist li,
.commentlist li ul,
.commentlist li ul li {
  margin: 30px 0 0 0;
}

.commentlist ul:first-child {
  margin-top: 0;
}

.commentlist li {
  position: relative;
  margin: 30px 0 0 30px;
}

#reviews .commentlist li {
  margin-top: 20px;
}

.comment-wrap {
  position: relative;
  border: 1px solid #e5e5e5;
  border-radius: 5px;
  padding: 20px 20px 20px 35px;
}

.commentlist ul .comment-wrap {
  margin-left: 25px;
  padding-left: 20px;
}

#reviews .comment-wrap {
  border: 0;
  padding: 10px 0 0 35px;
}

.commentlist > li:first-child,
#reviews .commentlist > li:first-child {
  padding-top: 0;
  margin-top: 0;
}

.commentlist li .children {
  margin-top: 0;
}

.commentlist li li .children {
  margin-left: 30px;
}

.commentlist li .comment-content,
.pingback {
  position: relative;
  overflow: hidden;
}

.commentlist li .comment-content p,
.pingback p {
  margin: 20px 0 0 0;
}

.commentlist li .comment-content {
  padding: 0 0 0 15px;
}

.commentlist li .comment-meta {
  float: left;
  margin-right: 0;
  line-height: 1;
}

.comment-avatar {
  position: absolute;
  top: 15px;
  left: -35px;
  padding: 4px;
  background: #fff;
  border: 1px solid #E5E5E5;
  border-radius: 50%;
}

.comment-avatar img {
  display: block;
  border-radius: 50%;
}

.commentlist li .children .comment-avatar {
  left: -25px;
}

.comment-content .comment-author {
  margin-bottom: -10px;
  font-size: 1rem;
  font-weight: bold;
  color: #555;
}

.comment-content .comment-author a {
  border: none;
  color: #333;
}

.comment-content .comment-author a:hover {
  color: #2a93c9;
}

.comment-content .comment-author span {
  display: block;
}

.comment-content .comment-author span,
.comment-content .comment-author span a {
  font-size: 12px;
  font-weight: normal;
  font-family: "PT Serif", serif;
  font-style: italic;
  color: #AAA;
}

.comment-content .comment-author span a:hover {
  color: #888;
}

.comment-reply-link,
.review-comment-ratings {
  display: block;
  position: absolute;
  top: 4px;
  left: auto;
  text-align: center;
  right: 0px;
  width: 14px;
  height: 14px;
  color: #CCC;
  font-size: 0.875rem;
  line-height: 1;
}

.review-comment-ratings {
  width: auto;
  color: #333;
}

.comment-reply-link:hover {
  color: #888;
}

/* ----------------------------------------------------------------
	Comment Form
-----------------------------------------------------------------*/
#respond,
#respond form {
  margin-bottom: 0;
}

.commentlist li #respond {
  margin: 30px 0 0;
}

.commentlist li li #respond {
  margin-left: 30px;
}

#respond p {
  margin: 10px 0 0 0;
}

#respond p:first-child {
  margin-top: 0;
}

#respond label small, #respond label .small {
  color: #999;
  font-weight: normal;
}

#respond input[type=text],
#respond textarea {
  margin-bottom: 0;
}

.fb-comments,
.fb_iframe_widget,
.fb-comments > span,
.fb_iframe_widget > span,
.fb-comments > span > iframe,
.fb_iframe_widget > span > iframe {
  display: block !important;
  width: 100% !important;
  margin: 0;
}

/* Post Elements
-----------------------------------------------------------------*/
img.alignleft,
div.alignleft {
  float: left;
  margin: 5px 20px 13px 0;
  max-width: 100%;
}

div.alignleft > img,
div.alignnone > img,
div.aligncenter > img,
div.alignright > img {
  display: block;
  float: none;
}

img.alignnone,
img.aligncenter,
div.alignnone,
div.aligncenter {
  display: block;
  margin: 10px 0;
  float: none;
}

img.aligncenter,
div.aligncenter,
div.aligncenter img {
  margin-left: auto;
  margin-right: auto;
  clear: both;
}

img.alignright,
div.alignright {
  float: right;
  margin: 5px 0 13px 20px;
}

.wp-caption {
  text-align: center;
  margin: 10px 20px 13px 20px;
  font-family: "Lato", sans-serif, Georgia, "Times New Roman", Times, serif;
  font-style: italic;
}

.wp-caption img,
.wp-caption img a {
  display: block;
  margin: 0;
}

p.wp-caption-text {
  display: inline-block;
  margin: 10px 0 0 0;
  padding: 5px 10px;
  background-color: #eeeeee;
  border-radius: 50px;
}

.wp-smiley {
  max-height: 13px;
  margin: 0 !important;
}

/* ----------------------------------------------------------------

	shop.scss

-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Shop
-----------------------------------------------------------------*/
.shop, .product {
  position: relative;
}

.product .product-image {
  position: relative;
  overflow: hidden;
}
.product .product-image > a,
.product .product-image .slide a,
.product .product-image img {
  display: block;
  width: 100%;
}

body:not(.device-touch):not(.device-sm):not(.device-xs) .product-image > a {
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}

.product-image > a:nth-of-type(2) {
  opacity: 0;
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
}

.product-image:hover > a:nth-of-type(2) {
  opacity: 1;
  z-index: 2;
}

.product-desc {
  padding: 15px 0;
}

.product-title {
  margin-bottom: 7px;
}

.product-title h3, .product-title .h3 {
  margin: 0;
  font-size: 1.25rem;
}

.product-title h3 a, .product-title .h3 a,
.single-product .product-title h2 a,
.single-product .product-title .h2 a {
  color: #333333;
}

.product-title h3 a:hover, .product-title .h3 a:hover,
.single-product .product-title h2 a:hover,
.single-product .product-title .h2 a:hover {
  color: #2a93c9;
}

.product-price {
  font-size: 1.125rem;
  font-weight: 700;
  color: #444;
  margin-bottom: 4px;
}

.product-price del {
  font-weight: 400;
  font-size: 90%;
  color: #888888;
}

.product-price ins {
  text-decoration: none;
  color: #2a93c9;
}

.product-image > .badge,
.sale-flash {
  position: absolute;
  top: 10px;
  left: 10px;
  z-index: 5;
}

/* Shop - Single
-----------------------------------------------------------------*/
.single-product .product {
  width: 100%;
  margin: 0;
}

.shop-quick-view-ajax,
.portfolio-ajax-modal {
  position: relative;
  background-color: #fff;
  width: auto;
  max-width: 800px;
  margin: 0 auto;
}

.portfolio-ajax-modal {
  max-width: 1000px;
}

.single-product .product-image,
.single-product .product-image img {
  height: auto;
}

.single-product .product-title h2, .single-product .product-title .h2 {
  font-size: 1.75rem;
  margin-bottom: 8px;
}

.single-product .product-price {
  font-size: 1.5rem;
  color: #2a93c9;
  margin-bottom: 0;
}

.single-product .product-desc .line {
  margin: 20px 0;
}

.quantity {
  display: -ms-inline-flexbox;
  display: inline-flex;
  justify-content: center;
  align-items: stretch;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: 30px;
}

.quantity input[type=number]::-webkit-inner-spin-button,
.quantity input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.quantity .qty {
  width: 50px;
  height: 40px;
  line-height: 40px;
  border: 0;
  border-left: 1px solid #dddddd;
  border-right: 1px solid #dddddd;
  background-color: #eeeeee;
  text-align: center;
  margin-bottom: 0;
}

.quantity .plus,
.quantity .minus {
  display: block;
  cursor: pointer;
  border: 0px transparent;
  padding: 0;
  width: 36px;
  height: 40px;
  line-height: 40px;
  text-align: center;
  background-color: #eeeeee;
  font-size: 1rem;
  font-weight: bold;
  transition: background-color 0.2s linear;
  -webkit-transition: background-color 0.2s linear;
  -o-transition: background-color 0.2s linear;
}

.quantity .plus:hover,
.quantity .minus:hover {
  background-color: #dddddd;
}

.quantity .qty:focus,
.quantity .plus:focus,
.quantity .minus:focus {
  box-shadow: none !important;
  outline: 0 !important;
}

.product-meta {
  font-size: 90%;
  color: #777777;
}

.product-meta > .card-body > span {
  display: inline-block;
  margin: 0 5px;
}

.single-product .fbox-plain.fbox-sm .fbox-icon {
  width: 2.25rem;
}

.single-product .fbox-plain.fbox-sm .fbox-icon i {
  font-size: 1.125rem;
  line-height: 1.5;
}

/* ----------------------------------------------------------------
	Cart
-----------------------------------------------------------------*/
.cart .remove {
  font-size: 0.875rem;
  color: #FF0000;
}

.cart .remove:hover {
  color: #000;
}

.cart th {
  padding: 12px 15px !important;
  font-size: 0.875rem;
  color: #555555;
}

.cart td {
  padding: 12px 15px !important;
  vertical-align: middle !important;
  border-color: #e5e5e5 !important;
}

.cart-product-thumbnail a {
  display: block;
  width: 68px;
}

.cart-product-thumbnail img {
  display: block;
  width: 64px;
  height: 64px;
  border: 2px solid #eeeeee;
}

.cart-product-thumbnail img:hover {
  border-color: #2a93c9;
}

.cart-product-name a,
.product-name a {
  font-weight: bold;
  font-size: 0.8125rem;
  color: #333333;
}

.cart-product-name a:hover,
.product-name a:hover {
  color: #666666;
}

.cart-product-price,
.cart-product-quantity,
.cart-product-subtotal {
  text-align: center !important;
}

.cart-product-quantity .quantity {
  margin: 0;
}

@media (max-width: 575.98px) {
  .cart:not(.cart-totals) thead th {
    display: none;
  }
  .cart:not(.cart-totals) tbody td {
    display: block;
    width: 100%;
    border: 0;
    padding: 0 !important;
    text-align: center;
  }
  .cart:not(.cart-totals) .cart_item {
    display: block;
    position: relative;
    border: 1px solid #e5e5e5;
    margin-bottom: 1.5rem;
    padding: 1.5rem;
  }
  .cart:not(.cart-totals) .cart_item .cart-product-remove {
    display: block;
    position: absolute;
    top: 1.25rem;
    left: auto;
    right: 1.25rem;
    width: 1.25rem;
    height: 1.25rem;
  }
  .cart:not(.cart-totals) .cart-product-thumbnail, .cart:not(.cart-totals) .cart-product-name, .cart:not(.cart-totals) .cart-product-quantity {
    margin-bottom: 1rem;
  }
  .cart:not(.cart-totals) .cart_item .cart-product-thumbnail a {
    display: inline-block;
  }
  .cart:not(.cart-totals) .cart_item .cart-product-price {
    display: none;
  }
  .cart:not(.cart-totals) .cart_item .cart-product-name a {
    font-size: 1rem;
  }
  .cart:not(.cart-totals) .cart_item .cart-product-subtotal {
    font-size: 1.25rem;
    color: #2a93c9;
  }

  .quantity .qty {
    width: 42px;
  }

  .quantity .qty,
.quantity .plus,
.quantity .minus {
    font-size: 0.875rem;
  }
}
td.actions {
  padding: 20px 15px !important;
  vertical-align: top !important;
  background-color: whitesmoke;
}

/* ----------------------------------------------------------------

	event.scss

-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Events
-----------------------------------------------------------------*/
.event.entry::after {
  display: none !important;
}
.event .grid-inner {
  background-color: #f9f9f9;
  border-bottom: 3px solid #eeeeee;
}
.event .entry-image .entry-date {
  position: absolute;
  top: 10px;
  left: 10px;
  background-color: rgba(0, 0, 0, 0.7);
  color: #FFF;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  width: 4.0005rem;
  height: 3.745rem;
  text-align: center;
  padding-top: 9px;
  font-size: 1.75rem;
  line-height: 1;
}
.event .entry-image .entry-date span {
  display: block;
  font-size: 0.75rem;
  margin-top: 7px;
}

.single-event .event-meta .iconlist li,
.parallax .iconlist li {
  margin: 5px 0;
}

.single-event .entry-overlay,
.parallax .entry-overlay-meta {
  position: absolute;
  top: auto;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 5rem;
  background-color: rgba(0, 0, 0, 0.85);
  text-align: center;
  line-height: 5rem;
  font-size: 1.25rem;
  color: #FFF;
  z-index: 5;
}

.single-event .countdown-section {
  padding: 0 15px;
  border-left-color: rgba(255, 255, 255, 0.3);
  color: #CCC;
}
.single-event .countdown-section .countdown-amount {
  color: #FFF;
}

.parallax .entry-overlay,
.parallax .entry-overlay-meta {
  background: transparent;
  width: auto;
  text-align: right;
  left: auto;
  right: 30px;
  bottom: 30px;
  left: 0 !important;
  right: 0 !important;
  width: 90%;
  margin: 0 auto;
}
.parallax .entry-overlay-meta {
  padding: 20px;
  font-size: 0.875rem;
  text-align: left;
  right: 38px;
  bottom: 130px;
  width: 368px;
  height: auto;
  line-height: inherit;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 3px;
}
.parallax.overlay-left .entry-overlay, .parallax.overlay-left .entry-overlay-meta {
  right: auto;
  left: 30px;
}
.parallax.overlay-left .entry-overlay-meta {
  left: 38px;
}
.parallax.overlay-center .entry-overlay, .parallax.overlay-center .entry-overlay-meta {
  bottom: 100px;
  right: auto;
  left: 50%;
  margin-left: -192px;
}
.parallax.overlay-center .entry-overlay-meta {
  top: 100px;
  bottom: auto;
  margin-left: 184px;
}
.parallax .entry-overlay-meta h2, .parallax .entry-overlay-meta .h2 {
  font-size: 1.25rem;
  text-transform: uppercase;
  border-bottom: 1px dashed rgba(255, 255, 255, 0.2);
  padding-bottom: 17px;
}
.parallax .entry-overlay-meta h2 a, .parallax .entry-overlay-meta .h2 a {
  color: #FFF;
}
.parallax .entry-overlay-meta h2 a:hover, .parallax .entry-overlay-meta .h2 a:hover {
  color: #DDD;
}
.parallax .countdown {
  top: 0;
}
.parallax .countdown-section {
  border-left: 0;
  width: 4.0005rem;
  height: 4.0005rem;
  margin: 0 8px;
  font-size: 13px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 3px;
  padding-top: 14px;
}
.parallax .countdown-amount {
  font-size: 28px;
  margin-bottom: 6px;
}

/*-----------------------------------------------------------------------------------

	quicklinks.scss

-----------------------------------------------------------------------------------*/
.quicklinks, .quicklinks ul {
  list-style-type: none;
}

.quicklinks li {
  position: relative;
  border-top: 1px solid #ededed;
}

.quicklinks li > span {
  position: absolute;
  right: 6px;
  top: 6px;
  cursor: pointer;
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transform-origin: center;
  padding: 6px;
}

.quicklinks li ul {
  padding-left: 1rem;
  display: none;
}

.quicklinks li:hover > span {
  color: #f7d401;
}

.quicklinks li:hover > a {
  background: #287f31;
  color: #f7d401;
  text-decoration: none;
}

.quicklinks a {
  text-transform: capitalize;
  display: block;
  transition: margin 0.4s;
  padding: 13px 25px 9px 0;
  color: #555;
  padding-left: 12px;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.quicklinks .active > a {
  text-decoration: none;
  color: #2a93c9 !important;
}

.quicklinks .up {
  -webkit-transform: rotate(180deg);
  transform: rotate(180deg);
  color: white;
}

.quicklinks .down {
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
}

.sidenav {
  height: 100%;
  width: 300px !important;
  position: fixed;
  z-index: 99999;
  top: 0;
  left: -300px;
  background-color: white;
  overflow-x: hidden;
  padding-top: 60px;
  transition: 0.5s;
  padding: 5rem 1.5rem 0 1.5rem !important;
  border-radius: 0 !important;
}

.sidenav .closebtn {
  position: absolute;
  top: 0;
  right: 20px;
  font-size: 36px;
  margin-left: 50px;
}

.dark-curtain {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  z-index: 999;
  display: none;
  z-index: 99999;
}

.side-menu > ul {
  list-style-type: none;
  padding: 0 0 20px;
}

.side-menu ul li {
  position: relative;
}

.side-menu ul li > a div {
  padding: 15px 50px 15px 0px;
}

.side-menu ul li > a {
  border: none;
  padding: 0;
  display: block;
  text-transform: capitalize;
  font-family: "Lato", sans-serif;
  color: #2C2C2C;
  text-decoration: none;
  border-bottom: 1px solid #E5E5E5;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  font-size: 16px;
  font-weight: 500;
  letter-spacing: var(--primary-menu-font-spacing);
  text-transform: none;
  line-height: 1.5;
}

.side-menu ul li.active > a div:after {
  transform: translateX(0);
  opacity: 1;
}

.side-menu ul li.active > a,
.side-menu ul li.active > a div i {
  color: #2a93c9 !important;
}

.side-menu ul li > a div {
  position: relative;
}

.side-menu ul li > a div:after {
  content: "";
  position: absolute;
  z-index: -1;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.3;
  transform: scale3d(0, 1, 1);
  transform-origin: 0% 50%;
  transition: transform 0.5s;
  transition-timing-function: cubic-bezier(0.2, 1, 0.3, 1);
  background: white;
}

.side-menu ul li:hover > a div:after {
  transform: scale3d(1, 1, 1);
}

.side-menu ul li:hover > a {
  color: #2a93c9;
}

.side-menu ul li > ul {
  display: block;
}

.side-menu ul ul {
  padding: 0;
  list-style-type: none;
}

.side-menu ul ul li > a div {
  padding: 15px 50px 15px 15px;
}

.side-menu ul ul li > a {
  background-color: transparent;
  font-size: var(--primary-menu-font-size);
  font-weight: 500;
  letter-spacing: var(--primary-menu-font-spacing);
  text-transform: none;
}

.side-menu ul ul li ul li > a div {
  padding: 15px 50px 15px 30px;
}

.side-menu ul ul li ul li ul li > a {
  padding: 14px 50px 14px 60px;
}

.side-menu .dropdown-append {
  width: 49px;
  height: 39px;
  line-height: 39px;
  position: absolute;
  top: 7px;
  right: 0;
  cursor: pointer;
}

.side-menu .dropdown-append:before, .side-menu .dropdown-append:after {
  position: absolute;
  content: "";
  top: 18px;
  right: 18px;
  width: 13px;
  height: 1px;
  background-color: #555;
}

.side-menu .dropdown-append:after {
  -webkit-transform: rotate(90deg);
  transform: rotate(90deg);
}

.side-menu .dropdown-append.dropdown-open:after {
  display: none;
}

.side-menu.menu-category ul li > a {
  padding: 0;
  border-color: transparent;
}

.side-menu.menu-category ul li:hover > a {
  color: #EA891B !important;
}

.side-menu.menu-category ul ul li > a {
  font-size: 1em;
}

.side-menu.menu-category .dropdown-append {
  height: 31px;
  width: 31px;
  line-height: 31px;
}

.side-menu.menu-category .dropdown-append:before, .side-menu.menu-category .dropdown-append:after {
  top: 15px;
  right: 10px;
}

.sidenav li li:first-child a {
  padding-top: 0 !important;
}

.sidenav li li a {
  padding: 0 !important;
  border-bottom: 1px solid #e5e5e5 !important;
}

.sidenav li li:last-child a {
  padding-bottom: 0px !important;
}

/*-----------------------------------------------------------------------------------
	shortcodes
-----------------------------------------------------------------------------------*/
/*-----------------------------------------------------------------------------------

	Shortcodes: countdown.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------

	Countdown

-----------------------------------------------------------------*/
.countdown {
  position: relative;
  display: block;
  /* Countdown - Inline
  -----------------------------------------------------------------*/
}
.countdown-row {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  -ms-flex-pack: center;
  justify-content: center;
  text-align: center;
}
.countdown-section {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
  font-size: 11px;
  line-height: 1;
  text-align: center;
  border-left: 1px dotted #bbbbbb;
  color: #888;
  text-transform: capitalize;
  /* Countdown - Medium */
  /* Countdown - large */
}
.countdown-medium .countdown-section {
  font-size: 0.875rem;
}
.countdown-large .countdown-section {
  font-size: 18px;
}
.countdown-section:first-child {
  border-left: 0;
}
.countdown-amount {
  display: block;
  font-size: 1.25rem;
  color: #333;
  margin-bottom: 5px;
  /* Countdown - Medium */
  /* Countdown - large */
}
.countdown-medium .countdown-amount {
  font-size: 30px;
  margin-bottom: 7px;
}
.countdown-large .countdown-amount {
  font-size: 44px;
  font-weight: 700;
  margin-bottom: 8px;
}
.countdown-descr {
  display: block;
  width: 100%;
}
.countdown.countdown-inline {
  display: inline-block;
}
.countdown.countdown-inline .countdown-row {
  display: inline-block;
  text-align: center;
}
.countdown.countdown-inline .countdown-section {
  display: inline-block;
  font-size: inherit;
  line-height: inherit;
  width: auto;
  border: none;
  color: inherit;
  margin-left: 7px;
  text-transform: inherit;
}
.countdown.countdown-inline .countdown-section:first-child {
  margin-left: 0;
}
.countdown.countdown-inline .countdown-amount {
  display: inline-block;
  font-size: inherit;
  color: inherit;
  font-weight: bold;
  margin: 0 3px 0 0;
}
.countdown.countdown-inline .countdown-descr {
  display: inline-block;
  width: auto;
}

/* Countdown - Coming Soon
-----------------------------------------------------------------*/
@media (min-width: 768px) {
  .countdown-large.coming-soon .countdown-section {
    border: none;
    padding: 15px;
  }
  .countdown-large.coming-soon .countdown-amount {
    width: 140px;
    height: 140px;
    line-height: 140px;
    margin-left: auto;
    margin-right: auto;
    border-radius: 50%;
    background-color: rgba(0, 0, 0, 0.2);
    margin-bottom: 15px !important;
  }
}
/*-----------------------------------------------------------------------------------

	Shortcodes: buttons.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Buttons
-----------------------------------------------------------------*/
.button {
  display: inline-block;
  position: relative;
  cursor: pointer;
  outline: none;
  white-space: nowrap;
  margin: 5px;
  padding: 8px 22px;
  font-size: 0.875rem;
  line-height: 24px;
  background-color: #2a93c9;
  color: #fff;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  border: none;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  /* Buttons - Border
  -----------------------------------------------------------------*/
  /* Buttons - 3D
  -----------------------------------------------------------------*/
  /* Buttons - Icon Reveal
  -----------------------------------------------------------------*/
}
.button i {
  position: relative;
  margin-right: 5px;
  width: 16px;
  text-align: center;
}
.button.text-end i {
  margin: 0 0 0 5px;
}
body:not(.device-touch) .button {
  transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  body:not(.device-touch) .button {
    transition: none;
  }
}
.button.button-dark {
  background-color: #333333;
}
.button.button-dark:hover {
  background-color: #2a93c9;
}
.button.button-light {
  background-color: #eeeeee;
  color: #333333;
  text-shadow: none !important;
}
.button:hover {
  background-color: #444;
  color: #FFF;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}
.button-mini {
  padding: 4px 14px;
  font-size: 11px;
  line-height: 20px;
}
.button-mini i {
  margin-right: 3px;
}
.button-mini.text-end i {
  margin: 0 0 0 3px;
}
.button-small {
  padding: 7px 17px;
  font-size: 12px;
  line-height: 20px;
}
.button-small i {
  margin-right: 4px;
}
.button-small.text-end i {
  margin: 0 0 0 4px;
}
.button-large {
  padding: 8px 26px;
  font-size: 16px;
  line-height: 30px;
}
.button-xlarge {
  padding: 9px 32px;
  font-size: 18px;
  letter-spacing: 2px;
  line-height: 34px;
}
.button-xlarge i {
  margin-right: 8px;
}
.button-xlarge.text-end i {
  margin: 0 0 0 8px;
}
.button-desc {
  text-align: left;
  padding: 24px 34px;
  font-size: 1.25rem;
  height: auto;
  line-height: 1;
  font-family: "Poppins", sans-serif;
}
.button-desc span {
  display: block;
  margin-top: 10px;
  font-size: 0.875rem;
  font-weight: 400;
  letter-spacing: 1px;
  font-family: "PT Serif", serif;
  font-style: italic;
  text-transform: none;
}
.button-desc i {
  font-size: 3rem;
  width: 3rem;
  text-align: center;
  margin-right: 12px;
}
.button-desc.text-end i {
  margin: 0 0 0 12px;
}
.button-desc div {
  display: inline-block;
}
.button-rounded {
  border-radius: 3px;
}
.button.button-border {
  border: 2px solid #333333;
  background: transparent;
  color: #222222;
  font-weight: 700;
  text-shadow: none;
}
.button.button-border-thin {
  border-width: 1px;
}
.button.button-border.button-desc {
  line-height: 1;
}
.button.button-border:not(.button-fill):hover {
  background-color: #333333;
  color: #FFF;
  border-color: transparent !important;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}
.button.button-border.button-light {
  border-color: #FFF;
  color: #FFF;
}
.button.button-border.button-light:hover {
  background-color: #FFF;
  color: #222222;
  text-shadow: none;
}
.button.button-3d {
  border-radius: 3px;
  box-shadow: inset 0 -3px 0 rgba(0, 0, 0, 0.15);
  transition: none;
}
.button.button-3d:hover {
  background-color: #2a93c9;
  opacity: 0.9;
}
.button.button-3d.button-light:hover, .button.button-reveal.button-light:hover {
  text-shadow: none;
  color: #222222;
}
.button.button-reveal {
  padding-left: 28px;
  padding-right: 28px;
  overflow: hidden;
}
.button.button-reveal i {
  display: block;
  position: absolute;
  top: 0;
  left: -32px;
  width: 32px;
  height: 100%;
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0;
  background-color: rgba(0, 0, 0, 0.1);
}
.button.button-reveal.button-border i {
  top: -2px;
}
.button.button-reveal.text-end i {
  left: auto;
  right: -32px;
}
.button.button-reveal.button-3d i, .button.button-reveal.button-rounded i {
  border-radius: 3px 0 0 3px;
}
.button.button-reveal.button-3d i.text-end i, .button.button-reveal.button-rounded i.text-end i {
  border-radius: 0 3px 3px 0;
}
.button.button-reveal span {
  display: inline-block;
  position: relative;
  left: 0;
}
.button.button-reveal.button-mini {
  padding-left: 17px;
  padding-right: 17px;
}
.button.button-reveal.button-mini i {
  left: -22px;
  width: 22px;
}
.button.button-reveal.button-mini.text-end i {
  left: auto;
  right: -22px;
}
.button.button-reveal.button-mini:hover span {
  left: 11px;
}
.button.button-reveal.button-mini.text-end:hover span {
  left: -11px;
}
.button.button-reveal.button-small {
  padding-left: 22px;
  padding-right: 22px;
}
.button.button-reveal.button-small i {
  left: -26px;
  width: 26px;
}
.button.button-reveal.button-small.text-end i {
  left: auto;
  right: -26px;
}
.button.button-reveal.button-small:hover span {
  left: 13px;
}
.button.button-reveal.button-small.text-end:hover span {
  left: -13px;
}
.button.button-reveal.button-large {
  padding-left: 32px;
  padding-right: 32px;
}
.button.button-reveal.button-large i {
  left: -38px;
  width: 38px;
}
.button.button-reveal.button-large.text-end i {
  left: auto;
  right: -38px;
}
.button.button-reveal.button-large:hover span {
  left: 19px;
}
.button.button-reveal.button-large.text-end:hover span {
  left: -19px;
}
.button.button-reveal.button-xlarge {
  padding-right: 40px;
  padding-left: 40px;
}
.button.button-reveal.button-xlarge i {
  left: -44px;
  width: 44px;
}
.button.button-reveal.button-xlarge.text-end i {
  left: auto;
  right: -44px;
}
.button.button-reveal.button-xlarge:hover span {
  left: 22px;
}
.button.button-reveal.button-xlarge.text-end:hover span {
  left: -22px;
}
.button.button-reveal:hover i {
  left: 0;
}
.button.button-reveal.text-end:hover i {
  left: auto;
  right: 0;
}
.button.button-reveal:hover span {
  left: 16px;
}
.button.button-reveal.text-end:hover span {
  left: -16px;
}

body:not(.device-touch) .button-reveal i,
body:not(.device-touch) .button-reveal span {
  transition: left 0.3s ease, right 0.3s ease;
}
@media (prefers-reduced-motion: reduce) {
  body:not(.device-touch) .button-reveal i,
body:not(.device-touch) .button-reveal span {
    transition: none;
  }
}

/* Buttons - Promo 100% Width
-----------------------------------------------------------------*/
.button.button-full {
  display: block;
  width: 100%;
  white-space: normal;
  margin: 0;
  height: auto;
  line-height: 1.6;
  padding: 30px 0;
  font-size: 1.8725rem;
  font-weight: 300;
  text-transform: none;
  border-radius: 0;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
}
.button.button-full.button-light {
  border-bottom: 1px solid rgba(0, 0, 0, 0.15);
}
.button.button-full.button-light strong {
  border-bottom-color: #333333;
}
.button.button-full.button-light:hover strong {
  border-bottom-color: #eeeeee;
}
.button.button-full strong {
  font-weight: 700;
  border-bottom: 2px solid #eeeeee;
}
body:not(.device-touch) .button.button-full strong {
  transition: all 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  body:not(.device-touch) .button.button-full strong {
    transition: none;
  }
}

/* Buttons - Circle
-----------------------------------------------------------------*/
.button.button-circle {
  border-radius: 20px;
}
.button.button-circle.button-mini {
  border-radius: 14px;
}
.button.button-circle.button-small {
  border-radius: 17px;
}
.button.button-circle.button-large {
  border-radius: 23px;
}
.button.button-circle.button-xlarge {
  border-radius: 26px;
}

/* Buttons - Fill Effect
-----------------------------------------------------------------*/
.button.button-border.button-fill {
  overflow: hidden;
  transform-style: preserve-3d;
  -webkit-mask-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAIAAACQd1PeAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAA5JREFUeNpiYGBgAAgwAAAEAAGbA+oJAAAAAElFTkSuQmCC);
  -webkit-backface-visibility: hidden;
}
.button.button-border.button-fill span {
  position: relative;
}
.button.button-border.button-fill:hover {
  background: transparent !important;
  color: #FFF;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}
.button.button-border.button-fill.button-light:hover {
  border-color: #eeeeee !important;
  color: #333333 !important;
  text-shadow: none;
}
.button.button-border.button-fill.button-light ::before {
  background-color: #eeeeee;
}
.button.button-border.button-fill::before {
  content: "";
  position: absolute;
  background-color: #333333;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  transition: all 0.4s ease;
  -webkit-backface-visibility: hidden;
}
@media (prefers-reduced-motion: reduce) {
  .button.button-border.button-fill::before {
    transition: none;
  }
}
.button.button-border.button-fill::before ::before {
  width: 100%;
}
.button.button-border.button-fill.fill-from-right::before {
  left: auto;
  right: 0;
}
.button.button-border.button-fill:hover::before {
  width: 100%;
}
.button.button-border.button-fill.fill-from-top::before, .button.button-border.button-fill.fill-from-bottom::before {
  transition: all 0.3s ease;
}
@media (prefers-reduced-motion: reduce) {
  .button.button-border.button-fill.fill-from-top::before, .button.button-border.button-fill.fill-from-bottom::before {
    transition: none;
  }
}
.button.button-border.button-fill.fill-from-top::before {
  width: 100%;
  height: 0;
}
.button.button-border.button-fill.fill-from-top:hover::before {
  height: 100%;
}
.button.button-border.button-fill.fill-from-bottom::before {
  width: 100%;
  height: 0;
  top: auto;
  bottom: 0;
}
.button.button-border.button-fill.fill-from-bottom:hover::before {
  height: 100%;
}

/* Buttons - Colors
-----------------------------------------------------------------*/
.button-red {
  background-color: #C02942;
}
.button-red.button-3d:hover, .button-red.button-reveal:hover, .button-red.button-border:hover, .button-red.button-border.button-fill::before {
  background-color: #C02942 !important;
}

.button-border.button-red, .button-border.button-light.button-red {
  color: #C02942;
  border-color: #C02942;
}

.button-teal {
  background-color: #53777A;
}
.button-teal.button-3d:hover, .button-teal.button-reveal:hover, .button-teal.button-border:hover, .button-teal.button-border.button-fill::before {
  background-color: #53777A !important;
}

.button-border.button-teal, .button-border.button-light.button-teal {
  color: #53777A;
  border-color: #53777A;
}

.button-yellow {
  background-color: #ECD078;
}
.button-yellow.button-3d:hover, .button-yellow.button-reveal:hover, .button-yellow.button-border:hover, .button-yellow.button-border.button-fill::before {
  background-color: #ECD078 !important;
}

.button-border.button-yellow, .button-border.button-light.button-yellow {
  color: #ECD078;
  border-color: #ECD078;
}

.button-purple {
  background-color: #5D4157;
}
.button-purple.button-3d:hover, .button-purple.button-reveal:hover, .button-purple.button-border:hover, .button-purple.button-border.button-fill::before {
  background-color: #5D4157 !important;
}

.button-border.button-purple, .button-border.button-light.button-purple {
  color: #5D4157;
  border-color: #5D4157;
}

.button-pink {
  background-color: #F89FA1;
}
.button-pink.button-3d:hover, .button-pink.button-reveal:hover, .button-pink.button-border:hover, .button-pink.button-border.button-fill::before {
  background-color: #F89FA1 !important;
}

.button-border.button-pink, .button-border.button-light.button-pink {
  color: #F89FA1;
  border-color: #F89FA1;
}

.button-blue {
  background-color: #1265A8;
}
.button-blue.button-3d:hover, .button-blue.button-reveal:hover, .button-blue.button-border:hover, .button-blue.button-border.button-fill::before {
  background-color: #1265A8 !important;
}

.button-border.button-blue, .button-border.button-light.button-blue {
  color: #1265A8;
  border-color: #1265A8;
}

.button-green {
  background-color: #59BA41;
}
.button-green.button-3d:hover, .button-green.button-reveal:hover, .button-green.button-border:hover, .button-green.button-border.button-fill::before {
  background-color: #59BA41 !important;
}

.button-border.button-green, .button-border.button-light.button-green {
  color: #59BA41;
  border-color: #59BA41;
}

.button-brown {
  background-color: #774F38;
}
.button-brown.button-3d:hover, .button-brown.button-reveal:hover, .button-brown.button-border:hover, .button-brown.button-border.button-fill::before {
  background-color: #774F38 !important;
}

.button-border.button-brown, .button-border.button-light.button-brown {
  color: #774F38;
  border-color: #774F38;
}

.button-aqua {
  background-color: #40C0CB;
}
.button-aqua.button-3d:hover, .button-aqua.button-reveal:hover, .button-aqua.button-border:hover, .button-aqua.button-border.button-fill::before {
  background-color: #40C0CB !important;
}

.button-border.button-aqua, .button-border.button-light.button-aqua {
  color: #40C0CB;
  border-color: #40C0CB;
}

.button-lime {
  background-color: #AEE239;
}
.button-lime.button-3d:hover, .button-lime.button-reveal:hover, .button-lime.button-border:hover, .button-lime.button-border.button-fill::before {
  background-color: #AEE239 !important;
}

.button-border.button-lime, .button-border.button-light.button-lime {
  color: #AEE239;
  border-color: #AEE239;
}

.button-leaf {
  background-color: #A8CABA;
}
.button-leaf.button-3d:hover, .button-leaf.button-reveal:hover, .button-leaf.button-border:hover, .button-leaf.button-border.button-fill::before {
  background-color: #A8CABA !important;
}

.button-border.button-leaf, .button-border.button-light.button-leaf {
  color: #A8CABA;
  border-color: #A8CABA;
}

.button-dirtygreen {
  background-color: #1693A5;
}
.button-dirtygreen.button-3d:hover, .button-dirtygreen.button-reveal:hover, .button-dirtygreen.button-border:hover, .button-dirtygreen.button-border.button-fill::before {
  background-color: #1693A5 !important;
}

.button-border.button-dirtygreen, .button-border.button-light.button-dirtygreen {
  color: #1693A5;
  border-color: #1693A5;
}

.button-amber {
  background-color: #EB9C4D;
}
.button-amber.button-3d:hover, .button-amber.button-reveal:hover, .button-amber.button-border:hover, .button-amber.button-border.button-fill::before {
  background-color: #EB9C4D !important;
}

.button-border.button-amber, .button-border.button-light.button-amber {
  color: #EB9C4D;
  border-color: #EB9C4D;
}

.button-black {
  background-color: #111;
}
.button-black.button-3d:hover, .button-black.button-reveal:hover, .button-black.button-border:hover, .button-black.button-border.button-fill::before {
  background-color: #111 !important;
}

.button-border.button-black, .button-border.button-light.button-black {
  color: #111;
  border-color: #111;
}

.button-light.button-yellow {
  background-color: #ECD078;
}

.button-border.button-yellow:hover,
.button-border.button-yellow.button-fill.button-light:hover {
  color: #333 !important;
}

.button-white {
  background-color: #F9F9F9;
}

.button-3d.button-white:hover,
.button-reveal.button-white:hover {
  background-color: #F9F9F9 !important;
}

/* Buttons - No Hover
-----------------------------------------------------------------*/
.button.button-nohover:hover {
  opacity: inherit !important;
  background-color: inherit !important;
  color: inherit !important;
  border-color: inherit !important;
}

/*-----------------------------------------------------------------------------------

	Shortcodes: promo.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Promo Boxes
-----------------------------------------------------------------*/
.promo {
  position: relative;
  /* Promo - Light
  -----------------------------------------------------------------*/
  /* Promo - Dark
  -----------------------------------------------------------------*/
  /* Promo - 100% Full Width
  -----------------------------------------------------------------*/
  /* Promo - Parallax
  -----------------------------------------------------------------*/
  /* Promo - Mini
  -----------------------------------------------------------------*/
}
.promo h3, .promo .h3 {
  font-weight: bold;
  margin-bottom: 0;
}
.promo h3 + span, .promo .h3 + span {
  display: block;
  color: #444;
  font-weight: 300;
  font-size: 1.125rem;
  margin-top: 6px;
}
.promo-uppercase {
  text-transform: uppercase;
}
.promo-uppercase h3, .promo-uppercase .h3 {
  font-size: 1.25rem;
}
.promo-uppercase h3 + span, .promo-uppercase .h3 + span {
  font-size: 1rem;
}
.promo-border {
  border: 1px solid #e5e5e5;
  border-radius: 3px;
}
.promo-light {
  background-color: whitesmoke;
  border-radius: 3px;
}
.promo-dark {
  border: 0 !important;
  background-color: #333333;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  border-radius: 3px;
}
.promo-dark h3, .promo-dark .h3 {
  color: #fff;
}
.promo-dark h3 + span, .promo-dark .h3 + span {
  color: #EEE;
  font-weight: 400;
}
.promo-full {
  border-radius: 0 !important;
  border-left: 0 !important;
  border-right: 0 !important;
}
.promo.parallax {
  padding: 80px 0 !important;
}
.promo-mini {
  text-align: left;
}
.promo-mini h3, .promo-mini .h3 {
  font-size: 1.25rem;
}
.promo-mini.promo-uppercase h3, .promo-mini.promo-uppercase .h3 {
  font-size: 1rem;
}

/*-----------------------------------------------------------------------------------

	Shortcodes: feature-box.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Featured Boxes
-----------------------------------------------------------------*/
.feature-box {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-left: -0.75rem;
  margin-right: -0.75rem;
}
.feature-box:first-child {
  margin-top: 0;
}

.fbox {
  /* Feature Box - Icon Small
  -----------------------------------------------------------------*/
  /* Feature Box - Icon Large
  -----------------------------------------------------------------*/
  /* Feature Box - Icon X-Large
  -----------------------------------------------------------------*/
  /* Feature Box - Icon Outline
  -----------------------------------------------------------------*/
  /* Feature Box - Icon Rounded
  -----------------------------------------------------------------*/
  /* Feature Box - Light Background
  -----------------------------------------------------------------*/
  /* Feature Box - Dark Background
  -----------------------------------------------------------------*/
  /* Feature Box - Border
  -----------------------------------------------------------------*/
  /* Feature Box - Plain
  -----------------------------------------------------------------*/
  /* Feature Box - Center
  -----------------------------------------------------------------*/
  /* Feature Box - Subtitle
  -----------------------------------------------------------------*/
  /* Feature Box - Background
  -----------------------------------------------------------------*/
}
.fbox-icon {
  width: 5.5rem;
  height: 4rem;
  padding: 0 0.75rem;
}
.fbox-icon a,
.fbox-icon i,
.fbox-icon img {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
  color: #FFF;
}
.fbox-icon i,
.fbox-icon img {
  border-radius: 50%;
  background-color: #2a93c9;
}
.fbox-icon i {
  font-style: normal;
  font-size: 1.75rem;
  line-height: 4rem;
  text-align: center;
}
.fbox-content {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  min-width: 0;
  max-width: 100%;
  padding: 0 0.75rem;
}
.fbox-content h3, .fbox-content .h3 {
  font-size: 1rem;
  font-weight: 600;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  margin-bottom: 0;
  color: #333333;
}
.fbox-content p {
  margin-top: 0.5rem;
  margin-bottom: 0px;
  color: #999;
}
.fbox-content .before-heading {
  font-size: 0.875rem;
}
.fbox-content-sm h3, .fbox-content-sm .h3,
.fbox-content-sm p {
  font-size: 0.875rem;
}
.fbox-content-sm .before-heading {
  font-size: 0.75rem;
}
.fbox-content-lg h3, .fbox-content-lg .h3 {
  font-size: 1.25rem;
}
.fbox-content-lg p {
  font-size: 1.125rem;
  margin-top: 0.75rem;
}
.fbox-content-lg .before-heading {
  font-size: 1rem;
}
.fbox-sm {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}
.fbox-sm .fbox-icon {
  width: 3rem;
  height: 2rem;
  padding: 0 0.5rem;
}
.fbox-sm .fbox-icon i {
  font-size: 1rem;
  line-height: 2rem;
}
.fbox-sm .fbox-content {
  padding: 0 0.5rem;
}
.fbox-lg .fbox-icon {
  width: 7.5rem;
  height: 6rem;
}
.fbox-lg .fbox-icon i {
  font-size: 2.5rem;
  line-height: 6rem;
}
.fbox-xl .fbox-icon {
  width: 9.5rem;
  height: 8rem;
}
.fbox-xl .fbox-icon i {
  font-size: 4.5rem;
  line-height: 8rem;
}
.fbox-outline {
  /* Feature Box - Icon Outline Sizes
  -----------------------------------------------------------------*/
}
.fbox-outline .fbox-icon a {
  border: 1px solid #2a93c9;
  padding: 0.25rem;
  border-radius: 50%;
}
.fbox-outline .fbox-icon i {
  line-height: 3.5rem;
}
.fbox-outline.fbox-sm .fbox-icon i {
  line-height: 1.5rem;
}
.fbox-outline.fbox-lg .fbox-icon i {
  line-height: 5.5rem;
}
.fbox-outline.fbox-xl .fbox-icon i {
  line-height: 7.5rem;
}
.fbox-rounded {
  /* Feature Box - Icon Rounded Sizes
  -----------------------------------------------------------------*/
}
.fbox-rounded .fbox-icon {
  border-radius: 3px !important;
}
.fbox-rounded .fbox-icon i,
.fbox-rounded .fbox-icon img {
  border-radius: 3px !important;
}
.fbox-rounded .fbox-lg .fbox-icon {
  border-radius: 4px !important;
}
.fbox-rounded .fbox-lg .fbox-icon i,
.fbox-rounded .fbox-lg .fbox-icon img {
  border-radius: 4px !important;
}
.fbox-rounded .fbox-xl .fbox-icon {
  border-radius: 6px !important;
}
.fbox-rounded .fbox-xl .fbox-icon i,
.fbox-rounded .fbox-xl .fbox-icon img {
  border-radius: 6px !important;
}
.fbox-light.fbox-outline .fbox-icon a {
  border-color: #e5e5e5;
}
.fbox-light .fbox-icon i,
.fbox-light .fbox-icon img {
  border: 1px solid #e5e5e5;
  background-color: whitesmoke;
  color: #444;
}
.fbox-dark.fbox-outline .fbox-icon a {
  border-color: #4e4e4e;
}
.fbox-dark.fbox-outline .fbox-icon i,
.fbox-dark.fbox-outline .fbox-icon img {
  background-color: #3d3d3d;
}
.fbox-border {
  /* Feature Box - Border - Light
  -----------------------------------------------------------------*/
  /* Feature Box - Border - Light / Dark
  -----------------------------------------------------------------*/
}
.fbox-border .fbox-icon a {
  border: 1px solid #2a93c9;
  border-radius: 50%;
}
.fbox-border .fbox-icon i,
.fbox-border .fbox-icon img {
  border: none;
  background-color: transparent !important;
  color: #2a93c9;
}
.fbox-border.fbox-light .fbox-icon a {
  border-color: #e5e5e5;
}
.fbox-border.fbox-light .fbox-icon i,
.fbox-border.fbox-light .fbox-icon img {
  color: #888;
}
.fbox-border.fbox-dark .fbox-icon a {
  border-color: #4e4e4e;
}
.fbox-border.fbox-dark .fbox-icon i,
.fbox-border.fbox-dark .fbox-icon img {
  color: #444;
}
.fbox-plain {
  /* Feature Box - Icon Plain Sizes
  -----------------------------------------------------------------*/
}
.fbox-plain .fbox-icon {
  border: none !important;
  height: auto !important;
}
.fbox-plain .fbox-icon i,
.fbox-plain .fbox-icon img {
  border: none;
  background-color: transparent;
  color: #2a93c9;
  border-radius: 0;
}
.fbox-plain .fbox-icon i {
  font-size: 3rem;
  line-height: 1;
}
.fbox-plain .fbox-icon img {
  height: auto;
}
.fbox-plain.fbox-image .fbox-icon {
  width: auto;
}
.fbox-plain.fbox-image .fbox-icon img {
  width: auto;
  display: inline-block;
}
.fbox-plain.fbox-light .fbox-icon i,
.fbox-plain.fbox-light .fbox-icon img {
  color: #888;
}
.fbox-plain.fbox-dark .fbox-icon i,
.fbox-plain.fbox-dark .fbox-icon img {
  color: #444;
}
.fbox-plain.fbox-sm .fbox-icon i {
  font-size: 2rem;
}
.fbox-plain.fbox-lg .fbox-icon i {
  font-size: 4.5rem;
}
.fbox-plain.fbox-xl .fbox-icon i {
  font-size: 6rem;
}
.fbox-center {
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-align: center;
  align-items: center;
  text-align: center;
}
.fbox-center .fbox-icon {
  margin-bottom: 1.5rem;
}
.fbox-content h3 + .fbox-icon, .fbox-content .h3 + .fbox-icon {
  margin-top: 1.5rem;
  margin-bottom: 0 !important;
}
.fbox-content h3 span.subtitle, .fbox-content .h3 span.subtitle {
  display: block;
  margin-top: 5px;
  color: #444;
  font-weight: 300;
  text-transform: none;
}
.fbox-center.fbox-sep:not(.fbox-bg) h3::after, .fbox-center.fbox-sep:not(.fbox-bg) .h3::after {
  content: "";
  display: block;
  margin: 1.25rem auto;
  width: 2rem;
  height: 2px;
  background-color: #555;
  -webkit-transition: width 0.3s ease;
  -o-transition: width 0.3s ease;
  transition: width 0.3s ease;
}
.fbox-center.fbox-sep:not(.fbox-bg):hover h3::after, .fbox-center.fbox-sep:not(.fbox-bg):hover .h3::after {
  width: 3rem;
}
.fbox-center.fbox-italic p {
  font-style: italic;
  font-family: "Lato", sans-serif;
}
.fbox-bg.fbox-center {
  margin-top: 2rem;
  margin-left: 0;
  margin-right: 0;
  padding: 0 2rem 2rem;
  border: 1px solid whitesmoke;
  border-radius: 5px;
}
.fbox-bg.fbox-center .fbox-icon {
  margin-top: -2rem;
  background-color: #FFF;
}
.fbox-bg.fbox-center .fbox-lg {
  margin-top: 3rem;
}
.fbox-bg.fbox-center .fbox-lg .fbox-icon {
  margin-top: -3rem;
}
.fbox-bg.fbox-center .fbox-xl {
  margin-top: 4rem;
}
.fbox-bg.fbox-center .fbox-xl .fbox-icon {
  margin-top: -4rem;
}

/* Animated Icon Hovers
-----------------------------------------------------------------*/
.fbox-effect .fbox-icon i {
  z-index: 1;
}
.fbox-effect .fbox-icon i::after {
  pointer-events: none;
  position: absolute;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  content: "";
  -webkit-box-sizing: content-box;
  -moz-box-sizing: content-box;
  box-sizing: content-box;
}

.fbox-rounded.fbox-effect .fbox-icon i::after {
  border-radius: 3px;
}

.fbox-effect .fbox-icon i {
  -webkit-transition: background-color 0.3s, color 0.3s;
  -o-transition: background-color 0.3s, color 0.3s;
  transition: background-color 0.3s, color 0.3s;
}
.fbox-effect .fbox-icon i::after {
  top: -3px;
  left: -3px;
  padding: 3px;
  box-shadow: 0 0 0 2px #3d3d3d;
  -webkit-transition: -webkit-transform 0.3s, opacity 0.3s;
  -webkit-transform: scale(0.8);
  -moz-transition: -moz-transform 0.3s, opacity 0.3s;
  -moz-transform: scale(0.8);
  -ms-transform: scale(0.8);
  transition: transform 0.3s, opacity 0.3s;
  transform: scale(0.8);
  opacity: 0;
}
.fbox-effect .fbox-icon i:hover, .fbox-effect:hover .fbox-icon i {
  background-color: #3d3d3d;
  color: #FFF;
}
.fbox-effect.fbox-dark .fbox-icon i::after {
  box-shadow: 0 0 0 2px #2a93c9;
}
.fbox-effect.fbox-dark .fbox-icon i:hover, .fbox-effect.fbox-dark:hover .fbox-icon i {
  background-color: #2a93c9;
}
.fbox-effect .fbox-icon i:hover::after, .fbox-effect:hover .fbox-icon i::after {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

/* Icon Effects - Bordered
-----------------------------------------------------------------*/
.fbox-border.fbox-effect .fbox-icon i {
  -webkit-transition: color 0.5s, box-shadow 0.5s, background-color 0.5s;
  -o-transition: color 0.5s, box-shadow 0.5s, background-color 0.5s;
  transition: color 0.5s, box-shadow 0.5s, background-color 0.5s;
}
.fbox-border.fbox-effect .fbox-icon i::after {
  top: -2px;
  left: -2px;
  padding: 2px;
  z-index: -1;
  box-shadow: none;
  background-image: url("images/icons/iconalt.svg");
  background-position: center center;
  background-size: 100% 100%;
  -webkit-transition: -webkit-transform 0.5s, opacity 0.5s, background-color 0.5s;
  -o-transition: -moz-transform 0.5s, opacity 0.5s, background-color 0.5s;
  transition: transform 0.5s, opacity 0.5s, background-color 0.5s;
}
.fbox-border.fbox-rounded.fbox-effect .fbox-icon i::after {
  border-radius: 3px;
}
.fbox-border.fbox-effect .fbox-icon i:hover, .fbox-border.fbox-effect:hover .fbox-icon i {
  color: #FFF;
  box-shadow: 0 0 0 1px #3d3d3d;
}
.fbox-border.fbox-effect .fbox-icon i::after {
  -webkit-transform: scale(1.3);
  -moz-transform: scale(1.3);
  -ms-transform: scale(1.3);
  transform: scale(1.3);
  opacity: 0;
  background-color: #3d3d3d;
}
.fbox-border.fbox-effect.fbox-dark .fbox-icon i:hover, .fbox-border.fbox-effect.fbox-dark:hover .fbox-icon i {
  box-shadow: 0 0 0 1px #2a93c9;
}
.fbox-border.fbox-effect.fbox-dark .fbox-icon i::after {
  background-color: #2a93c9;
}
.fbox-border.fbox-effect .fbox-icon i:hover::after, .fbox-border.fbox-effect:hover .fbox-icon i::after {
  -webkit-transform: scale(1);
  -moz-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  opacity: 1;
}

/* Media Featured Box
-----------------------------------------------------------------*/
.media-box {
  padding: 0;
  margin-left: 0;
  margin-right: 0;
  -ms-flex-direction: column;
  flex-direction: column;
}
.media-box .fbox-media {
  margin: 0 0 1.5rem;
  position: relative;
  display: block;
  width: 100%;
  height: auto;
}
.media-box .fbox-media a,
.media-box .fbox-media img {
  position: relative;
  display: block;
  width: 100%;
  height: auto;
}
.media-box .fbox-media iframe {
  display: block;
}
.media-box p {
  margin-top: 1rem;
}
.media-box.fbox-bg .fbox-media {
  margin: 0;
}
.media-box.fbox-bg .fbox-media img {
  border-radius: 5px 5px 0 0;
}
.media-box.fbox-bg .fbox-content {
  padding: 1.5rem;
  background-color: #FFF;
  border: 1px solid whitesmoke;
  border-top: 0;
  border-radius: 0 0 5px 5px;
}
.media-box .fbox-content {
  -ms-flex-preferred-size: auto;
}

/*-----------------------------------------------------------------------------------

	Shortcodes: process-steps.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Process Steps
-----------------------------------------------------------------*/
.process-steps {
  margin: 0 0 40px;
  list-style: none;
}
.process-steps li {
  position: relative;
  text-align: center;
  overflow: hidden;
}
.process-steps li .i-circled {
  margin-bottom: 0 !important;
}
.process-steps li .i-bordered {
  background-color: #FFF;
}
.process-steps li.active h5, .process-steps li.active .h5, .process-steps li.ui-tabs-active h5, .process-steps li.ui-tabs-active .h5 {
  font-weight: 600;
  color: #2a93c9;
}
.process-steps li.active a, .process-steps li.ui-tabs-active a {
  background-color: #2a93c9 !important;
  color: #FFF;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  border-color: #2a93c9 !important;
}
@media (min-width: 992px) {
  .process-steps li::before, .process-steps li::after {
    content: "";
    position: absolute;
    top: 30px;
    left: 0;
    margin-left: -26px;
    width: 50%;
    border-top: 1px dashed #DDD;
  }
  .process-steps li::after {
    left: auto;
    right: 0;
    margin: 0 -26px 0 0;
  }
  .process-steps li:first-child::before, .process-steps li:last-child::after {
    display: none;
  }
  .process-steps li h5, .process-steps li .h5 {
    margin: 15px 0 0 0;
    font-size: 1rem;
    font-weight: 300;
  }
}

/*-----------------------------------------------------------------------------------

	Shortcodes: alerts.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Alerts
-----------------------------------------------------------------*/
.style-msg,
.style-msg2 {
  position: relative;
  display: block;
  color: #444;
  border-left: 4px solid rgba(0, 0, 0, 0.1);
  margin-bottom: 20px;
}

.style-msg2 {
  border-left-color: rgba(0, 0, 0, 0.1);
}

.primarymsg {
  color: #084298;
  background-color: #cfe2ff;
  border-color: #b6d4fe;
}
.primarymsg .alert-link {
  color: #06357a;
}

.secondarymsg {
  color: #41464b;
  background-color: #e2e3e5;
  border-color: #d3d6d8;
}
.secondarymsg .alert-link {
  color: #34383c;
}

.successmsg {
  color: #0f5132;
  background-color: #d1e7dd;
  border-color: #badbcc;
}
.successmsg .alert-link {
  color: #0c4128;
}

.infomsg {
  color: #055160;
  background-color: #cff4fc;
  border-color: #b6effb;
}
.infomsg .alert-link {
  color: #04414d;
}

.warningmsg {
  color: #664d03;
  background-color: #fff3cd;
  border-color: #ffecb5;
}
.warningmsg .alert-link {
  color: #523e02;
}

.dangermsg {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}
.dangermsg .alert-link {
  color: #6a1a21;
}

.lightmsg {
  color: #636464;
  background-color: #fefefe;
  border-color: #fdfdfe;
}
.lightmsg .alert-link {
  color: #4f5050;
}

.darkmsg {
  color: #141619;
  background-color: #d3d3d4;
  border-color: #bcbebf;
}
.darkmsg .alert-link {
  color: #101214;
}

.errormsg {
  color: #842029;
  background-color: #f8d7da;
  border-color: #f5c2c7;
}
.errormsg .alert-link {
  color: #6a1a21;
}

.alertmsg {
  color: #997404;
  background-color: #fff3cd;
  border-color: #ffecb5;
}
.alertmsg .alert-link {
  color: #7a5d03;
}

.style-msg .sb-msg,
.style-msg2 .msgtitle,
.style-msg2 .sb-msg {
  display: block;
  padding: 1rem;
  border-left: 1px solid rgba(255, 255, 255, 0.5);
  font-size: 0.875rem;
  text-shadow: 1px 1px 1px rgba(255, 255, 255, 0.6);
}

.style-msg2 .msgtitle {
  background: rgba(0, 0, 0, 0.15);
  border-top: none;
  border-bottom: none;
  font-size: 1rem;
  font-weight: bold;
}

.style-msg-light .sb-msg,
.style-msg-light .msgtitle {
  color: #FFF;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
}

.style-msg2 .sb-msg {
  font-weight: normal;
}

.style-msg2 ol,
.style-msg2 ul {
  margin: 0 0 0 30px;
}

.style-msg2 ol {
  list-style-type: decimal;
}

.style-msg2 ul {
  list-style-type: disc;
}

.alert i,
.sb-msg i,
.msgtitle i {
  position: relative;
  top: 1px;
  font-size: 0.875rem;
  width: 0.875rem;
  text-align: center;
  margin-right: 5px;
}

.style-msg .btn-close {
  position: absolute;
  top: 0;
  left: auto;
  right: 0;
}

/*-----------------------------------------------------------------------------------

	Shortcodes: styled-icons.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Styled Icons
-----------------------------------------------------------------*/
.i-rounded,
.i-plain,
.i-circled,
.i-bordered,
.social-icon {
  display: block;
  float: left;
  margin: 4px 11px 7px 0;
  text-align: center !important;
  font-size: 28px;
  color: #fff;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  font-style: normal;
}
body:not(.device-touch) .i-rounded,
body:not(.device-touch) .i-plain,
body:not(.device-touch) .i-circled,
body:not(.device-touch) .i-bordered,
body:not(.device-touch) .social-icon {
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}

.i-rounded,
.i-circled,
.i-bordered {
  width: 52px !important;
  height: 52px !important;
  line-height: 52px !important;
  background-color: #444;
  border-radius: 3px;
}

.i-bordered {
  border: 1px solid #555555;
  line-height: 50px !important;
  background: transparent !important;
  color: #444;
  text-shadow: none;
}
.i-bordered:hover {
  background-color: #444 !important;
  color: #fff;
  border-color: #444;
}

.i-plain {
  width: 36px !important;
  height: 36px !important;
  font-size: 28px;
  line-height: 36px !important;
  color: #444;
  text-shadow: 1px 1px 1px #fff;
}
.i-plain:hover {
  color: #777;
}

.i-circled {
  border-radius: 50%;
}

.i-light {
  background-color: #F5F5F5;
  color: #fff;
  text-shadow: 1px 1px 1px #fff;
  box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.2);
}

.i-alt {
  background-image: url("images/icons/iconalt.svg");
  background-position: center center;
  background-size: 100% 100%;
}

.i-rounded:hover,
.i-circled:hover {
  background-color: #2a93c9;
  color: #fff;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
}

/* Icon Size - Small
-----------------------------------------------------------------*/
.i-small.i-rounded, .i-small.i-plain, .i-small.i-circled, .i-small.i-bordered {
  margin: 4px 11px 7px 0;
  font-size: 14px;
}
.i-small.i-rounded, .i-small.i-circled, .i-small.i-bordered {
  width: 28px !important;
  height: 28px !important;
  line-height: 28px !important;
}
.i-small.i-bordered {
  line-height: 26px !important;
}
.i-small.i-plain {
  width: 14px !important;
  height: 14px !important;
  font-size: 14px;
  line-height: 14px !important;
}

/* Icon Size - Small
-----------------------------------------------------------------*/
.i-medium.i-rounded, .i-medium.i-plain, .i-medium.i-circled, .i-medium.i-bordered {
  margin: 6px 13px 9px 0;
  font-size: 21px;
}
.i-medium.i-rounded, .i-medium.i-circled, .i-medium.i-bordered {
  width: 36px !important;
  height: 36px !important;
  line-height: 36px !important;
}
.i-medium.i-bordered {
  line-height: 34px !important;
}
.i-medium.i-plain {
  width: 28px !important;
  height: 28px !important;
  font-size: 20px;
  line-height: 28px !important;
}

/* Icon Size - Large
-----------------------------------------------------------------*/
.i-large.i-rounded, .i-large.i-plain, .i-large.i-circled, .i-large.i-bordered {
  margin: 4px 11px 7px 0;
  font-size: 42px;
}
.i-large.i-rounded, .i-large.i-circled, .i-large.i-bordered {
  width: 64px !important;
  height: 64px !important;
  line-height: 64px !important;
}
.i-large.i-bordered {
  line-height: 62px !important;
}
.i-large.i-plain {
  width: 48px !important;
  height: 48px !important;
  font-size: 42px;
  line-height: 48px !important;
}

/* Icon Size - Extra Large
-----------------------------------------------------------------*/
.i-xlarge.i-rounded, .i-xlarge.i-plain, .i-xlarge.i-circled, .i-xlarge.i-bordered {
  margin: 4px 11px 7px 0;
  font-size: 56px;
}
.i-xlarge.i-rounded, .i-xlarge.i-circled, .i-xlarge.i-bordered {
  width: 84px !important;
  height: 84px !important;
  line-height: 84px !important;
}
.i-xlarge.i-bordered {
  line-height: 82px !important;
}
.i-xlarge.i-plain {
  width: 64px !important;
  height: 64px !important;
  font-size: 56px;
  line-height: 64px !important;
}

/* Icon Size - Extra Extra Large
-----------------------------------------------------------------*/
.i-xxlarge.i-rounded, .i-xxlarge.i-plain, .i-xxlarge.i-circled, .i-xxlarge.i-bordered {
  margin: 6px 15px 10px 0;
  font-size: 64px;
}
.i-xxlarge.i-rounded, .i-xxlarge.i-circled, .i-xxlarge.i-bordered {
  width: 96px !important;
  height: 96px !important;
  line-height: 96px !important;
}
.i-xxlarge.i-bordered {
  line-height: 94px !important;
}
.i-xxlarge.i-plain {
  width: 80px !important;
  height: 80px !important;
  font-size: 64px;
  line-height: 80px !important;
}

/*-----------------------------------------------------------------------------------

	Shortcodes: social-icons.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Social Icons
-----------------------------------------------------------------*/
.social-icon {
  margin: 0 5px 5px 0;
  width: 40px;
  height: 40px;
  font-size: 1.25rem;
  line-height: 38px !important;
  color: #555555;
  text-shadow: none;
  border: 1px solid #aaaaaa;
  border-radius: 3px;
  overflow: hidden;
  /* Social Icons - Large
  -----------------------------------------------------------------*/
  /* Social Icons - Small
  -----------------------------------------------------------------*/
  /* Social Icons - Hover
  	-----------------------------------------------------------------*/
}
.d-flex > .social-icon, .d-sm-flex > .social-icon, .d-md-flex > .social-icon, .d-lg-flex > .social-icon, .d-xl-flex > .social-icon {
  float: none;
}
.social-icon i {
  display: block;
  position: relative;
}
body:not(.device-touch) .social-icon i {
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.social-icon i:last-child {
  color: #FFF !important;
}
.social-icon:hover i:first-child {
  margin-top: -38px;
}
.social-icon.si-large {
  margin: 0 10px 10px 0;
  width: 56px;
  height: 56px;
  font-size: 30px;
  line-height: 54px !important;
}
.social-icon.si-large:hover i:first-child {
  margin-top: -54px;
}
.social-icon.si-small {
  width: 32px;
  height: 32px;
  font-size: 14px;
  line-height: 30px !important;
}
.social-icon.si-small:hover i:first-child {
  margin-top: -30px;
}
.social-icon:hover {
  color: #555555;
  border-color: transparent;
}
.social-icon.si-colored {
  border-color: transparent;
}

/* Social Icons - Rounded
-----------------------------------------------------------------*/
.si-rounded {
  border-radius: 50%;
}

/* Social Icons - Borderless
-----------------------------------------------------------------*/
.si-borderless {
  border-color: transparent !important;
}

/* Social Icons - Dark
-----------------------------------------------------------------*/
.si-dark {
  background-color: #444;
  color: #fff !important;
  border-color: transparent;
}

/* Social Icons - Light
-----------------------------------------------------------------*/
.si-light {
  background-color: #EEE;
  color: #555555 !important;
  border-color: transparent;
}

/* Social Icons - Colored
-----------------------------------------------------------------*/
.si-colored {
  border-color: transparent !important;
}
.si-colored i {
  color: #FFF;
}

/* Social Icons - Sticky
-----------------------------------------------------------------*/
.si-sticky {
  position: fixed;
  top: 50%;
  left: 5px;
  width: 36px;
  z-index: 499;
  transform: translateY(-50%);
  /* Social Icons - Sticky Right
  -----------------------------------------------------------------*/
}
.si-sticky.si-sticky-right {
  left: auto;
  right: 8px;
}

/* Social Icons - Share
-----------------------------------------------------------------*/
.si-share {
  position: relative;
  padding-left: 5px;
  border-top: 1px solid #eeeeee;
  border-bottom: 1px solid #eeeeee;
}
.si-share span {
  line-height: 36px;
  font-weight: bold;
  font-size: 13px;
}
.si-share .social-icon {
  margin: -1px 0;
  border-radius: 0;
  font-size: 15px;
}

/* Social Icons - bg-colors
-----------------------------------------------------------------*/
.si-facebook:hover,
.si-colored.si-facebook {
  background-color: #3B5998 !important;
}

.si-whatsapp:hover,
.si-colored.si-whatsapp {
  background-color: #25d366 !important;
}

.si-delicious:hover,
.si-colored.si-delicious {
  background-color: #205CC0 !important;
}

.si-paypal:hover,
.si-colored.si-paypal {
  background-color: #00588B !important;
}

.si-flattr:hover,
.si-colored.si-flattr {
  background-color: #F67C1A !important;
}

.si-android:hover,
.si-colored.si-android {
  background-color: #A4C639 !important;
}

.si-smashmag:hover,
.si-colored.si-smashmag {
  background-color: #E53B2C !important;
}

.si-gplus:hover,
.si-colored.si-gplus {
  background-color: #DD4B39 !important;
}

.si-wikipedia:hover,
.si-colored.si-wikipedia {
  background-color: #333 !important;
}

.si-stumbleupon:hover,
.si-colored.si-stumbleupon {
  background-color: #F74425 !important;
}

.si-foursquare:hover,
.si-colored.si-foursquare {
  background-color: #25A0CA !important;
}

.si-call:hover,
.si-colored.si-call {
  background-color: #444 !important;
}

.si-ninetyninedesigns:hover,
.si-colored.si-ninetyninedesigns {
  background-color: #F26739 !important;
}

.si-forrst:hover,
.si-colored.si-forrst {
  background-color: #5B9A68 !important;
}

.si-digg:hover,
.si-colored.si-digg {
  background-color: #191919 !important;
}

.si-spotify:hover,
.si-colored.si-spotify {
  background-color: #81B71A !important;
}

.si-reddit:hover,
.si-colored.si-reddit {
  background-color: #C6C6C6 !important;
}

.si-blogger:hover,
.si-colored.si-blogger {
  background-color: #FC4F08 !important;
}

.si-cc:hover,
.si-colored.si-cc {
  background-color: #688527 !important;
}

.si-dribbble:hover,
.si-colored.si-dribbble {
  background-color: #EA4C89 !important;
}

.si-evernote:hover,
.si-colored.si-evernote {
  background-color: #5BA525 !important;
}

.si-flickr:hover,
.si-colored.si-flickr {
  background-color: #FF0084 !important;
}

.si-google:hover,
.si-colored.si-google {
  background-color: #DD4B39 !important;
}

.si-instapaper:hover,
.si-colored.si-instapaper {
  background-color: #333 !important;
}

.si-klout:hover,
.si-colored.si-klout {
  background-color: #FF5F52 !important;
}

.si-linkedin:hover,
.si-colored.si-linkedin {
  background-color: #0E76A8 !important;
}

.si-vk:hover,
.si-colored.si-vk {
  background-color: #2B587A !important;
}

.si-rss:hover,
.si-colored.si-rss {
  background-color: #EE802F !important;
}

.si-skype:hover,
.si-colored.si-skype {
  background-color: #00AFF0 !important;
}

.si-twitter:hover,
.si-colored.si-twitter {
  background-color: #00ACEE !important;
}

.si-youtube:hover,
.si-colored.si-youtube {
  background-color: #C4302B !important;
}

.si-vimeo:hover,
.si-colored.si-vimeo {
  background-color: #86C9EF !important;
}

.si-aim:hover,
.si-colored.si-aim {
  background-color: #FCD20B !important;
}

.si-yahoo:hover,
.si-colored.si-yahoo {
  background-color: #720E9E !important;
}

.si-email3:hover,
.si-colored.si-email3 {
  background-color: #6567A5 !important;
}

.si-macstore:hover,
.si-colored.si-macstore {
  background-color: #333333 !important;
}

.si-myspace:hover,
.si-colored.si-myspace {
  background-color: #666666 !important;
}

.si-podcast:hover,
.si-colored.si-podcast {
  background-color: #E4B21B !important;
}

.si-cloudapp:hover,
.si-colored.si-cloudapp {
  background-color: #525557 !important;
}

.si-dropbox:hover,
.si-colored.si-dropbox {
  background-color: #3D9AE8 !important;
}

.si-ebay:hover,
.si-colored.si-ebay {
  background-color: #89C507 !important;
}

.si-github:hover,
.si-colored.si-github {
  background-color: #171515 !important;
}

.si-googleplay:hover,
.si-colored.si-googleplay {
  background-color: #DD4B39 !important;
}

.si-itunes:hover,
.si-colored.si-itunes {
  background-color: #222 !important;
}

.si-plurk:hover,
.si-colored.si-plurk {
  background-color: #CF5A00 !important;
}

.si-pinboard:hover,
.si-colored.si-pinboard {
  background-color: #0000E6 !important;
}

.si-soundcloud:hover,
.si-colored.si-soundcloud {
  background-color: #FF7700 !important;
}

.si-tumblr:hover,
.si-colored.si-tumblr {
  background-color: #34526F !important;
}

.si-wordpress:hover,
.si-colored.si-wordpress {
  background-color: #1E8CBE !important;
}

.si-yelp:hover,
.si-colored.si-yelp {
  background-color: #C41200 !important;
}

.si-intensedebate:hover,
.si-colored.si-intensedebate {
  background-color: #009EE4 !important;
}

.si-eventbrite:hover,
.si-colored.si-eventbrite {
  background-color: #F16924 !important;
}

.si-scribd:hover,
.si-colored.si-scribd {
  background-color: #666666 !important;
}

.si-stripe:hover,
.si-colored.si-stripe {
  background-color: #008CDD !important;
}

.si-print:hover,
.si-colored.si-print {
  background-color: #111 !important;
}

.si-dwolla:hover,
.si-colored.si-dwolla {
  background-color: #FF5C03 !important;
}

.si-statusnet:hover,
.si-colored.si-statusnet {
  background-color: #131A30 !important;
}

.si-acrobat:hover,
.si-colored.si-acrobat {
  background-color: #D3222A !important;
}

.si-drupal:hover,
.si-colored.si-drupal {
  background-color: #27537A !important;
}

.si-buffer:hover,
.si-colored.si-buffer {
  background-color: #333333 !important;
}

.si-pocket:hover,
.si-colored.si-pocket {
  background-color: #EE4056 !important;
}

.si-bitbucket:hover,
.si-colored.si-bitbucket {
  background-color: #0E4984 !important;
}

.si-stackoverflow:hover,
.si-colored.si-stackoverflow {
  background-color: #EF8236 !important;
}

.si-hackernews:hover,
.si-colored.si-hackernews {
  background-color: #FF6600 !important;
}

.si-xing:hover,
.si-colored.si-xing {
  background-color: #126567 !important;
}

.si-instagram:hover,
.si-colored.si-instagram {
  background-color: #8A3AB9 !important;
}

.si-quora:hover,
.si-colored.si-quora {
  background-color: #A82400 !important;
}

.si-openid:hover,
.si-colored.si-openid {
  background-color: #E16309 !important;
}

.si-steam:hover,
.si-colored.si-steam {
  background-color: #111 !important;
}

.si-amazon:hover,
.si-colored.si-amazon {
  background-color: #E47911 !important;
}

.si-disqus:hover,
.si-colored.si-disqus {
  background-color: #E4E7EE !important;
}

.si-plancast:hover,
.si-colored.si-plancast {
  background-color: #222 !important;
}

.si-appstore:hover,
.si-colored.si-appstore {
  background-color: #000 !important;
}

.si-pinterest:hover,
.si-colored.si-pinterest {
  background-color: #C8232C !important;
}

.si-fivehundredpx:hover,
.si-colored.si-fivehundredpx {
  background-color: #111 !important;
}

.si-tiktok:hover,
.si-colored.si-tiktok {
  background-color: #000 !important;
}

.si-tripadvisor:hover,
.si-colored.si-tripadvisor {
  background-color: #00AF87 !important;
}

.si-gpay:hover,
.si-colored.si-gpay {
  background-color: #6877DF !important;
}

.si-unsplash:hover,
.si-colored.si-unsplash {
  background-color: #111 !important;
}

.si-snapchat:hover,
.si-colored.si-snapchat {
  background-color: #FFFC00 !important;
}

/* Social Icons - colors
-----------------------------------------------------------------*/
.si-text-color.si-facebook i {
  color: #3B5998;
}
.si-text-color.si-whatsapp i {
  color: #25d366;
}
.si-text-color.si-delicious i {
  color: #205CC0;
}
.si-text-color.si-paypal i {
  color: #00588B;
}
.si-text-color.si-flattr i {
  color: #F67C1A;
}
.si-text-color.si-android i {
  color: #A4C639;
}
.si-text-color.si-smashmag i {
  color: #E53B2C;
}
.si-text-color.si-gplus i {
  color: #DD4B39;
}
.si-text-color.si-wikipedia i {
  color: #333;
}
.si-text-color.si-stumbleupon i {
  color: #F74425;
}
.si-text-color.si-foursquare i {
  color: #25A0CA;
}
.si-text-color.si-call i {
  color: #444;
}
.si-text-color.si-ninetyninedesigns i {
  color: #F26739;
}
.si-text-color.si-forrst i {
  color: #5B9A68;
}
.si-text-color.si-digg i {
  color: #191919;
}
.si-text-color.si-spotify i {
  color: #81B71A;
}
.si-text-color.si-reddit i {
  color: #C6C6C6;
}
.si-text-color.si-blogger i {
  color: #FC4F08;
}
.si-text-color.si-cc i {
  color: #688527;
}
.si-text-color.si-dribbble i {
  color: #EA4C89;
}
.si-text-color.si-evernote i {
  color: #5BA525;
}
.si-text-color.si-flickr i {
  color: #FF0084;
}
.si-text-color.si-google i {
  color: #DD4B39;
}
.si-text-color.si-instapaper i {
  color: #333;
}
.si-text-color.si-klout i {
  color: #FF5F52;
}
.si-text-color.si-linkedin i {
  color: #0E76A8;
}
.si-text-color.si-vk i {
  color: #2B587A;
}
.si-text-color.si-rss i {
  color: #EE802F;
}
.si-text-color.si-skype i {
  color: #00AFF0;
}
.si-text-color.si-twitter i {
  color: #00ACEE;
}
.si-text-color.si-youtube i {
  color: #C4302B;
}
.si-text-color.si-vimeo i {
  color: #86C9EF;
}
.si-text-color.si-aim i {
  color: #FCD20B;
}
.si-text-color.si-yahoo i {
  color: #720E9E;
}
.si-text-color.si-email3 i {
  color: #6567A5;
}
.si-text-color.si-macstore i {
  color: #333333;
}
.si-text-color.si-myspace i {
  color: #666666;
}
.si-text-color.si-podcast i {
  color: #E4B21B;
}
.si-text-color.si-cloudapp i {
  color: #525557;
}
.si-text-color.si-dropbox i {
  color: #3D9AE8;
}
.si-text-color.si-ebay i {
  color: #89C507;
}
.si-text-color.si-github i {
  color: #171515;
}
.si-text-color.si-googleplay i {
  color: #DD4B39;
}
.si-text-color.si-itunes i {
  color: #222;
}
.si-text-color.si-plurk i {
  color: #CF5A00;
}
.si-text-color.si-pinboard i {
  color: #0000E6;
}
.si-text-color.si-soundcloud i {
  color: #FF7700;
}
.si-text-color.si-tumblr i {
  color: #34526F;
}
.si-text-color.si-wordpress i {
  color: #1E8CBE;
}
.si-text-color.si-yelp i {
  color: #C41200;
}
.si-text-color.si-intensedebate i {
  color: #009EE4;
}
.si-text-color.si-eventbrite i {
  color: #F16924;
}
.si-text-color.si-scribd i {
  color: #666666;
}
.si-text-color.si-stripe i {
  color: #008CDD;
}
.si-text-color.si-print i {
  color: #111;
}
.si-text-color.si-dwolla i {
  color: #FF5C03;
}
.si-text-color.si-statusnet i {
  color: #131A30;
}
.si-text-color.si-acrobat i {
  color: #D3222A;
}
.si-text-color.si-drupal i {
  color: #27537A;
}
.si-text-color.si-buffer i {
  color: #333333;
}
.si-text-color.si-pocket i {
  color: #EE4056;
}
.si-text-color.si-bitbucket i {
  color: #0E4984;
}
.si-text-color.si-stackoverflow i {
  color: #EF8236;
}
.si-text-color.si-hackernews i {
  color: #FF6600;
}
.si-text-color.si-xing i {
  color: #126567;
}
.si-text-color.si-instagram i {
  color: #8A3AB9;
}
.si-text-color.si-quora i {
  color: #A82400;
}
.si-text-color.si-openid i {
  color: #E16309;
}
.si-text-color.si-steam i {
  color: #111;
}
.si-text-color.si-amazon i {
  color: #E47911;
}
.si-text-color.si-disqus i {
  color: #E4E7EE;
}
.si-text-color.si-plancast i {
  color: #222;
}
.si-text-color.si-appstore i {
  color: #000;
}
.si-text-color.si-pinterest i {
  color: #C8232C;
}
.si-text-color.si-fivehundredpx i {
  color: #111;
}
.si-text-color.si-tiktok i {
  color: #000;
}
.si-text-color.si-tripadvisor i {
  color: #00AF87;
}
.si-text-color.si-gpay i {
  color: #6877DF;
}
.si-text-color.si-unsplash i {
  color: #111;
}
.si-text-color.si-snapchat i {
  color: #FFFC00;
}
.si-text-color:hover {
  color: #fff;
}

/*-----------------------------------------------------------------------------------

	Shortcodes: toggles-accordions.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Toggle
-----------------------------------------------------------------*/
.toggle {
  display: block;
  position: relative;
  margin: 0 0 20px 0;
}

.toggle-header,
.accordion-header {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0 -5px;
  cursor: pointer;
  color: #444;
  font-size: 1rem;
  font-weight: 700;
}

.toggle-icon,
.accordion-icon {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
  padding: 0 5px;
  text-align: center;
}

.toggle-icon i,
.toggle-icon span {
  width: 1rem;
}

.toggle-title,
.accordion-title {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
  padding: 0 5px;
}

.toggle:not(.toggle-active) .toggle-open, .toggle-active .toggle-closed {
  display: none;
}

.toggle-active .toggle-open {
  display: inline-block;
}

.toggle-content,
.accordion-content {
  display: none;
  position: relative;
  padding: 0.75rem 0;
}

/* Toggle - with Title Background
-----------------------------------------------------------------*/
.toggle-bg .toggle-header {
  background-color: #eeeeee;
  margin: 0;
  padding: 0.75rem;
  border-radius: 2px;
}
.toggle-bg .toggle-content {
  padding: 1rem 0.75rem;
}

/* Toggle - Bordered
-----------------------------------------------------------------*/
.toggle-border {
  border: 1px solid #cccccc;
  border-radius: 4px;
}
.toggle-border .toggle-header {
  padding: 0.75rem;
  margin: 0;
}
.toggle-border .toggle-content {
  padding: 1rem;
  padding-top: 0;
}

/* Toggle - FAQs
-----------------------------------------------------------------*/
.faqs .toggle {
  border-bottom: 1px solid #eeeeee;
  padding-bottom: 12px;
  margin-bottom: 12px;
}
.faqs .toggle-content {
  padding-bottom: 10px;
}

/* ----------------------------------------------------------------
	Accordions
-----------------------------------------------------------------*/
.accordion {
  margin-bottom: 20px;
}
.accordion-header {
  padding: 0.75rem 0;
  border-top: 1px dotted #dddddd;
  cursor: pointer;
}
.accordion-header:first-child {
  border-top: none;
}
.accordion:not([data-collapsible=true]) .accordion-header.accordion-active {
  cursor: auto;
}
.accordion-header:not(.accordion-active) .accordion-open, .accordion-active .accordion-closed {
  display: none;
}
.accordion-active .accordion-open {
  display: inline-block;
}
.accordion-content {
  padding-top: 0;
  padding-bottom: 1.5rem;
}

/* Accordion - with Title Background
-----------------------------------------------------------------*/
.accordion-bg .accordion-header {
  background-color: #eeeeee;
  padding: 0.75rem;
  border-radius: 2px;
  margin: 0;
  margin-bottom: 5px;
  border-top: 0;
}
.accordion-bg .accordion-content {
  padding: 0.75rem 0.75rem 1.5rem;
}

/* Accordion - Bordered
-----------------------------------------------------------------*/
.accordion-border {
  border: 1px solid #dddddd;
  border-radius: 4px;
}
.accordion-border .accordion-header {
  border-color: #cccccc;
  padding: 0.75rem;
  margin: 0;
}
.accordion-border .accordion-content {
  padding: 0 1.125rem 1.5rem;
}

/* Accordion - Large
-----------------------------------------------------------------*/
.accordion-lg .accordion-header {
  font-size: 1.25rem;
}

/*-----------------------------------------------------------------------------------

	Shortcodes: tabs.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Tabs
-----------------------------------------------------------------*/
.tab {
  position: relative;
  margin: 0 0 30px 0;
}
.tab-nav {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin: 0;
  border-bottom: 1px solid #dddddd;
  list-style: none;
  padding: 0 15px;
}
.tab-nav li {
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: auto;
  max-width: 100%;
  border: 1px solid #dddddd;
  border-bottom: 0;
  border-left: 0;
  height: 41px;
  text-align: center;
}
.tabs-justify .tab-nav li {
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
}
.tab-nav li:first-child {
  border-left: 1px solid #dddddd;
}
.tab-nav-sm.tab-nav li {
  height: 31px;
}

.tab-nav-lg.tab-nav li {
  padding: 0;
  height: 51px;
}

.tab-nav-xl.tab-nav li {
  height: 61px;
}

.tab-nav-lg.tab-nav li i {
  font-size: 1.25rem;
}

.tab-nav li a {
  display: block;
  padding: 0 15px;
  color: #444;
  height: 40px;
  line-height: 40px;
  background-color: #f2f2f2;
  font-weight: bold;
  font-size: 1rem;
}
@media (max-width: 575.98px) {
  .tab-nav li a {
    font-size: 0.875rem;
  }
}
.tab-nav-sm.tab-nav li a {
  padding: 0 12px;
  height: 30px;
  line-height: 30px;
  font-size: 0.8125rem;
}

.tab-nav-lg.tab-nav li a {
  padding: 0 20px;
  height: 50px;
  line-height: 50px;
  text-transform: none;
  letter-spacing: 0;
  font-weight: 600;
}

.tab-nav-xl.tab-nav li a {
  padding: 0 20px;
  height: 60px;
  line-height: 60px;
  font-size: 1rem;
}

.tab-nav li.ui-tabs-active a {
  position: relative;
  top: 1px;
  background-color: #fff;
}

/* Tabs - Alternate Nav
-----------------------------------------------------------------*/
.tabs-alt .tab-nav li {
  border-color: transparent;
}
.tabs-alt .tab-nav li:first-child {
  border-left: 0;
}
.tabs-alt .tab-nav li a {
  background-color: #fff;
}
.tabs-alt .tab-nav li.ui-tabs-active a {
  border: 1px solid #dddddd;
  border-bottom: 0;
}

/* Tabs - Top Border
-----------------------------------------------------------------*/
.tabs-tb .tab-nav li.ui-tabs-active a {
  top: -1px;
  height: 43px;
  line-height: 38px;
  border-top: 2px solid #2a93c9;
}
.tabs-alt.tabs-tb .tab-nav li.ui-tabs-active a {
  height: 42px;
}

.tabs-tb.tabs-alt ul.tab-nav.tab-nav-sm li.ui-tabs-active a,
.tabs-tb ul.tab-nav.tab-nav-sm li.ui-tabs-active a {
  height: 33px;
  line-height: 28px;
}
.tabs-tb.tabs-alt ul.tab-nav.tab-nav-lg li.ui-tabs-active a,
.tabs-tb ul.tab-nav.tab-nav-lg li.ui-tabs-active a {
  height: 52px;
  line-height: 48px;
}
.tabs-tb.tabs-alt ul.tab-nav.tab-nav-xl li.ui-tabs-active a,
.tabs-tb ul.tab-nav.tab-nav-xl li.ui-tabs-active a {
  height: 62px;
  line-height: 58px;
}

/* Tabs - Bottom Border
-----------------------------------------------------------------*/
.tabs-bb .tab-nav {
  padding: 0;
}
.tabs-bb .tab-nav li {
  border: 0 !important;
  margin: 0;
}
.tabs-bb .tab-nav li a {
  background-color: transparent;
  border-bottom: 2px solid transparent;
}
.tabs-bb .tab-nav li.ui-tabs-active a {
  border-bottom: 2px solid #2a93c9;
  top: -2px;
  height: 44px;
  line-height: 44px;
}
.tabs-bb .tab-nav.tab-nav-sm li.ui-tabs-active a {
  height: 34px;
  line-height: 34px;
}
.tabs-bb .tab-nav.tab-nav-lg li.ui-tabs-active a {
  height: 54px;
  line-height: 54px;
}
.tabs-bb .tab-nav.tab-nav-xl li.ui-tabs-active a {
  height: 64px;
  line-height: 64px;
}

/* Tabs - Navigation Style 2
-----------------------------------------------------------------*/
.tab-nav.tab-nav2 {
  border-bottom: 0;
  padding: 0;
}
.tab-nav.tab-nav2 li {
  border: 0;
  margin-left: 10px;
}
.tab-nav.tab-nav2 li:first-child {
  margin-left: 0;
  border-left: 0;
}
.tab-nav.tab-nav2 li a {
  background-color: #f2f2f2;
  border-radius: 2px;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
}
.tab-nav.tab-nav2 li.ui-state-active a {
  top: 0;
  background-color: #2a93c9;
  color: #fff;
}

/* Tabs - Large Navigation
-----------------------------------------------------------------*/
.tab-nav-lg {
  margin-bottom: 20px;
  list-style: none;
}
.tab-nav-lg li {
  position: relative;
  text-align: center;
  padding: 16px;
}
.tab-nav-lg li a {
  display: inline-block;
  color: #444;
  font-family: "Poppins", sans-serif;
  font-size: 13px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.tab-nav-lg li i {
  display: block;
  font-size: 28px;
  margin: 0 0 7px;
}
.tab-nav-lg li.ui-tabs-active a {
  color: #2a93c9;
}
.tab-nav-lg li.ui-tabs-active::before, .tab-nav-lg li.ui-tabs-active::after {
  content: "";
  position: absolute;
  z-index: 2;
  top: auto;
  bottom: -50px;
  border: solid transparent;
}
.tab-nav-lg li.ui-tabs-active::after {
  border-top-color: #fff;
  border-width: 16px;
  left: 50%;
  margin-left: -16px;
}
.tab-nav-lg li.ui-tabs-active::before {
  border-top-color: #fff;
  border-width: 16px;
  left: 50%;
  margin-left: -16px;
}

/* Tabs - Content Area
-----------------------------------------------------------------*/
.tab-container {
  position: relative;
  padding: 20px 0 0;
}
.tab-nav i {
  position: relative;
  top: 1px;
  margin-right: 3px;
}

.tabs .tab-content:not(:first-child) {
  display: none;
}

/* Tabs - Bordered
-----------------------------------------------------------------*/
.tabs-bordered .tab-nav {
  padding: 0;
}
.tabs-bordered .tab-nav li:first-child {
  margin-left: 0;
}
.tabs-bordered .tab-container {
  border: 1px solid #dddddd;
  border-top: 0;
  padding: 20px;
}

/* Tabs - Side Tabs
-----------------------------------------------------------------*/
@media (min-width: 768px) {
  .side-tabs {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }
  .side-tabs .tab-nav {
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
    flex-flow: column;
    min-width: 200px;
    width: auto;
    max-width: 100%;
    padding: 20px 0;
    border-bottom: 0;
    border-right: 1px solid #dddddd;
  }
  .side-tabs .tab-nav.tab-nav-sm {
    width: 170px;
  }
  .side-tabs .tab-nav li {
    border: 1px solid #dddddd;
    border-right: 0;
    border-top: 0;
    height: auto;
    text-align: left;
  }
  .side-tabs .tab-nav li:first-child {
    margin-left: 0;
    border-top: 1px solid #dddddd;
    border-left: 1px solid #dddddd;
  }
  .side-tabs .tab-nav li a {
    height: auto;
    line-height: 42px;
  }
  .side-tabs .tab-nav li.ui-tabs-active a {
    top: 0;
    right: -1px;
  }
  .side-tabs .tab-nav.tab-nav-lg li i {
    margin-right: 5px;
    top: 2px;
  }
  .side-tabs .tab-container {
    -ms-flex-preferred-size: 0;
    flex-basis: 0;
    -ms-flex-positive: 1;
    flex-grow: 1;
    padding: 15px 0 0 20px;
  }
  .side-tabs ul.tab-nav.tab-nav-sm li a, .side-tabs.tabs-bordered ul.tab-nav.tab-nav-sm li a {
    line-height: 34px;
  }
  .side-tabs ul.tab-nav.tab-nav-lg li a, .side-tabs.tabs-bordered ul.tab-nav.tab-nav-lg li a {
    line-height: 54px;
  }
  .side-tabs ul.tab-nav.tab-nav-xl li a, .side-tabs.tabs-bordered ul.tab-nav.tab-nav-xl li a {
    line-height: 54px;
  }

  .ui-tabs .ui-tabs-hide {
    position: absolute !important;
    left: -10000px !important;
    display: block !important;
  }

  /* Tabs - Side Tabs & Nav Style 2
  -----------------------------------------------------------------*/
  .side-tabs .tab-nav.tab-nav2 {
    border: 0;
  }
  .side-tabs .tab-nav.tab-nav2 li {
    border: 0;
    margin: 6px 0 0;
  }
  .side-tabs .tab-nav.tab-nav2 li:first-child {
    margin-top: 0;
  }
  .side-tabs .tab-nav.tab-nav2 li a {
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.1);
  }
  .side-tabs .tab-nav.tab-nav2 li.ui-state-active a {
    right: 0;
  }
}
/* Tabs - Side Tabs & Bordered
-----------------------------------------------------------------*/
.tabs-bordered.side-tabs .tab-nav {
  position: relative;
  padding: 0;
  z-index: 2;
  margin-right: -1px;
}
.tabs-bordered.side-tabs .tab-nav li a {
  height: auto;
  line-height: 42px;
}
.tabs-bordered.side-tabs .tab-nav li.ui-tabs-active a {
  right: -1px;
  border-right: 1px solid #fff;
}
.tabs-bordered.side-tabs .tab-container {
  overflow: hidden;
  padding: 20px;
  border-top: 1px solid #dddddd;
}

/* ----------------------------------------------------------------
	Side Navigation
-----------------------------------------------------------------*/
/* Chevrons */
.sidenav {
  width: 100%;
  padding: 0;
  background-color: #fff;
  border-radius: 4px;
}
.sidenav li {
  list-style-type: none;
}
.sidenav li a {
  display: block;
  position: relative;
  margin: 0 0 -1px;
  padding: 11px 20px 11px 14px;
  border: 1px solid #e5e5e5;
  border-right-width: 2px;
  color: #666666;
}
.sidenav li ul {
  display: none;
}
.sidenav li li a {
  padding: 6px 20px 6px 35px;
  font-size: 0.875rem;
  border-top: none;
  border-bottom: none;
}
.sidenav li li:first-child a {
  padding-top: 20px;
}
.sidenav li li:last-child a {
  padding-bottom: 20px;
}
.sidenav li i {
  position: relative;
  top: 1px;
  margin-right: 6px;
  font-size: 1rem;
  text-align: center;
  width: 1.125rem;
}
.sidenav > li:first-child > a {
  border-radius: 4px 4px 0 0;
}
.sidenav > li:last-child > a {
  border-radius: 0 0 4px 4px;
  border-bottom-width: 2px;
}
.sidenav > li > a:hover {
  background-color: #F5F5F5;
  color: #222;
}
.sidenav > .ui-tabs-active > a {
  position: relative;
  z-index: 2;
  border-color: rgba(0, 0, 0, 0.1) !important;
  color: #FFF !important;
  font-weight: 700;
  background-color: #2a93c9 !important;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}
.sidenav > .ui-tabs-active > a:hover {
  position: relative;
  z-index: 2;
  border-color: rgba(0, 0, 0, 0.1) !important;
  color: #FFF !important;
  font-weight: 700;
  background-color: #2a93c9 !important;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
}
.sidenav li.ui-tabs-active ul {
  display: block;
}
.sidenav .icon-chevron-right {
  position: absolute;
  width: 1rem;
  height: 1rem;
  top: 50%;
  left: auto;
  right: 6px;
  margin-top: -8px;
  margin-right: 0;
  opacity: 0.4;
  font-size: 12px !important;
}
.sidenav a:hover .icon-chevron-right {
  opacity: 0.5;
}
.sidenav .ui-#tabs-active .icon-chevron-right {
  opacity: 1;
}
.sidenav .ui-#tabs-active a:hover .icon-chevron-right {
  opacity: 1;
}

/* Bootstrap Tabs */
.nav[role=tablist] a:not([class*=i-]):not(.active) {
  color: inherit;
}

:not(.dark) .list-group-media .list-group-item-action.active h3, :not(.dark) .list-group-media .list-group-item-action.active .h3,
:not(.dark) .list-group-media .list-group-item-action.active h4,
:not(.dark) .list-group-media .list-group-item-action.active .h4,
:not(.dark) .list-group-media .list-group-item-action.active h5,
:not(.dark) .list-group-media .list-group-item-action.active .h5,
:not(.dark) .list-group-media .list-group-item-action.active h6,
:not(.dark) .list-group-media .list-group-item-action.active .h6 {
  color: #FFF;
}

.list-group-item {
  background-color: #fff;
  border-color: #dddddd;
}

.list-group-item.disabled, .list-group-item:disabled {
  background-color: #fff;
}

.list-group-item-action:hover,
.list-group-item-action:focus {
  background-color: #f9f9f9;
}

:not(.dark) .list-group-media .list-group-item-action p {
  opacity: 0.8;
  line-height: 1.6 !important;
}

/* Tab Icons */
[role=tablist] a.i-plain.active {
  color: #2a93c9;
}

[role=tablist] a.active {
  border-color: #2a93c9;
  background-color: #2a93c9;
  color: #FFF;
}

/* Tab Bordered */
.tab-bordered {
  position: relative;
}

.tab-bordered::before {
  content: "";
  height: 1px;
  border: 1px dashed #dddddd;
  position: absolute;
  margin: auto;
  top: 50%;
  left: 15px;
  right: 15px;
  z-index: 0;
}

.tab-bordered a {
  z-index: 2;
}

.dark .tab-bordered li:not(:last-child):before,
.dark.tab-bordered li:not(:last-child):before {
  border-color: rgba(255, 255, 255, 0.2);
}

.tab-bordered a.i-bordered:not(.active):not(:hover) {
  background-color: #fff !important;
}

.dark .tab-bordered a.i-bordered:not(.active):not(:hover),
.dark.tab-bordered a.i-bordered:not(.active):not(:hover) {
  background-color: #383838 !important;
}

.tab-bordered.list-group-horizontal-sm::before {
  height: 100%;
  width: 1px;
  border: 1px dashed #dddddd;
  top: 15px;
  bottom: 15px;
  left: 26px;
  right: auto;
  z-index: 0;
}

.tab-sticky.sticky-sm-none {
  position: relative;
  top: auto;
  height: auto;
}

.tab-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 80px;
  height: 100%;
  z-index: 1;
}

/*-----------------------------------------------------------------------------------

	Shortcodes: faqs.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	FAQ List
-----------------------------------------------------------------*/
.faqlist li {
  margin-bottom: 5px;
  font-size: 15px;
}
.faqlist li a {
  color: #444;
}
.faqlist li a:hover {
  color: #2a93c9 !important;
}

/*-----------------------------------------------------------------------------------

	Shortcodes: clients.scss

-----------------------------------------------------------------------------------*/
.clients-grid {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  list-style: none;
  overflow: hidden;
}
.clients-grid .grid-item {
  position: relative;
  padding: 20px 0;
  -webkit-backface-visibility: hidden;
}
.clients-grid .grid-item a {
  opacity: 0.6;
}
body:not(.device-touch) .clients-grid .grid-item a {
  -webkit-transition: all 0.5s ease;
  -o-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.clients-grid .grid-item a,
.clients-grid .grid-item img {
  display: block;
  width: 75%;
  margin-left: auto;
  margin-right: auto;
}
.clients-grid .grid-item img {
  width: 100%;
}
.clients-grid .grid-item::before, .clients-grid .grid-item::after {
  content: "";
  position: absolute;
}
.clients-grid .grid-item::before {
  height: 100%;
  top: 0;
  left: -1px;
  border-left: 1px dashed #dddddd;
}
.clients-grid .grid-item::after {
  width: 100%;
  height: 0;
  top: auto;
  left: 0;
  bottom: -1px;
  border-bottom: 1px dashed #dddddd;
}

/* ----------------------------------------------------------------
	Clients
-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Testimonials
-----------------------------------------------------------------*/
/* Testimonials - Grid
-----------------------------------------------------------------*/
.testimonials-grid {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  list-style: none;
  overflow: hidden;
}
.testimonials-grid .grid-item {
  position: relative;
  padding: 2rem;
  -webkit-backface-visibility: hidden;
  /* Testimonials - Item
  -----------------------------------------------------------------*/
}
.testimonials-grid .grid-item::before, .testimonials-grid .grid-item::after {
  content: "";
  position: absolute;
  height: 100%;
  top: 0;
  left: -1px;
  border-left: 1px dashed #dddddd;
}
.testimonials-grid .grid-item::after {
  height: 0;
  top: auto;
  left: 0;
  bottom: -1px;
  border-bottom: 1px dashed #dddddd;
}
.testimonials-grid .grid-item .testimonial {
  padding: 0;
  background-color: transparent !important;
  border: 0 !important;
  box-shadow: none !important;
}

.testimonial {
  position: relative;
  padding: 20px;
  background-color: #fff;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  box-shadow: 0 1px 1px rgba(0, 0, 0, 0.1);
  /* Testimonial - Pagination
  -----------------------------------------------------------------*/
}
.testimonial .flex-control-nav {
  top: auto;
  bottom: 6px;
  right: 0;
}
.testimonial .flex-control-nav li {
  margin: 0 2px;
  width: 6px;
  height: 6px;
}
.testimonial .flex-control-nav li a {
  width: 6px !important;
  height: 6px !important;
  border: none;
  background-color: #2a93c9;
  opacity: 0.5;
}
.testimonial .flex-control-nav li:hover a {
  opacity: 0.75;
}
.testimonial .flex-control-nav li a.flex-active {
  opacity: 1;
}

.testi-image {
  float: left;
  margin-right: 15px;
  display: block;
  width: 64px;
  height: 64px;
}
.testi-image a {
  display: block;
  width: 64px;
  height: 64px;
}
.testi-image img {
  display: block;
  width: 64px;
  height: 64px;
  border-radius: 50%;
}
.testi-image i {
  display: block;
  width: 64px;
  height: 64px;
  text-align: center;
  background-color: #EEE;
  border-radius: 50%;
  line-height: 64px;
  font-size: 28px;
  color: #888888;
  text-shadow: 1px 1px 1px #fff;
}

.testi-content {
  position: relative;
  overflow: hidden;
}
.testi-content p {
  margin-bottom: 0;
  font-family: "PT Serif", serif;
  font-style: italic;
}
.testi-content p::before {
  content: ' " ';
}
.testi-content p::after {
  content: ' " ';
}

.testi-meta {
  margin-top: 10px;
  font-size: 15px;
  font-weight: bold;
  text-transform: uppercase;
}
.testi-meta::before {
  content: "–";
}
.testi-meta span {
  display: block;
  font-weight: normal;
  color: #999;
  font-size: 13px;
  text-transform: none;
  padding-left: 10px;
}

/* Twitter - Small Scroller
-----------------------------------------------------------------*/
.testimonial.twitter-scroll .testi-content p::before, .testimonial.twitter-scroll .testi-content p::after {
  content: "";
}
.testimonial.twitter-scroll .testi-meta::before {
  content: "";
}
.testimonial.twitter-scroll .testi-meta span {
  padding-left: 0;
}
.testimonial.twitter-scroll .testi-meta span a {
  color: #999;
}
.testimonial.twitter-scroll .testi-meta span a:hover {
  color: #222;
}

.testimonial.testimonial-full {
  padding: 30px;
  /* Testimonial - Full Scroller
  -----------------------------------------------------------------*/
}
.testimonial.testimonial-full .flex-control-nav {
  position: relative;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  height: 6px;
  margin-top: 20px;
}
.testimonial.testimonial-full .flex-control-nav li {
  display: inline-block;
  float: none;
}

.testimonial.testimonial-full[data-animation=fade] {
  padding: 30px;
}
.testimonial.testimonial-full[data-animation=fade] .flexslider {
  overflow: visible !important;
}

/* Testimonials - Full Width
-----------------------------------------------------------------*/
.testimonial-full .testi-image {
  float: none;
  margin: 0 auto 20px;
  display: block;
  width: 72px;
  height: 72px;
}
.testimonial-full .testi-image a {
  display: block;
  width: 72px;
  height: 72px;
}
.testimonial-full .testi-image img {
  display: block;
  width: 72px;
  height: 72px;
}
.testimonial-full .testi-image i {
  display: block;
  width: 72px;
  height: 72px;
  line-height: 72px;
}
.testimonial-full .testi-content {
  text-align: center;
  font-size: 1.125rem;
}
.testimonial-full .testi-meta {
  margin-top: 15px;
}
.testimonial-full .testi-meta span {
  padding-left: 0;
}

/* Testimonial - Section Scroller
-----------------------------------------------------------------*/
.section > .testimonial {
  padding: 0;
  background-color: transparent !important;
  border: 0 !important;
  box-shadow: none !important;
  max-width: 800px;
  margin: 0 auto;
}
.section > .testimonial .testi-content {
  font-size: 1.2499875rem;
}
.section > .testimonial .testi-meta {
  font-size: 0.8748055988rem;
}
.section > .testimonial .testi-meta span {
  font-size: 0.8748055988rem;
}
.section > .testimonial[data-animation=fade] {
  padding: 0;
}

/*-----------------------------------------------------------------------------------

	Shortcodes: team.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Team
-----------------------------------------------------------------*/
.team, .team-image {
  position: relative;
  /* Team Center
  -----------------------------------------------------------------*/
  /* Team List
  -----------------------------------------------------------------*/
}
.team-image > a,
.team-image img {
  display: block;
  width: 100%;
}
.team-desc {
  text-align: center;
}
.team-desc-bg {
  background-color: #fff;
  padding: 10px 0 30px;
}
.team .si-share, .team-image .si-share {
  margin-top: 30px;
  text-align: left;
}
.team .si-share .social-icon, .team-image .si-share .social-icon {
  font-size: 14px;
  width: 32px;
}
.team-title {
  position: relative;
  padding-top: 20px;
}
.team-title h4, .team-title .h4 {
  display: block;
  margin-bottom: 0;
  font-size: 1.25rem;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-weight: 700;
}
.team-title span {
  display: block;
  color: 1rem;
  font-weight: 400;
  font-family: "PT Serif", serif;
  font-style: italic;
  font-size: 1rem;
  margin-top: 5px;
}
.team-content {
  margin-top: 1rem;
  color: #777777;
}
.team-content p:last-child {
  margin-bottom: 0;
}
.team.center .team-title::after, .center.team-image .team-title::after {
  left: 50%;
  margin-left: -40px;
}
.team-list .team-desc {
  text-align: left;
}
.team-list .team-title {
  padding-top: 0;
}

.team .team-desc > .social-icon, .team-image .team-desc > .social-icon {
  margin-top: 20px;
}
.team .team-content ~ .social-icon, .team-image .team-content ~ .social-icon {
  margin-top: 30px;
}
.team:not(.team-list) .social-icon, .team-image:not(.team-list) .social-icon {
  display: inline-block !important;
  vertical-align: middle;
  float: none;
  margin-left: 4px;
  margin-right: 4px;
}

/*-----------------------------------------------------------------------------------

	Shortcodes: pricing.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Pricing Boxes
-----------------------------------------------------------------*/
.pricing {
  position: relative;
  /* Pricing Boxesma
  -----------------------------------------------------------------*/
  /* Pricing Boxes - Title
  -----------------------------------------------------------------*/
  /* Pricing Boxes - Price
  -----------------------------------------------------------------*/
  /* Pricing Boxes - Features
  -----------------------------------------------------------------*/
  /* Pricing Boxes - Action
  -----------------------------------------------------------------*/
  /* Pricing Boxes - Best Price
  -----------------------------------------------------------------*/
  /* Pricing Boxes - Simple
  -----------------------------------------------------------------*/
  /* Pricing Boxes - Minimal
  -----------------------------------------------------------------*/
  /* Pricing Boxes - Extended
  -----------------------------------------------------------------*/
  /* Pricing Boxes - 5 Columns
  -----------------------------------------------------------------*/
}
.pricing [class^=col-] {
  margin-top: 1.25rem;
}
.pricing-box {
  position: relative;
  overflow: hidden;
  border: 1px solid rgba(0, 0, 0, 0.075);
  border-radius: 3px;
  background-color: whitesmoke;
}
.pricing-title {
  padding: 1rem 0;
  background-color: #f9f9f9;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  letter-spacing: 1px;
}
.pricing-title h3, .pricing-title .h3 {
  margin: 0;
  font-size: 1.5rem;
  font-weight: bold;
  color: #555555;
}
.pricing-title span {
  display: block;
  color: #777777;
  font-weight: 300;
  font-size: 1.05rem;
  margin: 3px 0;
  text-transform: uppercase;
}
.pricing-title.title-sm h3, .pricing-title.title-sm .h3 {
  font-size: 1.25rem;
}
.pricing-title.title-sm span {
  font-size: 0.875rem;
}
.pricing-price {
  position: relative;
  padding: 1.5rem 0;
  color: #333333;
  font-size: 4rem;
  line-height: 1;
}
.pricing-price .price-unit {
  display: inline-block;
  vertical-align: top;
  margin: 7px 3px 0 0;
  font-size: 1.74rem;
  font-weight: normal;
}
.pricing-price .price-tenure {
  vertical-align: baseline;
  font-size: 0.99354rem;
  font-weight: normal;
  letter-spacing: 1px;
  color: #999999;
  margin: 0 0 0 3px;
}
.pricing-features {
  border-top: 1px solid rgba(0, 0, 0, 0.075);
  border-bottom: 1px solid rgba(0, 0, 0, 0.075);
  background-color: #f9f9f9;
  padding: 2rem 0;
}
.pricing-features ul {
  margin: 0;
  list-style: none;
}
.pricing-features li {
  padding: 0.375rem 0;
}
.pricing-action {
  padding: 1.25rem 0;
}
.pricing-highlight {
  box-shadow: 0 0 8px rgba(0, 0, 0, 0.1);
  z-index: 10;
  margin: -1.25rem -1px;
}
.pricing-highlight .pricing-title,
.pricing-highlight .pricing-features {
  background-color: #fff;
}
.pricing-highlight .pricing-price {
  font-size: 4.5rem;
}
.pricing-simple {
  border: 0;
}
.pricing-simple .pricing-title,
.pricing-simple .pricing-features {
  border: 0;
  background-color: transparent;
}
.pricing-minimal .pricing-price {
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.075);
}
.pricing-minimal .pricing-price::after {
  display: none;
}

.pricing-extended {
  background-color: #fff;
  text-align: left;
}
.pricing-extended .pricing-title {
  background-color: transparent;
  padding-top: 0;
  text-align: left;
}
.pricing-extended .pricing-features {
  border: 0;
  background-color: transparent;
}
.pricing-extended .pricing-action-area {
  border-left: 1px solid rgba(0, 0, 0, 0.05);
  background-color: #f9f9f9;
  padding: 30px;
  text-align: center;
}
.pricing-extended .pricing-action-area .pricing-price,
.pricing-extended .pricing-action-area .pricing-action {
  padding: 0;
}
.pricing-extended .pricing-action-area .pricing-price {
  padding-bottom: 1.25rem;
}
.pricing-extended .pricing-action-area .pricing-price::after {
  display: none;
}
.pricing-extended .pricing-action-area .pricing-price span.price-tenure {
  display: block;
  margin: 10px 0 0 0;
  font-weight: 300;
  text-transform: uppercase;
  font-size: 0.875rem;
}
.pricing-extended .pricing-meta {
  color: #999999;
  font-weight: 300;
  font-size: 1rem;
  letter-spacing: 1px;
  text-transform: uppercase;
  padding-bottom: 10px;
}

.pricing.pricing-5 .pricing-box {
  float: left;
  width: 20%;
  margin-top: 1.25rem;
}
.pricing.pricing-5 .pricing-box.best-price {
  margin-top: 0;
}
.pricing.pricing-5 .pricing-box:nth-child(5) {
  margin-left: -1px;
}
.pricing.pricing-5 .pricing-title h3, .pricing.pricing-5 .pricing-title .h3 {
  font-size: 1.5rem;
}
.pricing.pricing-5 .best-price .pricing-title h3, .pricing.pricing-5 .best-price .pricing-title .h3 {
  font-size: 1.5rem;
}

.table-comparison, .table-comparison th {
  text-align: center;
}
.table-comparison th:first-child, .table-comparison td:first-child {
  text-align: left;
  font-weight: bold;
}

/*-----------------------------------------------------------------------------------

	Shortcodes: counter-skills.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Counter
-----------------------------------------------------------------*/
.counter {
  font-size: 42px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
}
.counter.counter-small {
  font-size: 28px;
}
.counter.counter-large {
  font-size: 56px;
}
.counter.counter-xlarge {
  font-size: 64px;
  font-weight: 400;
}
.counter.counter-inherit {
  font-size: inherit;
  font-weight: inherit;
}
.counter + h5, .counter + .h5 {
  font-weight: 400;
  text-transform: uppercase;
  letter-spacing: 1px;
}
.widget .counter + h5, .widget .counter + .h5 {
  opacity: 0.7;
}
.counter.counter-lined + h5::before, .counter.counter-lined + .h5::before {
  display: block;
  position: relative;
  margin: 20px auto 25px auto;
  content: "";
  width: 15%;
  border-top: 2px solid #444;
  opacity: 0.9;
}
.counter.counter-small + h5, .counter.counter-small + .h5 {
  font-size: 0.875rem;
}
.counter.counter-large + h5, .counter.counter-large + .h5, .counter.counter-xlarge + h5, .counter.counter-xlarge + .h5 {
  font-size: 15px;
  font-weight: 300;
}

/* ----------------------------------------------------------------
	Animated Rounded Skills
-----------------------------------------------------------------*/
.rounded-skill {
  display: inline-block;
  margin: 0 15px 15px;
  display: inline-block;
  position: relative;
  text-align: center;
  font-size: 1.25rem;
  font-weight: bold;
  color: #333333;
  /* News Carousel Overlays
  -----------------------------------------------------------------*/
}
body:not(.device-touch) .rounded-skill {
  -webkit-transition: opacity 0.4s ease;
  -o-transition: opacity 0.4s ease;
  transition: opacity 0.4s ease;
}
.rounded-skill + h5, .rounded-skill + .h5 {
  margin-bottom: 0;
}
.rounded-skill i {
  font-size: 42px;
}
.rounded-skill canvas {
  position: absolute;
  top: 0;
  left: 0;
}
.news-carousel .rounded-skill {
  position: absolute;
  margin: 0 !important;
  top: 30px;
  right: 30px;
  color: #FFF;
  font-weight: 700;
}

/* ----------------------------------------------------------------
	Skills Bar
-----------------------------------------------------------------*/
.skills {
  margin: 0;
  list-style: none;
  padding-top: 25px;
}
.skills li {
  position: relative;
  margin-top: 38px;
  height: 4px;
  background-color: whitesmoke;
  box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}
.skills li:first-child {
  margin-top: 0;
}
.skills li .progress {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #2a93c9;
  overflow: visible !important;
  border-radius: 2px;
  -webkit-transition: width 1.2s ease-in-out;
  -o-transition: width 1.2s ease-in-out;
  transition: width 1.2s ease-in-out;
}
.skills li .progress-percent {
  position: absolute;
  top: -28px;
  right: 0;
  background-color: #333333;
  color: #f9f9f9;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.2);
  height: 22px;
  line-height: 22px;
  padding: 0 5px;
  font-size: 11px;
  border-radius: 2px;
}
.skills li .progress-percent .counter {
  font-weight: 400;
  font-family: "Lato", sans-serif;
}
.skills li > span {
  position: absolute;
  z-index: 1;
  top: -23px;
  left: 0;
  line-height: 20px;
  font-size: 12px;
  font-weight: bold;
  color: #222222;
}

.progress-percent::after,
.progress-percent::before {
  top: 100%;
  border: solid transparent;
  content: " ";
  height: 0;
  width: 0;
  position: absolute;
  pointer-events: none;
}

.progress-percent::after {
  border-top-color: #333333;
  border-width: 4px;
  left: 50%;
  margin-left: -4px;
}

.progress-percent::before {
  border-top-color: #333333;
  border-width: 5px;
  left: 50%;
  margin-left: -5px;
}

/*-----------------------------------------------------------------------------------

	Shortcodes: carousels.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Owl Carousel CSS
-----------------------------------------------------------------*/
.owl-carousel .animated {
  -webkit-animation-duration: 1000ms;
  animation-duration: 1000ms;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.owl-carousel .owl-animated-in {
  z-index: 0;
}
.owl-carousel .owl-animated-out {
  z-index: 1;
}
.owl-carousel .fadeOut {
  -webkit-animation-name: fadeOut;
  animation-name: fadeOut;
}

@-webkit-keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
@keyframes fadeout {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
.owl-height {
  -webkit-transition: height 500ms ease-in-out;
  -o-transition: height 500ms ease-in-out;
  transition: height 500ms ease-in-out;
}

.owl-carousel {
  display: none;
  -webkit-tap-highlight-color: transparent;
  position: relative;
  z-index: 1;
  width: 100%;
  /* Owl Carousel - Controls
  -----------------------------------------------------------------*/
  /* Owl Carousel - Controls - Arrows
  -----------------------------------------------------------------*/
}
.owl-carousel .owl-stage {
  position: relative;
  -ms-touch-action: pan-Y;
}
.owl-carousel .owl-stage::after {
  content: ".";
  display: block;
  clear: both;
  visibility: hidden;
  line-height: 0;
  height: 0;
}
.owl-carousel .owl-stage-outer {
  position: relative;
  overflow: hidden;
  -webkit-transform: translate3d(0, 0, 0);
}
.owl-carousel.owl-loaded {
  display: block;
}
.owl-carousel.owl-loading {
  display: block;
  min-height: 100px;
  background: no-repeat center center;
  opacity: 0;
  display: block;
}
.owl-carousel .owl-refresh .owl-item {
  display: none;
}
.owl-carousel .owl-item {
  position: relative;
  min-height: 1px;
  float: left;
  -webkit-tap-highlight-color: transparent;
  -webkit-touch-callout: none;
}
.owl-carousel .owl-item img {
  display: block;
  width: 100%;
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.slider-element .owl-carousel .owl-item img {
  -webkit-transform-style: preserve-3d;
}
.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
  display: none;
}
.owl-carousel.owl-hidden {
  opacity: 0;
}
.mega-menu-content .owl-carousel {
  opacity: 1;
}
.owl-carousel.owl-refresh .owl-item {
  display: none;
}
.owl-carousel.owl-drag .owl-item {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.owl-carousel.owl-grab {
  cursor: move;
  cursor: -webkit-grab;
  cursor: -o-grab;
  cursor: -ms-grab;
  cursor: grab;
}
.owl-carousel.owl-rtl {
  direction: rtl;
}
.owl-carousel.owl-rtl .owl-item {
  float: right;
}
.no-js .owl-carousel {
  display: block;
}
.owl-carousel .owl-item .owl-lazy {
  opacity: 0;
  -webkit-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}
.owl-carousel .owl-video-wrapper {
  position: relative;
  height: 100%;
  background: #111;
}
.owl-carousel .owl-video-play-icon {
  position: absolute;
  height: 64px;
  width: 64px;
  left: 50%;
  top: 50%;
  margin-left: -32px;
  margin-top: -32px;
  background: url("images/icons/play.png") no-repeat;
  cursor: pointer;
  z-index: 1;
  -webkit-backface-visibility: hidden;
  -webkit-transition: scale 100ms ease;
  -o-transition: scale 100ms ease;
  transition: scale 100ms ease;
}
.owl-carousel .owl-video-play-icon:hover {
  transition: scale(1.3);
}
@media (prefers-reduced-motion: reduce) {
  .owl-carousel .owl-video-play-icon:hover {
    transition: none;
  }
}
.owl-carousel .owl-video-play-icon .owl-video-play-icon,
.owl-carousel .owl-video-play-icon .owl-video-tn {
  display: none;
}
.owl-carousel .owl-video-play-icon .owl-video-tn {
  opacity: 0;
  height: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  -webkit-background-size: contain;
  -moz-background-size: contain;
  -o-background-size: contain;
  background-size: contain;
  -webkit-transition: opacity 400ms ease;
  -o-transition: opacity 400ms ease;
  transition: opacity 400ms ease;
}
.owl-carousel .owl-video-play-icon .owl-video-frame {
  position: relative;
  z-index: 1;
  height: 100%;
  width: 100%;
}
.owl-carousel .owl-dots,
.owl-carousel .owl-nav {
  text-align: center;
  -webkit-tap-highlight-color: transparent;
  line-height: 1;
}
.owl-carousel .owl-nav [class*=owl-] {
  position: absolute;
  top: 50%;
  margin-top: -18px;
  left: -36px;
  zoom: 1;
  width: 36px;
  height: 36px;
  line-height: 32px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  color: #666;
  background-color: #fff;
  font-size: 18px;
  border-radius: 50%;
  opacity: 0;
  left: -36px;
  transition: all 0.3s ease;
}
@media (prefers-reduced-motion: reduce) {
  .owl-carousel .owl-nav [class*=owl-] {
    transition: none;
  }
}
.owl-carousel.with-carousel-dots .owl-nav [class*=owl-] {
  margin-top: -38px;
}

.owl-nav .owl-prev,
.owl-nav .owl-next,
.owl-dot,
.owl-dots button {
  cursor: pointer;
  cursor: hand;
  padding: 0;
  border: 0;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.slider-element .owl-nav [class*=owl-],
.owl-carousel-full .owl-nav [class*=owl-] {
  margin-top: -30px;
  left: 0 !important;
  height: 60px;
  line-height: 60px;
  border: none;
  color: #eeeeee;
  background-color: rgba(0, 0, 0, 0.4);
  font-size: 28px;
  border-radius: 0 3px 3px 0;
}

.owl-carousel-full .with-carousel-dots .owl-nav [class*=owl-] {
  margin-top: -50px;
}

.owl-carousel .owl-nav .owl-next {
  left: auto;
  right: -36px;
}

.slider-element .owl-nav .owl-next,
.owl-carousel-full .owl-nav .owl-next {
  left: auto !important;
  right: 0 !important;
  border-radius: 3px 0 0 3px;
}

.owl-carousel:hover .owl-nav [class*=owl-] {
  opacity: 1;
  left: -18px;
}

.owl-carousel:hover .owl-nav .owl-next {
  left: auto;
  right: -18px;
}

.owl-carousel .owl-nav [class*=owl-]:hover {
  background-color: #2a93c9 !important;
  color: #fff !important;
  text-decoration: none;
}

.owl-carousel .owl-nav .disabled {
  display: none !important;
}

/* Owl Carousel - Controls - Dots
-----------------------------------------------------------------*/
.owl-carousel .owl-dots .owl-dot {
  display: inline-block;
  zoom: 1;
  opacity: 0.5;
  width: 8px;
  height: 8px;
  margin: 30px 4px 0 4px;
  border-radius: 50%;
  background-color: #2a93c9;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.owl-carousel .owl-dots .owl-dot.active, .owl-carousel .owl-dots .owl-dot:hover {
  opacity: 1;
}

/* ----------------------------------------------------------------

	Flip Cards

-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Flip Cards
-----------------------------------------------------------------*/
.flip-card {
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
  -webkit-perspective: 1000px;
  perspective: 1000px;
}
.flip-card-front, .flip-card-back {
  background-size: cover;
  background-position: center;
  transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  width: 100%;
  height: 280px;
  border-radius: 6px;
  color: #fff;
  font-size: 1.5rem;
}
@media (prefers-reduced-motion: reduce) {
  .flip-card-front, .flip-card-back {
    transition: none;
  }
}
.flip-card-front.no-after::after, .flip-card-back.no-after::after {
  content: none;
}
.flip-card-front::after, .flip-card-back::after {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  height: 100%;
  content: "";
  display: block;
  opacity: 0.6;
  background-color: #000;
  -webkit-backface-visibility: hidden;
  backface-visibility: hidden;
  border-radius: 6px;
}
.flip-card-front:hover .flip-card-front, .flip-card-front:hover .flip-card-back, .flip-card-back:hover .flip-card-front, .flip-card-back:hover .flip-card-back {
  transition: transform 0.7s cubic-bezier(0.4, 0.2, 0.2, 1);
}
@media (prefers-reduced-motion: reduce) {
  .flip-card-front:hover .flip-card-front, .flip-card-front:hover .flip-card-back, .flip-card-back:hover .flip-card-front, .flip-card-back:hover .flip-card-back {
    transition: none;
  }
}
.flip-card-back {
  background-color: #666;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}
.flip-card-inner {
  -webkit-transform: translateY(-50%) translateZ(60px) scale(0.94);
  transform: translateY(-50%) translateZ(60px) scale(0.94);
  top: 50%;
  position: absolute;
  left: 0;
  width: 100%;
  padding: 2rem;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  outline: 1px solid transparent;
  -webkit-perspective: inherit;
  perspective: inherit;
  z-index: 2;
}
.flip-card-back {
  -webkit-transform: rotateY(180deg);
  transform: rotateY(180deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.top-to-bottom .flip-card-back {
  -webkit-transform: rotateX(180deg);
  transform: rotateX(180deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.flip-card-front, .flip-card:hover .flip-card-back {
  -webkit-transform: rotateY(0deg);
  transform: rotateY(0deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.flip-card:hover .flip-card-front {
  -webkit-transform: rotateY(-180deg);
  transform: rotateY(-180deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.top-to-bottom .flip-card-front, .top-to-bottom:hover .flip-card-back {
  -webkit-transform: rotateX(0deg);
  transform: rotateX(0deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.top-to-bottom:hover .flip-card-front {
  -webkit-transform: rotateX(-180deg);
  transform: rotateX(-180deg);
  -webkit-transform-style: preserve-3d;
  transform-style: preserve-3d;
}
.flip-card-inner span {
  font-size: 18px;
  line-height: 20px;
  font-weight: 300;
}
.flip-card-inner p {
  position: relative;
  font-size: 1rem;
  margin-bottom: 0;
  color: rgba(255, 255, 255, 0.7);
}

/*-----------------------------------------------------------------------------------

	Shortcodes: headings.scss

-----------------------------------------------------------------------------------*/
/* Titular
-----------------------------------------------------------------*/
.titular-title {
  font-weight: 500;
  letter-spacing: -1px;
}
h1.titular-title, .titular-title.h1 {
  font-size: 3.25rem;
}

h2.titular-title, .titular-title.h2 {
  font-size: 2.75rem;
}

h3.titular-title, .titular-title.h3 {
  font-size: 2rem;
}

.titular-sub-title {
  margin: 0;
  font-weight: 600;
  letter-spacing: 4px;
  font-size: 1rem;
  text-transform: uppercase;
}
.titular-title + .titular-sub-title {
  margin-top: -25px;
  margin-bottom: 30px;
}

/* Block Titles
-----------------------------------------------------------------*/
.title-block {
  padding: 2px 0 3px 20px;
  border-left: 7px solid #2a93c9;
  margin-bottom: 30px;
}
.title-block-right {
  padding: 2px 20px 3px 0;
  border-left: 0;
  border-right: 7px solid #2a93c9;
  text-align: right;
}
.title-block h1, .title-block .h1,
.title-block h2,
.title-block .h2,
.title-block h3,
.title-block .h3,
.title-block h4,
.title-block .h4 {
  margin-bottom: 0;
}
.title-block > span {
  display: block;
  margin-top: 4px;
  color: #555555;
  font-weight: 300;
}
.title-block h1 + span, .title-block .h1 + span,
.title-block h2 + span,
.title-block .h2 + span {
  font-size: 1.25rem;
}
.title-block h3 + span, .title-block .h3 + span {
  font-size: 1.125rem;
}
.title-block h4 + span, .title-block .h4 + span {
  font-size: 0.9375rem;
}

/* Heading Block - with Subtitle
-----------------------------------------------------------------*/
.heading-block {
  margin-bottom: 50px;
}
.heading-block h1, .heading-block .h1,
.heading-block h2,
.heading-block .h2,
.heading-block h3,
.heading-block .h3,
.heading-block h4,
.heading-block .h4 {
  margin-bottom: 0;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #333333;
}
.heading-block h1, .heading-block .h1 {
  font-size: 32px;
}
.heading-block h2, .heading-block .h2 {
  font-size: 30px;
}
.heading-block h3, .heading-block .h3 {
  font-size: 1.75rem;
}
.heading-block h4, .heading-block .h4 {
  font-size: 1.25rem;
}
.heading-block > span:not(.before-heading) {
  display: block;
  margin-top: 10px;
  font-weight: 300;
  color: #111111;
}
.heading-block .before-heading {
  margin-bottom: 7px;
}
.heading-block.center > span, .heading-block.text-center > span, .center .heading-block > span, .text-center .heading-block > span {
  max-width: 700px;
  margin-left: auto;
  margin-right: auto;
}
@media (min-width: 768px) {
  .text-md-start .heading-block > span {
    max-width: none !important;
  }
}
.heading-block h1 + span, .heading-block .h1 + span {
  font-size: 24px;
}
.heading-block h2 + span, .heading-block .h2 + span,
.heading-block h3 + span,
.heading-block .h3 + span,
.heading-block h4 + span,
.heading-block .h4 + span {
  font-size: 20px;
}
.heading-block::after {
  content: "";
  display: block;
  margin-top: 30px;
  width: 40px;
  border-top: 2px solid #444;
}
.center .heading-block::after, .text-center .heading-block::after, .heading-block.center::after, .heading-block.text-center::after {
  margin: 30px auto 0;
}
@media (min-width: 768px) {
  .text-md-start .heading-block::after {
    margin-left: 0 !important;
  }
}
.text-end .heading-block, .heading-block.text-end, .heading-block.title-right {
  direction: rtl;
}
.heading-block.border-0::after, .heading-block.border-bottom-0::after {
  display: none;
}
.heading-block.border-color::after {
  border-color: #2a93c9;
}

/* Emphasis Title
-----------------------------------------------------------------*/
.emphasis-title {
  margin: 0 0 50px;
}
.emphasis-title h1, .emphasis-title .h1,
.emphasis-title h2,
.emphasis-title .h2 {
  font-weight: 700;
  text-transform: uppercase;
  color: #333;
  font-weight: 400;
  text-transform: none;
  font-size: 64px;
  letter-spacing: -2px;
}
.emphasis-title h1 strong, .emphasis-title .h1 strong,
.emphasis-title h2 strong,
.emphasis-title .h2 strong {
  font-weight: 700;
}

/* Justify Border Title
-----------------------------------------------------------------*/
.fancy-title {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  margin-bottom: 2rem;
}
.fancy-title h1, .fancy-title .h1,
.fancy-title h2,
.fancy-title .h2,
.fancy-title h3,
.fancy-title .h3,
.fancy-title h4,
.fancy-title .h4,
.fancy-title h5,
.fancy-title .h5,
.fancy-title h6,
.fancy-title .h6 {
  position: relative;
  margin-bottom: 0;
}
.fancy-title::before, .fancy-title::after {
  content: "";
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
  height: 0;
  border-top: 3px double #e5e5e5;
}
.fancy-title::before {
  display: none;
  margin-right: 0.75rem;
}
.fancy-title::after {
  margin-left: 0.75rem;
}

.title-border::before,
.title-border::after {
  border-top-width: 1px;
  border-top-style: solid;
}

.title-border-color::before,
.title-border-color::after {
  border-top: 1px solid #2a93c9;
}

/* Fancy Title - Center Align
-----------------------------------------------------------------*/
.title-center::before {
  display: block;
}

/* Fancy Title - Right Align
-----------------------------------------------------------------*/
.title-right::before {
  display: block;
}
.title-right::after {
  display: none;
}

/* Fancy Title - Bottom Short Border
-----------------------------------------------------------------*/
.title-bottom-border::before, .title-bottom-border::after {
  display: none;
}
.title-bottom-border h1, .title-bottom-border .h1,
.title-bottom-border h2,
.title-bottom-border .h2,
.title-bottom-border h3,
.title-bottom-border .h3,
.title-bottom-border h4,
.title-bottom-border .h4,
.title-bottom-border h5,
.title-bottom-border .h5,
.title-bottom-border h6,
.title-bottom-border .h6 {
  width: 100%;
  padding: 0 0 0.75rem;
  border-bottom: 2px solid #2a93c9;
}

/* Sub Heading
-----------------------------------------------------------------*/
.before-heading {
  display: block;
  margin: 0 0 5px;
  font: normal 400 16px "PT Serif", serif;
  color: #999999;
}

/*-----------------------------------------------------------------------------------

	Shortcodes: dividers.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Divider
-----------------------------------------------------------------*/
.divider {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  overflow: hidden;
  margin: 3rem auto;
  color: #e5e5e5;
  width: 100%;
  /* Divider - Icon Align Right
  -----------------------------------------------------------------*/
  /* Divider - Icon Align Center
  -----------------------------------------------------------------*/
  /* Divider - Rounded Icon
  -----------------------------------------------------------------*/
  /* Divider - Rounded & Border
  -----------------------------------------------------------------*/
  /* Divider - Line Only
  -----------------------------------------------------------------*/
}
.divider.divider-margin-lg {
  margin: 5rem auto;
}
.divider::after, .divider::before {
  content: "";
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  -ms-flex-positive: 1;
  flex-grow: 1;
  max-width: 100%;
  height: 1px;
  background-color: #eeeeee;
}
.divider::before {
  display: none;
  margin-right: 0.5rem;
}
.divider::after {
  margin-left: 0.5rem;
}
.divider.divider-thick::after, .divider.divider-thick::before {
  height: 3px;
}
.divider.divider-sm {
  width: 55%;
}
.divider.divider-xs {
  width: 35%;
}
.divider i,
.divider a, .divider-text {
  position: relative;
  -ms-flex: 0 0 auto;
  flex: 0 0 auto;
  width: 24px;
  max-width: 100%;
  line-height: 1;
  font-size: 18px !important;
  text-align: center;
}
.divider a, .divider-text {
  width: auto;
  color: inherit;
  -webkit-transition: all 0.3s ease;
  -o-transition: all 0.3s ease;
  transition: all 0.3s ease;
}
.divider a:hover, .divider-text:hover {
  color: #888888;
}
.divider.divider-right::before {
  display: block;
}
.divider.divider-right::after {
  display: none;
}
.divider.divider-center::before {
  display: block;
}
.divider.divider-rounded i, .divider.divider-border i {
  width: 40px;
  height: 40px;
  line-height: 40px;
  color: #bbbbbb;
  background-color: whitesmoke;
  border-radius: 50%;
}
.divider.divider-border::before, .divider.divider-border::after, .divider.divider-rounded::before, .divider.divider-rounded::after {
  margin-left: 0;
  margin-right: 0;
}
.divider.divider-border {
  color: #e5e5e5;
}
.divider.divider-border i {
  line-height: 38px;
  background-color: transparent;
  border: 1px solid #eeeeee;
}
.divider.divider-line::before, .divider.divider-line::after {
  margin-left: 0;
  margin-right: 0;
}

/*-----------------------------------------------------------------------------------

	Shortcodes: misc.scss

-----------------------------------------------------------------------------------*/
/* Infinity Scroll - Message Style
-----------------------------------------------------------------*/
#infscr-loading,
#portfolio-ajax-loader {
  position: fixed;
  z-index: 20;
  top: 50%;
  left: 50%;
  width: 48px;
  height: 48px;
  margin: -24px 0 0 -24px;
  background-color: rgba(0, 0, 0, 0.7);
  border-radius: 3px;
  line-height: 48px;
  font-size: 1.5rem;
  color: #FFF;
  text-align: center;
}
#infscr-loading img,
#portfolio-ajax-loader img {
  display: none;
  width: 24px;
  height: 24px;
  margin: 12px;
}

#portfolio-ajax-loader {
  display: none;
}
#portfolio-ajax-loader img {
  display: block;
}

.page-load-status {
  position: relative;
  display: none;
  padding: 30px 0;
}
.page-load-status .css3-spinner-ball-pulse-sync > div {
  background-color: #333;
}

/* ----------------------------------------------------------------
	Quotes & Blockquotes
-----------------------------------------------------------------*/
blockquote {
  padding: 10px 20px;
  margin: 0 0 20px;
  font-size: 1.125rem;
  border-left: 5px solid #EEE;
}
blockquote p {
  margin-bottom: 15px;
}
blockquote.float-start {
  max-width: 300px;
  margin: 5px 20px 10px 0;
  padding-right: 0;
}
blockquote.float-end {
  max-width: 300px;
  margin: 5px 0 10px 20px;
  padding-left: 0;
}

.blockquote-reverse {
  padding-right: 15px;
  padding-left: 0;
  border-right: 5px solid #EEE;
  border-left: 0;
  text-align: right;
}
.blockquote-reverse::before {
  content: "";
  left: auto;
  right: 0;
}

.quote {
  border: none !important;
  position: relative;
}
.quote p {
  position: relative;
}
.quote::before {
  font-family: "font-icons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  content: "";
  position: absolute;
  width: 48px;
  height: 48px;
  line-height: 48px;
  font-size: 42px;
  top: 0;
  left: 0;
  color: #EEE;
}

/* ----------------------------------------------------------------
	Dropcaps & Highlights
-----------------------------------------------------------------*/
.dropcap {
  float: left;
  font-size: 42px;
  line-height: 1;
  margin: 0 5px 0 0;
  text-transform: uppercase;
}

.highlight {
  padding: 2px 5px;
  background-color: #444;
  color: #FFF;
  border-radius: 2px;
}

/* ----------------------------------------------------------------
	Text Rotater
-----------------------------------------------------------------*/
.text-rotater .t-rotate {
  display: none;
}
.text-rotater .t-rotate.morphext, .text-rotater .t-rotate.plugin-typed-init {
  display: inherit;
}

.typed-cursor {
  font-weight: 400;
}

.text-rotater > .t-rotate > .animated {
  display: inline-block;
}

/* ----------------------------------------------------------------
	Overlays
-----------------------------------------------------------------*/
.bg-overlay,
.bg-overlay-bg,
.bg-overlay-content {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  overflow: hidden;
}

.bg-overlay {
  z-index: 5;
}
.bg-overlay [data-hover-animate]:not(.animated) {
  opacity: 0;
}
.bg-overlay-content {
  display: -ms-flexbox;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  padding: 20px;
}
.bg-overlay-bg {
  background-color: rgba(255, 255, 255, 0.85);
}

.dark .bg-overlay-bg,
.bg-overlay-bg.dark {
  background-color: rgba(0, 0, 0, 0.5);
}

.text-overlay-mask {
  opacity: 0.85;
  top: auto;
  bottom: 0;
  height: auto;
  padding: 40px 15px 15px;
  background: -moz-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.85) 100%);
  /* FF3.6+ */
  background: -webkit-gradient(linear, left top, left bottom, color-stop(0%, rgba(0, 0, 0, 0)), color-stop(100%, rgba(0, 0, 0, 0.85)));
  /* Chrome,Safari4+ */
  background: -webkit-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.85) 100%);
  /* Chrome10+,Safari5.1+ */
  background: -o-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.85) 100%);
  /* Opera 11.10+ */
  background: -ms-linear-gradient(top, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.85) 100%);
  /* IE10+ */
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.85) 100%);
  /* W3C */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#00000000", endColorstr="#a6000000",GradientType=0 );
  /* IE6-9 */
}

.overlay-trigger-icon {
  display: block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  margin: 0 0.25rem;
  font-size: 1.125rem;
  text-align: center;
  border-radius: 50%;
  -webkit-backface-visibility: hidden;
  transition: all 0.3s ease;
}

.overlay-trigger-icon .icon-line-play {
  position: relative;
  left: 2px;
}

.overlay-trigger-icon.size-sm {
  width: 30px;
  height: 30px;
  line-height: 30px;
  margin: 0 0.5rem;
  font-size: 0.875rem;
}

.overlay-trigger-icon.size-lg {
  width: 64px;
  height: 64px;
  line-height: 64px;
  margin: 0 0.5rem;
  font-size: 1.5rem;
}

/* ----------------------------------------------------------------
	Google Maps
-----------------------------------------------------------------*/
.gmap {
  position: relative;
  width: 100%;
  height: 450px;
}
.gmap img {
  max-width: none !important;
}

.gm-style .gm-style-iw h3 span, .gm-style .gm-style-iw .h3 span {
  font-size: inherit;
  font-family: inherit;
}

#map-overlay {
  position: relative;
  padding: 100px 0;
}
#map-overlay .gmap {
  position: absolute;
  height: 100%;
  top: 0;
  left: 0;
}

/* ----------------------------------------------------------------
	Google Custom Search
-----------------------------------------------------------------*/
#content .cse .gsc-control-cse,
#content .gsc-control-cse,
#content .gsc-above-wrapper-area,
#content .gsc-adBlock,
#content .gsc-thumbnail-inside,
#content .gsc-url-top,
#content .gsc-table-result,
#content .gsc-webResult,
#content .gsc-result {
  padding: 0 !important;
}
#content .gsc-selected-option-container {
  width: auto !important;
}
#content .gsc-result-info {
  padding-left: 0 !important;
}
#content .gsc-above-wrapper-area-container,
#content .gsc-table-result {
  margin-bottom: 10px;
}
#content .gcsc-branding {
  display: none;
}
#content .gsc-results,
#content .gsc-webResult {
  width: 100% !important;
}
#content .gs-no-results-result .gs-snippet,
#content .gs-error-result .gs-snippet {
  margin: 0 !important;
}

/* ----------------------------------------------------------------
	Magazine Specific Classes
-----------------------------------------------------------------*/
.top-advert {
  padding: 5px;
  border-left: 1px solid #eeeeee;
  border-right: 1px solid #eeeeee;
}
.top-advert a,
.top-advert img {
  display: block;
}

.bnews-title {
  display: block;
  float: left;
  margin-top: 2px;
  padding-top: 0.3em;
  text-transform: uppercase;
}

.bnews-slider {
  float: left;
  width: 970px;
  margin-left: 20px;
  min-height: 0;
}

/* ----------------------------------------------------------------
	Go To Top
-----------------------------------------------------------------*/
#gotoTop {
  display: none;
  z-index: 599;
  position: fixed;
  width: 40px;
  height: 40px;
  background-color: #333;
  background-color: rgba(0, 0, 0, 0.3);
  font-size: 1.25rem;
  line-height: 36px;
  text-align: center;
  color: #fff;
  top: auto;
  left: auto;
  right: 30px;
  bottom: 50px;
  cursor: pointer;
  border-radius: 2px;
}
body:not(.device-touch) #gotoTop {
  transition: background-color 0.2s linear;
  -webkit-transition: background-color 0.2s linear;
  -o-transition: background-color 0.2s linear;
}
.stretched #gotoTop {
  right: 30px;
  bottom: 50px;
}
#gotoTop:hover {
  background-color: #2a93c9;
}
@media (max-width: 575.98px) {
  #gotoTop {
    display: none !important;
  }
}

/* ----------------------------------------------------------------
	Read More
-----------------------------------------------------------------*/
.read-more-wrap {
  position: relative;
  overflow: hidden;
  -webkit-transition: height 0.5s ease;
  transition: height 0.5s ease;
}
.read-more-mask {
  position: absolute;
  z-index: 9;
  top: auto;
  bottom: 0;
  left: 0;
  width: 100%;
  min-height: 60px;
  height: 35%;
  background-image: linear-gradient(rgba(255, 255, 255, 0), white);
}
.read-more-trigger {
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
}
.read-more-trigger i {
  position: relative;
  top: 1px;
  margin-left: 3px;
}
.read-more-wrap .read-more-trigger {
  position: absolute;
  display: block !important;
  z-index: 10;
  top: auto;
  bottom: 5px;
  left: 5px;
  margin: 0 !important;
}

/* ----------------------------------------------------------------
	GDPR Settings
-----------------------------------------------------------------*/
.gdpr-settings {
  position: fixed;
  max-width: calc(100% - 2rem);
  margin-bottom: 1rem;
  border-radius: 4px;
  top: auto;
  bottom: 0;
  left: 1rem;
  opacity: 0;
  padding: 3rem;
  z-index: 999;
  background-color: #EEE;
  transition: all 0.3s ease;
}
.gdpr-settings-sm {
  max-width: 30rem;
  margin-right: 1rem;
}
.gdpr-settings-sm.gdpr-settings-right {
  left: auto;
  right: 1rem;
  margin-right: 0;
  margin-left: 1rem;
}
.gdpr-settings.dark {
  background-color: #111;
}

/* ----------------------------------------------------------------
	Error 404
-----------------------------------------------------------------*/
.error404 {
  display: block;
  font-size: 18vw;
  font-weight: 700;
  color: #DDD;
  line-height: 1;
  letter-spacing: 4px;
}
.error404-wrap .container {
  z-index: 7;
}
.error404-wrap .error404 {
  line-height: 0.9;
  margin-bottom: 40px;
  font-weight: bold;
  font-size: 19vw;
  color: #FFF !important;
  opacity: 0.2;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.4);
}
.error404-wrap .heading-block h4, .error404-wrap .heading-block .h4 {
  font-weight: 300;
  margin-bottom: 8px;
}
.error404-wrap .heading-block span {
  font-size: 1.125rem;
}
.error404-wrap form {
  max-width: 500px;
}

/* ----------------------------------------------------------------
	Landing Pages
-----------------------------------------------------------------*/
.landing-wide-form {
  background: rgba(0, 0, 0, 0.3);
  padding: 30px;
  border-radius: 3px;
}

.landing-form-overlay {
  position: absolute;
  z-index: 10;
  top: auto;
  left: auto;
  right: 0;
  bottom: -154px;
  background-color: rgba(0, 0, 0, 0.6);
  border-radius: 3px 3px 0 0;
}

.landing-video {
  z-index: 1;
  width: 560px;
  height: 315px;
  margin: 22px 0 0 95px;
  overflow: hidden;
}

.landing-promo h3, .landing-promo .h3 {
  font-size: 1.75rem;
}

.landing-promo > .container > span {
  font-size: 1.125rem;
}

/* Navigation Tree
-----------------------------------------------------------------*/
.nav-tree {
  position: relative;
  margin-bottom: 40px;
}
.nav-tree ul {
  margin-bottom: 0;
  list-style: none;
}
.nav-tree ul ul {
  display: none;
}
.nav-tree ul ul a {
  font-size: 12px;
  padding: 6px 0;
  letter-spacing: 1px;
  font-family: "Lato", sans-serif;
  padding-left: 20px;
}
.nav-tree ul ul a i.icon-angle-down {
  font-size: 12px;
}
.nav-tree ul ul ul a {
  padding-left: 40px;
}
.nav-tree ul ul ul ul a {
  padding-left: 60px;
}
.nav-tree ul ul ul ul ul a {
  padding-left: 80px;
}
.nav-tree > ul {
  margin: -8px 0;
}
.nav-tree li {
  position: relative;
}
.nav-tree li a {
  display: block;
  padding: 8px 0;
  color: #333333;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: 700;
  letter-spacing: 2px;
  font-family: "Poppins", sans-serif;
}
.nav-tree li i {
  font-size: 13px;
  width: 16px;
  text-align: center;
}
.nav-tree li i:not(.icon-angle-down) {
  margin-right: 8px;
  position: relative;
  top: 1px;
}
.nav-tree li i.icon-angle-down {
  width: auto;
  font-size: 12px;
  margin-left: 2px;
}
.nav-tree li:hover > a, .nav-tree li.current > a, .nav-tree li.active > a {
  color: #2a93c9 !important;
}
.nav-tree li.current > ul {
  display: block;
}

/* ----------------------------------------------------------------
	Wedding
-----------------------------------------------------------------*/
.wedding-head {
  position: relative;
  line-height: 1;
  font-size: 80px;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.15);
  font-family: "Poppins", sans-serif;
}
.wedding-head .first-name,
.wedding-head .last-name,
.wedding-head .and {
  display: inline-block;
  margin-right: 15px;
  font-weight: bold;
  text-align: right;
  text-transform: uppercase;
  letter-spacing: 2px;
}
.wedding-head .last-name {
  margin: 0 0 0 15px;
  text-align: left;
}
.wedding-head .first-name span,
.wedding-head .last-name span {
  display: block;
  margin-top: 10px;
  font-size: 56px;
  font-weight: 400;
  font-style: italic;
  font-family: "PT Serif", serif;
  text-transform: none;
}
.wedding-head .and {
  margin: 0;
  font-size: 200px;
  font-family: "Times New Roman", serif;
}

/* ------------------------------------------------------
	SWITCH
--------------------------------------------------------------  */
.switch-toggle {
  position: absolute;
  margin-left: -9999px;
  visibility: hidden;
}
.switch-toggle + label {
  display: block;
  position: relative;
  cursor: pointer;
  outline: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* --------------------------------------------------------------
	SWITCH 1 - ROUND
----------------------------------------------------------------- */
input.switch-toggle-round + label {
  padding: 2px;
  width: 60px;
  height: 30px;
  background-color: #dddddd;
  -webkit-border-radius: 15px;
  -o-border-radius: 15px;
  border-radius: 15px;
}
input.switch-toggle-round + label::before, input.switch-toggle-round + label::after {
  display: block;
  position: absolute;
  top: 1px;
  left: 1px;
  bottom: 1px;
  content: "";
}
input.switch-toggle-round + label::before {
  right: 1px;
  width: 100%;
  background-color: #f1f1f1;
  -webkit-border-radius: 15px;
  -o-border-radius: 15px;
  border-radius: 15px;
  -webkit-transition: background 0.4s;
  -moz-transition: background 0.4s;
  -o-transition: background 0.4s;
  transition: background 0.4s;
}
input.switch-toggle-round + label::after {
  width: 28px;
  background-color: #fff;
  -webkit-border-radius: 100%;
  -o-border-radius: 100%;
  border-radius: 100%;
  -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
  -webkit-transition: margin 0.4s;
  -moz-transition: margin 0.4s;
  -o-transition: margin 0.4s;
  transition: margin 0.4s;
}
input.switch-toggle-round:checked + label::before {
  background-color: #2a93c9;
}
input.switch-toggle-round:checked + label::after {
  margin-left: 30px;
}

/* --------------------------------------------------------------
	SWITCH 1 - ROUND- MINI
----------------------------------------------------------------- */
input.switch-rounded-mini.switch-toggle-round + label {
  padding: 1px;
  width: 32px;
  height: 16px;
  -webkit-border-radius: 8px;
  -o-border-radius: 8px;
  border-radius: 8px;
}
input.switch-rounded-mini.switch-toggle-round + label::before {
  -webkit-border-radius: 8px;
  -o-border-radius: 8px;
  border-radius: 8px;
}
input.switch-rounded-mini.switch-toggle-round + label::after {
  width: 15px;
}
input.switch-rounded-mini.switch-toggle-round:checked + label::after {
  margin-left: 15px;
}

/* --------------------------------------------------------------
	SWITCH 1 - ROUND- LARGE
----------------------------------------------------------------- */
input.switch-rounded-large.switch-toggle-round + label {
  width: 90px;
  height: 45px;
  -webkit-border-radius: 45px;
  -o-border-radius: 45px;
  border-radius: 45px;
}
input.switch-rounded-large.switch-toggle-round + label::before {
  -webkit-border-radius: 45px;
  -o-border-radius: 45px;
  border-radius: 45px;
}
input.switch-rounded-large.switch-toggle-round + label::after {
  width: 43px;
}
input.switch-rounded-large.switch-toggle-round:checked + label::after {
  margin-left: 45px;
}

/* --------------------------------------------------------------
	SWITCH 1 - ROUND- XLARGE
----------------------------------------------------------------- */
input.switch-rounded-xlarge.switch-toggle-round + label {
  width: 120px;
  height: 60px;
  -webkit-border-radius: 60px;
  -o-border-radius: 60px;
  border-radius: 60px;
}
input.switch-rounded-xlarge.switch-toggle-round + label::before {
  -webkit-border-radius: 60px;
  -o-border-radius: 60px;
  border-radius: 60px;
}
input.switch-rounded-xlarge.switch-toggle-round + label::after {
  width: 58px;
}
input.switch-rounded-xlarge.switch-toggle-round:checked + label::after {
  margin-left: 60px;
}

/* -----------------------------------------------------------
	SWITCH 2 - ROUND FLAT
-------------------------------------------------------------- */
input.switch-toggle-flat + label {
  padding: 2px;
  width: 60px;
  height: 30px;
  background-color: #dddddd;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  border-radius: 30px;
  -webkit-transition: background 0.4s;
  -moz-transition: background 0.4s;
  -o-transition: background 0.4s;
  transition: background 0.4s;
}
input.switch-toggle-flat + label::before, input.switch-toggle-flat + label::after {
  display: block;
  position: absolute;
  content: "";
}
input.switch-toggle-flat + label::before {
  top: 2px;
  left: 2px;
  bottom: 2px;
  right: 2px;
  background-color: #fff;
  -webkit-border-radius: 30px;
  -moz-border-radius: 30px;
  -ms-border-radius: 30px;
  -o-border-radius: 30px;
  border-radius: 30px;
  -webkit-transition: background 0.4s;
  -moz-transition: background 0.4s;
  -o-transition: background 0.4s;
  transition: background 0.4s;
}
input.switch-toggle-flat + label::after {
  top: 4px;
  left: 4px;
  bottom: 4px;
  width: 22px;
  background-color: #dddddd;
  -webkit-border-radius: 22px;
  -moz-border-radius: 22px;
  -ms-border-radius: 22px;
  -o-border-radius: 22px;
  border-radius: 22px;
  -webkit-transition: margin 0.4s, background 0.4s;
  -moz-transition: margin 0.4s, background 0.4s;
  -o-transition: margin 0.4s, background 0.4s;
  transition: margin 0.4s, background 0.4s;
}
input.switch-toggle-flat:checked + label {
  background-color: #2a93c9;
}
input.switch-toggle-flat:checked + label::after {
  margin-left: 30px;
  background-color: #2a93c9;
}

/* -----------------------------------------------------------
	SWITCH 2 - FLAT - MINI
-------------------------------------------------------------- */
input.switch-flat-mini.switch-toggle-flat + label {
  padding: 1px;
  width: 32px;
  height: 16px;
  -webkit-border-radius: 16px;
  -o-border-radius: 16px;
  border-radius: 16px;
}
input.switch-flat-mini.switch-toggle-flat + label::before {
  top: 1px;
  left: 1px;
  bottom: 1px;
  right: 1px;
  -webkit-border-radius: 16px;
  -o-border-radius: 16px;
  border-radius: 16px;
}
input.switch-flat-mini.switch-toggle-flat + label::after {
  top: 2px;
  left: 2px;
  bottom: 2px;
  width: 12px;
  -webkit-border-radius: 12px;
  -o-border-radius: 12px;
  border-radius: 12px;
}
input.switch-flat-mini.switch-toggle-flat:checked + label::after {
  margin-left: 16px;
}

/* -----------------------------------------------------------
	SWITCH 2 - FLAT - LARGE
-------------------------------------------------------------- */
input.switch-flat-large.switch-toggle-flat + label {
  width: 90px;
  height: 45px;
  -webkit-border-radius: 45px;
  -o-border-radius: 45px;
  border-radius: 45px;
}
input.switch-flat-large.switch-toggle-flat + label::before {
  -webkit-border-radius: 45px;
  -o-border-radius: 45px;
  border-radius: 45px;
}
input.switch-flat-large.switch-toggle-flat + label::after {
  width: 37px;
  -webkit-border-radius: 37px;
  -o-border-radius: 37px;
  border-radius: 37px;
}
input.switch-flat-large.switch-toggle-flat:checked + label::after {
  margin-left: 45px;
}

/* -----------------------------------------------------------
	SWITCH 2 - FLAT - XLARGE
-------------------------------------------------------------- */
input.switch-flat-xlarge.switch-toggle-flat + label {
  padding: 2px;
  width: 120px;
  height: 60px;
  -webkit-border-radius: 60px;
  -o-border-radius: 60px;
  border-radius: 60px;
}
input.switch-flat-xlarge.switch-toggle-flat + label::before {
  -webkit-border-radius: 60px;
  -o-border-radius: 60px;
  border-radius: 60px;
}
input.switch-flat-xlarge.switch-toggle-flat + label::after {
  width: 52px;
  -webkit-border-radius: 52px;
  -o-border-radius: 52px;
  border-radius: 52px;
}
input.switch-flat-xlarge.switch-toggle-flat:checked + label::after {
  margin-left: 60px;
}

.show-error-msg .switch + label.error {
  display: block !important;
}

.switch input[type=checkbox].error + label {
  border: 1px solid red;
}

@media (min-width: 576px) {
  .card-columns {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-gap: 1.25rem;
    -moz-column-gap: 1.25rem;
    column-gap: 1.25rem;
    orphans: 1;
    widows: 1;
  }

  .card-columns.grid-2 {
    -webkit-column-count: 2;
    -moz-column-count: 2;
    column-count: 2;
  }

  .card-columns > * {
    display: inline-block;
    width: 100%;
  }
}
.form-group {
  margin-bottom: 1rem;
}

/*-----------------------------------------------------------------------------------

	Shortcodes: Forms.scss

-----------------------------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Forms
-----------------------------------------------------------------*/
.sm-form-control {
  display: block;
  width: 100%;
  height: 38px;
  padding: 8px 14px;
  font-size: 15px;
  line-height: 1.42857143;
  color: #555;
  background-color: #fff;
  background-image: none;
  border: 2px solid #dddddd;
  border-radius: 0 !important;
  -webkit-transition: border-color ease-in-out 0.15s;
  -o-transition: border-color ease-in-out 0.15s;
  transition: border-color ease-in-out 0.15s;
  height: auto;
}
.sm-form-control:focus {
  border-color: #aaaaaa;
  outline: 0;
  -webkit-box-shadow: none;
  box-shadow: none;
}
.sm-form-control::-moz-placeholder {
  color: #999999;
  opacity: 1;
}
.sm-form-control:-ms-input-placeholder {
  color: #999999;
  opacity: 1;
}
.sm-form-control::-webkit-input-placeholder {
  color: #999999;
  opacity: 1;
}
.sm-form-control[disabled], .sm-form-control[readonly], fieldset[disabled] .sm-form-control {
  cursor: not-allowed;
  background-color: #eeeeee;
  opacity: 1;
}
.sm-form-control.error {
  border-color: #E42C3E;
}

.form-control {
  border-radius: 3px;
}
.form-control:active, .form-control:focus {
  border-color: #aaaaaa;
  box-shadow: none;
}
.form-control.error {
  border-color: #E42C3E;
}

label {
  display: inline-block;
  font-size: 13px;
  font-weight: 700;
  font-family: "Poppins", sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #555;
  margin-bottom: 10px;
  cursor: pointer;
}
label.label-muted {
  color: #aaaaaa;
  font-weight: normal;
  margin-right: 5px;
}
label.error {
  display: none !important;
  margin-top: 5px;
  color: #E42C3E;
  font-weight: 400;
}
.show-error-msg + label.error, input[type=checkbox]:not(:checked) + label.error {
  display: block !important;
}

/* ----------------------------------------------------------------
	Contact Form
-----------------------------------------------------------------*/
.contact-form-overlay {
  position: relative;
  background-color: #fff;
  z-index: 10;
  border-radius: 4px;
  box-shadow: 0 1px 10px rgba(0, 0, 0, 0.15);
}

/* ----------------------------------------------------------------
	Preloaders
-----------------------------------------------------------------*/
.preloader, .form-process, .preloader2 {
  display: block;
  width: 100%;
  height: 100%;
  background: center center no-repeat #fff;
}

.preloader2 {
  background-color: transparent;
}

.form-process {
  display: none;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 3;
  background-color: transparent;
}
.form-process::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  opacity: 0.7;
  z-index: -1;
}

/* ----------------------------------------------------------------

	footer.scss

-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Footer
-----------------------------------------------------------------*/
#footer {
  position: relative;
  background-color: #eeeeee;
  border-top: 5px solid rgba(0, 0, 0, 0.2);
}

@media (min-width: 992px) {
  .sticky-footer #slider:not(.slider-parallax-invisible),
.sticky-footer #page-submenu,
.sticky-footer #page-title,
.sticky-footer #content {
    z-index: 2;
  }

  .sticky-footer #footer {
    position: sticky;
    top: auto;
    bottom: 0;
    left: 0;
  }
}
#footer .footer-widgets-wrap {
  position: relative;
  padding: 80px 0;
}

#copyrights {
  padding: 40px 0;
  background-color: #dddddd;
  font-size: 0.875rem;
  line-height: 1.8;
}
#copyrights i.footer-icon {
  position: relative;
  top: 1px;
  font-size: 0.875rem;
  width: 0.875rem;
  text-align: center;
  margin-right: 3px;
}
#copyrights a {
  display: inline-block;
  margin: 0 3px;
  color: #333333;
  border-bottom: 1px dotted #444;
}
#copyrights a:hover {
  color: #555555;
  border-bottom: 1px solid #666666;
}
#copyrights .text-end a:last-child {
  margin-right: 0;
}

.copyrights-menu {
  margin-bottom: 10px;
}
.copyrights-menu a {
  font-size: 0.875rem;
  margin: 0 10px;
  border-bottom: 0 !important;
}
.copyrights-menu a:first-child {
  margin-left: 0;
}

.footer-logo {
  display: block;
  margin-bottom: 30px;
}

/* ----------------------------------------------------------------

	widgets.scss

-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Widgets
-----------------------------------------------------------------*/
.widget {
  position: relative;
  margin-top: 50px;
  /* Widget - Links
  -----------------------------------------------------------------*/
  /* Widget - Testimonial & Twitter
  -----------------------------------------------------------------*/
}
.widget p {
  line-height: 1.7;
}
.sidebar-widgets-wrap .widget {
  padding-top: 50px;
  border-top: 1px solid white;
}
.sidebar-widgets-wrap .widget:first-child {
  padding-top: 0;
  border-top: 0;
}
.sidebar-widgets-wrap .widget > h4, .sidebar-widgets-wrap .widget > .h4 {
  letter-spacing: 3px;
}
.widget:first-child {
  margin-top: 0;
}
.widget > h4, .widget > .h4 {
  margin-bottom: 25px;
  font-size: 15px;
  font-weight: 600;
  letter-spacing: 2px;
  text-transform: uppercase;
}
.widget_nav_menu ul,
.widget_nav_menu li, .widget_links ul,
.widget_links li, .widget_meta ul,
.widget_meta li, .widget_archive ul,
.widget_archive li, .widget_recent_comments ul,
.widget_recent_comments li, .widget_recent_entries ul,
.widget_recent_entries li, .widget_categories ul,
.widget_categories li, .widget_pages ul,
.widget_pages li, .widget_rss ul,
.widget_rss li {
  list-style: none;
  margin: 0;
}
.widget_nav_menu li, .widget_links li, .widget_meta li, .widget_archive li, .widget_recent_comments li, .widget_recent_entries li, .widget_categories li, .widget_pages li, .widget_rss li {
  display: flex;
  padding: 4px 0;
  font-size: 15px;
}
.widget_nav_menu li a, .widget_links li a, .widget_meta li a, .widget_archive li a, .widget_recent_comments li a, .widget_recent_entries li a, .widget_categories li a, .widget_pages li a, .widget_rss li a {
  display: inline-block;
  padding: 0 3px 0 12px;
  border: none !important;
  color: #444;
}
.widget_nav_menu li a:hover, .widget_links li a:hover, .widget_meta li a:hover, .widget_archive li a:hover, .widget_recent_comments li a:hover, .widget_recent_entries li a:hover, .widget_categories li a:hover, .widget_pages li a:hover, .widget_rss li a:hover {
  color: #000;
}
.widget_nav_menu:not(.widget-li-noicon) li::before, .widget_links:not(.widget-li-noicon) li::before, .widget_meta:not(.widget-li-noicon) li::before, .widget_archive:not(.widget-li-noicon) li::before, .widget_recent_comments:not(.widget-li-noicon) li::before, .widget_recent_entries:not(.widget-li-noicon) li::before, .widget_categories:not(.widget-li-noicon) li::before, .widget_pages:not(.widget-li-noicon) li::before, .widget_rss:not(.widget-li-noicon) li::before {
  content: "";
  font-family: "font-icons";
  display: inline-block;
  position: relative;
  top: 1px;
  left: 0;
}
.widget_nav_menu.widget-li-noicon li a, .widget_links.widget-li-noicon li a, .widget_meta.widget-li-noicon li a, .widget_archive.widget-li-noicon li a, .widget_recent_comments.widget-li-noicon li a, .widget_recent_entries.widget-li-noicon li a, .widget_categories.widget-li-noicon li a, .widget_pages.widget-li-noicon li a, .widget_rss.widget-li-noicon li a {
  padding-left: 0;
}
.widget_nav_menu > ul, .widget_links > ul, .widget_meta > ul, .widget_archive > ul, .widget_recent_comments > ul, .widget_recent_entries > ul, .widget_categories > ul, .widget_pages > ul, .widget_rss > ul {
  margin-top: -4px !important;
}
.widget_nav_menu > ul > li:first-child, .widget_links > ul > li:first-child, .widget_meta > ul > li:first-child, .widget_archive > ul > li:first-child, .widget_recent_comments > ul > li:first-child, .widget_recent_entries > ul > li:first-child, .widget_categories > ul > li:first-child, .widget_pages > ul > li:first-child, .widget_rss > ul > li:first-child {
  border-top: 0 !important;
}
.widget_nav_menu ul ul, .widget_links ul ul, .widget_meta ul ul, .widget_archive ul ul, .widget_recent_comments ul ul, .widget_recent_entries ul ul, .widget_categories ul ul, .widget_pages ul ul, .widget_rss ul ul {
  margin-left: 15px;
}
.widget_recent_comments li::before {
  content: "";
}
.widget .testimonial.no-image .testi-image {
  display: none;
}
.widget .testimonial.twitter-scroll .testi-image {
  margin-right: 10px;
  width: 28px;
  height: 28px;
}
.widget .testimonial.twitter-scroll .testi-image a,
.widget .testimonial.twitter-scroll .testi-image img,
.widget .testimonial.twitter-scroll .testi-image i {
  width: 28px;
  height: 28px;
}
.widget .testimonial.twitter-scroll .testi-image i {
  background-color: #eeeeee;
  line-height: 28px;
  font-size: 0.875rem;
  color: #888888;
}
.widget .testimonial:not(.twitter-scroll) .testi-image, .widget .testimonial:not(.twitter-scroll) .testi-image a, .widget .testimonial:not(.twitter-scroll) .testi-image img {
  width: 42px;
  height: 42px;
}
.widget .testimonial p {
  font-size: 0.875rem;
}

@media (min-width: 768px) and (max-width: 991.98px) {
  .sidebar-widgets-wrap {
    position: relative;
    display: -ms-flex;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0 -20px -40px -20px;
  }
  .sidebar-widgets-wrap .widget {
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
    margin: 0 0 40px 0;
    padding-top: 0;
    padding-left: 20px;
    padding-right: 20px;
    border-top: 0;
  }
}
/* Widget - Quick Contact Form
-----------------------------------------------------------------*/
.quick-contact-widget.form-widget .form-control,
.quick-contact-widget.form-widget .input-group,
.quick-contact-widget.form-widget .sm-form-control {
  margin-bottom: 10px;
}
.quick-contact-widget.form-widget .form-control {
  margin-bottom: 0;
}

.quick-contact-widget.form-widget form,
#template-contactform {
  position: relative;
}

/* Newsletter Widget
-----------------------------------------------------------------*/
.subscribe-widget h5, .subscribe-widget .h5 {
  font-weight: 300;
  font-size: 0.875rem;
  line-height: 1.5;
}

/* Twitter Feed Widget
-----------------------------------------------------------------*/
.widget-twitter-feed li {
  margin: 15px 0 0 0;
}
.widget-twitter-feed li:first-child {
  margin-top: 0;
}
.widget-twitter-feed small, .widget-twitter-feed .small {
  display: block;
  margin-top: 3px;
}
.widget-twitter-feed a:not(:hover) {
  color: #999999;
}
.widget-twitter-feed .twitter-feed:not(.twitter-feed-avatar) a.twitter-avatar,
.widget-twitter-feed .twitter-feed.twitter-feed-avatar i.icon-twitter {
  display: none;
}
.widget-twitter-feed .twitter-feed-avatar {
  margin-left: 44px;
}
.widget-twitter-feed .twitter-feed-avatar > li a.twitter-avatar {
  display: block;
  position: absolute;
  left: -44px;
  text-align: center;
  top: 2px;
  width: 32px;
  height: 32px;
}
.widget-twitter-feed a.twitter-avatar img {
  border-radius: 50%;
}

/* Widget Filter Links
-----------------------------------------------------------------*/
.widget-filter-links ul {
  list-style: none;
  margin-bottom: 0;
}
.widget-filter-links .widget-filter-reset {
  position: absolute;
  top: 0;
  left: auto;
  right: 0;
  font-size: 12px;
  line-height: 22px;
}
.widget-filter-links .widget-filter-reset a {
  color: red !important;
}
.widget-filter-links .widget-filter-reset.active-filter {
  display: none;
}
.widget-filter-links:not(:first-child) .widget-filter-reset {
  top: 50px;
}
.widget-filter-links li {
  position: relative;
  font-size: 15px;
  line-height: 24px;
}
.widget-filter-links li:not(:first-child) {
  margin-top: 10px;
}
.widget-filter-links li a {
  display: block;
  color: #444;
  font-family: "Poppins", sans-serif;
  font-weight: 500;
}
.widget-filter-links li span {
  position: absolute;
  top: 1px;
  left: auto;
  right: 0;
  font-size: 11px;
  width: 22px;
  height: 22px;
  line-height: 22px;
  text-align: center;
  color: #777777;
  background-color: #eeeeee;
  border-radius: 50%;
}
.widget-filter-links li:hover a {
  color: #777777;
}
.widget-filter-links li.active-filter a {
  font-weight: 700;
}
.widget-filter-links li.active-filter span {
  color: #FFF !important;
  background-color: #2a93c9 !important;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.1);
}

/* Tag Cloud
-----------------------------------------------------------------*/
.tagcloud {
  margin-bottom: -2px;
}
.tagcloud a {
  display: block;
  float: left;
  margin-right: 4px;
  margin-bottom: 4px;
  padding: 3px 6px;
  border: 1px solid #e5e5e5;
  font-size: 13px !important;
  color: #666666;
  border-radius: 2px;
}
body:not(.device-touch) .tagcloud a {
  -webkit-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.tagcloud a:hover {
  border-color: #2a93c9 !important;
  color: #2a93c9 !important;
}

/* ----------------------------------------------------------------

	Paginations.scss

-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Styled Paginations
-----------------------------------------------------------------*/
.pagination {
  /* Pagination Rounded */
  /* Pagination Margins */
  /* Pagination 3d */
  /* Pagination Transparent */
  /* Pagination Transparent */
  /* Pagination Inside Transparent */
  /* Pagination Button */
  /* Pagination Pill */
  /* Pagination Bootstrap Background */
  /* Pagination Bootstrap Background */
  /* Pagination Bootstrap Background */
  /* Pagination Bootstrap Background */
  /* Pagination Bootstrap Background */
  /* Pagination Bootstrap Background */
  /* Pagination Bootstrap Background */
  /* Pagination Bootstrap Background */
  /* Pagination light */
}
.pagination.pagination-circle .page-item .page-link {
  width: 35px;
  height: 35px;
  line-height: 33px;
  text-align: center;
  padding: 0;
  border-radius: 50%;
}
.pagination.pagination-circle.pagination-lg .page-item .page-link {
  width: 56px;
  height: 56px;
  line-height: 54px;
}
.pagination.pagination-circle.pagination-sm .page-item .page-link {
  width: 26px;
  height: 26px;
  line-height: 24px;
}
.pagination.pagination-rounded .page-item .page-link {
  border-radius: 4px;
}
.pagination.pagination-circle .page-item:not(:first-child) .page-link, .pagination.pagination-rounded .page-item:not(:first-child) .page-link {
  margin-left: 5px;
}
.pagination.pagination-circle.pagination-lg .page-item:not(:first-child) .page-link, .pagination.pagination-rounded.pagination-lg .page-item:not(:first-child) .page-link {
  margin-left: 8px;
}
.pagination.pagination-circle.pagination-sm .page-item:not(:first-child) .page-link, .pagination.pagination-rounded.pagination-sm .page-item:not(:first-child) .page-link {
  margin-left: 3px;
}
.pagination.pagination-3d .page-item .page-link {
  border-bottom: 3px solid rgba(0, 0, 0, 0.15) !important;
}
.pagination.pagination-transparent .page-item .page-link {
  border: 0;
}
.pagination.pagination-inside-transparent .page-item .page-link:not(:hover) {
  color: #222;
}
.pagination.pagination-inside-transparent .page-item:not(:first-child):not(:nth-child(2)):not(:nth-last-child(1)) .page-link {
  border-left: 0;
}
.pagination.pagination-inside-transparent .page-item.active .page-link, .pagination.pagination-inside-transparent .page-link:hover, .pagination.pagination-inside-transparent .page-link:focus {
  border-color: transparent !important;
}
.pagination.pagination-inside-transparent.pagination-button .page-item:not(:first-child):not(:nth-last-child(1)) .page-link {
  border: 0;
}
.pagination.pagination-pill .page-item:first-child .page-link {
  border-top-left-radius: 10rem;
  border-bottom-left-radius: 10rem;
}
.pagination.pagination-pill .page-item:last-child .page-link {
  border-top-right-radius: 10rem;
  border-bottom-right-radius: 10rem;
}
.pagination.pagination-primary .page-item .page-link {
  background-color: #0d6efd;
  border-color: #0d6efd;
  color: #FFF;
}
.pagination.pagination-primary .page-item.active .page-link,
.pagination.pagination-primary .page-link:hover,
.pagination.pagination-primary .page-link:focus {
  background-color: #222 !important;
  border-color: #222 !important;
  color: #FFF;
}
.pagination.pagination-secondary .page-item .page-link {
  background-color: #6c757d;
  border-color: #6c757d;
  color: #FFF;
}
.pagination.pagination-secondary .page-item.active .page-link,
.pagination.pagination-secondary .page-link:hover,
.pagination.pagination-secondary .page-link:focus {
  background-color: #222 !important;
  border-color: #222 !important;
  color: #FFF;
}
.pagination.pagination-success .page-item .page-link {
  background-color: #198754;
  border-color: #198754;
  color: #FFF;
}
.pagination.pagination-success .page-item.active .page-link,
.pagination.pagination-success .page-link:hover,
.pagination.pagination-success .page-link:focus {
  background-color: #222 !important;
  border-color: #222 !important;
  color: #FFF;
}
.pagination.pagination-info .page-item .page-link {
  background-color: #0dcaf0;
  border-color: #0dcaf0;
  color: #FFF;
}
.pagination.pagination-info .page-item.active .page-link,
.pagination.pagination-info .page-link:hover,
.pagination.pagination-info .page-link:focus {
  background-color: #222 !important;
  border-color: #222 !important;
  color: #FFF;
}
.pagination.pagination-warning .page-item .page-link {
  background-color: #ffc107;
  border-color: #ffc107;
  color: #FFF;
}
.pagination.pagination-warning .page-item.active .page-link,
.pagination.pagination-warning .page-link:hover,
.pagination.pagination-warning .page-link:focus {
  background-color: #222 !important;
  border-color: #222 !important;
  color: #FFF;
}
.pagination.pagination-danger .page-item .page-link {
  background-color: #dc3545;
  border-color: #dc3545;
  color: #FFF;
}
.pagination.pagination-danger .page-item.active .page-link,
.pagination.pagination-danger .page-link:hover,
.pagination.pagination-danger .page-link:focus {
  background-color: #222 !important;
  border-color: #222 !important;
  color: #FFF;
}
.pagination.pagination-light .page-item .page-link {
  background-color: #f8f9fa;
  border-color: #f8f9fa;
  color: #FFF;
}
.pagination.pagination-light .page-item.active .page-link,
.pagination.pagination-light .page-link:hover,
.pagination.pagination-light .page-link:focus {
  background-color: #222 !important;
  border-color: #222 !important;
  color: #FFF;
}
.pagination.pagination-dark .page-item .page-link {
  background-color: #212529;
  border-color: #212529;
  color: #FFF;
}
.pagination.pagination-dark .page-item.active .page-link,
.pagination.pagination-dark .page-link:hover,
.pagination.pagination-dark .page-link:focus {
  background-color: #222 !important;
  border-color: #222 !important;
  color: #FFF;
}
.pagination.pagination-light .page-item .page-link {
  color: #222;
}

/* ----------------------------------------------------------------

	extras.scss

-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Bootstrap Specific
-----------------------------------------------------------------*/
.table .success {
  color: #3c763d;
}
.table .info {
  color: #31708f;
}
.table .warning {
  color: #8a6d3b;
}
.table .danger {
  color: #a94442;
}

.btn-link,
.page-link,
.page-link:hover,
.page-link:focus {
  color: #2a93c9;
}

.btn-link:hover, .btn-link:focus {
  color: #222;
}

.boot-tabs {
  margin-bottom: 20px !important;
}

.carousel-control .icon-chevron-left,
.carousel-control .icon-chevron-right {
  position: absolute;
  top: 47.5%;
  z-index: 5;
  display: inline-block;
}
.carousel-control .icon-chevron-left {
  left: 50%;
}
.carousel-control .icon-chevron-right {
  right: 50%;
}

.responsive-utilities th small, .responsive-utilities th .small {
  display: block;
  font-weight: 400;
  color: #999;
}

.show-grid [class^=col-] {
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #EEE;
  border: 1px solid #DDD;
}

.img-circle {
  border-radius: 50% !important;
}

.page-item.active .page-link,
.page-link:hover,
.page-link:focus {
  color: #FFF !important;
  background-color: #2a93c9 !important;
  border-color: #2a93c9 !important;
}

.nav-pills > li.active > a,
.nav-pills > li.active > a:hover,
.nav-pills > li.active > a:focus {
  background-color: #2a93c9;
}

label.radio, label.checkbox {
  padding-left: 20px;
}

.input-group-lg > .input-group-addon {
  line-height: 1;
}

/* ----------------------------------------------------------------
	Shape Dividers
-----------------------------------------------------------------*/
.shape-divider {
  position: absolute;
  top: -1px;
  left: 0;
  width: 100%;
  overflow: hidden;
  z-index: 1;
}
.shape-divider[data-position=bottom] {
  top: auto;
  bottom: -1px;
}
.shape-divider[data-front=true] {
  z-index: 4;
}
.shape-divider svg {
  position: relative;
  width: 100%;
  width: calc( 100% + 1.3px );
  height: 50px;
  left: 50%;
  transform: translateX(-50%);
  opacity: 0;
}
.shape-divider[data-position=bottom], .shape-divider[data-flip-vertical=true] {
  transform: rotate(180deg);
}
.shape-divider[data-position=bottom][data-flip-vertical=true] {
  transform: rotate(0deg);
}
.shape-divider[data-flip=true] svg {
  transform: translateX(-50%) rotateY(180deg);
}
.shape-divider-fill {
  fill: #fff;
  transform-origin: center;
  transform: rotateY(0deg);
}

/* ----------------------------------------------------------------
	HTML5 Youtube Video Backgrounds
-----------------------------------------------------------------*/
.mb_YTVPlayer {
  display: block;
  transform: translateZ(0);
  transform-style: preserve-3d;
  perspective: 1000;
  backface-visibility: hidden;
  transform: translate3d(0, 0, 0);
  animation-timing-function: linear;
}
.mb_YTVPlayer:focus {
  outline: 0;
}
.mb_YTVPlayer.fullscreen {
  display: block !important;
  position: fixed !important;
  width: 100% !important;
  height: 100% !important;
  top: 0 !important;
  left: 0 !important;
  margin: 0 !important;
  border: none !important;
}
.mb_YTVPlayer.fullscreen .mbYTP_wrapper {
  opacity: 1 !important;
}

.mbYTP_wrapper iframe {
  max-width: 4000px !important;
}

.inline_YTPlayer {
  margin-bottom: 20px;
  vertical-align: top;
  position: relative;
  left: 0;
  overflow: hidden;
  border-radius: 4px;
  -moz-box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
  -webkit-box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.7);
  background: rgba(0, 0, 0, 0.5);
}
.inline_YTPlayer img {
  border: none !important;
  -moz-transform: none !important;
  -webkit-transform: none !important;
  -o-transform: none !important;
  transform: none !important;
  margin: 0 !important;
  padding: 0 !important;
}

/* ----------------------------------------------------------------
	Datepicker Component
-----------------------------------------------------------------*/
.datepicker td,
.datepicker th {
  padding: 5px;
}

/* ----------------------------------------------------------------
	Star Ratings Component
-----------------------------------------------------------------*/
.rating-xl {
  font-size: 3rem !important;
}
.rating-lg {
  font-size: 2.5rem !important;
}
.rating-md {
  font-size: 2rem !important;
}
.rating-sm {
  font-size: 1.75rem !important;
}
.rating-xs {
  font-size: 1.25rem !important;
}
.rating-container .star {
  margin: 0 2px !important;
}
.rating-container .filled-stars {
  -webkit-text-stroke: 0px !important;
  text-shadow: none !important;
}

/* ----------------------------------------------------------------
	Custom Animate.css Styles
-----------------------------------------------------------------*/
@-webkit-keyframes fadeInDownSmall {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInDownSmall {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.fadeInDownSmall {
  -webkit-animation-name: fadeInDownSmall;
  animation-name: fadeInDownSmall;
}

@-webkit-keyframes fadeInLeftSmall {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInLeftSmall {
  from {
    opacity: 0;
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.fadeInLeftSmall {
  -webkit-animation-name: fadeInLeftSmall;
  animation-name: fadeInLeftSmall;
}

@-webkit-keyframes fadeInRightSmall {
  from {
    opacity: 0;
    -webkit-transform: translate3d(50%, 0, 0);
    transform: translate3d(50%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInRightSmall {
  from {
    opacity: 0;
    -webkit-transform: translate3d(50%, 0, 0);
    transform: translate3d(50%, 0, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.fadeInRightSmall {
  -webkit-animation-name: fadeInRightSmall;
  animation-name: fadeInRightSmall;
}

@-webkit-keyframes fadeInUpSmall {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
@keyframes fadeInUpSmall {
  from {
    opacity: 0;
    -webkit-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }
  to {
    opacity: 1;
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
  }
}
.fadeInUpSmall {
  -webkit-animation-name: fadeInUpSmall;
  animation-name: fadeInUpSmall;
}

@-webkit-keyframes fadeOutDownSmall {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }
}
@keyframes fadeOutDownSmall {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, 50%, 0);
    transform: translate3d(0, 50%, 0);
  }
}
.fadeOutDownSmall {
  -webkit-animation-name: fadeOutDownSmall;
  animation-name: fadeOutDownSmall;
}

@-webkit-keyframes fadeOutLeftSmall {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
}
@keyframes fadeOutLeftSmall {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(-50%, 0, 0);
    transform: translate3d(-50%, 0, 0);
  }
}
.fadeOutLeftSmall {
  -webkit-animation-name: fadeOutLeftSmall;
  animation-name: fadeOutLeftSmall;
}

@-webkit-keyframes fadeOutRightSmall {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(50%, 0, 0);
    transform: translate3d(50%, 0, 0);
  }
}
@keyframes fadeOutRightSmall {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(50%, 0, 0);
    transform: translate3d(50%, 0, 0);
  }
}
.fadeOutRightSmall {
  -webkit-animation-name: fadeOutRightSmall;
  animation-name: fadeOutRightSmall;
}

@-webkit-keyframes fadeOutUpSmall {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
  }
}
@keyframes fadeOutUpSmall {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
    -webkit-transform: translate3d(0, -50%, 0);
    transform: translate3d(0, -50%, 0);
  }
}
.fadeOutUpSmall {
  -webkit-animation-name: fadeOutUpSmall;
  animation-name: fadeOutUpSmall;
}

/* ----------------------------------------------------------------

	page-loaders.scss

-----------------------------------------------------------------*/
/* ----------------------------------------------------------------
	Page Transitions
-----------------------------------------------------------------*/
.page-transition #wrapper,
.animsition-overlay {
  position: relative;
  opacity: 0;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.page-transition-wrap, .css3-spinner {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 1000;
  text-align: center;
  background-color: #fff;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  -webkit-perspective: 1000;
}

.css3-spinner {
  position: absolute;
  z-index: auto;
  background-color: transparent;
}
.css3-spinner > div {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-top: -9px;
  margin-left: 13px;
  width: 18px;
  height: 18px;
  background-color: #dddddd;
  border-radius: 100%;
  display: inline-block;
  -webkit-animation: bouncedelay 1.4s infinite ease-in-out;
  animation: bouncedelay 1.4s infinite ease-in-out;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.css3-spinner .css3-spinner-bounce1 {
  margin-left: -31px;
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}
.css3-spinner .css3-spinner-bounce2 {
  margin-left: -9px;
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
@-webkit-keyframes bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
@keyframes bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0);
    transform: scale(0);
  }
  40% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
}
.css3-spinner > .css3-spinner-flipper {
  width: 32px;
  height: 32px;
  margin-top: -16px;
  margin-left: -16px;
  border-radius: 0;
  -webkit-animation: rotateplane 1.2s infinite ease-in-out;
  animation: rotateplane 1.2s infinite ease-in-out;
}
@-webkit-keyframes rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}
@keyframes rotateplane {
  0% {
    transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(0deg) rotateY(0deg);
  }
  50% {
    transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    -webkit-transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
  }
  100% {
    transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    -webkit-transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
  }
}
.css3-spinner > .css3-spinner-double-bounce1, .css3-spinner > .css3-spinner-double-bounce2 {
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
  border-radius: 50%;
  opacity: 0.6;
  -webkit-animation: cssspinnerbounce 2s infinite ease-in-out;
  animation: cssspinnerbounce 2s infinite ease-in-out;
}
.css3-spinner > .css3-spinner-double-bounce2 {
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
@-webkit-keyframes cssspinnerbounce {
  0%, 100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
@keyframes cssspinnerbounce {
  0%, 100% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  50% {
    transform: scale(1);
    -webkit-transform: scale(1);
  }
}
.css3-spinner > .css3-spinner-rect1, .css3-spinner > .css3-spinner-rect2, .css3-spinner > .css3-spinner-rect3, .css3-spinner > .css3-spinner-rect4, .css3-spinner > .css3-spinner-rect5 {
  height: 30px;
  width: 6px;
  margin-top: -15px;
  margin-left: -21px;
  border-radius: 0;
  -webkit-animation: stretchdelay 1.2s infinite ease-in-out;
  animation: stretchdelay 1.2s infinite ease-in-out;
}
.css3-spinner > .css3-spinner-rect2 {
  margin-left: -12px;
  -webkit-animation-delay: -1.1s;
  animation-delay: -1.1s;
}
.css3-spinner > .css3-spinner-rect3 {
  margin-left: -3px;
  -webkit-animation-delay: -1s;
  animation-delay: -1s;
}
.css3-spinner > .css3-spinner-rect4 {
  margin-left: 6px;
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
.css3-spinner > .css3-spinner-rect5 {
  margin-left: 15px;
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
@-webkit-keyframes stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}
@keyframes stretchdelay {
  0%, 40%, 100% {
    transform: scaleY(0.4);
    -webkit-transform: scaleY(0.4);
  }
  20% {
    transform: scaleY(1);
    -webkit-transform: scaleY(1);
  }
}
.css3-spinner > .css3-spinner-cube1, .css3-spinner > .css3-spinner-cube2 {
  width: 16px;
  height: 16px;
  border-radius: 0;
  margin-top: -20px;
  margin-left: -20px;
  -webkit-animation: cubemove 1.8s infinite ease-in-out;
  animation: cubemove 1.8s infinite ease-in-out;
}
.css3-spinner > .css3-spinner-cube2 {
  -webkit-animation-delay: -0.9s;
  animation-delay: -0.9s;
}
@-webkit-keyframes cubemove {
  25% {
    transform: translateX(42px) rotate(-90deg) scale(0.5);
    -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
  }
  50% {
    transform: translateX(42px) translateY(42px) rotate(-179deg);
    -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
  }
  50.1% {
    transform: translateX(42px) translateY(42px) rotate(-180deg);
    -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
  }
  75% {
    transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
    -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
  }
  100% {
    transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
  }
}
@keyframes cubemove {
  25% {
    transform: translateX(42px) rotate(-90deg) scale(0.5);
    -webkit-transform: translateX(42px) rotate(-90deg) scale(0.5);
  }
  50% {
    transform: translateX(42px) translateY(42px) rotate(-179deg);
    -webkit-transform: translateX(42px) translateY(42px) rotate(-179deg);
  }
  50.1% {
    transform: translateX(42px) translateY(42px) rotate(-180deg);
    -webkit-transform: translateX(42px) translateY(42px) rotate(-180deg);
  }
  75% {
    transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
    -webkit-transform: translateX(0px) translateY(42px) rotate(-270deg) scale(0.5);
  }
  100% {
    transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
  }
}
.css3-spinner > .css3-spinner-scaler {
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
  -webkit-animation: scaleout 1s infinite ease-in-out;
  animation: scaleout 1s infinite ease-in-out;
}
@-webkit-keyframes scaleout {
  0% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
    opacity: 0;
  }
}
@keyframes scaleout {
  0% {
    transform: scale(0);
    -webkit-transform: scale(0);
  }
  100% {
    transform: scale(1);
    -webkit-transform: scale(1);
    opacity: 0;
  }
}
.css3-spinner > .css3-spinner-grid-pulse {
  display: -ms-flex;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  width: 48px;
  height: 48px;
  margin-top: -24px;
  margin-left: -24px;
  background-color: transparent !important;
  -webkit-animation: none;
  animation: none;
}
.css3-spinner-grid-pulse > div {
  background-color: #dddddd;
  width: 12px;
  height: 12px;
  max-width: 12px;
  border-radius: 100%;
  margin: 2px;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  display: inline-block;
  -webkit-animation-name: ball-grid-pulse;
  animation-name: ball-grid-pulse;
  -webkit-animation-iteration-count: infinite;
  animation-iteration-count: infinite;
  -webkit-animation-delay: 0;
  animation-delay: 0;
}
.css3-spinner-grid-pulse > div:nth-child(1) {
  -webkit-animation-delay: 0.73s;
  animation-delay: 0.73s;
  -webkit-animation-duration: 1.3s;
  animation-duration: 1.3s;
}
.css3-spinner-grid-pulse > div:nth-child(2) {
  -webkit-animation-delay: 0.32s;
  animation-delay: 0.32s;
  -webkit-animation-duration: 1.3s;
  animation-duration: 1.3s;
}
.css3-spinner-grid-pulse > div:nth-child(3) {
  -webkit-animation-delay: 0.71s;
  animation-delay: 0.71s;
  -webkit-animation-duration: 0.88s;
  animation-duration: 0.88s;
}
.css3-spinner-grid-pulse > div:nth-child(4) {
  -webkit-animation-delay: 0.62s;
  animation-delay: 0.62s;
  -webkit-animation-duration: 1.06s;
  animation-duration: 1.06s;
}
.css3-spinner-grid-pulse > div:nth-child(5) {
  -webkit-animation-delay: 0.31s;
  animation-delay: 0.31s;
  -webkit-animation-duration: 0.62s;
  animation-duration: 0.62s;
}
.css3-spinner-grid-pulse > div:nth-child(6) {
  -webkit-animation-delay: -0.14s;
  animation-delay: -0.14s;
  -webkit-animation-duration: 1.48s;
  animation-duration: 1.48s;
}
.css3-spinner-grid-pulse > div:nth-child(7) {
  -webkit-animation-delay: -0.1s;
  animation-delay: -0.1s;
  -webkit-animation-duration: 1.47s;
  animation-duration: 1.47s;
}
.css3-spinner-grid-pulse > div:nth-child(8) {
  -webkit-animation-delay: 0.4s;
  animation-delay: 0.4s;
  -webkit-animation-duration: 1.49s;
  animation-duration: 1.49s;
}
.css3-spinner-grid-pulse > div:nth-child(9) {
  -webkit-animation-delay: 0.73s;
  animation-delay: 0.73s;
  -webkit-animation-duration: 0.7s;
  animation-duration: 0.7s;
}
@-webkit-keyframes ball-grid-pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0.7;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
@keyframes ball-grid-pulse {
  0% {
    -webkit-transform: scale(1);
    transform: scale(1);
  }
  50% {
    -webkit-transform: scale(0.5);
    transform: scale(0.5);
    opacity: 0.7;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 1;
  }
}
.css3-spinner > .css3-spinner-clip-rotate {
  width: 33px;
  height: 33px;
  margin-top: -17px;
  margin-left: -17px;
  background-color: transparent !important;
  -webkit-animation: none;
  animation: none;
}
.css3-spinner-clip-rotate > div {
  border-radius: 100%;
  border: 2px solid #dddddd;
  border-bottom-color: transparent !important;
  height: 33px;
  width: 33px;
  background: transparent !important;
  -webkit-animation: rotate 1s 0s linear infinite;
  animation: rotate 1s 0s linear infinite;
}
@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
  }
  50% {
    -webkit-transform: rotate(180deg) scale(0.6);
    transform: rotate(180deg) scale(0.6);
  }
  100% {
    -webkit-transform: rotate(360deg) scale(1);
    transform: rotate(360deg) scale(1);
  }
}
@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg) scale(1);
    transform: rotate(0deg) scale(1);
  }
  50% {
    -webkit-transform: rotate(180deg) scale(0.6);
    transform: rotate(180deg) scale(0.6);
  }
  100% {
    -webkit-transform: rotate(360deg) scale(1);
    transform: rotate(360deg) scale(1);
  }
}
.css3-spinner > .css3-spinner-ball-rotate {
  width: 12px;
  height: 12px;
  margin-top: -6px;
  margin-left: -6px;
  background-color: transparent !important;
  -webkit-animation: ballrotate 1s 0s cubic-bezier(0.7, -0.13, 0.22, 0.86) infinite;
  animation: ballrotate 1s 0s cubic-bezier(0.7, -0.13, 0.22, 0.86) infinite;
}
.css3-spinner-ball-rotate > div {
  background-color: #dddddd;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  position: relative;
}
.css3-spinner-ball-rotate > div:nth-child(1), .css3-spinner-ball-rotate > div:nth-child(3) {
  width: 12px;
  height: 12px;
  border-radius: 100%;
  content: "";
  position: absolute;
  opacity: 0.7;
}
.css3-spinner-ball-rotate > div:nth-child(1) {
  top: 0px;
  left: -22px;
}
.css3-spinner-ball-rotate > div:nth-child(3) {
  top: 0px;
  left: 22px;
}
@-webkit-keyframes ballrotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
@keyframes ballrotate {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  50% {
    -webkit-transform: rotate(180deg);
    transform: rotate(180deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.css3-spinner > .css3-spinner-zig-zag {
  background-color: transparent !important;
  -webkit-transform: translate(-15px, -15px);
  -ms-transform: translate(-15px, -15px);
  transform: translate(-15px, -15px);
  -webkit-animation: none;
  animation: none;
}
.css3-spinner-zig-zag > div {
  background-color: #dddddd;
  width: 12px;
  height: 12px;
  border-radius: 100%;
  position: absolute;
  margin-left: 15px;
  top: 4px;
  left: -7px;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.css3-spinner-zig-zag > div:first-child {
  -webkit-animation: ball-zig-deflect 1.5s 0s infinite linear;
  animation: ball-zig-deflect 1.5s 0s infinite linear;
}
.css3-spinner-zig-zag > div:last-child {
  -webkit-animation: ball-zag-deflect 1.5s 0s infinite linear;
  animation: ball-zag-deflect 1.5s 0s infinite linear;
}
@-webkit-keyframes ball-zig-deflect {
  17% {
    -webkit-transform: translate(-15px, -30px);
    transform: translate(-15px, -30px);
  }
  34% {
    -webkit-transform: translate(15px, -30px);
    transform: translate(15px, -30px);
  }
  50% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  67% {
    -webkit-transform: translate(15px, -30px);
    transform: translate(15px, -30px);
  }
  84% {
    -webkit-transform: translate(-15px, -30px);
    transform: translate(-15px, -30px);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@keyframes ball-zig-deflect {
  17% {
    -webkit-transform: translate(-15px, -30px);
    transform: translate(-15px, -30px);
  }
  34% {
    -webkit-transform: translate(15px, -30px);
    transform: translate(15px, -30px);
  }
  50% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  67% {
    -webkit-transform: translate(15px, -30px);
    transform: translate(15px, -30px);
  }
  84% {
    -webkit-transform: translate(-15px, -30px);
    transform: translate(-15px, -30px);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@-webkit-keyframes ball-zag-deflect {
  17% {
    -webkit-transform: translate(15px, 30px);
    transform: translate(15px, 30px);
  }
  34% {
    -webkit-transform: translate(-15px, 30px);
    transform: translate(-15px, 30px);
  }
  50% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  67% {
    -webkit-transform: translate(-15px, 30px);
    transform: translate(-15px, 30px);
  }
  84% {
    -webkit-transform: translate(15px, 30px);
    transform: translate(15px, 30px);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
@keyframes ball-zag-deflect {
  17% {
    -webkit-transform: translate(15px, 30px);
    transform: translate(15px, 30px);
  }
  34% {
    -webkit-transform: translate(-15px, 30px);
    transform: translate(-15px, 30px);
  }
  50% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
  67% {
    -webkit-transform: translate(-15px, 30px);
    transform: translate(-15px, 30px);
  }
  84% {
    -webkit-transform: translate(15px, 30px);
    transform: translate(15px, 30px);
  }
  100% {
    -webkit-transform: translate(0, 0);
    transform: translate(0, 0);
  }
}
.css3-spinner > .css3-spinner-ball-scale-multiple {
  width: 60px;
  height: 60px;
  margin-left: -30px;
  margin-top: -30px;
  background-color: transparent !important;
  -webkit-animation: none;
  animation: none;
}
.css3-spinner-ball-scale-multiple > div {
  background-color: #dddddd;
  border-radius: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
  width: 60px;
  height: 60px;
  -webkit-animation: ball-scale-multiple 1.25s 0s linear infinite;
  animation: ball-scale-multiple 1.25s 0s linear infinite;
}
.css3-spinner-ball-scale-multiple > div:nth-child(2) {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.css3-spinner-ball-scale-multiple > div:nth-child(3) {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
@-webkit-keyframes ball-scale-multiple {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}
@keyframes ball-scale-multiple {
  0% {
    -webkit-transform: scale(0);
    transform: scale(0);
    opacity: 0;
  }
  5% {
    opacity: 1;
  }
  100% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0;
  }
}
.css3-spinner > .css3-spinner-triangle-path {
  background-color: transparent !important;
  -webkit-transform: translate(-29.994px, -37.50938px);
  -ms-transform: translate(-29.994px, -37.50938px);
  transform: translate(-29.994px, -37.50938px);
  -webkit-animation: none;
  animation: none;
}
.css3-spinner-triangle-path > div {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  position: absolute;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: #dddddd;
}
.css3-spinner-triangle-path > div:nth-child(1) {
  -webkit-animation: ball-triangle-path-1 2s 0s ease-in-out infinite;
  animation: ball-triangle-path-1 2s 0s ease-in-out infinite;
}
.css3-spinner-triangle-path > div:nth-child(2) {
  -webkit-animation: ball-triangle-path-2 2s 0s ease-in-out infinite;
  animation: ball-triangle-path-2 2s 0s ease-in-out infinite;
}
.css3-spinner-triangle-path > div:nth-child(3) {
  -webkit-animation: ball-triangle-path-3 2s 0s ease-in-out infinite;
  animation: ball-triangle-path-3 2s 0s ease-in-out infinite;
}
.css3-spinner-triangle-path > div:nth-of-type(1) {
  top: 50px;
}
.css3-spinner-triangle-path > div:nth-of-type(2) {
  left: 25px;
}
.css3-spinner-triangle-path > div:nth-of-type(3) {
  top: 50px;
  left: 50px;
}
@-webkit-keyframes ball-triangle-path-1 {
  33% {
    -webkit-transform: translate(25px, -50px);
    transform: translate(25px, -50px);
  }
  66% {
    -webkit-transform: translate(50px, 0px);
    transform: translate(50px, 0px);
  }
  100% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
}
@keyframes ball-triangle-path-1 {
  33% {
    -webkit-transform: translate(25px, -50px);
    transform: translate(25px, -50px);
  }
  66% {
    -webkit-transform: translate(50px, 0px);
    transform: translate(50px, 0px);
  }
  100% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
}
@-webkit-keyframes ball-triangle-path-2 {
  33% {
    -webkit-transform: translate(25px, 50px);
    transform: translate(25px, 50px);
  }
  66% {
    -webkit-transform: translate(-25px, 50px);
    transform: translate(-25px, 50px);
  }
  100% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
}
@keyframes ball-triangle-path-2 {
  33% {
    -webkit-transform: translate(25px, 50px);
    transform: translate(25px, 50px);
  }
  66% {
    -webkit-transform: translate(-25px, 50px);
    transform: translate(-25px, 50px);
  }
  100% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
}
@-webkit-keyframes ball-triangle-path-3 {
  33% {
    -webkit-transform: translate(-50px, 0px);
    transform: translate(-50px, 0px);
  }
  66% {
    -webkit-transform: translate(-25px, -50px);
    transform: translate(-25px, -50px);
  }
  100% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
}
@keyframes ball-triangle-path-3 {
  33% {
    -webkit-transform: translate(-50px, 0px);
    transform: translate(-50px, 0px);
  }
  66% {
    -webkit-transform: translate(-25px, -50px);
    transform: translate(-25px, -50px);
  }
  100% {
    -webkit-transform: translate(0px, 0px);
    transform: translate(0px, 0px);
  }
}
.css3-spinner > .css3-spinner-ball-pulse-sync {
  width: 70px;
  height: 34px;
  margin-left: -35px;
  margin-top: -17px;
  background-color: transparent !important;
  -webkit-animation: none;
  animation: none;
}
.css3-spinner-ball-pulse-sync > div {
  display: inline-block;
  background-color: #dddddd;
  width: 14px;
  height: 14px;
  margin: 10px 3px 0;
  border-radius: 100%;
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}
.css3-spinner-ball-pulse-sync > div:nth-child(0) {
  -webkit-animation: ball-pulse-sync 0.7s -0.21s infinite ease-in-out;
  animation: ball-pulse-sync 0.7s -0.21s infinite ease-in-out;
}
.css3-spinner-ball-pulse-sync > div:nth-child(1) {
  -webkit-animation: ball-pulse-sync 0.7s -0.14s infinite ease-in-out;
  animation: ball-pulse-sync 0.7s -0.14s infinite ease-in-out;
}
.css3-spinner-ball-pulse-sync > div:nth-child(2) {
  -webkit-animation: ball-pulse-sync 0.7s -0.07s infinite ease-in-out;
  animation: ball-pulse-sync 0.7s -0.07s infinite ease-in-out;
}
.css3-spinner-ball-pulse-sync > div:nth-child(3) {
  -webkit-animation: ball-pulse-sync 0.7s 0s infinite ease-in-out;
  animation: ball-pulse-sync 0.7s 0s infinite ease-in-out;
}
@-webkit-keyframes ball-pulse-sync {
  33% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
    opacity: 0.85;
  }
  66% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    opacity: 0.7;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
@keyframes ball-pulse-sync {
  33% {
    -webkit-transform: translateY(10px);
    transform: translateY(10px);
    opacity: 0.85;
  }
  66% {
    -webkit-transform: translateY(-10px);
    transform: translateY(-10px);
    opacity: 0.7;
  }
  100% {
    -webkit-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
  }
}
.css3-spinner > .css3-spinner-scale-ripple {
  width: 50px;
  height: 50px;
  margin-left: -25px;
  margin-top: -25px;
  background-color: transparent !important;
  -webkit-animation: none;
  animation: none;
}
.css3-spinner-scale-ripple > div {
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
  position: absolute;
  top: 0;
  width: 50px;
  height: 50px;
  border-radius: 100%;
  border: 2px solid #dddddd;
  -webkit-animation: ball-scale-ripple-multiple 1.4s 0s infinite cubic-bezier(0.21, 0.53, 0.56, 0.8);
  animation: ball-scale-ripple-multiple 1.4s 0s infinite cubic-bezier(0.21, 0.53, 0.56, 0.8);
}
.css3-spinner-scale-ripple > div:nth-child(0) {
  -webkit-animation-delay: -0.8s;
  animation-delay: -0.8s;
}
.css3-spinner-scale-ripple > div:nth-child(1) {
  -webkit-animation-delay: -0.6s;
  animation-delay: -0.6s;
}
.css3-spinner-scale-ripple > div:nth-child(2) {
  -webkit-animation-delay: -0.4s;
  animation-delay: -0.4s;
}
.css3-spinner-scale-ripple > div:nth-child(3) {
  -webkit-animation-delay: -0.2s;
  animation-delay: -0.2s;
}
@-webkit-keyframes ball-scale-ripple-multiple {
  0% {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    opacity: 1;
  }
  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.7;
  }
  100% {
    opacity: 0;
  }
}
@keyframes ball-scale-ripple-multiple {
  0% {
    -webkit-transform: scale(0.1);
    transform: scale(0.1);
    opacity: 1;
  }
  70% {
    -webkit-transform: scale(1);
    transform: scale(1);
    opacity: 0.7;
  }
  100% {
    opacity: 0;
  }
}

/*-----------------------------------------------------------------------------------
	Dark Scheme - sass/dark.scss
-----------------------------------------------------------------------------------*/
.dark,
.dark h1,
.dark .h1,
.dark h2,
.dark .h2,
.dark h3,
.dark .h3,
.dark h4,
.dark .h4,
.dark h5,
.dark .h5,
.dark h6,
.dark .h6 {
  color: #EEE;
}

.dark a:not([class*=btn-]):hover {
  color: #EEE;
}

body.dark,
.dark #wrapper,
.dark #content,
.dark .shop-quick-view-ajax,
.dark .portfolio-ajax-modal,
.dark .ajax-modal-title,
.dark .modal-content,
.dark .page-transition-wrap,
.dark .css3-spinner,
body.dark #side-panel,
body #side-panel.dark {
  background-color: #383838;
}

.dark .css3-spinner > div {
  background-color: rgba(255, 255, 255, 0.3);
}

.dark .css3-spinner-clip-rotate > div,
.dark .css3-spinner-scale-ripple > div {
  border-color: rgba(255, 255, 255, 0.3);
}

.dark .line,
.dark .double-line {
  border-top-color: rgba(255, 255, 255, 0.15);
}

.dark .section,
.section.dark {
  background-color: #282828;
  border-color: rgba(255, 255, 255, 0.1);
}

.dark #top-bar,
#top-bar.dark {
  border-bottom-color: rgba(255, 255, 255, 0.1);
  background-color: #282828;
}

.dark .top-links {
  border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark .top-links-item {
  border-left-color: rgba(255, 255, 255, 0.1);
}

.dark .top-links-item > a {
  color: #aaaaaa;
}

.dark .top-links-item:hover {
  background-color: #333333;
}

.dark .top-links-sub-menu,
.dark .top-links-section {
  background: #333333;
  border-color: #2c2c2c;
  border-top-color: #2a93c9;
}

.dark .top-links-sub-menu .top-links-item:not(:first-child) {
  border-top-color: #2c2c2c;
}

.dark .top-links-sub-menu .top-links-item:hover {
  background-color: #383838;
}

.dark #top-social li {
  border-left-color: rgba(255, 255, 255, 0.1);
}

.dark #top-social a {
  color: #bbbbbb;
}

.dark #header,
#header.dark {
  background-color: #333333;
  border-bottom-color: rgba(255, 255, 255, 0.05);
}

.dark #header-wrap {
  background-color: #333333;
}

@media (min-width: 992px) {
  .dark #header.transparent-header,
#header.transparent-header.dark,
.dark #header.transparent-header:not(.sticky-header) #header-wrap,
#header.transparent-header.dark:not(.sticky-header) #header-wrap {
    background: transparent;
    border-bottom-color: transparent;
  }

  .dark #header.transparent-header.semi-transparent #header-wrap,
#header.transparent-header.semi-transparent.dark #header-wrap {
    background-color: rgba(0, 0, 0, 0.6);
  }

  .dark .transparent-header.floating-header .container,
.transparent-header.floating-header.dark .container {
    background-color: #333333;
  }

  .dark #header.full-header:not(.transparent-header),
#header.full-header.dark:not(.transparent-header) {
    border-bottom-color: rgba(255, 255, 255, 0.15);
  }

  .dark #header.transparent-header.full-header #header-wrap,
#header.transparent-header.full-header.dark #header-wrap {
    border-bottom-color: rgba(255, 255, 255, 0.15);
  }

  #slider + .dark #header.transparent-header.full-header #header-wrap,
#slider + #header.transparent-header.full-header.dark #header-wrap {
    border-top-color: rgba(255, 255, 255, 0.15);
  }

  .dark .sticky-header #header-wrap .sticky-header.dark #header-wrap {
    background-color: #333333;
  }

  .dark .sticky-header.full-header #header-wrap,
.sticky-header.full-header.dark #header-wrap {
    border-bottom-color: rgba(255, 255, 255, 0.15);
  }

  .dark .semi-transparent.sticky-header #header-wrap,
.semi-transparent.sticky-header.dark #header-wrap {
    background-color: rgba(0, 0, 0, 0.6);
  }
}
@media (max-width: 767.98px) {
  .dark .sticky-header #header-wrap,
.sticky-header.dark #header-wrap {
    background-color: #333333;
    border-bottom-color: rgba(255, 255, 255, 0.15);
  }

  body.dark:not(.primary-menu-open) .transparent-header-responsive,
body:not(.primary-menu-open) .transparent-header-responsive.dark,
body.dark:not(.primary-menu-open) .transparent-header-responsive #header-wrap,
body:not(.primary-menu-open) .transparent-header-responsive.dark #header-wrap {
    background-color: transparent;
    border-bottom-color: transparent;
  }

  body.dark:not(.primary-menu-open) .semi-transparent.transparent-header-responsive,
body:not(.primary-menu-open) .semi-transparent.transparent-header-responsive.dark {
    background-color: rgba(0, 0, 0, 0.6);
  }
}
.dark #logo a {
  color: #fff;
}

@media (min-width: 992px) {
  .dark .full-header #logo,
.full-header.dark #logo {
    border-right-color: rgba(255, 255, 255, 0.15);
  }

  .dark #header.transparent-header.full-header #logo,
#header.transparent-header.full-header.dark #logo {
    border-right-color: rgba(255, 255, 255, 0.15);
  }
}
.dark .primary-menu + .primary-menu,
.dark .menu-item:not(:first-child),
.dark .sub-menu-container,
.dark .mega-menu-content,
.dark .sub-menu-container .menu-item:not(:first-child) {
  border-top-color: rgba(255, 255, 255, 0.15);
}

.dark .svg-trigger path {
  stroke: #fff;
}

.dark .menu-item .sub-menu-trigger {
  color: #f7f7f7;
}

.dark .menu-link {
  color: #f7f7f7;
}

.dark .primary-menu:not(.not-dark) .sub-menu-container .menu-item > .menu-link {
  color: #e5e5e5;
}

.dark .menu-item:hover > .menu-link,
.dark .menu-item.current > .menu-link {
  color: #2a93c9;
}

@media (max-width: 767.98px) {
  .dark .mega-menu-content .sub-menu-container {
    border-top-color: rgba(255, 255, 255, 0.2);
  }

  /* Off Canvas Menu
  -----------------------------------------------------------------*/
  .dark #header:not(.sticky-header) .mobile-menu-off-canvas .menu-container,
#header.dark:not(.sticky-header) .mobile-menu-off-canvas .menu-container {
    background-color: #333333;
    border-right-color: rgba(255, 255, 255, 0.2) !important;
  }

  .dark #header:not(.sticky-header) .mobile-menu-off-canvas.from-right .menu-container,
#header.dark:not(.sticky-header) .mobile-menu-off-canvas.from-right .menu-container {
    border-left-color: rgba(255, 255, 255, 0.2) !important;
  }
}
@media (min-width: 992px) {
  .dark .full-header .primary-menu .menu-container,
.full-header.dark .primary-menu .menu-container {
    border-right-color: rgba(255, 255, 255, 0.15);
  }

  .dark .transparent-header.full-header .primary-menu .menu-container,
.transparent-header.full-header.dark .primary-menu .menu-container {
    border-right-color: rgba(255, 255, 255, 0.2);
  }

  .dark .primary-menu:not(.not-dark) .sub-menu-container,
.dark .primary-menu:not(.not-dark) .mega-menu-content {
    background-color: #333333;
    border-color: rgba(255, 255, 255, 0.05);
    border-top-color: #2a93c9;
  }

  .dark .primary-menu:not(.not-dark) .sub-menu-container .menu-item > .menu-link {
    color: #999999;
  }

  .dark .primary-menu:not(.not-dark) .sub-menu-container .menu-item:hover > .menu-link {
    background-color: rgba(0, 0, 0, 0.1);
    color: #2a93c9;
  }

  .dark .primary-menu:not(.not-dark) .mega-menu-content .sub-menu-container:not(.mega-menu-dropdown) {
    background-color: transparent;
  }

  .dark .primary-menu:not(.not-dark) .sub-menu-container.mega-menu-column:not(:first-child) {
    border-left-color: rgba(255, 255, 255, 0.05);
  }

  .dark .primary-menu:not(.not-dark) .mega-menu-style-2 .mega-menu-title > .menu-link {
    color: #bbbbbb;
  }

  .dark .primary-menu:not(.not-dark) .mega-menu-style-2 .mega-menu-title:hover > .menu-link {
    background-color: transparent;
    color: #2a93c9;
  }

  .dark .style-3 .menu-container > .menu-item:hover > .menu-link {
    color: #f7f7f7;
    background-color: rgba(255, 255, 255, 0.05);
  }

  .dark .style-3 .menu-container > .menu-item.current > .menu-link {
    color: #fff;
    background-color: #2a93c9;
  }

  .dark .style-4 .menu-container > .menu-item:hover > .menu-link,
.dark .style-4 .menu-container > .menu-item.current > .menu-link {
    color: #f7f7f7;
    border-color: #f7f7f7;
  }

  .dark .style-5 .menu-container {
    border-right-color: rgba(255, 255, 255, 0.15);
  }

  .dark .sub-title .menu-container > .menu-item:not(:first-child) > .menu-link::before {
    background-color: rgba(255, 255, 255, 0.15);
  }

  .dark .sub-title .menu-container > .menu-item > .menu-link span {
    color: #999999;
  }

  .dark .sub-title .menu-container > .menu-item:hover > .menu-link,
.dark .sub-title .menu-container > .menu-item.current > .menu-link {
    color: #fff;
  }

  .dark .sub-title .menu-container > .menu-item:hover > .menu-link span,
.dark .sub-title .menu-container > .menu-item.current > .menu-link span {
    color: #EEE;
  }

  .side-header.dark #header,
.side-header #header.dark {
    background-color: #333333;
    border-right-color: rgba(255, 255, 255, 0.15);
  }

  .side-header.open-header.dark #header-trigger {
    background-color: rgba(0, 0, 0, 0.3);
  }

  .side-header.side-header-right.dark #header,
.side-header.side-header-right #header.dark {
    border-left-color: rgba(255, 255, 255, 0.15);
  }

  .overlay-menu.dark .primary-menu > #overlay-menu-close,
.overlay-menu .dark .primary-menu > #overlay-menu-close {
    color: #f7f7f7;
  }

  .overlay-menu.dark .primary-menu,
.overlay-menu .dark .primary-menu,
.overlay-menu .primary-menu.dark {
    background-color: rgba(0, 0, 0, 0.85);
  }

  .overlay-menu.dark .sub-menu-container,
.overlay-menu.dark .mega-menu-content,
.overlay-menu .dark .sub-menu-container,
.overlay-menu .dark .mega-menu-content {
    background-color: transparent;
  }
}
@media (max-width: 767.98px) {
  .side-push-panel.dark #side-panel-trigger-close a,
.side-push-panel .dark #side-panel-trigger-close a {
    color: #f7f7f7;
    background-color: rgba(0, 0, 0, 0.3);
  }
}
.dark .header-misc-icon > a {
  color: #f7f7f7;
}

.dark .top-search-form input {
  color: #f7f7f7 !important;
}

@media (min-width: 992px) {
  .dark .transparent-header:not(.sticky-header):not(.full-header):not(.floating-header) .top-search-form input,
.transparent-header.dark:not(.sticky-header):not(.full-header):not(.floating-header) .top-search-form input {
    border-bottom-color: rgba(255, 255, 255, 0.15);
  }
}
.search-overlay.dark .top-search-form,
.search-overlay .dark .top-search-form {
  background-color: rgba(0, 0, 0, 0.85);
}

.dark .top-search-form input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.6) !important;
}

.dark .top-search-form input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.6) !important;
}

.dark .top-search-form input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.6) !important;
}

.dark .top-cart-content {
  background-color: #333333;
  border-left-color: rgba(255, 255, 255, 0.1);
}

.dark .top-cart-title {
  border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark .top-cart-item:not(:first-child),
.dark .top-cart-action {
  border-top-color: rgba(255, 255, 255, 0.1);
}

.dark .top-cart-item-image:not(:hover) {
  border-color: rgba(255, 255, 255, 0.1);
}

.dark .top-cart-item-desc a {
  color: #f7f7f7;
}

.dark .top-cart-item-desc a:hover {
  color: #2a93c9;
}

.dark .top-cart-item-price,
.dark .top-cart-item-quantity {
  color: #aaaaaa;
}

@media (min-width: 992px) {
  .dark .top-cart-content {
    border-color: rgba(255, 255, 255, 0.1);
    border-top-color: #2a93c9;
  }

  .dark .dots-menu .page-menu-item:not(.current) > a {
    background-color: rgba(255, 255, 255, 0.3);
  }

  .dark .dots-menu .page-menu-item:not(.current) > a:hover {
    background-color: rgba(255, 255, 255, 0.6);
  }

  .dark .dots-menu.dots-menu-border .page-menu-item:not(.current) > a {
    background-color: transparent !important;
    border-color: rgba(255, 255, 255, 0.3);
  }

  .dark .dots-menu.dots-menu-border .page-menu-item:not(.current) > a:hover {
    border-color: rgba(255, 255, 255, 0.6);
  }
}
.dark .one-page-arrow,
.one-page-arrow.dark {
  color: #fff;
}

.dark #side-panel,
#side-panel.dark {
  background-color: #333333;
}

.dark #side-panel .widget:not(:first-child),
#side-panel.dark .widget:not(:first-child) {
  border-top-color: rgba(255, 255, 255, 0.15);
}

.dark .slider-caption:not(.not-dark),
.dark .slider-caption:not(.not-dark) h2,
.dark .slider-caption:not(.not-dark) .h2 {
  color: #f7f7f7;
}

.dark #page-title,
#page-title.dark {
  background-color: #282828;
  border-bottom-color: rgba(255, 255, 255, 0.15);
}

.dark #page-title h1, .dark #page-title .h1,
#page-title.dark h1,
#page-title.dark .h1 {
  color: #f7f7f7;
}

.dark #page-title span,
#page-title.dark span {
  color: #aaaaaa;
}

.dark .breadcrumb a {
  color: #EEE;
}

.dark .breadcrumb a:hover {
  color: #2a93c9;
}

.dark .page-title-nobg,
.page-title-nobg.dark {
  border-bottom-color: rgba(255, 255, 255, 0.15);
}

.dark .grid-filter li a {
  color: #cccccc;
}

@media (min-width: 768px) {
  .dark .grid-filter {
    border-color: rgba(255, 255, 255, 0.15);
  }

  .dark .grid-filter:not(.style-3) li a {
    border-left-color: rgba(255, 255, 255, 0.15);
  }
}
.dark .grid-filter.style-4 li.activeFilter a {
  color: #EEE !important;
}

.dark .grid-shuffle {
  border-color: rgba(255, 255, 255, 0.15);
  color: #EEE;
}

.dark .portfolio-desc h3 a, .dark .portfolio-desc .h3 a {
  color: #f7f7f7;
}

.dark .portfolio-desc h3 a:hover, .dark .portfolio-desc .h3 a:hover {
  color: #2a93c9;
}

.dark .portfolio-desc span,
.dark .portfolio-desc span a {
  color: #bbbbbb;
}

.dark .portfolio-desc span a:hover {
  color: #EEE;
}

.dark .portfolio-reveal .portfolio-desc {
  background-color: #383838;
}

.dark .ajax-modal-title {
  border-bottom-color: rgba(255, 255, 255, 0.15);
}

.dark .portfolio-meta li {
  color: #bbbbbb;
}

.dark .portfolio-meta li span {
  color: #EEE;
}

.dark #portfolio-navigation a {
  color: #EEE;
}

.dark #portfolio-navigation a:hover {
  color: #2a93c9;
}

.dark .entry::after {
  background-color: rgba(255, 255, 255, 0.2);
}

.dark .entry-title h2 a, .dark .entry-title .h2 a,
.dark .entry-title h3 a,
.dark .entry-title .h3 a,
.dark .entry-title h4 a,
.dark .entry-title .h4 a {
  color: #f7f7f7;
}

.dark .entry-title h2 a:hover, .dark .entry-title .h2 a:hover,
.dark .entry-title h3 a:hover,
.dark .entry-title .h3 a:hover,
.dark .entry-title h4 a:hover,
.dark .entry-title .h4 a:hover {
  color: #2a93c9;
}

.dark .entry-meta li,
.dark .entry-meta a:not(:hover) {
  color: #bbbbbb;
}

.dark .entry-link {
  background-color: rgba(0, 0, 0, 0.2);
  color: #f7f7f7;
}

.dark .entry-link:hover {
  color: #fff;
  background-color: #2a93c9;
}

.dark .entry-link span {
  color: #aaaaaa;
}

.dark .entry-link:hover span {
  color: #EEE;
}

.dark .more-link:hover {
  border-bottom-color: #cccccc;
}

.dark #comments {
  border-top-color: rgba(255, 255, 255, 0.1);
}

.dark .commentlist {
  border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark .comment-wrap {
  border-color: rgba(255, 255, 255, 0.1);
}

.dark .comment-avatar {
  background: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1);
}

.dark .comment-content .comment-author {
  color: #cccccc;
}

.dark .comment-content .comment-author a {
  color: #bbbbbb;
}

.dark .comment-content .comment-author a:hover {
  color: #EEE;
}

.dark .review-comment-ratings {
  color: #cccccc;
}

.dark .comment-reply-link:hover {
  color: #888888;
}

.dark p.wp-caption-text {
  background-color: rgba(0, 0, 0, 0.2);
}

.dark .product-title h3 a, .dark .product-title .h3 a,
.dark .single-product .product-title h2 a,
.dark .single-product .product-title .h2 a {
  color: #f7f7f7;
}

.dark .product-title h3 a:hover, .dark .product-title .h3 a:hover,
.dark .single-product .product-title h2 a:hover,
.dark .single-product .product-title .h2 a:hover {
  color: #2a93c9;
}

.dark .product-price {
  color: #cccccc;
}

.dark .product-price del {
  color: #bbbbbb;
}

.dark .product-price ins {
  color: #2a93c9;
}

.dark .quantity .qty {
  border-left-color: rgba(255, 255, 255, 0.15);
  border-right-color: rgba(255, 255, 255, 0.15);
  background-color: rgba(0, 0, 0, 0.3);
  color: #cccccc;
}

.dark .quantity .plus,
.dark .quantity .minus {
  background-color: rgba(0, 0, 0, 0.3);
  color: #cccccc;
}

.dark .quantity .plus:hover,
.dark .quantity .minus:hover {
  background-color: rgba(0, 0, 0, 0.5);
}

.dark .product-meta {
  color: #bbbbbb;
}

.dark .cart .remove:hover {
  color: #EEE;
}

.dark .cart th {
  color: #cccccc;
}

.dark .cart td {
  border-color: rgba(255, 255, 255, 0.15) !important;
}

.dark .cart-product-thumbnail img {
  border-color: rgba(0, 0, 0, 0.2);
}

.dark .cart-product-thumbnail img:hover {
  border-color: #2a93c9;
}

.dark .cart-product-name a,
.dark .product-name a {
  color: #EEE;
}

.dark .cart-product-name a:hover,
.dark .product-name a:hover {
  color: #bbbbbb;
}

@media (max-width: 575.98px) {
  .dark .cart-product-quantity .quantity .qty {
    border-top-color: rgba(255, 255, 255, 0.15);
    border-bottom-color: rgba(255, 255, 255, 0.15);
  }
}
.dark td.actions {
  background-color: rgba(0, 0, 0, 0.2);
}

.dark .event .grid-inner {
  background-color: rgba(0, 0, 0, 0.2);
  border-bottom-color: rgba(255, 255, 255, 0.15);
}

.dark .countdown-section {
  border-left-color: rgba(255, 255, 255, 0.15);
  color: #cccccc;
}

.dark .countdown-amount {
  color: #f7f7f7;
}

.dark .button-dark:not(.button-border):not(:hover),
.dark .button:hover {
  background-color: rgba(0, 0, 0, 0.3);
}

.dark .button-light:not(.button-border):hover {
  background-color: #282828;
}

.dark .button.button-border:not(.button-light):not(.button-fill) {
  border-color: #bbbbbb;
  color: #bbbbbb;
}

.dark .button.button-border:not(.button-light):not(.button-fill):hover {
  color: #EEE;
}

.not-dark .button.button-border:not(.button-light) {
  border-color: #2c2c2c;
  color: #333333;
}

.not-dark .button.button-border:not(.button-light):hover {
  background-color: #2c2c2c;
  color: #fff;
}

.dark .promo h3 + span, .dark .promo .h3 + span,
.promo.dark h3 + span,
.promo.dark .h3 + span {
  color: rgba(255, 255, 255, 0.8);
}

.dark .promo.promo-border {
  border-color: rgba(255, 255, 255, 0.15);
}

.dark .promo.promo-light {
  background-color: rgba(255, 255, 255, 0.15);
}

.dark .promo.promo-dark:not(.bg-color) {
  background-color: rgba(0, 0, 0, 0.3);
}

.dark .fbox-content h3, .dark .fbox-content .h3 {
  color: #f7f7f7;
}

.dark .fbox-content p {
  color: #bbbbbb;
}

.dark .fbox-light.fbox-outline .fbox-icon a {
  border-color: rgba(255, 255, 255, 0.15);
}

.dark .fbox-light .fbox-icon i,
.dark .fbox-light .fbox-icon img {
  border-color: rgba(255, 255, 255, 0.15);
  background-color: rgba(255, 255, 255, 0.2);
  color: #EEE;
}

.dark .fbox-dark.fbox-outline .fbox-icon a {
  border-color: rgba(255, 255, 255, 0.15);
}

.dark .fbox-dark .fbox-icon i,
.dark .fbox-dark .fbox-icon img {
  background-color: rgba(255, 255, 255, 0.2);
}

.dark .fbox-border.fbox-light .fbox-icon a {
  border-color: rgba(255, 255, 255, 0.15);
}

.dark .fbox-border.fbox-light .fbox-icon i,
.dark .fbox-border.fbox-light .fbox-icon img {
  color: #bbbbbb;
}

.dark .fbox-border.fbox-dark .fbox-icon a {
  border-color: rgba(255, 255, 255, 0.1);
}

.dark .fbox-border.fbox-dark .fbox-icon i,
.dark .fbox-border.fbox-dark .fbox-icon img {
  color: #EEE;
}

.fbox-plain.fbox-light .fbox-icon i,
.fbox-plain.fbox-light .fbox-icon img {
  color: #bbbbbb;
}

.dark .fbox-plain.fbox-dark .fbox-icon i,
.dark .fbox-plain.fbox-dark .fbox-icon img {
  color: #EEE;
}

.dark .fbox-content h3 span.subtitle, .dark .fbox-content .h3 span.subtitle {
  color: #bbbbbb;
}

.dark .fbox-center.fbox-sep:not(.fbox-bg) h3::after, .dark .fbox-center.fbox-sep:not(.fbox-bg) .h3::after {
  background-color: #e5e5e5;
}

.dark .fbox-bg.fbox-center {
  border-color: rgba(255, 255, 255, 0.15);
}

.dark .fbox-bg.fbox-center .fbox-icon {
  background-color: #383838;
}

.dark .media-box.fbox-bg .fbox-content {
  background-color: rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.15);
}

.dark .fbox-effect .fbox-icon i:after {
  box-shadow: 0 0 0 2px #494949;
}

.dark .fbox-effect .fbox-icon i:hover,
.dark .fbox-effect:hover .fbox-icon i {
  background-color: #494949;
}

.dark .fbox-effect.fbox-dark .fbox-icon i:after {
  box-shadow: 0 0 0 2px #2a93c9;
}

.dark .fbox-effect.fbox-dark .fbox-icon i:hover,
.dark .fbox-effect.fbox-dark:hover .fbox-icon i {
  background-color: #2a93c9;
}

.dark .fbox-border.fbox-effect .fbox-icon i:hover,
.dark .fbox-border.fbox-effect:hover .fbox-icon i {
  box-shadow: 0 0 0 1px #494949;
}

.dark .fbox-border.fbox-effect .fbox-icon i:after {
  background-color: #494949;
}

.dark .fbox-border.fbox-effect.fbox-dark .fbox-icon i:hover,
.dark .fbox-border.fbox-effect.fbox-dark:hover .fbox-icon i {
  box-shadow: 0 0 0 1px #2a93c9;
}

.dark .fbox-border.fbox-effect.fbox-dark .fbox-icon i:after {
  background-color: #2a93c9;
}

.dark .process-steps li .i-bordered {
  background-color: rgba(255, 255, 255, 0.2);
}

@media (min-width: 992px) {
  .dark .process-steps li::before,
.dark .process-steps li::after {
    border-top-color: rgba(255, 255, 255, 0.15);
  }
}
.dark .style-msg2 {
  border-left-color: rgba(0, 0, 0, 0.3);
}

.dark .style-msg .sb-msg,
.dark .style-msg2 .msgtitle,
.dark .style-msg2 .sb-msg {
  border-left-color: rgba(255, 255, 255, 0.1);
}

.dark .i-rounded,
.dark .i-circled {
  background-color: rgba(0, 0, 0, 0.3);
}

.dark .i-bordered {
  border-color: rgba(255, 255, 255, 0.1);
  color: #EEE;
}

.dark .i-plain {
  color: #EEE;
  text-shadow: none;
}

.dark .i-plain:hover {
  color: #cccccc;
}

.dark .i-light {
  background-color: rgba(255, 255, 255, 0.15);
  color: #EEE;
  text-shadow: 1px 1px 1px none;
  box-shadow: inset 0 0 15px rgba(0, 0, 0, 0.2);
}

.dark .i-rounded:hover,
.dark .i-circled:hover {
  background-color: #2a93c9;
  color: #fff;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 0 rgba(0, 0, 0, 0.2);
}

.dark .i-bordered:hover {
  background-color: rgba(255, 255, 255, 0.15) !important;
  color: #fff;
  border-color: rgba(255, 255, 255, 0.15);
}

.dark .social-icon {
  color: #EEE;
  border-color: #aaaaaa;
}

.dark .si-dark {
  background-color: rgba(0, 0, 0, 0.2);
  color: #FFF !important;
  border-color: transparent;
}

.dark .si-light {
  background-color: rgba(255, 255, 255, 0.15);
  color: #CCC !important;
  border-color: transparent;
}

.dark .si-share {
  border-top-color: rgba(255, 255, 255, 0.15);
  border-bottom-color: rgba(255, 255, 255, 0.15);
}

.dark .toggle-header,
.dark .accordion-header {
  color: #EEE;
}

.dark .toggle-bg .toggle-header,
.dark .accordion-bg .accordion-header {
  background-color: rgba(0, 0, 0, 0.2);
}

.dark .toggle-border,
.dark .accordion-border,
.dark .accordion-border .accordion-header {
  border-color: rgba(255, 255, 255, 0.15);
}

.dark .faqs .toggle {
  border-bottom-color: rgba(255, 255, 255, 0.15);
}

.dark .accordion-header {
  border-top-color: rgba(255, 255, 255, 0.15);
}

.dark .tab-nav {
  border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark .tab-nav li {
  border-color: rgba(255, 255, 255, 0.1);
}

.dark .tab-nav li:first-child {
  border-left-color: rgba(255, 255, 255, 0.1);
}

.dark .tab-nav li a {
  color: #EEE;
  background-color: rgba(0, 0, 0, 0.2);
}

.dark .tab-nav li.ui-tabs-active a {
  background-color: #383838;
}

.dark .tabs-alt .tab-nav li a {
  background-color: #383838;
}

.dark .tabs-alt .tab-nav li {
  border-color: transparent;
}

.dark .tabs-alt .tab-nav li.ui-tabs-active a {
  border-color: rgba(255, 255, 255, 0.1);
}

.dark .tabs-tb .tab-nav li.ui-tabs-active a {
  border-top: 2px solid #2a93c9;
}

.dark .tabs-bb .tab-nav li a {
  background-color: transparent;
}

.dark .tab-nav.tab-nav2 li a {
  background-color: rgba(0, 0, 0, 0.2);
}

.dark .tab-nav.tab-nav2 li.ui-state-active a {
  background-color: #2a93c9;
}

.dark .tab-nav-lg li a {
  color: #EEE;
}

.dark .tab-nav-lg li.ui-tabs-active a {
  color: #2a93c9;
}

.dark .tab-nav-lg.tab-nav-section li.ui-tabs-active::before,
.dark .tab-nav-lg.tab-nav-section li.ui-tabs-active::after {
  border-color: transparent;
}

.dark .tab-nav-lg.tab-nav-section li.ui-tabs-active::after {
  border-top-color: rgba(255, 255, 255, 0.15);
}

.dark .tab-nav-lg.tab-nav-section li.ui-tabs-active::before {
  border-top-color: rgba(255, 255, 255, 0.15);
}

.dark .tabs-bordered .tab-container {
  border-color: rgba(255, 255, 255, 0.1);
}

@media (min-width: 768px) {
  .dark .side-tabs .tab-nav {
    border-right-color: rgba(255, 255, 255, 0.1);
  }

  .dark .side-tabs .tab-nav li {
    border-color: rgba(255, 255, 255, 0.1);
  }

  .dark .side-tabs .tab-nav li:first-child {
    border-top-color: rgba(255, 255, 255, 0.1);
    border-left-color: rgba(255, 255, 255, 0.1);
  }

  .dark .tabs-bordered.side-tabs .tab-nav li.ui-tabs-active a {
    border-right-color: #383838;
  }

  .dark .tabs-bordered.side-tabs .tab-container {
    border-left: 0;
    border-top-color: rgba(255, 255, 255, 0.1);
  }
}
.dark .sidenav {
  background-color: rgba(0, 0, 0, 0.2);
}

.dark .sidenav li a {
  border-color: rgba(255, 255, 255, 0.15);
  color: #bbbbbb;
}

.dark .sidenav > li > a:hover {
  background-color: rgba(255, 255, 255, 0.2);
  color: #EEE;
}

.dark .faqlist li a {
  color: #cccccc;
}

.dark .clients-grid .grid-item::before,
.dark .testimonials-grid .grid-item::before {
  border-left-color: rgba(255, 255, 255, 0.15);
}

.dark .clients-grid .grid-item::after,
.dark .testimonials-grid .grid-item::after {
  border-bottom-color: rgba(255, 255, 255, 0.15);
}

.dark .testimonial {
  background-color: rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.15);
}

.dark .testi-image i {
  background-color: rgba(0, 0, 0, 0.2);
  color: #bbbbbb;
  text-shadow: none;
}

.dark .testi-meta span {
  color: #aaaaaa;
}

.dark .testimonial.twitter-scroll .testi-meta span a {
  color: #bbbbbb;
}

.dark .testimonial.twitter-scroll .testi-meta span a:hover {
  color: #EEE;
}

.dark .team-desc.team-desc-bg {
  background-color: rgba(0, 0, 0, 0.2);
}

.dark .team-content {
  color: #aaaaaa;
}

.dark .pricing-box {
  border-color: rgba(255, 255, 255, 0.15);
  background-color: rgba(0, 0, 0, 0.2);
}

.dark .pricing-title {
  background-color: rgba(0, 0, 0, 0.1);
  border-bottom-color: rgba(255, 255, 255, 0.15);
}

.dark .pricing-title h3, .dark .pricing-title .h3 {
  color: rgba(255, 255, 255, 0.95);
}

.dark .pricing-title span {
  color: rgba(255, 255, 255, 0.75);
}

.dark .pricing-price {
  color: rgba(255, 255, 255, 0.9);
}

.dark .pricing-price .price-tenure {
  color: rgba(255, 255, 255, 0.7);
}

.dark .pricing-features {
  border-top-color: rgba(255, 255, 255, 0.15);
  border-bottom-color: rgba(255, 255, 255, 0.15);
  background-color: rgba(0, 0, 0, 0.1);
}

.dark .pricing-highlight .pricing-title,
.dark .pricing-highlight .pricing-features {
  background-color: rgba(0, 0, 0, 0.2);
}

.dark .pricing-simple,
.dark .pricing-simple .pricing-title,
.dark .pricing-simple .pricing-features,
.pricing-simple.dark,
.pricing-simple.dark .pricing-title,
.pricing-simple.dark .pricing-features {
  border: 0;
}

.dark .pricing-simple .pricing-title,
.dark .pricing-simple .pricing-features,
.pricing-simple.dark .pricing-title,
.pricing-simple.dark .pricing-features {
  background-color: transparent;
}

.dark .pricing-minimal .pricing-price {
  background-color: rgba(0, 0, 0, 0.2);
  border-bottom: rgba(255, 255, 255, 0.15);
}

.dark .pricing-extended {
  background-color: rgba(0, 0, 0, 0.2);
}

.dark .pricing-extended .pricing-action-area {
  border-left-color: rgba(255, 255, 255, 0.15);
  background-color: rgba(0, 0, 0, 0.1);
}

.dark .pricing-extended .pricing-meta {
  color: rgba(255, 255, 255, 0.7);
}

.dark .counter.counter-lined + h5::before, .dark .counter.counter-lined + .h5::before {
  border-top-color: rgba(255, 255, 255, 0.15);
}

.dark .rounded-skill {
  color: rgba(255, 255, 255, 0.9);
}

.dark .skills li {
  background-color: rgba(255, 255, 255, 0.15);
}

.dark .skills li .progress-percent {
  background-color: #666666;
  color: rgba(255, 255, 255, 0.95);
}

.dark .progress-percent::after {
  border-top-color: #666666;
}

.dark .progress-percent::before {
  border-top-color: #666666;
}

.dark .skills li > span {
  color: rgba(255, 255, 255, 0.9);
}

.dark blockquote {
  border-left-color: rgba(255, 255, 255, 0.15);
}

.dark .blockquote-reverse {
  border-right-color: rgba(255, 255, 255, 0.15);
}

.dark .quote::before {
  color: rgba(255, 255, 255, 0.2);
}

.dark .highlight {
  background-color: rgba(255, 255, 255, 0.15);
  color: rgba(255, 255, 255, 0.95);
}

.dark .owl-carousel .owl-nav [class*=owl-] {
  border-color: rgba(255, 255, 255, 0.15);
  color: #cccccc;
  background-color: rgba(0, 0, 0, 0.5);
}

.dark .flip-card-front,
.dark .flip-card-back {
  color: #fff;
}

.dark .flip-card-back {
  background-color: rgba(255, 255, 255, 0.2);
}

.dark .flip-card-inner p {
  color: rgba(255, 255, 255, 0.95);
}

.dark .sm-form-control {
  color: #EEE;
  background-color: rgba(0, 0, 0, 0.3);
  border-color: rgba(255, 255, 255, 0.15);
}

.dark .sm-form-control:focus {
  border-color: rgba(255, 255, 255, 0.25);
}

.dark .sm-form-control::-moz-placeholder {
  color: #aaaaaa;
}

.dark .sm-form-control:-ms-input-placeholder {
  color: #aaaaaa;
}

.dark .sm-form-control::-webkit-input-placeholder {
  color: #aaaaaa;
}

.dark .sm-form-control[disabled],
.dark .sm-form-control[readonly],
.dark fieldset[disabled] .sm-form-control {
  background-color: #eeeeee;
}

.dark .form-control:active,
.dark .form-control:focus {
  border-color: rgba(255, 255, 255, 0.25);
}

.dark label {
  color: #e5e5e5;
}

.dark label.label-muted {
  color: #aaaaaa;
}

.dark .contact-form-overlay {
  background-color: #333333;
}

.dark .title-block > span {
  color: rgba(255, 255, 255, 0.7);
}

.dark .heading-block > span:not(.before-heading) {
  color: rgba(255, 255, 255, 0.65);
}

.dark .heading-block::after {
  border-top-color: rgba(255, 255, 255, 0.7);
}

.dark .fancy-title::before,
.dark .fancy-title::after {
  border-top-color: rgba(255, 255, 255, 0.15);
}

.dark .before-heading {
  color: rgba(255, 255, 255, 0.7);
}

.dark .divider {
  color: rgba(255, 255, 255, 0.15);
}

.dark .divider::after,
.dark .divider::before {
  background-color: rgba(255, 255, 255, 0.15);
}

.dark .divider a:hover {
  color: #cccccc;
}

.dark .divider.divider-rounded i,
.dark .divider.divider-border i {
  color: rgba(255, 255, 255, 0.7);
  background-color: rgba(255, 255, 255, 0.15);
}

.dark .divider.divider-border {
  color: rgba(255, 255, 255, 0.15);
}

.dark .divider.divider-border i {
  background-color: transparent;
  border-color: rgba(255, 255, 255, 0.15);
}

.dark .top-advert {
  border-left-color: rgba(255, 255, 255, 0.15);
  border-right-color: rgba(255, 255, 255, 0.15);
}

.dark #gotoTop {
  background-color: rgba(255, 255, 255, 0.3);
}

.dark #gotoTop:hover {
  background-color: #2a93c9;
}

.dark .read-more-wrap .read-more-mask {
  background-image: linear-gradient(rgba(0, 0, 0, 0), black);
}

.dark .gdpr-settings {
  background-color: #111;
}

.dark .error404 {
  color: rgba(255, 255, 255, 0.2);
}

.dark .preloader, .dark .form-process,
.dark .preloader2,
.dark .form-process::before {
  background-color: #383838;
}

.dark #footer,
#footer.dark {
  color: rgba(255, 255, 255, 0.75);
  background-color: #282828;
  border-top-color: rgba(255, 255, 255, 0.15);
}

.dark .footer-widgets-wrap a {
  color: rgba(255, 255, 255, 0.75);
}

.dark .footer-widgets-wrap a:hover {
  color: rgba(255, 255, 255, 0.85);
}

.dark #footer .social-icon.si-borderless,
#footer.dark .social-icon.si-borderless {
  color: rgba(255, 255, 255, 0.5) !important;
}

.dark #copyrights,
#copyrights.dark {
  color: rgba(255, 255, 255, 0.4);
  background-color: rgba(0, 0, 0, 0.2);
}

.dark .copyright-links a {
  color: rgba(255, 255, 255, 0.4);
  border-bottom-color: rgba(255, 255, 255, 0.65);
}

.dark .copyright-links a:hover {
  color: rgba(255, 255, 255, 0.6);
  border-bottom-color: rgba(255, 255, 255, 0.7);
}

.dark .sidebar-widgets-wrap .widget {
  border-top-color: rgba(255, 255, 255, 0.15);
}

.dark .tagcloud a {
  border-color: rgba(255, 255, 255, 0.15);
  color: rgba(255, 255, 255, 0.6);
}

.dark .widget_nav_menu li a,
.dark .widget_links li a,
.dark .widget_meta li a,
.dark .widget_archive li a,
.dark .widget_recent_entries li a,
.dark .widget_categories li a,
.dark .widget_pages li a,
.dark .widget_rss li a,
.dark .widget_recent_comments li a {
  color: rgba(255, 255, 255, 0.8);
}

.dark .widget_nav_menu li a:hover,
.dark .widget_links li a:hover,
.dark .widget_meta li a:hover,
.dark .widget_archive li a:hover,
.dark .widget_recent_entries li a:hover,
.dark .widget_categories li a:hover,
.dark .widget_pages li a:hover,
.dark .widget_rss li a:hover,
.dark .widget_recent_comments li a:hover {
  color: rgba(255, 255, 255, 0.9);
}

.dark .widget .testimonial.twitter-scroll .testi-image i {
  background-color: rgba(0, 0, 0, 0.2);
  color: rgba(255, 255, 255, 0.7);
}

.dark .widget-twitter-feed small a:not(:hover), .dark .widget-twitter-feed .small a:not(:hover) {
  color: rgba(255, 255, 255, 0.6);
}

.dark .widget-filter-links li a {
  color: rgba(255, 255, 255, 0.9);
}

.dark .widget-filter-links li span {
  color: rgba(255, 255, 255, 0.7);
  background-color: rgba(0, 0, 0, 0.3);
}

.dark .widget-filter-links li:hover a {
  color: rgba(255, 255, 255, 0.7);
}

.dark .nav-tree li a {
  color: rgba(255, 255, 255, 0.9);
}

.dark .pagination.pagination-3d .page-item .page-link {
  border-bottom-color: rgba(255, 255, 255, 0.15) !important;
}

.dark .pagination.pagination-inside-transparent .page-item .page-link:not(:hover) {
  color: rgba(255, 255, 255, 0.95);
}

.dark .pagination.pagination-primary .page-item.active .page-link,
.dark .pagination.pagination-primary .page-link:hover,
.dark .pagination.pagination-primary .page-link:focus,
.dark .pagination.pagination-secondary .page-item.active .page-link,
.dark .pagination.pagination-secondary .page-link:hover,
.dark .pagination.pagination-secondary .page-link:focus,
.dark .pagination.pagination-success .page-item.active .page-link,
.dark .pagination.pagination-success .page-link:hover,
.dark .pagination.pagination-success .page-link:focus,
.dark .pagination.pagination-danger .page-item.active .page-link,
.dark .pagination.pagination-danger .page-link:hover,
.dark .pagination.pagination-danger .page-link:focus,
.dark .pagination.pagination-info .page-item.active .page-link,
.dark .pagination.pagination-info .page-link:hover,
.dark .pagination.pagination-info .page-link:focus,
.dark .pagination.pagination-light .page-item.active .page-link,
.dark .pagination.pagination-light .page-link:hover,
.dark .pagination.pagination-light .page-link:focus {
  background-color: rgba(0, 0, 0, 0.1) !important;
  border-color: rgba(0, 0, 0, 0.1) !important;
  color: rgba(255, 255, 255, 0.95);
}

.dark input.switch-toggle-round + label {
  background-color: rgba(255, 255, 255, 0.2);
}

.dark input.switch-toggle-round + label::before {
  background-color: rgba(255, 255, 255, 0.1);
}

.dark input.switch-toggle-round + label::after {
  background-color: rgba(255, 255, 255, 0.5);
}

.dark input.switch-toggle-round:checked + label::before {
  background-color: #2a93c9;
}

.dark input.switch-toggle-round:checked + label::after {
  background-color: rgba(255, 255, 255, 0.85);
}

.dark input.switch-toggle-flat + label {
  background-color: rgba(255, 255, 255, 0.2);
}

.dark input.switch-toggle-flat + label::before {
  background-color: #383838;
}

.dark input.switch-toggle-flat + label::after {
  background-color: rgba(255, 255, 255, 0.5);
}

.dark input.switch-toggle-flat:checked + label {
  background-color: #2a93c9;
}

.dark input.switch-toggle-flat:checked + label::after {
  background-color: #2a93c9;
}

.dark .shape-divider-fill {
  fill: #383838;
}

/* Dark Scheme - Bootstrap
-----------------------------------------------------------------*/
.dark .show-grid [class^=col-] {
  background-color: rgba(255, 255, 255, 0.05);
  border-color: rgba(255, 255, 255, 0.1);
}

.dark mark, .dark .mark {
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
}

.dark fieldset {
  border-color: rgba(255, 255, 255, 0.1);
}

.dark .img-thumbnail {
  background-color: rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.1);
}

.dark hr {
  border-top-color: rgba(255, 255, 255, 0.1);
}

.dark .page-header {
  border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark blockquote {
  border-left-color: rgba(255, 255, 255, 0.2);
}

.dark .blockquote-reverse,
.dark blockquote.pull-right {
  border-right-color: rgba(255, 255, 255, 0.2);
}

.dark code {
  color: #DF6F72;
  background-color: rgba(0, 0, 0, 0.3);
}

.dark kbd {
  background-color: rgba(255, 255, 255, 0.1);
}

.dark pre {
  color: #fff;
  background-color: rgba(0, 0, 0, 0.3);
  border-color: rgba(255, 255, 255, 0.15);
}

.dark .table {
  color: #EEE;
}

.dark .table > thead > tr > th,
.dark .table > tbody > tr > th,
.dark .table > tfoot > tr > th,
.dark .table > thead > tr > td,
.dark .table > tbody > tr > td,
.dark .table > tfoot > tr > td {
  border-top-color: rgba(255, 255, 255, 0.1);
}

.dark .table > thead > tr > th {
  border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark .table > tbody + tbody {
  border-top-color: rgba(255, 255, 255, 0.1);
}

.dark .table .table {
  background-color: rgba(0, 0, 0, 0.2);
}

.dark .table-bordered {
  border-color: rgba(255, 255, 255, 0.1);
}

.dark .table-bordered > thead > tr > th,
.dark .table-bordered > tbody > tr > th,
.dark .table-bordered > tfoot > tr > th,
.dark .table-bordered > thead > tr > td,
.dark .table-bordered > tbody > tr > td,
.dark .table-bordered > tfoot > tr > td {
  border-color: rgba(255, 255, 255, 0.1);
}

.dark .table-striped > tbody > tr:nth-child(odd) > td,
.dark .table-striped > tbody > tr:nth-child(odd) > th,
.table-striped > tbody > tr:nth-child(odd) {
  background-color: rgba(0, 0, 0, 0.05);
}

.dark .table-hover > tbody > tr:hover > td,
.dark .table-hover > tbody > tr:hover > th {
  background-color: rgba(0, 0, 0, 0.1);
}

.dark .table > thead > tr > td.active,
.dark .table > tbody > tr > td.active,
.dark .table > tfoot > tr > td.active,
.dark .table > thead > tr > th.active,
.dark .table > tbody > tr > th.active,
.dark .table > tfoot > tr > th.active,
.dark .table > thead > tr.active > td,
.dark .table > tbody > tr.active > td,
.dark .table > tfoot > tr.active > td,
.dark .table > thead > tr.active > th,
.dark .table > tbody > tr.active > th,
.dark .table > tfoot > tr.active > th {
  background-color: rgba(0, 0, 0, 0.1);
}

.dark .table-hover > tbody > tr > td.active:hover,
.dark .table-hover > tbody > tr > th.active:hover,
.dark .table-hover > tbody > tr.active:hover > td,
.dark .table-hover > tbody > tr.active:hover > th {
  background-color: rgba(255, 255, 255, 0.1);
}

@media (max-width: 575.98px) {
  .table-responsive {
    border-color: rgba(255, 255, 255, 0.1);
  }
}
.dark legend {
  color: #cccccc;
  border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark output {
  color: #999999;
}

.dark .form-control:not(.not-dark),
.dark .sm-form-control:not(.not-dark),
.dark .form-select:not(.not-dark) {
  color: #999999;
  background-color: rgba(0, 0, 0, 0.2);
  border-color: rgba(255, 255, 255, 0.15);
}

.dark .form-select:not(.not-dark) {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23AAAAAA' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
}

.dark .form-select:not(.not-dark) option,
.dark .sm-form-select:not(.not-dark) option {
  background-color: #282828;
}

.dark .form-control:not(.not-dark):active,
.dark .form-control:not(.not-dark):focus,
.dark .sm-form-control:not(.not-dark):active,
.dark .sm-form-control:not(.not-dark):focus,
.dark .form-select:not(.not-dark):active,
.dark .form-select:not(.not-dark):focus {
  background-color: rgba(0, 0, 0, 0.3);
  border-color: rgba(255, 255, 255, 0.25) !important;
}

.dark .form-control:not(.not-dark)::-moz-placeholder {
  color: #666666;
}

.dark .form-control:not(.not-dark):-ms-input-placeholder {
  color: #666666;
}

.dark .form-control:not(.not-dark)::-webkit-input-placeholder {
  color: #666666;
}

.dark .sm-form-control:not(.not-dark)::-moz-placeholder {
  color: #666666;
}

.dark .sm-form-control:not(.not-dark):-ms-input-placeholder {
  color: #666666;
}

.dark .sm-form-control:not(.not-dark)::-webkit-input-placeholder {
  color: #666666;
}

.dark .form-control:not(.not-dark)[disabled],
.dark .form-control:not(.not-dark)[readonly],
.dark fieldset[disabled] .form-control:not(.not-dark),
.dark .form-select:not(.not-dark)[disabled],
.dark .form-select:not(.not-dark)[readonly],
.dark fieldset[disabled] .form-select:not(.not-dark),
.dark .sm-form-control:not(.not-dark)[disabled],
.dark .sm-form-control:not(.not-dark)[readonly],
.dark fieldset[disabled] .sm-form-control:not(.not-dark) {
  background-color: rgba(255, 255, 255, 0.1);
}

.dark .input-group .form-control.error:not(.not-dark) {
  color: #E42C3E;
}

.dark .sm-form-control.error:not(.not-dark) {
  border-color: #E42C3E !important;
}

.dark .form-control.error:not(.not-dark)::-moz-placeholder {
  color: rgba(228, 44, 62, 0.6);
}

.dark .form-control.error:not(.not-dark):-ms-input-placeholder {
  color: rgba(228, 44, 62, 0.6);
}

.dark .form-control.error:not(.not-dark)::-webkit-input-placeholder {
  color: rgba(228, 44, 62, 0.6);
}

.dark .form-select.error:not(.not-dark)::-moz-placeholder {
  color: rgba(228, 44, 62, 0.6);
}

.dark .form-select.error:not(.not-dark):-ms-input-placeholder {
  color: rgba(228, 44, 62, 0.6);
}

.dark .form-select.error:not(.not-dark)::-webkit-input-placeholder {
  color: rgba(228, 44, 62, 0.6);
}

.dark .dropdown-menu {
  background-color: #333333;
  border-color: #313131;
}

.dark .dropdown-menu .dropdown-divider {
  border-top-color: rgba(255, 255, 255, 0.1);
}

.dark .dropdown-item {
  color: #cccccc;
}

.dark .dropdown-item:hover,
.dark .dropdown-item:focus {
  color: #EEE;
  background-color: rgba(255, 255, 255, 0.1);
}

.dark .input-group-text {
  color: #999999;
  background-color: rgba(0, 0, 0, 0.25);
  border-color: rgba(255, 255, 255, 0.15);
}

.dark .nav > li > a:hover,
.dark .nav > li > a:focus,
.dark .nav .open > a,
.dark .nav .open > a:hover,
.dark .nav .open > a:focus {
  background-color: rgba(0, 0, 0, 0.2);
}

.dark .nav .nav-divider {
  background-color: rgba(255, 255, 255, 0.1);
}

.dark .nav-tabs {
  border-bottom-color: rgba(255, 255, 255, 0.15);
}

.dark .nav-tabs > li > a:hover {
  border-color: rgba(255, 255, 255, 0.2);
  border-bottom: none;
}

.dark .nav-tabs.nav-justified > li > a:hover,
.dark .nav-tabs-justified > li > a:hover {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.dark .nav-tabs > li.active > a,
.dark .nav-tabs > li.active > a:hover,
.dark .nav-tabs > li.active > a:focus {
  color: #cccccc;
  background-color: #383838;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-bottom-color: #383838;
}

.dark .nav-tabs.nav-justified > .active > a,
.dark .nav-tabs.nav-justified > .active > a:hover,
.dark .nav-tabs.nav-justified > .active > a:focus {
  border-color: rgba(255, 255, 255, 0.15);
}

@media (min-width: 768px) {
  .dark .nav-tabs.nav-justified > li > a {
    border-bottom-color: rgba(255, 255, 255, 0.15);
  }

  .dark .nav-tabs.nav-justified > .active > a,
.dark .nav-tabs.nav-justified > .active > a:hover,
.dark .nav-tabs.nav-justified > .active > a:focus {
    border-bottom-color: #383838;
  }
}
.dark .nav-tabs-justified > .active > a,
.dark .nav-tabs-justified > .active > a:hover,
.dark .nav-tabs-justified > .active > a:focus {
  border-color: rgba(255, 255, 255, 0.15);
}

@media (min-width: 768px) {
  .dark .nav-tabs-justified > li > a {
    border-bottom-color: rgba(255, 255, 255, 0.15);
  }

  .dark .nav-tabs-justified > .active > a,
.dark .nav-tabs-justified > .active > a:hover,
.dark .nav-tabs-justified > .active > a:focus {
    border-bottom-color: #383838;
  }
}
.dark .navbar-default {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1);
}

.dark .navbar-default .navbar-brand {
  color: #EEE;
}

.dark .navbar-default .navbar-brand:hover,
.dark .navbar-default .navbar-brand:focus {
  color: #bbbbbb;
}

.dark .navbar-default .navbar-text {
  color: #999999;
}

.dark .navbar-default .navbar-nav > li > a {
  color: #bbbbbb;
}

.dark .navbar-default .navbar-nav > li > a:hover,
.dark .navbar-default .navbar-nav > li > a:focus {
  color: #EEE;
  background-color: transparent;
}

.dark .navbar-default .navbar-nav > .active > a,
.dark .navbar-default .navbar-nav > .active > a:hover,
.dark .navbar-default .navbar-nav > .active > a:focus {
  color: #EEE;
  background-color: rgba(0, 0, 0, 0.25);
}

.dark .navbar-default .navbar-nav > .disabled > a,
.dark .navbar-default .navbar-nav > .disabled > a:hover,
.dark .navbar-default .navbar-nav > .disabled > a:focus {
  color: #999999;
}

.dark .navbar-default .navbar-toggle {
  border-color: rgba(255, 255, 255, 0.15);
}

.dark .navbar-default .navbar-toggle:hover,
.dark .navbar-default .navbar-toggle:focus {
  background-color: rgba(255, 255, 255, 0.15);
}

.dark .navbar-default .navbar-toggle .icon-bar {
  background-color: #bbbbbb;
}

.dark .navbar-default .navbar-collapse,
.dark .navbar-default .navbar-form {
  border-color: rgba(255, 255, 255, 0.15);
}

.dark .navbar-default .navbar-nav > .open > a,
.dark .navbar-default .navbar-nav > .open > a:hover,
.dark .navbar-default .navbar-nav > .open > a:focus {
  background-color: rgba(0, 0, 0, 0.25);
  color: #EEE;
}

@media (max-width: 575.98px) {
  .dark .navbar-default .navbar-nav .open .dropdown-item {
    color: #bbbbbb;
  }

  .dark .navbar-default .navbar-nav .open .dropdown-item:hover,
.dark .navbar-default .navbar-nav .open .dropdown-item:focus {
    color: #EEE;
  }

  .dark .navbar-default .navbar-nav .open .dropdown-item.active,
.dark .navbar-default .navbar-nav .open .dropdown-item.active:hover,
.dark .navbar-default .navbar-nav .open .dropdown-item.active:focus {
    background-color: rgba(0, 0, 0, 0.25);
    color: #EEE;
  }

  .dark .navbar-default .navbar-nav .open .dropdown-item.disabled,
.dark .navbar-default .navbar-nav .open .dropdown-item.disabled:hover,
.dark .navbar-default .navbar-nav .open .dropdown-item.disabled:focus {
    color: #999999;
  }
}
.dark .navbar-default .navbar-link {
  color: #bbbbbb;
}

.dark .navbar-default .navbar-link:hover {
  color: #EEE;
}

.dark .page-link {
  color: #bbbbbb;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: #666666;
}

.dark .page-link:hover,
.dark .page-link:focus {
  color: #EEE;
  background-color: rgba(0, 0, 0, 0.1);
}

.dark .disabled .page-link,
.dark .disabled .page-link:hover,
.dark .disabled .page-link:focus {
  color: #999999;
  background-color: rgba(255, 255, 255, 0.2);
  border-color: #777;
}

.dark .label-default {
  background-color: rgba(255, 255, 255, 0.2);
}

.dark .label-default[href]:hover,
.dark .label-default[href]:focus {
  background-color: rgba(0, 0, 0, 0.2);
}

.dark .badge:not([class*=badge-]) {
  background-color: rgba(255, 255, 255, 0.2);
}

.dark .jumbotron {
  background-color: rgba(255, 255, 255, 0.15);
}

.dark .thumbnail {
  background-color: rgba(255, 255, 255, 0.15);
  border-color: rgba(255, 255, 255, 0.1);
}

.dark .thumbnail .caption {
  color: #EEE;
}

.dark .progress {
  background-color: rgba(255, 255, 255, 0.15);
}

.dark .list-group-item {
  background-color: rgba(0, 0, 0, 0.2);
  border-color: #2c2c2c;
}

.dark a.list-group-item {
  color: #bbbbbb;
}

.dark a.list-group-item .list-group-item-heading {
  color: #cccccc;
}

.dark a.list-group-item:hover,
.dark a.list-group-item:focus {
  background-color: rgba(255, 255, 255, 0.05);
}

.dark a.list-group-item.active,
.dark a.list-group-item.active:hover,
.dark a.list-group-item.active:focus {
  color: #ffffff;
  background-color: #428bca;
  border-color: #428bca;
}

.dark .list-group-item-success {
  color: #3c763d;
  background-color: #dff0d8;
}

.dark a.list-group-item-success {
  color: #3c763d;
}

.dark a.list-group-item-success:hover,
.dark a.list-group-item-success:focus {
  color: #3c763d;
  background-color: #d0e9c6;
}

.dark a.list-group-item-success.active,
.dark a.list-group-item-success.active:hover,
.dark a.list-group-item-success.active:focus {
  color: #fff;
  background-color: #3c763d;
  border-color: #3c763d;
}

.dark .list-group-item-info {
  color: #31708f;
  background-color: #d9edf7;
}

.dark a.list-group-item-info {
  color: #31708f;
}

.dark a.list-group-item-info:hover,
.dark a.list-group-item-info:focus {
  color: #31708f;
  background-color: #c4e3f3;
}

.dark a.list-group-item-info.active,
.dark a.list-group-item-info.active:hover,
.dark a.list-group-item-info.active:focus {
  color: #fff;
  background-color: #31708f;
  border-color: #31708f;
}

.dark .list-group-item-warning {
  color: #8a6d3b;
  background-color: #fcf8e3;
}

.dark a.list-group-item-warning {
  color: #8a6d3b;
}

.dark a.list-group-item-warning:hover,
.dark a.list-group-item-warning:focus {
  color: #8a6d3b;
  background-color: #faf2cc;
}

.dark a.list-group-item-warning.active,
.dark a.list-group-item-warning.active:hover,
.dark a.list-group-item-warning.active:focus {
  color: #fff;
  background-color: #8a6d3b;
  border-color: #8a6d3b;
}

.dark .list-group-item-danger {
  color: #a94442;
  background-color: #f2dede;
}

.dark a.list-group-item-danger {
  color: #a94442;
}

.dark a.list-group-item-danger:hover,
.dark a.list-group-item-danger:focus {
  color: #a94442;
  background-color: #ebcccc;
}

.dark a.list-group-item-danger.active,
.dark a.list-group-item-danger.active:hover,
.dark a.list-group-item-danger.active:focus {
  color: #fff;
  background-color: #a94442;
  border-color: #a94442;
}

.dark .card {
  background-color: rgba(0, 0, 0, 0.2);
}

.dark .card-footer {
  background-color: rgba(255, 255, 255, 0.1);
  border-top-color: rgba(255, 255, 255, 0.1);
}

.dark .card > .card-body + .table,
.dark .card > .card-body + .table-responsive {
  border-top-color: rgba(255, 255, 255, 0.1);
}

.dark .card-group .card-heading + .collapse .card-body {
  border-top-color: rgba(255, 255, 255, 0.1);
}

.dark .card-group .card-footer + .collapse .card-body {
  border-bottom-color: rgba(255, 255, 255, 0.1);
}

.dark .card-default {
  border-color: rgba(255, 255, 255, 0.15);
}

.dark .card-default > .card-heading {
  color: #cccccc;
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1);
}

.dark .card-default > .card-heading + .collapse .card-body {
  border-top-color: rgba(255, 255, 255, 0.15);
}

.dark .card-default > .card-footer + .collapse .card-body {
  border-bottom-color: rgba(255, 255, 255, 0.15);
}

.dark .btn-close {
  opacity: 1;
  color: rgba(0, 0, 0, 0.15);
  text-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
}

.dark .btn-close:hover,
.dark .btn-close:focus {
  color: rgba(0, 0, 0, 0.4);
}

.dark .modal-content {
  border-color: #555555;
}

.dark .modal-header {
  border-bottom-color: rgba(255, 255, 255, 0.15);
}

.dark .modal-footer {
  border-top-color: rgba(255, 255, 255, 0.15);
}

.dark .popover {
  background-color: #282828;
  border-color: #494949;
}

.dark .popover-title {
  background-color: rgba(255, 255, 255, 0.1);
  border-bottom-color: rgba(255, 255, 255, 0.075);
}

.dark .popover.top > .arrow {
  border-top-color: #494949;
}

.dark .popover.top > .arrow:after {
  border-top-color: #282828;
}

.dark .popover.right > .arrow {
  border-right-color: #494949;
}

.dark .popover.right > .arrow:after {
  border-right-color: #282828;
}

.dark .popover.bottom > .arrow {
  border-bottom-color: #494949;
}

.dark .popover.bottom > .arrow:after {
  border-bottom-color: #282828;
}

.dark .popover.left > .arrow {
  border-left-color: #494949;
}

.dark .popover.left > .arrow:after {
  border-left-color: #282828;
}